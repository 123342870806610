import * as PropTypes from "prop-types";
import React from "react";
import {CircularProgress, Dialog, FormControl, FormControlLabel, InputLabel, MenuItem, PrimaryButton, Select,
  Switch, TextField} from "../../common/components";
import moment from "moment";
import {If} from "../../common/containers";

import "./PubDocEditDialog.css";

class PubDocEditDialog extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    custId: PropTypes.string,
    typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypes: PropTypes.array,
    categories: PropTypes.array,
    documents: PropTypes.array,
    documentEditOpen: PropTypes.bool.isRequired,
    documentCategoryEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    documentEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addDocument: PropTypes.func,
    editDocument: PropTypes.func,
    pubDocsFileTypes: PropTypes.string,
    date: PropTypes.string,
    documentEditLoading: PropTypes.bool.isRequired,
    hasBulkUploadPermission: PropTypes.bool.isRequired,
    editDocumentLinked: PropTypes.func,
    addDocumentLinked: PropTypes.func,
    setBulkUploaderOpen: PropTypes.func.isRequired,
    setPubDocTypeId: PropTypes.func.isRequired,
    typeSelectDisabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const {typeId, categories} = this.props;
    this.state = {
      name: "",
      pubDocTypeId: typeId ? typeId : null,
      categoryId: categories.length > 0 ? categories[0].id : "Uncategorized",
      fileTitle: null,
      fileExtension: null,
      file: null,
      description: null,
      date: null,
      createdBy: null,
      createdDate: null,
      updatedDate: null,
      isPrivate: false,
      documentType: null,
      url: "",
      duplicateErrorOpen: false,
      fileSizeErrorOpen: false,
      typeErrorOpen: false,
      bulkUploaderOpen: false
    };
  }

  handleTypeChange = (event) => {
    const typeId = event.target.value;
    this.setState({pubDocTypeId: typeId});
    this.props.setPubDocTypeId(typeId);
  };

  handleCategoryChange = (event) => {
    this.setState({categoryId: event.target.value});
  };

  handleNameChange = (event) => {
    this.setState({name: event.target.value});
  };

  handleFileChange = (event) => {
    const {name} = this.state;
    const file = (event.target.files && event.target.files.length > 0) ? event.target.files[0] : null;
    const filename = file ? file.name.substring(0, file.name.lastIndexOf('.')) : null;
    this.setState({
      fileTitle: file ? file.name.substring(0, file.name.lastIndexOf('.')) : null,
      fileExtension: file ? file.name.split('.').pop() : null,
      file: file,
      name: name ? name : filename
    });
  };

  handleDateChange = (e) => {
    this.setState({date: e.target.value});
  };

  handleDescriptionChange = (event) => {
    this.setState({description: event.target.value});
  };

  handleCancelClick = () => {
    let {typeId, categories, onCancel} = this.props;
    this.setState({
      name: "",
      pubDocTypeId: typeId ? typeId : null,
      categoryId: categories.length > 0 ? categories[0].id : "Uncategorized",
      fileTitle: null,
      fileExtension: null,
      file: null,
      description: null,
      date: null,
      createdBy: null,
      createdDate: null,
      updatedDate: null,
      isPrivate: false,
      documentType: "DOCUMENT",
      url: ""
    });
    onCancel();
  };

  handleSaveClick = () => {
    const {custId, documentEditId, editDocument, addDocument, editDocumentLinked, addDocumentLinked, documents} = this.props;
    const {pubDocTypeId, categoryId, name, description, file, date, isPrivate, documentType, url} = this.state;
    const dateString = date ? moment(date).format("YYYY-MM-DD") : null;
    if (pubDocTypeId == null) {
      this.setState({duplicateErrorOpen: true});
      return;
    }
    if (documents.filter(currDoc => {return (currDoc.title === name && currDoc.date === dateString && currDoc.categoryId === categoryId && currDoc.id !== documentEditId);}).length > 0) {
      this.setState({duplicateErrorOpen: true});
      return;
    }
    if ((documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE') && file) {
      if (file.size > 100000000) {
        this.setState({fileSizeErrorOpen: true});
        return;
      }
    }
    if (name.length > 0) {
      if (documentEditId) {
        if (documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE') {
          editDocument(custId, documentEditId, categoryId, pubDocTypeId, name, description, dateString, file, isPrivate, documentType);
        } else {
          editDocumentLinked(custId, documentEditId, categoryId, pubDocTypeId, name, description, dateString, isPrivate, documentType, url);
        }
      } else {
        if (documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE') {
          addDocument(custId, categoryId, pubDocTypeId, name, description, dateString, file, isPrivate, documentType);
        } else {
          addDocumentLinked(custId, categoryId, pubDocTypeId, name, description, dateString, isPrivate, documentType, url);
        }
      }
    }
  };

  handleChangePrivate = (event) => {
    this.setState({isPrivate: event.target.checked});
  };

  handleDocumentTypeChange = (event) => {
    this.setState({
      fileTitle: null,
      fileExtension: null,
      file: null,
      url: "",
      validUrl: false,
      documentType: event.target.value
    });
  };

  handleUrlChange = (event) => {
    const regex = new RegExp("(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/=]*)");
    this.setState({
      url: event.target.value,
      validUrl: regex.test(event.target.value)
    });
  };

  handleCloseDuplicateError = () => {
    this.setState({duplicateErrorOpen: false});
  };

  handleCloseFileSizeError = () => {
    this.setState({fileSizeErrorOpen: false});
  };

  handleCloseTypeError = () => {
    this.setState({typeErrorOpen: false});
  };

  openBulkUploaderDialog = () => {
    const { setBulkUploaderOpen } = this.props;
    this.handleCancelClick();
    setBulkUploaderOpen();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.documentEditOpen;
    const {typeId, categories, documentEditOpen, documentEditId, documents, documentCategoryEditId} = this.props;
    const regex = new RegExp("(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/=]*)");
    if (documentEditOpen !== prevEditOpen) {
      const pubDoc = documents.filter(document => {
        return document.id === documentEditId;
      })[0];
      if (pubDoc) {
        this.setState({
          name: pubDoc.title ? pubDoc.title : "",
          pubDocTypeId: typeId ? typeId : null,
          categoryId: pubDoc.categoryId ? pubDoc.categoryId : (categories.length > 0 ? categories[0].id : "Uncategorized"),
          file: null,
          fileTitle: pubDoc.title ? pubDoc.title : null,
          fileExtension: pubDoc.extension ? pubDoc.extension : null,
          description: pubDoc.description ? pubDoc.description : null,
          date: (!pubDoc.date ? null : ((typeof pubDoc.date === "string" || pubDoc.date instanceof String)
            ? (new Date(pubDoc.date + " 00:00")) : pubDoc.date)),
          createdBy: pubDoc.createdBy ? pubDoc.createdBy : null,
          createdDate: pubDoc.created ? pubDoc.created.substr(0, 10) : null,
          updatedDate: pubDoc.updated ? pubDoc.updated.substr(0, 10) : null,
          isPrivate: pubDoc.private ? pubDoc.private : false,
          documentType: pubDoc.type,
          url: pubDoc.url ? pubDoc.url : "",
          validUrl: pubDoc.url ? regex.test(pubDoc.url) : false
        });
      } else {
        let uncategorizedId = null;
        if (categories.length > 0) {
          let listOfUncategorized = categories.filter(currCat => {return (currCat.title === "(Uncategorized)");});
          if (listOfUncategorized.length > 0) {
            uncategorizedId = listOfUncategorized[0].id;
          }
        }

        this.setState({
          name: "",
          pubDocTypeId: typeId ? typeId : null,
          categoryId: documentCategoryEditId ? documentCategoryEditId : uncategorizedId,
          file: null,
          fileTitle: null,
          fileExtension: null,
          description: null,
          date: null,
          createdBy: null,
          createdDate: null,
          updatedDate: null,
          isPrivate: false,
          documentType: 'DOCUMENT',
          url: ""
        });
      }
      const name = this.state.name;
      if (documentEditId) {
        this.setState({"title": "Edit a Public Document " + name});
      } else {
        this.setState({"title": "Add a new Public Document " + name});
      }
    }
  }

  render() {
    const { documentEditOpen, pubDocTypeTitle, pubDocTypes, categories, pubDocsFileTypes, documentEditLoading, documentEditId,
      hasBulkUploadPermission, typeSelectDisabled } = this.props;
    const { title, name, pubDocTypeId, categoryId, fileTitle, fileExtension, description, createdBy, createdDate,
      updatedDate, isPrivate, documentType, url, validUrl, duplicateErrorOpen, fileSizeErrorOpen, typeErrorOpen } = this.state;
    const currentDate = new Date();
    const dateString = !this.state.date ? "" : ((typeof this.state.date === "string" || this.state.date instanceof String)
      ? this.state.date : moment(this.state.date).format("YYYY-MM-DD"));
    const date = !this.state.date ? null : ((typeof this.state.date === "string" || this.state.date instanceof String)
      ? new Date(dateString) : this.state.date);
    return (
      <Dialog
        id="doc-edit-dialog"
        title={
          <span className="pubDocEditDialogTitle">
            <span>{title}</span>
            <If test={!documentEditId && hasBulkUploadPermission}>
              <PrimaryButton
                variant="contained"
                className={"pubDocToolbarButton pubDocBulkUploaderButton"}
                onClick={this.openBulkUploaderDialog}
              >
                Bulk Uploader
              </PrimaryButton>
            </If>
          </span>
        }
        open={documentEditOpen}
        onCancel={this.handleCancelClick}
        onSubmit={this.handleSaveClick}
        submitLabel="Save"
        submitDisabled={documentEditLoading || name.length < 1 || (fileTitle === null && !validUrl) || pubDocTypeId == null}
        width="md"
        DialogContentProps={{className:`dialogContent pubDocEditDialogContent ${!documentEditLoading ? "" : "loading"}`}}
      >
        <If test={documentEditLoading}>
          <div className="spinner">
            <CircularProgress size={80}/>
          </div>
        </If>
        <If test={!documentEditLoading}>
          <If test={typeSelectDisabled}>
            <div className="dialogRow">
              <div>
                <label
                  id="edit-doc-type-label"
                  htmlFor="edit-doc-type"
                  className={"pubDocEditDialogTypeLabel"}
                >
                  Type:
                </label>
                <span id="edit-doc-type">{pubDocTypeTitle}</span>
              </div>
            </div>
          </If>
          <If test={!typeSelectDisabled}>
            <div className="dialogRow">
              <FormControl variant="outlined" className="outlined-control pubDocEditDialogTypeSelect">
                <InputLabel id="edit-doc-type-label" htmlFor="edit-doc-type" className="outlined-control-label">
                  Type
                </InputLabel>
                <Select
                  label="Type"
                  value={pubDocTypeId ? pubDocTypeId : 0}
                  onChange={this.handleTypeChange}
                  inputProps={{
                    id: "edit-doc-type",
                    name: "edit-doc-type",
                    className: "outlined-control-input"
                  }}
                  SelectDisplayProps={{
                    id: "edit-doc-type-select",
                    name: "edit-doc-type-select",
                    className: "outlined-control-select"
                  }}
                  variant="outlined"
                >
                  {pubDocTypes && pubDocTypes.map((type) => (<MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
          </If>
          <div className="dialogRow">
            <FormControl variant="outlined" className="outlined-control pubDocEditDialogCategorySelect">
              <InputLabel id="edit-doc-category-label" htmlFor="edit-doc-category" className="outlined-control-label">
                Category
              </InputLabel>
              <Select
                label="Category"
                value={categoryId == null ? (categories.filter(cat => {
                  return cat.title === "(Uncategorized)";
                }).length === 1 ? categories.filter(cat => {
                  return cat.title === "(Uncategorized)";
                })[0].id : "Uncategorized") : categoryId}
                onChange={this.handleCategoryChange}
                inputProps={{
                  id: "edit-doc-category",
                  name: "edit-doc-category",
                  className: "outlined-control-input"
                }}
                SelectDisplayProps={{
                  id: "edit-doc-category-select",
                  name: "edit-doc-category-select",
                  className: "outlined-control-select"
                }}
                variant="outlined"
              >
                {(categories.filter(currCat => {
                  return currCat.title === "(Uncategorized)";
                }).length === 0) && (<MenuItem value="Uncategorized">(Uncategorized)</MenuItem>)}
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>))}
              </Select>
            </FormControl>
          </div>
          <div className="dialogRow">
            <FormControl variant="outlined" className="outlined-control pubDocEditDialogTypeSelect">
              <InputLabel className="outlined-control-label">Media Source</InputLabel>
              <Select
                value={documentType}
                onChange={this.handleDocumentTypeChange}
                inputProps={{
                  className: "outlined-control-input"
                }}
                SelectDisplayProps={{
                  className: "outlined-control-select"
                }}
              >
                <MenuItem value={'DOCUMENT'}>Document</MenuItem>
                <MenuItem value={'AUDIO_FILE'}>Audio File</MenuItem>
                <MenuItem value={'AUDIO_LINK'}>Audio Link</MenuItem>
                <MenuItem value={'VIDEO_LINK'}>Video Link</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="dialogRow">
            <If test={documentType === 'DOCUMENT' || documentType === 'AUDIO_FILE'}>
              <div className="fileContainer">
                <input
                  id="edit-doc-file"
                  className="pubDocFile pubDocEditDialogFileSelect"
                  name="pubDocFile"
                  type="file"
                  onChange={this.handleFileChange}
                  accept={documentType === 'AUDIO_FILE' ? "audio/*" : (!pubDocsFileTypes ? "*" : pubDocsFileTypes)}
                />
                <label htmlFor="edit-doc-file">
                  <PrimaryButton variant="contained" component="span">Upload</PrimaryButton>
                </label>
                <label htmlFor="edit-doc-file"
                       className="fileInputLabel">{fileTitle && fileExtension ? fileTitle + "." + fileExtension : "No file uploaded"}</label>
              </div>
            </If>
            <If test={documentType === 'AUDIO_LINK' || documentType === 'VIDEO_LINK'}>
              <TextField label="URL" variant="outlined" onChange={this.handleUrlChange} value={url} fullWidth/>
              <If test={!validUrl}>
                <span className={"pubDocEditDialogErrorText"}>*Please enter a valid url</span>
              </If>
            </If>
          </div>
          <div className="dialogRow">
            <TextField
              id="edit-doc-name"
              className="docName pubDocEditDialogNameField"
              label="Name"
              variant="outlined"
              value={!name ? "" : name}
              onChange={this.handleNameChange}
            />
          </div>
          <div className="dialogRow">
            <TextField
              id="edit-doc-description"
              className="editDocDescriptions pubDocEditDialogDescriptionField"
              label="Description"
              variant="outlined"
              rows={5}
              value={!description ? "" : description}
              onChange={this.handleDescriptionChange}
              placeholder={"This text is shown to the user when looking at details for the document. It is also used for finding the document when searching."}
              multiline
            />
          </div>
          <div className="dialogRow outlined-date-picker">
            <TextField
              type="date"
              label="Date"
              id="edit-doc-date"
              className={"pubDocEditDialogDateField"}
              value={dateString}
              onChange={this.handleDateChange}
              InputProps={{
                inputProps: {
                  min: "1500-01-01",
                  max: moment(new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate())).format("YYYY-MM-DD")
                }
              }}
              InputLabelProps={{shrink: true}}
            />
          </div>
          <If test={createdBy}>
            <div className="dialogRow">
              <label
                id="edit-doc-created-by-label"
                htmlFor="edit-doc-created-by"
                className={"pubDocEditDialogMetadataLabel"}
              >
                Created By:
              </label>
              <span id="edit-doc-created-by">{createdBy}</span>
            </div>
          </If>
          <If test={createdDate}>
            <div className="dialogRow">
              <label
                id="edit-doc-created-label"
                htmlFor="edit-doc-created"
                className={"pubDocEditDialogMetadataLabel"}
              >
                Created:
              </label>
              <span id="edit-doc-created">{createdDate}</span>
            </div>
          </If>
          <If test={updatedDate}>
            <div className="dialogRow">
              <label
                id="edit-doc-updated-label"
                htmlFor="edit-doc-updated"
                className={"pubDocEditDialogMetadataLabel"}
              >
                Updated:
              </label>
              <span id="edit-doc-updated">{updatedDate}</span>
            </div>
          </If>
          <FormControlLabel
            label="Private?"
            control={<Switch checked={isPrivate} onChange={this.handleChangePrivate}/>}
          />
        </If>
        <Dialog
          title="Could not save public document"
          open={duplicateErrorOpen}
          onClose={this.handleCloseDuplicateError}
        >
          There already exists a public document with the name: {name} and
          date: {date ? moment(date).format("M/D/YYYY") : "No Date"} in the selected category
        </Dialog>
        <Dialog
          title="Failed to upload file"
          open={fileSizeErrorOpen}
          onClose={this.handleCloseFileSizeError}
        >
          File size must be less than 100mb
        </Dialog>
        <Dialog
          title="A Type is required"
          open={typeErrorOpen}
          onClose={this.handleCloseTypeError}
        >
          You must specify a Type for the document
        </Dialog>
      </Dialog>
    );
  }
}

export default PubDocEditDialog;