import React from 'react';
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '../../common/components';
import {AddAlert, ArrowBack, ArrowForward, Download, Email, Print, Share} from "../../common/components/icons";
import './ToolSpeedDial.css';
import {getMediaHeight, getMediaWidth} from "../../common/selectors";
import {connect} from "react-redux";
import {mediaHeights, mediaWidths} from "../../common/reducers";

window.generalcode = window.generalcode || {};

const ToolSpeedDial = ({mediaWidth, mediaHeight}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleToggle = () => setOpen(!open);

  if (mediaWidth > mediaWidths.medium) {
    return <></>;
  }

  // noinspection JSUnresolvedReference
  const arrowBack = <SpeedDialAction
    icon={<ArrowBack size={28}/>} tooltipTitle="Previous part of the code"
    onClick={() => {
      const prevToolbar = document.getElementById("gpsPrevToolbar");
      if (prevToolbar) {
        window.location.href = prevToolbar.href
      }
    }
    }/>;
  // noinspection JSUnresolvedReference
  const print = <SpeedDialAction
    icon={<Print size={28}/>} tooltipTitle="Print"
    onClick={() => window.generalcode && window.generalcode.getPrintUrl && window.open(window.generalcode.getPrintUrl())}/>;
  // noinspection JSUnresolvedReference
  const email = <SpeedDialAction
    icon={<Email size={28}/>} tooltipTitle="Email"
    onClick={() => window.generalcode && window.generalcode.getEmailUrl && window.open(window.generalcode.getEmailUrl())}/>;
  // noinspection JSUnresolvedReference
  const download = <SpeedDialAction
    icon={<Download size={28}/>} tooltipTitle="Download"
    onClick={(e) => window.generalcode && window.generalcode.doDownload && window.generalcode.doDownload(e)}/>;
  // noinspection JSUnresolvedReference
  const share = <SpeedDialAction
    icon={<Share size={28}/>} tooltipTitle="Share"
    onClick={(e) => window.generalcode && window.generalcode.shareButtonClick && window.generalcode.shareButtonClick(e)}/>;
  // noinspection JSUnresolvedReference
  const addAlert = <SpeedDialAction
    icon={<AddAlert size={28}/>} tooltipTitle="Add alert"
    onClick={(e) => window.generalcode && window.generalcode.alertButtonClick && window.generalcode.alertButtonClick(e)}/>;

  const arrowNext = <SpeedDialAction
    icon={<ArrowForward size={28}/>} tooltipTitle="Next part of the code"
    onClick={() => {
      const nextToolbar = document.getElementById("gpsNextToolbar");
      if (nextToolbar) {
        window.location.href = nextToolbar.href
      }
    }
    }/>;

  return (
    <SpeedDial
      id="toolSpeedDial"
      ariaLabel="Toolbar"
      icon={<SpeedDialIcon/>}
      open={open}
      direction={mediaHeight < mediaHeights.large ? "left" : "up"}
      onBlur={handleClose}
      onClose={handleClose}
      onFocus={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onClick={handleToggle}
  >
      {arrowNext}
      {addAlert}
      {share}
      {download}
      {email}
      {print}
      {arrowBack}
  </SpeedDial>
  );
};

const mapStateToProps = (state) => ({
  mediaWidth: getMediaWidth(state),
  mediaHeight: getMediaHeight(state),
});

export default connect(mapStateToProps)(ToolSpeedDial);
