/*
 * Copyright 2018 General Code
 */
import * as PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {Button, PrimaryButton} from "../../common/components";
import ColorPickerContainer from "../containers/ColorPickerContainer";
import DisplayModePicker from "../containers/DisplayModePickerContainer";
import VisibilityPickerContainer from "../containers/VisibilityPickerContainer";
import "./EditControls.css";

class EditControls extends PureComponent {

  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.any.isRequired,
    editNoteCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func
  };

  onCancel = (e) => {
    if (this.props.onCancel) {
      this.props.onCancel(e);
    }
    this.props.editNoteCancel();
  };

  render() {
    const {id, onSave} = this.props;
    return (
      <div className="noteEditControls">
        <ColorPickerContainer id={id} className="noteEditControl noteEditControls-colorPicker"/>
        <DisplayModePicker id={id} className="noteEditControl noteEditControls-displayModePicker"/>
        <VisibilityPickerContainer id={id} className="noteEditControl noteEditControls-visibilityPicker"/>
        <Button className="noteEditControls-cancel" onClick={this.onCancel}>Cancel</Button>
        <PrimaryButton className="noteEditControls-save" onClick={onSave}>Save</PrimaryButton>
      </div>
    );
  }
}
export default EditControls;