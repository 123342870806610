import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {
  hasIa2CompletePermission,
  hasIa2FinalizePermission,
  hasIa2PrintPermission,
  hasIa2StatusReportPermission
} from "../../common/selectors";

import {toJS} from "../../common/utils";
import {getAnalysis, getDisplayedQuestionCount, getDisplayedQuestionIds, getQuestions} from "../selectors";
import QuestionStatusPage from "../components/QuestionStatusPage";

const mapStateToProps = createStructuredSelector({
  analysis: getAnalysis,
  questions: getQuestions,
  displayedQuestionCount: getDisplayedQuestionCount,
  displayedQuestionIds: getDisplayedQuestionIds,
  hasFinalizePermission: hasIa2FinalizePermission,
  hasCompletePermission: hasIa2CompletePermission,
  hasPrintPermission: hasIa2PrintPermission,
  hasStatusReportPermission: hasIa2StatusReportPermission
});

const mapDispatchToProps = (dispatch) => ({});

const QuestionStatusPageContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionStatusPage);
QuestionStatusPageContainer.propTypes = {
  analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default QuestionStatusPageContainer;