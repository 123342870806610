/*
 * Copyright 2019 General Code
 */

import {Record} from 'immutable';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';


const Settings = Record({
  displayDate: "",
  isDisplayDateAdmin: false,
  mapUrl: "",
  oldDisplayDate: "",
  oldMapUrl: "",
  zoningStructure: null,
  zoningStructureLoading: false,
  nameOverride: "",
  textQuickLinks: null,
  contentGraphicalQuickLinks: null
});

const TextQuickLink = Record({
  id: "",
  title: "",
  url: "",
  textualQuickLinkType: ""
});

const ContentGraphicalQuickLink = Record({
  id: "",
  guid: "",
  label: "",
  enabled: false
});

const initialState = Settings({});

const setDisplayDate = (state, {payload: {displayDate}}) => state.set("displayDate", displayDate);
const displayDateUpdated = (state) => state.set("oldDisplayDate", state.displayDate);

const setMapUrl = (state, {payload: {mapUrl}}) => state.set("mapUrl", mapUrl);
const mapUrlUpdated = (state) => state.set("oldMapUrl", state.mapUrl);

const initSettings = (state, {payload}) => {
  return state.merge(payload);
}

const zoningStructureLoading = (state) => state.set("zoningStructureLoading", true);
const zoningStructureNotLoading = (state) => state.set("zoningStructureLoading", false);

const clearZoningStructure = (state, {payload: {structureTaxonomyList}}) =>
  structureTaxonomyList === null ? state : state.set("zoningStructure", null);

const setZoningStructure = (state, {payload: {structureTaxonomy}}) =>
  structureTaxonomy === null ? state : state.set("zoningStructure", {
    guid: structureTaxonomy.structureGuid,
    text: structureTaxonomy.structureTitle,
    url: "/" + structureTaxonomy.structureGuid
  });

const handleAddTextQuickLink = (state, {payload: {response}}) => {
  let textQuickLinks = state.textQuickLinks;
  textQuickLinks = textQuickLinks.push(TextQuickLink(response));
  return state.set("textQuickLinks", textQuickLinks);
};

const handleDeleteTextQuickLink = (state, {payload: {response}}) => {
  let textQuickLinks = state.textQuickLinks;
  textQuickLinks = textQuickLinks.filter((textQuickLink) => textQuickLink.id !== response.id);
  return state.set("textQuickLinks", textQuickLinks);
};

const handleEditTextQuickLink = (state, {payload: {response}}) => {
  let textQuickLinks = state.textQuickLinks;
  textQuickLinks = textQuickLinks.map(link => link.id === response.id ? TextQuickLink(response) : link);
  return state.set("textQuickLinks", textQuickLinks);
}

const reducer = handleActions({
  [actions.displayDateUpdated]: displayDateUpdated,
  [actions.initSettings]: initSettings,
  [actions.mapUrlUpdated]: mapUrlUpdated,
  [actions.setDisplayDate]: setDisplayDate,
  [actions.setMapUrl]: setMapUrl,
  [actions.clearZoningChapterStart]: zoningStructureLoading,
  [actions.clearZoningChapterSuccess]: clearZoningStructure,
  [actions.clearZoningChapterFinally]: zoningStructureNotLoading,
  [actions.setZoningChapterStart]: zoningStructureLoading,
  [actions.setZoningChapterSuccess]: setZoningStructure,
  [actions.setZoningChapterFinally]: zoningStructureNotLoading,
  [actions.addTextQuickLinkSuccess]: handleAddTextQuickLink,
  [actions.deleteTextQuickLinkSuccess]: handleDeleteTextQuickLink,
  [actions.editTextQuickLinkSuccess]: handleEditTextQuickLink
}, initialState);

export const State = Settings;
export {initialState, TextQuickLink, ContentGraphicalQuickLink};
export default reducer;
