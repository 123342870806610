import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import SearchResultsPageTitle from "../components/SearchResultsPageTitle";

import {getMaxPages, getResults} from "../selectors";

import {getAllResultsPage} from "../actions";
import {setPrintLoading} from '../../common/actions';

const mapStateToProps = createStructuredSelector({
  maxPages: getMaxPages,
  results: getResults
});

const mapDispatchToProps = (dispatch) => ({
  getAllPages: () => {
    dispatch(getAllResultsPage());
  },
  setPrintLoading: (isLoading) => {
    dispatch(setPrintLoading(isLoading));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SearchResultsPageTitle);