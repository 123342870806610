import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import SearchResultsPage from "../components/SearchResultsPage";

import {getSelectedCustIds, getSelectedCustomerFilters, getScope} from "../selectors";
import {getBodyTop} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  selectedCustIds: getSelectedCustIds,
  selectedCustomerFilters: getSelectedCustomerFilters,
  scope: getScope,
  bodyTop: getBodyTop
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SearchResultsPage);