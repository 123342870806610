/*
 * Copyright 2018 General Code
 */

import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import Metadata from '../components/Metadata';
import {getCreatedBy, getCreatedOn, getUpdatedBy, getUpdatedOn, isEditable} from "../selectors";


const mapStateToProps = createStructuredSelector({
  createdOn: getCreatedOn,
  createdBy: getCreatedBy,
  updatedBy: getUpdatedBy,
  updatedOn: getUpdatedOn,
  editable: isEditable,
});

const MetadataContainer = connect(mapStateToProps)(Metadata);

MetadataContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default MetadataContainer;

