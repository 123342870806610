import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {isSearchFieldActive, getSelectedCustomerFilters, getSelectedCustIds, getSuggestions} from "../selectors";
import {toJS} from "../../common/utils";
import AutoSuggestComponent from '../components/AutoSuggestComponent';
import {setSearchFieldActive} from "../actions";


const mapStateToProps = createStructuredSelector({
  isSearchFieldActive: isSearchFieldActive,
  suggestions: getSuggestions,
  selectedCustIds: getSelectedCustIds,
  selectedCustomerFilters: getSelectedCustomerFilters
});

const mapDispatchToProps = (dispatch) => ({
  setSearchFieldActive: (status) => {
    return dispatch(setSearchFieldActive(status));
  },
});


export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(AutoSuggestComponent);

