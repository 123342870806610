import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {MenuItem, PageControl, PageControlContent, PageControlHeader, Select} from "../../common/components";
import {defaultColorName} from "../../common/theme";
import "./NoteFilter.css";

class NoteFilter extends Component {

  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    noteField: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    options: PropTypes.array,
    removeNoteFilter: PropTypes.func,
    setNoteFilter: PropTypes.func
  };

  onChangeEvent = (event, noteField) => {
    const value = event.target.value;
    const newValue = noteField === "color" && value === defaultColorName ? "default" : value;
    if (value) {
      this.props.setNoteFilter(noteField, newValue);
    } else {
      this.props.removeNoteFilter(noteField);
    }
    if (window.generalcode?.refreshNoteLocations) {
      setTimeout(window.generalcode.refreshNoteLocations, 0);
    }
  }

  render() {
    const {noteField, placeholder, options, value} = this.props;
    const selectValue = value ? value : "";
    return (
      <PageControl>
        <PageControlHeader label={placeholder} htmlFor={`${noteField}FilterSelect`}/>
        <PageControlContent>
          <div className={`noteFilterSelectContainer ${noteField}FilterSelectContainer`}>
            <div className={"noteFilterSelect"}>
              <Select
                id={`${noteField}FilterSelect`}
                onChange={event => this.onChangeEvent(event, noteField)}
                value={selectValue}
                displayEmpty
                fullWidth
              >
                <MenuItem value={""}>(All)</MenuItem>
                {options.map(option => <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>)}
              </Select>
            </div>
          </div>
        </PageControlContent>
      </PageControl>
    );
  }

}

export default NoteFilter;

