import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {Dialog} from "../../common/components";

class PubDocsBulkDeleteDialog extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    selectedDocIds: PropTypes.array.isRequired,
    selectedDocs: PropTypes.array.isRequired,
    bulkDeleteOpen: PropTypes.bool.isRequired,
    closeBulkDeleteDialog: PropTypes.func.isRequired,
    bulkDeleteDocuments: PropTypes.func.isRequired
  }

  closeBulkDeleteDialog = () => {
    this.props.closeBulkDeleteDialog();
  }

  bulkDeleteDocuments = () => {
    this.closeBulkDeleteDialog();
    this.props.bulkDeleteDocuments(this.props.custId, this.props.selectedDocIds);
  }

  render() {
    const { selectedDocs, bulkDeleteOpen } = this.props;
    return (
      <Dialog
        title="Delete Public Document(s)?"
        open={bulkDeleteOpen}
        onCancel={this.closeBulkDeleteDialog}
        onSubmit={this.bulkDeleteDocuments}
      >
        <div className={"pubDocModalText"}>Are you sure you want to delete the following Public Documents(s)?</div>
        <ul className={"pubDocModalList"}>
          {selectedDocs.map((currDoc) => <li className={"pubDocModalListItem"} key={currDoc.id}>{currDoc.title}</li>)}
        </ul>
      </Dialog>
    );
  }
}
export default PubDocsBulkDeleteDialog;