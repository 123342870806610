import React from "react";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }
  componentDidCatch(error, info) {
    console.group("Error handled");
    console.error("Info: ");
    console.dir(info);
    console.error("Error:");
    console.dir(error);
    console.groupEnd();
    // Catch and display errors as a fallback
    this.props.registerError("An unknown error happened", null, [info], error);
  }

  render() {
    if(this.state.hasError) {
      return <div>Something went wrong</div>;
    }
    return this.props.children;
  }

}

export default ErrorBoundary;