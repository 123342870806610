/*
 * Copyright 2018 General Code
 */

import React from "react";
import * as PropTypes from "prop-types";
import {Code as CodeIcon} from "../../common/components/icons";
import If from "../../common/containers/If";

import "./Reference.css";

class ReferenceBreadcrumb extends React.Component {
  render() {
    const {structure} = this.props;
    if (!structure) return null;
    return (
      <span>
        <If test={!!structure.parent}>
          <ReferenceBreadcrumb structure={structure.parent}/>
          <span className="resultParentSeparator">/</span>
        </If>
        <If test={structure.content.type === "code"}>
          <a className="resultParentLink" href={`/${structure.content.guid}#${structure.content.guid}`} target="_blank"
             rel="noopener noreferrer">
            <div className="resultTypeIcon"><CodeIcon/></div>
            <span className="resultType customerTitleColor">Code</span>
          </a>
        </If>
        <If test={structure.content.type !== "code"}>
          <a href={"/" + structure.guid + "#" + structure.guid} className="resultParentLink" target="_blank" rel="noopener noreferrer">
            <If test={!structure.content.hideNumber && structure.content.indexNum}>{structure.content.indexNum}: </If>{structure.content.title}
          </a>
        </If>
      </span>
    );
  }
}
ReferenceBreadcrumb.propTypes = {
  structure: PropTypes.object
};
export default ReferenceBreadcrumb;