import {createAction} from "redux-actions";
import {registerError, showUserMessage} from "../../common/actions";
import {
  deleteFormData,
  deleteJson,
  getJson,
  getJsonNoCache,
  postFormData,
  postJson,
  putFormData,
  putJson
} from "../../common/utils";
import {getSelectedFilters} from "../selectors";
import {custId} from "../../common/utils/server-data";

const initializeState = createAction('PUBDOCS/STATE/INIT',(loadedState, initial) => ({loadedState, initial}));

const loadPubDocsStart = createAction('PUBDOCS/LOAD/START', (pubDocTypeId) => ({pubDocTypeId}));
const loadPubDocsSuccess = createAction('PUBDOCS/LOAD/SUCCESS', (response) => ({response}));
const loadPubDocsFail = createAction('PUBDOCS/LOAD/FAIL', (pubDocTypeId, error) => ({pubDocTypeId, error}));
const loadPubDocsFinally = createAction('PUBDOCS/LOAD/FINALLY', (pubDocTypeId) => ({pubDocTypeId}));
const loadPubDocs = (custId, pubDocTypeId) => async (dispatch) => {
  dispatch(loadPubDocsStart(pubDocTypeId));
  try {
    const response = await getJsonNoCache(`/api/${custId}/pub-doc/type/${pubDocTypeId}`);
    dispatch(loadPubDocsSuccess(response));
  } catch (error) {
    dispatch(registerError("Could not load document type", null, [pubDocTypeId], error));
    dispatch(loadPubDocsFail(pubDocTypeId, error));
  } finally {
    dispatch(loadPubDocsFinally(pubDocTypeId));
  }
};

const loadPubDocsCategoriesStart = createAction('PUBDOCS/CATEGORIES/LOAD/START', (custId, pubDocTypeId) => ({custId, pubDocTypeId}));
const loadPubDocsCategoriesSuccess = createAction('PUBDOCS/CATEGORIES/LOAD/SUCCESS', (pubDocTypeId, response) => ({pubDocTypeId, response}));
const loadPubDocsCategoriesFail = createAction('PUBDOCS/CATEGORIES/LOAD/FAIL', (custId, pubDocTypeId, error) => ({custId, pubDocTypeId, error}));
const loadPubDocsCategoriesFinally = createAction('PUBDOCS/CATEGORIES/LOAD/FINALLY', (custId, pubDocTypeId) => ({custId, pubDocTypeId}));
const loadPubDocsCategories = (custId, pubDocTypeId) => async (dispatch) => {
  dispatch(loadPubDocsCategoriesStart(custId, pubDocTypeId));
  try {
    const response = await getJsonNoCache(`/api/${custId}/pub-doc/category/all?${createDocumentCountQueryString()}`);
    dispatch(loadPubDocsCategoriesSuccess(pubDocTypeId, response));
  } catch (error) {
    dispatch(loadPubDocsCategoriesFail(custId, pubDocTypeId, error));
  } finally {
    dispatch(loadPubDocsCategoriesFinally(custId, pubDocTypeId));
  }
};

const createDocumentCountQueryString = (includeDocumentCount) =>
  `includeDocumentCount=${includeDocumentCount != null ? includeDocumentCount
    : window.location.pathname.endsWith("/document/types")}`;

const createFilterQueryString = (getState, includeDocumentCount = null) => {
  const filterState = getSelectedFilters(getState());
  let queryString = "";
  queryString += createDocumentCountQueryString(includeDocumentCount);
  if (filterState) {
    if (filterState.category) {
      queryString += `&filterCategory=${encodeURIComponent(filterState.category)}`;
    }
    if (filterState.year) {
      queryString += `&filterYear=${encodeURIComponent(filterState.year)}`;
    }
    if (filterState.month) {
      queryString += `&filterMonth=${encodeURIComponent(filterState.month)}`;
    }
    if (filterState.day) {
      queryString += `&filterDay=${encodeURIComponent(filterState.day)}`;
    }
  }
  return queryString;
};

const addFilterStateToFormData = (formData, getState) => {
  const filterState = getSelectedFilters(getState());
  if (filterState) {
    if (filterState.category != null) {
      formData.append('categoryName', filterState.category);
    }
    if (filterState.year != null) {
      formData.append('year', filterState.year);
    }
    if (filterState.month != null) {
      formData.append('month', filterState.month);
    }
    if (filterState.day != null) {
      formData.append('day', filterState.day);
    }
  }
};

const loadFiltersStart = createAction('PUBDOCS/FILTERS/LOAD/START', (pubDocTypeId) => ({pubDocTypeId}));
const loadFiltersSuccess = createAction('PUBDOCS/FILTERS/LOAD/SUCCESS', (response) => ({response}));
const loadFiltersFail = createAction('PUBDOCS/FILTERS/LOAD/FAIL', (pubDocTypeId, error) => ({pubDocTypeId, error}));
const loadFiltersFinally = createAction('PUBDOCS/FILTERS/LOAD/FINALLY', (pubDocTypeId) => ({pubDocTypeId}));
const loadFilters = (custId, pubDocTypeId) => async (dispatch, getState) => {
  dispatch(loadFiltersStart(pubDocTypeId));
  try {
    const response = await getJson(`/api/${custId}/pub-doc/filters/${pubDocTypeId}?${createFilterQueryString(getState)}`);
    dispatch(loadFiltersSuccess(response));
  } catch (error) {
    dispatch(loadFiltersFail(pubDocTypeId, error));
  } finally {
    dispatch(loadFiltersFinally(pubDocTypeId));
  }
};

const addPubDocTypeStart = createAction('PUBDOC/TYPE/ADD/START', (name, description) => ({name, description}));
const addPubDocTypeSuccess = createAction('PUBDOC/TYPE/ADD/SUCCESS', (response) => ({response}));
const addPubDocTypeFail = createAction('PUBDOC/TYPE/ADD/FAIL', (name, description, error) => ({name, description, error}));
const addPubDocTypeFinally = createAction('PUBDOC/TYPE/ADD/FINALLY', (name, description) => ({name, description}));
const addPubDocType = (custId, name, description, sortType) => async (dispatch) => {
  if (name.includes('/') || name.includes('_') || name.includes('\\')) {
    dispatch(showUserMessage("Invalid type name", "Name must not contain a /, \\, or _"));
    return;
  }
  dispatch(addPubDocTypeStart(name, description));
  try {
    const data = {title: name, description, sortType: sortType};
    const response = await postJson(`/api/${custId}/pub-doc/type`, data);
    dispatch(addPubDocTypeSuccess(response));
  } catch (error) {
    dispatch(registerError("Could not create document type because a document type with that name already exists.", "Document type already exists", [name, description], error));
    dispatch(addPubDocTypeFail(name, description, error));
  } finally {
    dispatch(addPubDocTypeFinally(name, description));
  }
};

const editPubDocTypeStart = createAction('PUBDOC/TYPE/EDIT/START', (name, description) => ({name, description}));
const editPubDocTypeSuccess = createAction('PUBDOC/TYPE/EDIT/SUCCESS', (response) => ({response}));
const editPubDocTypeFail = createAction('PUBDOC/TYPE/EDIT/FAIL', (name, description, error) => ({name, description, error}));
const editPubDocTypeFinally = createAction('PUBDOC/TYPE/EDIT/FINALLY', (name, description) => ({name, description}));
const editPubDocType = (custId, name, description, typeId, sortType) => async (dispatch) => {
  if (name.includes('/') || name.includes('_') || name.includes('\\')) {
    dispatch(showUserMessage("Invalid type name", "Name must not contain a /, \\, or _"));
    return;
  }
  dispatch(editPubDocTypeStart(name, description));
  try {
    const data = {title: name, description, sortType: sortType};
    const response = await putJson(`/api/${custId}/pub-doc/type/${typeId}`, data);
    dispatch(editPubDocTypeSuccess(response));
  } catch (error) {
    dispatch(registerError("Failed to edit PubDocType", "Type could not be edited", [name, description], error));
    dispatch(editPubDocTypeFail(name, description, error));
  } finally {
    dispatch(editPubDocTypeFinally(name, description));
  }
};

const addPubDocCategoryStart = createAction('PUBDOC/CATEGORY/ADD/START', (name) => ({name}));
const addPubDocCategorySuccess = createAction('PUBDOC/CATEGORY/ADD/SUCCESS', (response) => ({response}));
const addPubDocCategoryFail = createAction('PUBDOC/CATEGORY/ADD/FAIL', (name, error) => ({name, error}));
const addPubDocCategoryFinally = createAction('PUBDOC/CATEGORY/ADD/FINALLY', (name) => ({name}));
const addPubDocCategory = (custId, name, description, typeId, typeTitle, isPrivate, groupByType, sortByFirstType, sortBySecondType) => async (dispatch, getState) => {
  dispatch(addPubDocCategoryStart(name));
  try {
    const data = {custId, typeId, typeTitle, title: name, description, isPrivate, groupByType, sortByFirstType, sortBySecondType};
    const response = await postJson(`/api/${custId}/pub-doc/category?${createFilterQueryString(getState)}`, data);
    dispatch(addPubDocCategorySuccess(response));
  } catch (error) {
    dispatch(registerError("Could not create a category because a category with that name already exists.", "Category already exists", [name], error));
    dispatch(addPubDocCategoryFail(name, error));
  } finally {
    dispatch(addPubDocCategoryFinally(name));
  }
};

const editPubDocCategoryStart = createAction('PUBDOC/CATEGORY/EDIT/START', (name) => ({name}));
const editPubDocCategorySuccess = createAction('PUBDOC/CATEGORY/EDIT/SUCCESS', (response) => ({response}));
const editPubDocCategoryFail = createAction('PUBDOC/CATEGORY/EDIT/FAIL', (name, error) => ({name, error}));
const editPubDocCategoryFinally = createAction('PUBDOC/CATEGORY/EDIT/FINALLY', (name) => ({name}));
const editPubDocCategory = (custId, name, description, typeId, typeTitle, categoryId, isPrivate, groupByType, sortByFirstType, sortBySecondType) => async (dispatch, getState) => {
  dispatch(editPubDocCategoryStart(name));
  try {
    const data = {custId, typeId, typeTitle, title: name, description, categoryId, isPrivate, groupByType, sortByFirstType, sortBySecondType};
    const response = await putJson(`/api/${custId}/pub-doc/category/${categoryId}?${createFilterQueryString(getState)}`, data);
    dispatch(editPubDocCategorySuccess(response));
  } catch (error) {
    dispatch(editPubDocCategoryFail(name, error));
  } finally {
    dispatch(editPubDocCategoryFinally(name));
  }
};

const deletePubDocCategoryStart = createAction('PUBDOC/CATEGORY/DELETE/START', (categoryId) => ({categoryId}));
const deletePubDocCategorySuccess = createAction('PUBDOC/CATEGORY/DELETE/SUCCESS', (response) => ({response}));
const deletePubDocCategoryFail = createAction('PUBDOC/CATEGORY/DELETE/FAIL', (categoryId, error) => ({categoryId, error}));
const deletePubDocCategoryFinally = createAction('PUBDOC/CATEGORY/DELETE/FINALLY', (categoryId) => ({categoryId}));
const deletePubDocCategory = (custId, categoryId) => async (dispatch, getState) => {
  dispatch(deletePubDocCategoryStart(categoryId));
  try {
    const response = await deleteJson(`/api/${custId}/pub-doc/category/${categoryId}?${createFilterQueryString(getState, false)}`);
    dispatch(deletePubDocCategorySuccess(response));
  } catch (error) {
    dispatch(deletePubDocCategoryFail(categoryId, error));
  } finally {
    dispatch(deletePubDocCategoryFinally(categoryId));
  }
};

const deletePubDocTypeStart = createAction('PUBDOC/TYPE/DELETE/START', (pubDocTypeId) => ({pubDocTypeId}));
const deletePubDocTypeSuccess = createAction('PUBDOC/TYPE/DELETE/SUCCESS', (response) => ({response}));
const deletePubDocTypeFail = createAction('PUBDOC/TYPE/DELETE/FAIL', (pubDocTypeId, error) => ({pubDocTypeId, error}));
const deletePubDocTypeFinally = createAction('PUBDOC/TYPE/DELETE/FINALLY', (pubDocTypeId) => ({pubDocTypeId}));
const deletePubDocType = (custId, pubDocTypeId) => async (dispatch) => {
  dispatch(deletePubDocTypeStart(pubDocTypeId));
  try {
    const response = await deleteJson(`/api/${custId}/pub-doc/type/${pubDocTypeId}`);
    dispatch(deletePubDocTypeSuccess(response));
  } catch (e) {
    dispatch(registerError(null, "Document type has categories", [pubDocTypeId], e));
    dispatch(deletePubDocTypeFail(pubDocTypeId, e));
  } finally {
    dispatch(deletePubDocTypeFinally(pubDocTypeId));
  }
};

const addPubDocStart = createAction('PUBDOC/DOCUMENT/ADD/START', (name) => ({name}));
const addPubDocSuccess = createAction('PUBDOC/DOCUMENT/ADD/SUCCESS', (response) => ({response}));
const addPubDocFail = createAction('PUBDOC/DOCUMENT/ADD/FAIL', (name, error) => ({name, error}));
const addPubDocFinally = createAction('PUBDOC/DOCUMENT/ADD/FINALLY', (name) => ({name}));
const addPubDoc = (custId, categoryId, typeId, name, description, date, file, isPrivate, documentType) => async (dispatch, getState) => {
  dispatch(addPubDocStart(name));
  try {
    const formData = new FormData();
    formData.append('typeId', typeId);
    if (categoryId && categoryId !== 'Uncategorized') {
      formData.append('categoryId', categoryId);
    }
    formData.append('title', name);
    if (description) {
      formData.append('description', description);
    }
    if (date) {
      formData.append('date', date);
    }
    if (file) {
      formData.append('file', file);
    }
    formData.append('isPrivate', isPrivate);
    formData.append('documentType', documentType);

    addFilterStateToFormData(formData, getState);

    const response = await postFormData(`/api/${custId}/pub-doc`, formData);
    if (categoryId === 'Uncategorized' || categoryId === null) {
      dispatch(loadPubDocsCategories(custId, typeId));
    }
    const updateResponse = await response.json();
    dispatch(addPubDocSuccess(updateResponse));
  } catch (error) {
    dispatch(registerError("Could not upload public document", null, null, error));
    dispatch(addPubDocFail(name, error));
  } finally {
    dispatch(addPubDocFinally(name));
  }
};

const addPubDocLinkedStart = createAction('PUBDOC/LINKED/ADD/START', (name) => ({name}));
const addPubDocLinkedSuccess = createAction('PUBDOC/LINKED/ADD/SUCCESS', (response) => ({response}));
const addPubDocLinkedFail = createAction('PUBDOC/LINKED/ADD/FAIL', (name, error) => ({name, error}));
const addPubDocLinkedFinally = createAction('PUBDOC/LINKED/ADD/FINALLY', (name) => ({name}));
const addPubDocLinked = (custId, categoryId, typeId, name, description, date, isPrivate, documentType, url) => async (dispatch, getState) => {
  dispatch(addPubDocLinkedStart(name));
  try {
    const formData = new FormData();
    formData.append('typeId', typeId);
    if (categoryId && categoryId !== 'Uncategorized') {
      formData.append('categoryId', categoryId);
    }
    formData.append('title', name);
    if (description) {
      formData.append('description', description);
    }
    if (date) {
      formData.append('date', date);
    }
    formData.append('isPrivate', isPrivate);
    formData.append('documentType', documentType);
    formData.append('url', url);
    addFilterStateToFormData(formData, getState);

    const response = await postFormData(`/api/${custId}/pub-doc/linked`, formData);
    if (categoryId === 'Uncategorized' || categoryId === null) {
      dispatch(loadPubDocsCategories(custId, typeId));
    }
    const updateResponse = await response.json();
    dispatch(addPubDocLinkedSuccess(updateResponse));
  } catch (error) {
    dispatch(addPubDocLinkedFail(name, error));
  } finally {
    dispatch(addPubDocLinkedFinally(name));
  }
};

const editPubDocStart = createAction('PUBDOC/DOCUMENT/EDIT/START', (name) => ({name}));
const editPubDocSuccess = createAction('PUBDOC/DOCUMENT/EDIT/SUCCESS', (response) => ({response}));
const editPubDocFail = createAction('PUBDOC/DOCUMENT/EDIT/FAIL', (name, error) => ({name, error}));
const editPubDocFinally = createAction('PUBDOC/DOCUMENT/EDIT/FINALLY', (name) => ({name}));
const editPubDoc = (custId, documentId, categoryId, typeId, name, description, date, file, isPrivate, documentType) => async (dispatch, getState) => {
  dispatch(editPubDocStart(name));
  try {
    const formData = new FormData();
    formData.append('id', documentId);
    if (categoryId) {
      formData.append('categoryId', categoryId);
    }
    formData.append('typeId', typeId);
    formData.append('title', name);
    if (description) {
      formData.append('description', description);
    }
    if (date) {
      formData.append('date', date);
    }
    if (file) {
      formData.append('file', file);
    }
    formData.append('isPrivate', isPrivate);
    formData.append('documentType', documentType);

    const response = await putFormData(`/api/${custId}/pub-doc/${documentId}?${createFilterQueryString(getState)}`, formData);
    const updateResponse = await response.json();
    dispatch(editPubDocSuccess(updateResponse));
  } catch (error) {
    dispatch(registerError(error.message, error.title, [documentId], error));
    dispatch(editPubDocFail(name, error));
  } finally {
    dispatch(editPubDocFinally(name));
  }
};

const editPubDocLinkedStart = createAction('PUBDOC/LINKED/EDIT/START', (documentId) => ({documentId}));
const editPubDocLinkedSuccess = createAction('PUBDOC/LINKED/EDIT/SUCCESS', (response) => ({response}));
const editPubDocLinkedFail = createAction('PUBDOC/LINKED/EDIT/FAIL', (documentId, error) => ({documentId, error}));
const editPubDocLinkedFinally = createAction('PUBDOC/LINKED/EDIT/FINALLY', (documentId) => ({documentId}));
const editPubDocLinked = (custId, documentId, categoryId, typeId, name, description, date, isPrivate, documentType, url) => async (dispatch, getState) => {
  dispatch(editPubDocLinkedStart(documentId));
  try {
    const formData = new FormData();
    formData.append('id', documentId);
    if (categoryId) {
      formData.append('categoryId', categoryId);
    }
    formData.append('typeId', typeId);
    formData.append('title', name);
    if (description) {
      formData.append('description', description);
    }
    if (date) {
      formData.append('date', date);
    }
    formData.append('isPrivate', isPrivate);
    formData.append('documentType', documentType);
    formData.append('url', url);

    const response = await putFormData(`/api/${custId}/pub-doc/linked/${documentId}?${createFilterQueryString(getState)}`, formData);
    const updateResponse = await response.json();
    dispatch(editPubDocLinkedSuccess(updateResponse));
  } catch (error) {
    dispatch(editPubDocLinkedFail(documentId, error));
  } finally {
    dispatch(editPubDocLinkedFinally(documentId));
  }
};

const deletePubDocStart = createAction('PUBDOC/DELETE/START', (documentId) => ({documentId}));
const deletePubDocSuccess = createAction('PUBDOC/DELETE/SUCCESS', (response) => ({response}));
const deletePubDocFail = createAction('PUBDOC/DELETE/FAIL', (documentId, error) => ({documentId, error}));
const deletePubDocFinally = createAction('PUBDOC/DELETE/FINALLY', (documentId) => ({documentId}));
const deletePubDoc = (custId, documentId) => async (dispatch, getState) => {
  dispatch(deletePubDocStart(documentId));
  try {
    const response = await deleteJson(`/api/${custId}/pub-doc/${documentId}?${createFilterQueryString(getState)}`);
    dispatch(deletePubDocSuccess(response));
  } catch (error) {
    dispatch(deletePubDocFail(documentId, error));
  } finally {
    dispatch(deletePubDocFinally(documentId));
  }
};

const deleteMultipleDocumentsStart = createAction('PUBDOC/DELETE/MULTIPLE/START', (documentIdList) => ({documentIdList}));
const deleteMultipleDocumentsSuccess = createAction('PUBDOC/DELETE/MULTIPLE/SUCCESS', (response) => ({response}));
const deleteMultipleDocumentsFail = createAction('PUBDOC/DELETE/MULTIPLE/FAIL', (documentIdList, error) => ({documentIdList, error}));
const deleteMultipleDocumentsFinally = createAction('PUBDOC/DELETE/MULTIPLE/FINALLY', (documentIdList) => ({documentIdList}));
const deleteMultipleDocuments = (custId, documentIdList) => async (dispatch, getState) => {
  deleteMultipleDocumentsStart(documentIdList);
  try {
    const formData = new FormData();
    formData.append('documentIdList', documentIdList);
    const response = await deleteFormData(`/api/${custId}/pub-doc?${createFilterQueryString(getState)}`, formData);
    const updateResponse = await response.json();
    dispatch(deleteMultipleDocumentsSuccess(updateResponse));
  } catch (error) {
    deleteMultipleDocumentsFail(documentIdList, error);
  } finally {
    deleteMultipleDocumentsFinally(documentIdList);
  }
};

const movePublicDocumentsStart = createAction('PUBDOC/MOVE/START', (documentIdList) => ({documentIdList}));
const movePublicDocumentsSuccess = createAction('PUBDOC/MOVE/SUCCESS', (response) => ({response}));
const movePublicDocumentsFail = createAction('PUBDOC/MOVE/FAIL', (documentIdList, error) => ({documentIdList, error}));
const movePublicDocumentsFinally = createAction('PUBDOC/MOVE/FINALLY', (documentIdList) => ({documentIdList}));
const movePublicDocuments = (custId, documentIdList, categoryId, typeId) => async (dispatch) => {
  dispatch(movePublicDocumentsStart(documentIdList));
  try {
    const formData = new FormData();
    formData.append('documentIdList', documentIdList);
    if (categoryId !== "Uncategorized") {
      formData.append('categoryId', categoryId);
    }
    formData.append('typeId', typeId);
    const response = await postFormData(`/api/${custId}/pub-doc/move`, formData);
    const updateResponse = await response.json();
    dispatch(movePublicDocumentsSuccess(updateResponse));
  } catch (error) {
    dispatch(movePublicDocumentsFail(documentIdList, error));
  } finally {
    dispatch(movePublicDocumentsFinally(documentIdList));
  }
};

const bulkAddPubDocsStart = createAction('PUBDOC/BULK/ADD/MULTIPLE/START', (documents) => ({documents}));
const bulkAddPubDocsSuccess = createAction('PUBDOC/BULK/ADD/MULTIPLE/SUCCESS', (documents) => ({documents}));
const bulkAddPubDocsFail = createAction('PUBDOC/BULK/ADD/MULTIPLE/FAIL', (documents, error) => ({documents, error}));
const bulkAddPubDocSuccess = createAction('PUBDOC/BULK/ADD/SUCCESS', (response) => ({response}));
const bulkAddPubDocFail = createAction('PUBDOC/BULK/ADD/FAIL', (error) => ({error}));
const bulkAddPubDocsFinally = createAction('PUBDOC/BULK/ADD/MULTIPLE/FINALLY', (documents) => ({documents}));
const bulkAddPubDocs = (documents) => async (dispatch, getState) => {

  dispatch(bulkAddPubDocsStart(documents));
  let documentPromises = [];

  // Create uncategorized types if needed
  try {
    let uncategorizedTypeIds = [];
    for (let i = 0; i < documents.length; i++) {
      let document = documents[i];
      if ((uncategorizedTypeIds.indexOf(document.typeId) === -1)
        && (!document.categoryId || document.categoryId === "Uncategorized" || document.categoryId === 0)
      ) {
        uncategorizedTypeIds.push(document.typeId);
      }
    }
    let uncategorizedPromises = uncategorizedTypeIds.map((typeId) =>
      getJson(`/api/${custId}/pub-doc/type/${typeId}/category/uncategorized?${createFilterQueryString(getState)}`)
    );
    await Promise.allSettled(uncategorizedPromises).then(async (promises) => {

      // Create a map with the uncategorized IDs to use when uploading
      let uncategorizedIds = {};
      for (let i = 0; i < promises.length; i++) {
        const promise = promises[i];
        if (promise.status === "fulfilled" && promise.value) {
          const categoryResponse = promise.value;
          dispatch(addPubDocCategorySuccess(categoryResponse));
          uncategorizedIds[categoryResponse.typeId] = categoryResponse.id;
        }
      }

      // Then upload the documents
      for (let i = 0; i < documents.length; i++) {
        let document = documents[i];
        const formData = new FormData();
        formData.append("typeId", document.typeId);
        if (document.categoryId && document.categoryId !== "Uncategorized" && document.categoryId !== 0) {
          formData.append("categoryId", document.categoryId);
        } else if (uncategorizedIds[document.typeId]) {
          formData.append("categoryId", uncategorizedIds[document.typeId]);
        }
        formData.append("title", document.title);
        if (document.description) {
          formData.append("description", document.description);
        }
        if (document.date) {
          formData.append("date", document.date);
        }
        if (document.file) {
          formData.append("file", document.file);
        }
        formData.append("isPrivate", document.isPrivate);
        formData.append("documentType", "DOCUMENT");
        formData.append("replace", false.toString());
        documentPromises.push(postFormData(`/api/${custId}/pub-doc`, formData));
      }
    });
  } catch (error) {
    dispatch(bulkAddPubDocsFail(documents, error));
  }
  await Promise.allSettled(documentPromises).then(async (promises) => {
    for (let i = 0; i < promises.length; i++) {
      const promise = promises[i];
      if (promise.status === "fulfilled" && promise.value) {
        const response = promise.value;
        const updateResponse = await response.json();
        dispatch(bulkAddPubDocSuccess(updateResponse));
      }
    }
    dispatch(bulkAddPubDocsSuccess(documents));
  }).catch((error) => {
    dispatch(bulkAddPubDocFail(error));
  }).finally(() => {
    dispatch(bulkAddPubDocsFinally(documents));
  });
};
const clearBulkUploadedDocuments = createAction('PUBDOC/BULK/UPLOAD/CLEAR', () => ({}));

const setPubDocTypeEditOpen = createAction('PUBDOC/TYPE/EDIT/OPEN/SET', (open, typeEditId) => ({open, typeEditId}));
const setPubDocCategoryEditOpen = createAction('PUBDOC/CATEGORY/EDIT/OPEN/SET', (categoryEditOpen, categoryEditId, typeId) => ({categoryEditOpen, categoryEditId, typeId}));
const setPubDocEditOpen = createAction('PUBDOC/DOCUMENT/EDIT/OPEN/SET', (open, documentId, categoryId, typeId) => ({open, documentId, categoryId, typeId}));
const setDocumentMoveOpen = createAction('PUBDOC/DOCUMENT/MOVE/OPEN/SET', (open, documentId) => ({open, documentId}));
const setDocumentChecked = createAction('PUBDOCS/DOCUMENT/CHECKED', (documentId) => ({documentId}));
const setDocumentUnchecked = createAction('PUBDOCS/DOCUMENT/UNCHECKED', (documentId) => ({documentId}));

const setDownloadErrorOpen = createAction('PUBDOCS/DOWNLOAD_ERROR/OPEN/SET', (open) => ({open}));
const setBulkDeleteOpen = createAction('PUBDOCS/BULK_DELETE/OPEN/SET', (open) => ({open}));

const setPubDocTypeDeleteOpen = createAction('PUBDOCS/TYPE/DELETE/OPEN/SET', (open, typeId) => ({open, typeId}));
const setCategoryDeleteOpen = createAction('PUBDOCS/CATEGORY/DELETE/OPEN/SET', (open, categoryId) => ({open, categoryId}));
const setCategoryDeleteErrorOpen = createAction('PUBDOCS/CATEGORY/DELETE/ERROR/OPEN/SET', (open) => ({open}));


//hidden method only to be used by the actual updateFilterState
/**
 * Used by the reducer but do not call directly instead use updateFilterState
 * @param actionType name of the action
 * @param payloadCreator map function arguments to a payload object
 */
const updateFilterStateAction = createAction('PUBDOC/FILTER/UPDATED', (selectedFilters) => ({selectedFilters}));

/**
 * generate action to update the filters and also schedule a visibility refresh for after the state updates
 */
const updateFilterState = (selectedFilters) => {
  return updateFilterStateAction(selectedFilters);
};

export {
  initializeState,
  addPubDocType,
  addPubDocTypeStart,
  addPubDocTypeSuccess,
  addPubDocTypeFinally,
  editPubDocType,
  editPubDocTypeStart,
  editPubDocTypeSuccess,
  editPubDocTypeFinally,
  deletePubDocType,
  deletePubDocTypeSuccess,
  loadPubDocs,
  loadPubDocsStart,
  loadPubDocsSuccess,
  loadPubDocsFail,
  loadPubDocsFinally,
  setPubDocTypeEditOpen,
  setPubDocCategoryEditOpen,
  setPubDocEditOpen,
  addPubDocCategory,
  addPubDocCategoryStart,
  addPubDocCategorySuccess,
  addPubDocCategoryFinally,
  loadPubDocsCategories,
  loadPubDocsCategoriesSuccess,
  editPubDocCategory,
  editPubDocCategoryStart,
  editPubDocCategorySuccess,
  editPubDocCategoryFinally,
  deletePubDocCategory,
  deletePubDocCategorySuccess,
  loadFilters,
  loadFiltersSuccess,
  addPubDoc,
  addPubDocStart,
  addPubDocSuccess,
  addPubDocFinally,
  editPubDoc,
  editPubDocStart,
  editPubDocSuccess,
  editPubDocFinally,
  deletePubDoc,
  deletePubDocSuccess,
  addPubDocLinked,
  addPubDocLinkedStart,
  addPubDocLinkedSuccess,
  addPubDocLinkedFinally,
  editPubDocLinked,
  editPubDocLinkedStart,
  editPubDocLinkedSuccess,
  editPubDocLinkedFinally,
  deleteMultipleDocuments,
  deleteMultipleDocumentsSuccess,
  updateFilterState,
  updateFilterStateAction,
  setDocumentMoveOpen,
  movePublicDocuments,
  movePublicDocumentsSuccess,
  bulkAddPubDocsStart,
  bulkAddPubDocs,
  bulkAddPubDocsFinally,
  bulkAddPubDocSuccess,
  bulkAddPubDocsSuccess,
  clearBulkUploadedDocuments,
  setDocumentChecked,
  setDocumentUnchecked,
  setDownloadErrorOpen,
  setBulkDeleteOpen,
  setPubDocTypeDeleteOpen,
  setCategoryDeleteOpen,
  setCategoryDeleteErrorOpen
};