import {Record} from 'immutable';
import React from 'react';
import {CookiesProvider} from "react-cookie";
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {combineReducers} from "redux-immutable";
import thunkMiddleware from 'redux-thunk';

import * as AdminSettings from './admin-settings';
import * as AdminUsers from './admin-users';
import * as Common from './common';
import {registerError} from './common/actions';
import {ErrorBoundaryContainer} from './common/containers';
import AppContainer from "./common/containers/AppContainer";
import ErrorDialogContainer from "./common/containers/ErrorDialogContainer";
import apiMiddleware from "./common/middleware/api";


import ThemeProvider from './common/theme';
import './events';
import * as Content from './content';
import * as Index from './index/index';
import * as Laws from './laws';
import * as MessageQueue from './message-queue';
import * as Notes from './notes';
import * as PubDocs from './pub-docs';
import * as Questions from './questions';
import * as Search from './search';
import {replaceNodeWithReactComponent} from "./common/utils/ReactHelper";


window.SERVER_DATA = window.SERVER_DATA || {};
window.addEventListener("load", () => {
  //Create reducer config using ModuleKey and reducer from each module's exports
  const configFromModules = (modules, fun) => modules.reduce((config, module) => {
    config[module.ModuleKey] = fun(module);
    return config;
  }, {});

  //middleware like redux-thunk
  const middleware = [apiMiddleware, thunkMiddleware];

  if (process.env.NODE_ENV !== 'production') {
    const reduxLogger = require('redux-logger').default;
    middleware.push(reduxLogger);
  }

  const modules = [Content, MessageQueue, Index, AdminSettings, Notes, Questions, Laws, Search, PubDocs, Common];

  const rootReducer = combineReducers(configFromModules(modules, module => module.reducer));

  const State = Record({...configFromModules(modules, () => undefined)});
  const initialState =
    Content.getInitialState(
      Index.getInitialState(
        AdminSettings.getInitialState(
          Laws.getInitialState(
            Notes.getInitialState(
              Questions.getInitialState(
                Search.getInitialState(
                  PubDocs.getInitialState(
                    Common.getInitialState(
                      new State())))))))));

  const store = createStore(rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware)
    )
  );

  window.addEventListener("beforeunload", (e) => {
    //If any module has an isAnyEditing selector and it thinks we are editing then warn on navigation
    if (modules.reduce((editing, module) => editing ||
      (module.selectors?.isAnyEditing && module.selectors.isAnyEditing(store.getState())), false)) {
      const message = "You have an unsaved changes.  Are you sure you want to leave?";
      e.returnValue = message;
      e.preventDefault();
      return message;
    }
  });

  const Providers = ({children, name = 'unknown'}) =>
    <Provider store={store} key={`${name}-redux`}>
      <ErrorBoundaryContainer key={`${name}-error-boundry`}>
        <ThemeProvider key={`${name}-themes`}>
          <CookiesProvider key={`${name}-cookies`}>
            {children}
          </CookiesProvider>
        </ThemeProvider>
      </ErrorBoundaryContainer>
    </Provider>;

  const messageQueue = document.getElementById('messageQueue');
  if (messageQueue !== null) {
    ReactDOM.render(
      <Providers key="message-queue-provider" name="message-queue">
        <MessageQueue.RootComponent key="message-queue"/>
      </Providers>,
      messageQueue
    );
  }

  const adminSettingsRoot = document.getElementById('adminSettingsRoot');
  if (adminSettingsRoot !== null) {
    ReactDOM.render(
      <Providers key="admin-settings-provider" name="admin-settings">
        <AdminSettings.RootComponent key="admin-settings" {...(adminSettingsRoot.dataset)} />
      </Providers>,
      adminSettingsRoot
    );
  }

  const userItems = document.getElementsByClassName('userItem');
  if (userItems !== null && userItems.length > 0) {
    for (let i = 0; i < userItems.length; i++) {
      const userItem = userItems[i];
      const userId = userItem.id;
      const userEmail = userItem.getElementsByClassName('userEmail');
      const setPasswordButton = userItem.getElementsByClassName('userSetPassword');
      if (userEmail.length > 0 && setPasswordButton.length > 0 && !setPasswordButton[0].classList.contains('disabled')) {
        replaceNodeWithReactComponent(setPasswordButton[0],
          <Providers key={`users-${i}-provider`} name={`users-${i}`}>
            <AdminUsers.RootComponent key={userId} userId={userId} userName={userEmail[0].innerHTML}/>
          </Providers>,
        );
      }
    }
  }

  try {
    Common.init(Providers, store.dispatch);
    Search.init(Providers, store.dispatch, initialState);
    Notes.init(Providers, store.dispatch);
    Questions.init(Providers, store.dispatch, initialState);
    Laws.init(Providers, store.dispatch, initialState);
    PubDocs.init(Providers, store.dispatch);
    Index.init(Providers, store.dispatch);
    Content.init(Providers, store.dispatch);
  } catch (e) {
    console.error("Failed to initialize", e);
    store.dispatch(registerError("Failed to initialize", null, ['startup'], e));
    throw e;
  }

  const rootElement = document.getElementById("mainContainer");
  const errorContainer = document.getElementById("errorDialogContainer");
  if (rootElement) {
    ReactDOM.render(
      <Providers key="root-provider" name="root">
        <AppContainer key="root-app-container"/>
        <ErrorDialogContainer key="root-app-error-dialog"/>
      </Providers>,
      rootElement
    );
  } else if (errorContainer) {
    replaceNodeWithReactComponent(errorContainer, <Providers key="root-provider" name="root">
      <ErrorDialogContainer key="root-app-error-dialog"/>
    </Providers>);
  }
  //Now that react is done loading we can show the body
  setTimeout(() => {
    document.body.style.display = "initial";
    window.gc = window.gc || {};
    window.gc.runScript = true;
  }, 0);
});
