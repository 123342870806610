/*
 * Copyright 2018 General Code
 */

import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {Dialog, InfiniteScroll} from "../../common/components";
import {If} from "../../common/containers";
import {Cookies, withCookies} from "react-cookie";
import {custId} from "../../common/utils/server-data";
import AnalysisDueContainer from "../containers/AnalysisDueContainer";
import QuestionContainer from "../containers/QuestionContainer";

import "./Question.css";

class Analysis extends Component {

  static propTypes = {
    analysis: PropTypes.object.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    displayedQuestionCount: PropTypes.number,
    displayedQuestionIds: PropTypes.array.isRequired,
    filterString: PropTypes.string.isRequired,
    hasFinalizePermission: PropTypes.bool.isRequired,
    hasCompletePermission: PropTypes.bool.isRequired,
    hasPrintPermission: PropTypes.bool.isRequired,
    hasStatusReportPermission: PropTypes.bool.isRequired,
    clearAnalysisMessage: PropTypes.func.isRequired,
    checkAnalysis: PropTypes.func.isRequired,
    displayMoreQuestions: PropTypes.func.isRequired,
    finalizeAnalysis: PropTypes.func.isRequired,
    completeAnalysis: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      confirmOpen: false,
      loadExpanded: false,
      loadCollapsed: false,
    };
  }


  componentDidMount() {
    const {analysis, checkAnalysis, cookies} = this.props;
    if (cookies.get(`autoCheck-` + custId, {'path':'/'})) {
      checkAnalysis(analysis.id);
    }
  }


  handleDialogClose = () => {
    const {clearAnalysisMessage, analysis} = this.props;
    clearAnalysisMessage(analysis.id);
  };


  handleErrorLinkClick = (jumpLinkHash) => () => {
    if (window?.generalcode?.scrollToHash) {
      window.location.hash = jumpLinkHash;
      window.generalcode.scrollToHash();
    }
  };

  handleDisplayMoreQuestions = () => {
    const {displayMoreQuestions} = this.props;
    displayMoreQuestions();
  };

  cancelComplete = () => {
    this.setState({confirmOpen: false});
  };

  handleCompleteAnalysis = () => {
    this.cancelComplete();
    const {analysis, completeAnalysis} = this.props;
    completeAnalysis(analysis.id);
  };

  render() {
    const {analysis, displayedQuestionCount, displayedQuestionIds, filterString, hasCompletePermission} = this.props;
    const {confirmOpen} = this.state;
    return (
      <div className="analysis">
        <Dialog title={analysis.messageTitle}
                open={!!analysis.message}
                onClose={this.handleDialogClose}
        >
          <div className="analysisMessage">{analysis.message}</div>
        </Dialog>
        <If test={hasCompletePermission}>
          <Dialog
            title="Complete Analysis?"
            open={confirmOpen}
            onCancel={this.cancelComplete}
            onSubmit={this.handleCompleteAnalysis}
          >
            Completing this analysis will remove it from view. Complete analysis?
          </Dialog>
        </If>
        <div className="analysisHeader">
          <div className="analysisLabelContainer">
            <If test={!analysis.finalized}>
              <AnalysisDueContainer dueDate={analysis.dueDate}/>
            </If>
            <div className="analysisMessage">
              <If test={Object.keys(analysis.errorLinks).length > 0}>
                <div className="error">
                  Could not finalize the analysis.&nbsp;Please review the following questions/options:&nbsp;
                  {Object.keys(analysis.errorLinks)
                  .map((linkKey, i) => (<span key={linkKey}><a href={analysis.errorLinks[linkKey]}
                                                               onClick={this.handleErrorLinkClick(analysis.errorLinks[linkKey])}>{linkKey}</a>{(i !== Object.keys(
                    analysis.errorLinks).length - 1) ? ", " : ""}</span>))}
                </div>
              </If>
              <If test={(analysis.questionIds.length === 0)}>
                <div className="info">There are no pending questions for this analysis.</div>
              </If>
            </div>
          </div>
        </div>
        <pre className="debug">
          <br/>
          Displayed Question Count: {displayedQuestionCount}
          <br/>
          Displayed Question Ids: {displayedQuestionIds.length}
          <br/>
          Key: {filterString}
          <br/>
        </pre>
        <InfiniteScroll
          key={filterString}
          pageStart={0}
          initialLoad={false}
          loadMore={this.handleDisplayMoreQuestions}
          hasMore={displayedQuestionCount < displayedQuestionIds.length}
          threshold={1000}
        >
          {displayedQuestionIds.slice(0, displayedQuestionCount).map((questionId) => (
            <div key={questionId} id={"question-" + questionId}>
              <QuestionContainer questionId={questionId}/>
            </div>
          ))}
        </InfiniteScroll>
        <If test={!analysis.finalized && analysis.questionIds.length !== 0 && displayedQuestionIds.length === 0}>
          <div className="info">There are no questions matching the specified criteria for this analysis.</div>
        </If>
      </div>
    );
  }
}

export default withCookies(Analysis);