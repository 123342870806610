import * as PropTypes from "prop-types";
import React from "react";
import debounce from "lodash/debounce";

import {Button, Chip, ClipLoader, Dialog, Divider, Icon, IconButton} from "../../common/components";
import {Menu, MenuItem} from "../../common/components/menu";
import {
  AccountCircle as AccountCircleIcon,
  Search as SearchIcon,
  Warning as WarningIcon
} from "../../common/components/icons";
import If from "../../common/containers/If";
import AutoSuggestContainer from "../containers/AutoSuggestContainer";
import {mediaWidths} from "../../common/reducers";

import "./MenubarSearchComponent.css";

class SearchBar extends React.PureComponent {

  static propTypes = {
    query: PropTypes.string.isRequired,
    selectedStructures: PropTypes.object.isRequired,
    selectedCustIds: PropTypes.array.isRequired,
    selectedCustomerFilters: PropTypes.array.isRequired,
    searchedCodeCount: PropTypes.number.isRequired,
    hasAdvancedSearchPermission: PropTypes.bool.isRequired,
    hasMulticodePermission: PropTypes.bool.isRequired,
    searchSuggestionWaitTime: PropTypes.number.isRequired,
    isSearchFieldActive: PropTypes.bool.isRequired,
    mediaWidth: PropTypes.number.isRequired,
    custId: PropTypes.string,
    username: PropTypes.string,
    myNotesCount: PropTypes.number,
    customerWarnings: PropTypes.array,
    setQuery: PropTypes.func.isRequired,
    submitSearch: PropTypes.func.isRequired,
    setCodeFinderOpen: PropTypes.func.isRequired,
    resetSelectedCustomers: PropTypes.func.isRequired,
    resetSelectedStructures: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
    setSearchFieldActive: PropTypes.func.isRequired,
  };

  state = {
    menuButton: null,
    displayWarning: false
  };

  constructor(props) {
    super(props);
    this.handleGetSearchSuggestions = debounce(this.handleGetSearchSuggestions, props.searchSuggestionWaitTime);
    this.handleGetSearchSuggestions(props.query);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  handleWindowClick = (event) => {
    const {isSearchFieldActive, setSearchFieldActive} = this.props;
    if (isSearchFieldActive && !this.ref.contains(event.target)) {
      setSearchFieldActive(false);
    }
  };

  handleOpenCodeFinderDialog = () => {
    const {custId} = this.props;
    if (!custId) {
      this.props.setCodeFinderOpen(true);
    } else {
      window.open("/search?codeFinder=true" , "_blank");
    }
  };

  handleMulticodeChipClick = (event) => {
    if (event.buttons === 1 && event.target.tagName !== "svg" && event.target.tagName !== "path") {
      this.handleOpenCodeFinderDialog();
    }
  };

  handleMulticodeChipKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleOpenCodeFinderDialog();
    }
  };

  handleMulticodeChipDelete = () => {
    const {submitSearch, resetSelectedCustomers} = this.props;
    resetSelectedCustomers();
    submitSearch();
  };

  handleSelectedChipDelete = () => {
    const {submitSearch, resetSelectedStructures} = this.props;
    resetSelectedStructures();
    if(window.location.href.indexOf("/search") !== -1)
      submitSearch();
  };

  handleSearch = (event) => {
    const {submitSearch} = this.props;
    event.preventDefault();
    submitSearch(event.target.value);
  };

  handleOpenAdvancedSearchDialog = (event) => {
    const {setAdvancedSearchOpen} = this.props;
    event.preventDefault();
    setAdvancedSearchOpen(true);
  };

  handleGetSearchSuggestions = () => {
    const {query, getSearchSuggestions} = this.props;
    getSearchSuggestions(query);
  };

  handleQueryChange = (event) => {
    const {setQuery} = this.props;
    setQuery(event.target.value);
    this.handleGetSearchSuggestions(event.target.value);
  };

  handleSearchFieldActive = (event) => {
    const {isSearchFieldActive, setSearchFieldActive} = this.props;
    if (event.key && event.key === 'Tab') {
      setSearchFieldActive(false);
    } else {
      if (!isSearchFieldActive) {
        setSearchFieldActive(true);
      }
      if (event.key && event.key === 'ArrowDown'){
        const quickJumpItems = document.getElementsByClassName("quickJumpItem");
        if (quickJumpItems.length > 0) {
          quickJumpItems[0].focus();
          event.preventDefault();
        }
      }
    }
  };

  render() {
    const {query, selectedStructures, selectedCustIds, selectedCustomerFilters, searchedCodeCount,
      hasAdvancedSearchPermission, hasMulticodePermission, custId, username, myNotesCount, customerWarnings, mediaWidth
    } = this.props;
    const {menuButton, displayWarning} = this.state;
    const chips = [];
    const titles = Object.values(selectedStructures);
    if ((selectedCustomerFilters && selectedCustomerFilters.length > 0) || (selectedCustIds && (selectedCustIds.length > 1 || (selectedCustIds.length === 1 && (!custId || (selectedCustIds[0].toUpperCase() !== custId.toUpperCase()))))))  {
      chips.push(
        <Chip clickable
          key="codesChip"
          id="multicodeChip"
          className={"clickable muiChip menubarScopeChip"}
          label={"Multicode" + (searchedCodeCount ? " (" + searchedCodeCount.toLocaleString() + ")" : "")}
          deleteIcon={!searchedCodeCount ? <Icon id="menubarMulticodeChipLoadIcon"><ClipLoader/></Icon> : null}
          onDelete={this.handleMulticodeChipDelete}
          onMouseDown={this.handleMulticodeChipClick}
          onKeyDown={this.handleMulticodeChipKeyPress}
        />
      );
    }
    if(selectedStructures && titles.length > 0) {
      chips.push(
        <Chip key="selectedChip"
          className={"muiChip menubarScopeChip"}
          label={"Selected (" + (titles.length.toLocaleString()) + ")"}
          onDelete={this.handleSelectedChipDelete}
          title={titles.join(" + ")}
        />
      );
    }
    return (
      <div id="search-bar">
        <div id="search-bar-wrapper">
          <div className="content-area">
            <div id="menu">
              <div id="menuButtons">
                <div id="menubarExpand"><span id="menubarExpandLabel">&nbsp;</span></div>
              </div>
              <div id="menuSearchArea" className="menuButton searchArea">
                <div id="menubarSearchContainer" ref={ref => this.ref = ref}>
                  <form id="menubarSearchForm" method="GET" autoComplete="off" onSubmit={this.handleSearch}>
                    <div id="menubarScopeChipContainer">{ chips }</div>
                    <input id="menubarSearchField"
                      name="query"
                      type="text"
                      placeholder="Enter search term..."
                      value={query}
                      aria-label="Enter search term"
                      aria-owns="autoSuggestContainer"
                      onChange={this.handleQueryChange}
                      onFocus={this.handleSearchFieldActive}
                      onClick={this.handleSearchFieldActive}
                      onKeyDown={this.handleSearchFieldActive}
                    />
                    <AutoSuggestContainer/>
                    <IconButton
                      id="menubarSearchButton"
                      aria-label="Submit Search"
                      onClick={this.handleSearch}
                    >
                      <SearchIcon/>
                    </IconButton>
                  </form>
                  <If test={hasAdvancedSearchPermission || hasMulticodePermission}>
                    <div className="menubarSearchButtons">
                      <If test={hasAdvancedSearchPermission}>
                        <Button variant="contained" color="default" size="small" className="menubarSearchButton-advanced menubarSearchButton" onClick={this.handleOpenAdvancedSearchDialog}>Advanced</Button>
                      </If>
                      <If test={hasMulticodePermission}>
                        <Button variant="contained" color="default" size="small" className="menubarSearchButton-multicode menubarSearchButton" onClick={this.handleOpenCodeFinderDialog}>Multicode</Button>
                      </If>
                    </div>
                  </If>
                  <div id="search-bar-actions">
                    <If test={customerWarnings?.length}>
                      <div id="customer-warnings" className="search-bar-action">
                        <IconButton
                          id="customer-warnings-button"
                          title={"Display Warning" + (customerWarnings?.length > 1 ? "s" : "")}
                          onClick={() => this.setState({displayWarning: true})}
                        >
                          <WarningIcon
                            title={"Display Warning" + (customerWarnings?.length > 1 ? "s" : "")}
                            color={(customerWarnings?.length ? ("#" + customerWarnings[0].color) : null)}
                            size={26}
                          />
                        </IconButton>
                      </div>
                      <Dialog
                        title={"Warning" + (customerWarnings?.length > 1 ? "s" : "")}
                        open={displayWarning}
                        onClose={() => this.setState({displayWarning: false})}
                      >
                        <ul style={{listStyleType: "disc", paddingLeft: 20, margin: 0}}>
                          {customerWarnings && customerWarnings.map((customerWarning) => (
                            <li key={customerWarning.message} dangerouslySetInnerHTML={{__html: customerWarning.message}}/>
                          ))}
                        </ul>
                      </Dialog>
                    </If>
                    <If test={username}>
                      <div id="account-actions" className="search-bar-action">
                        <IconButton id="account-actions-button" aria-label="Account Actions" onClick={(e) => this.setState({menuButton: e.currentTarget})}>
                          <AccountCircleIcon size={26}/>
                        </IconButton>
                        <Menu
                          id="account-actions-menu"
                          anchorEl={menuButton}
                          open={menuButton !== null}
                          onClose={() => this.setState({menuButton: null})}
                          keepMounted={true}
                          MenuListProps={{"aria-labelledby": "account-actions"}}
                        >
                          <MenuItem component="a" href={"/user/settings" + (custId ? ("/" + custId) : "")}>User Settings</MenuItem>
                          <If test={myNotesCount}>
                            <MenuItem component="a" href={"/" + (custId ? (custId + "/") : "") + "note/all"}>My Notes</MenuItem>
                          </If>
                          <Divider />
                          <MenuItem component="a" href={"/user/logout" + (custId ? "/" + custId : "") + "?url=" + encodeURIComponent(window.location.pathname + "?" + window.location.search)} rel="nofollow">Logout</MenuItem>
                        </Menu>
                      </div>
                    </If>
                    <If
                      test={!username && !window.location.pathname.startsWith("/user/login") && mediaWidth > mediaWidths.medium}>
                      <div className="search-bar-action">
                        <Button component="a" variant="contained" color="default" size="small" href={"/user/login" + (custId ? "/" + custId : "") + "?url=" + encodeURIComponent(window.location.pathname + "?" + window.location.search)} rel="nofollow">Login</Button>
                      </div>
                    </If>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SearchBar;
