import {ModuleKey} from '../ModuleKey';

const isSearchResultsPage = (state) => state[ModuleKey].isSearchResultsPage;
const isSearchFieldActive = (state) => state[ModuleKey].isSearchFieldActive;
const getSuggestions = (state) => state[ModuleKey].suggestions;
const getQuery = (state) => state[ModuleKey].query;
const getResults = (state) => state[ModuleKey].results;
const getTotalResultCount = (state) => state[ModuleKey].totalResultCount;
const getSortOrder = (state) => state[ModuleKey].sortOrder;
const getCurrentPage = (state) => state[ModuleKey].currentPage;
const getMaxPages = (state) => state[ModuleKey].maxPages;
const getScope = (state) => state[ModuleKey].scope;
const getScopeTypes = (state) => state[ModuleKey].scopeTypes;
const getFacets = (state) => state[ModuleKey].facets;
const getFacetSelections = (state) => state[ModuleKey].facetSelections;
const isSearchLoading = (state) => state[ModuleKey].isSearchLoading;
const isCodeFinderOpen = (state) => state[ModuleKey].isCodeFinderOpen;
const getFoundCustomers = (state) => state[ModuleKey].foundCustomers;
const getSelectedCustIds = (state) => state[ModuleKey].selectedCustIds;
const getSelectedCustomerFilters = (state) => state[ModuleKey].selectedCustomerFilters;
const getCustomerSearchOptions = (state) => state[ModuleKey].customerSearchOptions;
const isCodeFinderLoading = (state) => state[ModuleKey].isCodeFinderLoading;
const getSearchedCodeCount = (state) => state[ModuleKey].searchedCodeCount;
const getSelectedStructures = (state) => state[ModuleKey].selectedStructures;
const getCustomers = (state) => state[ModuleKey].customers;
const getRelatedTerms = (state) => state[ModuleKey].relatedTerms;
const isAdvancedSearchOpen = (state) => state[ModuleKey].isAdvancedSearchOpen;

export {
  isSearchResultsPage,
  isSearchFieldActive,
  getSuggestions,
  getQuery,
  getResults,
  getTotalResultCount,
  getSortOrder,
  getCurrentPage,
  getMaxPages,
  getScope,
  getScopeTypes,
  getFacets,
  getFacetSelections,
  isSearchLoading,
  isCodeFinderOpen,
  getFoundCustomers,
  getSelectedCustIds,
  getSelectedCustomerFilters,
  getCustomerSearchOptions,
  isCodeFinderLoading,
  getSearchedCodeCount,
  getSelectedStructures,
  getCustomers,
  getRelatedTerms,
  isAdvancedSearchOpen
};
