import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {branch, compose, renderNothing} from 'recompose';
import {createStructuredSelector} from "reselect";

import {
  getReviewers,
  getIa2FileTypes,
  hasIa2CommentAddPermission,
  hasIa2CommentUpdatePermission,
  hasIa2QuestionLockPermission,
  hasIa2QuestionUnlockPermission,
  getShowDiscussionText,
  getHideDiscussionText
} from "../../common/selectors";

import {toJS} from "../../common/utils";
import {
  addComment,
  addOptionFile,
  addQuestionNote,
  deleteComment,
  deleteOptionResponse,
  deleteQuestionNote,
  loadQuestionHistory,
  removeOptionFile,
  resetQuestionResponses,
  setNeedsReview,
  toggleExpanded,
  setLocked,
  updateComment,
  updateOptionResponse
} from "../actions";
import Question from '../components/Question';
import {
  getComments,
  getDefaultExpanded,
  getDisplayedQuestionIds,
  getError,
  getHighlightedText,
  getLastEdit,
  getNumber,
  getOptionListMode,
  getOptions,
  getQuestionHistory,
  getQuestionNote,
  getReferences,
  hasError,
  isComplete,
  isFinalized,
  isNeedsReview,
  isOpen,
  isLocked
} from "../selectors";

const mapStateToProps = createStructuredSelector({
  number: getNumber,
  optionListMode: getOptionListMode,
  options: getOptions,
  questionNote: getQuestionNote,
  references: getReferences,
  text: getHighlightedText,
  isNeedsReview: isNeedsReview,
  ia2FileTypes: getIa2FileTypes,
  showDiscussionText: getShowDiscussionText,
  hideDiscussionText: getHideDiscussionText,
  isFinalized: isFinalized,
  isOpen: isOpen,
  displayedQuestionIds: getDisplayedQuestionIds,
  error: getError,
  hasError: hasError,
  isComplete: isComplete,
  lastEdit: getLastEdit,
  questionHistory: getQuestionHistory,
  comments: getComments,
  reviewers: getReviewers,
  isLocked: isLocked,
  defaultExpanded: getDefaultExpanded,
  hasCommentAddPermission: hasIa2CommentAddPermission,
  hasCommentEditPermission: hasIa2CommentUpdatePermission,
  hasQuestionLockPermission: hasIa2QuestionLockPermission,
  hasQuestionUnlockPermission: hasIa2QuestionUnlockPermission
});

const mapDispatchToProps = (dispatch) => ({
  setNeedsReview: (id, checked) => {
    dispatch(setNeedsReview(id, checked));
  },
  toggleExpanded: (id) => {
    dispatch(toggleExpanded(id));
  },
  setLocked: (id, finalized) => {
    dispatch(setLocked(id, finalized));
  },
  createResponse: (questionId, optionId, type) => {
    dispatch(updateOptionResponse(questionId, optionId, type, null));
  },
  deleteResponse: (questionId, optionId) => {
    dispatch(deleteOptionResponse(questionId, optionId));
  },
  updateResponse: (questionId, optionId, type, responseValue) => {
    dispatch(updateOptionResponse(questionId, optionId, type, responseValue));
  },
  resetResponses: (questionId) => {
    dispatch(resetQuestionResponses(questionId));
  },
  addOptionFile: (questionId, optionId, file) => {
    dispatch(addOptionFile(questionId, optionId, file));
  },
  removeOptionFile: (questionId, optionId, optionFileId) => {
    dispatch(removeOptionFile(questionId, optionId, optionFileId));
  },
  addNote: (questionId, questionNote,fileDescriptors) => {
    dispatch(addQuestionNote(questionId,questionNote,fileDescriptors));
  },
  deleteNote: (questionId, questionNote) => {
    dispatch(deleteQuestionNote(questionId,questionNote));
  },
  loadQuestionHistory: (questionId) => {
    dispatch(loadQuestionHistory(questionId));
  },
  addComment: (questionId, commentText) => {
    dispatch(addComment(questionId, commentText));
  },
  deleteComment: (questionId, commentId) => {
    dispatch(deleteComment(questionId, commentId));
  },
  updateComment: (questionId, commentId, commentText) => {
    dispatch(updateComment(questionId, commentId, commentText));
  }
});

//if there is no content then don't bother rendering -- the question is likely loading
const QuestionOrNothing = branch(({text}) => !text && text !== '', renderNothing)(Question);
const QuestionContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionOrNothing);
QuestionContainer.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scrollTo: PropTypes.bool,
  inline: PropTypes.bool
};

export default QuestionContainer;