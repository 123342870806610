import * as PropTypes from "prop-types";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import PubDocMoveDialog from "../components/PubDocMoveDialog";
import {createStructuredSelector} from "reselect";
import {getAllCategories, getFilteredCategories, getDocumentMoveOpen, getPubDocs, getPubDocTypes, getSelectedDocIds,
  getDocumentMoveId} from "../selectors";
import {movePublicDocuments} from "../actions";
import {getCustId} from "../../common/selectors";


const mapStateToProps = createStructuredSelector({
  documentMoveOpen: getDocumentMoveOpen,
  documents: getPubDocs,
  custId: getCustId,
  pubDocTypes: getPubDocTypes,
  allCategories: getAllCategories,
  categories: getFilteredCategories,
  selectedDocIds: getSelectedDocIds,
  documentMoveId: getDocumentMoveId
});

const mapDispatchToProps = (dispatch) => ({
  movePubDoc: (custId, documentIdList, categoryId, typeId) => {
    dispatch(movePublicDocuments(custId, documentIdList, categoryId, typeId));
  }
});

const PubDocMoveDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocMoveDialog);
PubDocMoveDialogContainer.propTypes = {
  selectedDocIds: PropTypes.array,
  onCancel: PropTypes.func
};
export default PubDocMoveDialogContainer;