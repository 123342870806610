import {List, Record} from "immutable";
import {handleActions} from "redux-actions";
import * as actions from "../actions";

const Word = Record({
  word: "",
  references: List()
});
const Reference = Record({
  label: null,
  href: "#"
});
const IndexState = Record({
  words: List(),
  letter: null
});

const initialState = IndexState({});

const initializeState = (state, {payload: {words, letter}}) => state.set("words", words).set("letter", letter);

const reducer = handleActions({
  [actions.initializeState]: initializeState,
}, initialState);

export {IndexState, initialState, Word, Reference};
export default reducer;
