import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {getPubDocsFileTypes} from "../../common/selectors";
import {toJS} from "../../common/utils";
import {bulkAddPubDocs, clearBulkUploadedDocuments} from "../actions";
import PubDocBulkUploaderDialog from "../components/PubDocBulkUploaderDialog";
import {
  getAllCategories,
  getPubDocs,
  getPubDocTypes,
  getBulkUploaderLoading,
  getBulkUploadedDocuments,
  getPubDocTypeTitle
} from "../selectors";

const mapStateToProps = createStructuredSelector({
  pubDocTypes: getPubDocTypes,
  categories: getAllCategories,
  documents: getPubDocs,
  loading: getBulkUploaderLoading,
  uploadedDocuments: getBulkUploadedDocuments,
  pubDocsFileTypes: getPubDocsFileTypes,
  typeTitle: getPubDocTypeTitle
});

const mapDispatchToProps = (dispatch) => ({
  bulkAddPubDocs: (pubDocs) => {
    dispatch(bulkAddPubDocs(pubDocs));
  },
  clearBulkUploadedDocuments: () => {
    dispatch(clearBulkUploadedDocuments());
  }
});

const PubDocBulkUploaderDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocBulkUploaderDialog);
PubDocBulkUploaderDialogContainer.propTypes = {
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  typeSelectDisabled: PropTypes.bool
};
export default PubDocBulkUploaderDialogContainer;