import {ModuleKey} from "../ModuleKey";
import {Map} from "immutable";

const getLetter = (state) => state[ModuleKey].letter;
const getWords = (state) => state[ModuleKey].words ? state[ModuleKey].words : Map();

export {
  getLetter,
  getWords
};
