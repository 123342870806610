import * as PropTypes from "prop-types";
import React from "react";
import {Dialog, FormControl, InputLabel, MenuItem, Select} from "../../common/components";
import "./PubDocMoveDialog.css";

class PubDocMoveDialog extends React.Component {

  static propTypes = {
    documentMoveOpen: PropTypes.bool.isRequired,
    documents: PropTypes.array.isRequired,
    custId: PropTypes.string.isRequired,
    pubDocTypes: PropTypes.array.isRequired,
    allCategories: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    selectedDocIds: PropTypes.array.isRequired,
    documentMoveId: PropTypes.number,
    movePubDoc: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      categoryId: "Placeholder",
      selectedType: "Placeholder"
    };
  }

  handleCancelClick = () => {
    const {onCancel} = this.props;
    this.setState({categoryId: "Placeholder"});
    this.setState({selectedType: "Placeholder"});
    onCancel();
  };

  handleMoveClick = () => {
    const {movePubDoc, custId, documentMoveId, selectedDocIds, onCancel} = this.props;
    const {categoryId, selectedType} = this.state;
    const docIds = documentMoveId ? [documentMoveId] : selectedDocIds;
    movePubDoc(custId, docIds, categoryId, selectedType);
    onCancel();
  };

  handleChangeType = (event) => {
    this.setState({categoryId: ""});
    this.setState({selectedType: event.target.value});
  };

  handleChangeCategory = (event) => {
    this.setState({categoryId: event.target.value});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevEditOpen = prevProps.documentMoveOpen;
    const { documentMoveOpen, documentMoveId, selectedDocIds, documents, allCategories } = this.props;
    if (documentMoveOpen && !prevEditOpen) {
      const docIds = documentMoveId ? [documentMoveId] : selectedDocIds;
      const categoryIds = [...new Set(documents.filter(currDoc => docIds.includes(currDoc.id)).map(currDoc => currDoc.categoryId))];
      if (categoryIds.length === 1) {
        //Since we only have one category we can prefill the categoryId state with that
        this.setState({categoryId: categoryIds[0]});
      } else if (categoryIds.length === 0) {
        //Since we have no categories present just set it to uncategorized
        this.setState({categoryId: "Uncategorized"});
      }
      const category = allCategories.filter(currCategory => {return currCategory.id === categoryIds[0];});
      const typeId = category.length > 0 ? category[0].typeId : null;
      this.setState({selectedType: typeId});
    }
  }

  render() {
    const {documentMoveOpen, documentMoveId, selectedDocIds, documents, pubDocTypes, allCategories} = this.props;
    const {categoryId, selectedType} = this.state;
    const docIds = documentMoveId ? [documentMoveId] : selectedDocIds;
    const selectedDocuments = documents.filter(document => docIds.includes(document.id));
    return(
      <Dialog
        title="Move Documents"
        open={documentMoveOpen}
        onCancel={this.handleCancelClick}
        onSubmit={this.handleMoveClick}
        submitLabel="Move"
        submitDisabled={categoryId === ""}
        width="md"
      >
        <div>
          <ul className={"pubDocMoveDialogList"}>
            {selectedDocuments.map(currDoc => <li className={"pubDocMoveDialogListItem"} key={currDoc.id}>{currDoc.title}</li>)}
          </ul>

          <FormControl fullWidth>
            <InputLabel>Type:</InputLabel>
            <Select value={selectedType} onChange={this.handleChangeType}>
              {pubDocTypes.map(currType =>
                <MenuItem key={currType.id} value={currType.id}>{currType.title}</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Category:</InputLabel>
            <Select value={categoryId} onChange={this.handleChangeCategory}>
              {(allCategories.filter(currCategory => {return selectedType === currCategory.typeId && currCategory.title === "(Uncategorized)";}).length === 0)
              && (<MenuItem value={"Uncategorized"}>(Uncategorized)</MenuItem>)}
              {allCategories.filter(currCategory => {return selectedType === currCategory.typeId;})
                .sort((cat1, cat2) => {return new Intl.Collator([], { numeric: true, sensitivity: 'base' }).compare(cat1.title.toLowerCase(), cat2.title.toLowerCase());})
                .map(catToAdd =>
                  <MenuItem key={catToAdd.id} value={catToAdd.id}>{catToAdd.title}</MenuItem>
                )}
            </Select>
          </FormControl>
        </div>
      </Dialog>
    );
  }
}
export default PubDocMoveDialog;