import {createStructuredSelector} from "reselect";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../utils";
import HomePage from "../components/HomePage"
import {
  getAnalysisId,
  getArchiveId,
  getCustId,
  getCustomer,
  getCustomization,
  getLawLedgerCount,
  getPubDocsOnly,
  hasDocumentAdminPermission,
  hasLawViewPermission,
  hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission,
  hasViewPermission
} from "../selectors";
import {getPubDocTypes} from "../../pub-docs/selectors";
import {getTextQuickLinks, getContentGraphicalQuickLinks} from "../../admin-settings/selectors";


const mapStateToProps = createStructuredSelector({
  customization: getCustomization,
  customer: getCustomer,
  custId: getCustId,
  pubDocsOnly: getPubDocsOnly,
  hasViewPermission: hasViewPermission,
  hasLawViewPermission: hasLawViewPermission,
  lawLedgerCount: getLawLedgerCount,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  hasPubDocTypeAddPermission: hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission,
  pubDocTypes: getPubDocTypes,
  archiveId: getArchiveId,
  textQuickLinks: getTextQuickLinks,
  contentGraphicalQuickLinks: getContentGraphicalQuickLinks,
  analysisId: getAnalysisId
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(HomePage);
