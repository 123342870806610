/*
 * Copyright 2019 General Code
 */

import {API} from "../actions/types";
import {apiError, apiStart, apiEnd} from "../actions/api";
import {getJson, postJson, putJson, deleteJson, patchJson} from '../utils/fetch';

const apiMiddleware = ({dispatch}) => next => async action => {

  if (!action) return;

  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data,
    errorMessage,
    onSuccess,
    onFailure,
    label,
    options
  } = action.payload;
  let requestMethod;
  dispatch(apiStart(label));
  switch (method) {
    case "GET":
      requestMethod = getJson;
      break;
    case "POST":
      requestMethod = postJson;
      break;
    case "PUT":
      requestMethod = putJson;
      break;
    case "DELETE":
      requestMethod = deleteJson;
      break;
    case "PATCH":
      requestMethod = patchJson;
      break;
    default:
      dispatch(apiError(label, new Error(`Invalid api(${label}) method: ${method} for "${url}"`)));
      return;
  }

  try {
    const response = await requestMethod.apply(null, [url, data, options]);
    if (onSuccess) {
      if (Array.isArray(onSuccess)) {
        for (let i = 0; i < onSuccess.length; i++) {
          dispatch(onSuccess[i](response));
        }
      } else {
        dispatch(onSuccess(response));
      }
    }
  } catch (e) {
    dispatch(apiError(label, errorMessage, e));
    if (onFailure) {
      dispatch(onFailure(e));
    }
  } finally {
    dispatch(apiEnd(label));
  }
};

export default apiMiddleware;
