import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {getLetter} from "../selectors";
import {getCodeId, getCustId} from "../../common/selectors";
import IndexPageControls from "../components/IndexPageControls";

const mapStateToProps = createStructuredSelector({
  letter: getLetter,
  custId: getCustId,
  codeId: getCodeId
});

const mapDispatchToProps = () => ({});

const IndexPageControlsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(IndexPageControls);
export default IndexPageControlsContainer;