import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import {getIa2FileTypes} from "../../common/selectors";
import {addOptionFile, removeOptionFile, updateOptionResponse} from "../actions";
import AdditionalOptionContent from "../components/AdditionalOptionContent";
import {isFinalized, isLocked} from "../selectors";

const mapStateToProps = createStructuredSelector({
  ia2FileTypes: getIa2FileTypes,
  isFinalized: isFinalized,
  isLocked: isLocked
});

const mapDispatchToProps = (dispatch) => ({
  updateResponse: (questionId, optionId, type, responseValue) => {
    dispatch(updateOptionResponse(questionId, optionId, type, responseValue));
  },
  addOptionFile: (questionId, optionId, file) => {
    dispatch(addOptionFile(questionId, optionId, file));
  },
  removeOptionFile: (questionId, optionId, optionFileId) => {
    dispatch(removeOptionFile(questionId, optionId, optionFileId));
  }
});

const AdditionalOptionContentContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(AdditionalOptionContent);
AdditionalOptionContentContainer.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  option: PropTypes.object.isRequired
};
export default AdditionalOptionContentContainer;