import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import Dialog from "../components/./dialog/Dialog";
import {getDialogOnCancel, getDialogOnSubmit, getDialogOnClose, getDialogWidth, getDialogMaxWidth,
  getDialogFullWidth, getDialogOpen, getDialogTitle, getDialogContextId, getDialogActions,
  getDialogContent, getDialogCancelLabel, getDialogSubmitLabel, getDialogSubmitDisabled,
  getDialogTitleProps, getDialogActionsProps, getDialogContentProps} from "../selectors";
import {toJS} from "../utils";
import * as PropTypes from "prop-types";

const mapStateToProps = createStructuredSelector({
  onCancel: getDialogOnCancel,
  onSubmit: getDialogOnSubmit,
  onClose: getDialogOnClose,
  width: getDialogWidth,
  maxWidth: getDialogMaxWidth,
  fullWidth: getDialogFullWidth,
  open: getDialogOpen,
  title: getDialogTitle,
  contextId: getDialogContextId,
  actions: getDialogActions,
  content: getDialogContent,
  cancelLabel: getDialogCancelLabel,
  submitLabel: getDialogSubmitLabel,
  submitDisabled: getDialogSubmitDisabled,
  DialogTitleProps: getDialogTitleProps,
  DialogActionsProps: getDialogActionsProps,
  DialogContentProps: getDialogContentProps
});

const mapDispatchToProps = () => ({});

const DialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(Dialog);
DialogContainer.propTypes = {
  id: PropTypes.string.isRequired
};
export default DialogContainer;
