import {connect} from 'react-redux';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import PubDocsPageTitle from "../components/PubDocsPageTitle";
import { getPubDocTypeTitle } from "../selectors";
import { isNewNav } from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  pubDocTypeTitle: getPubDocTypeTitle,
  isNewNav: isNewNav
});

const mapDispatchToProps = () => ({});

const PubDocsPageTitleContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsPageTitle);
export default PubDocsPageTitleContainer;