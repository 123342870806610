/**
 * Questions component
 * @module ecode/questions
 *
 * Copyright 2018 General Code
 */
import 'nodelist-foreach-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as actions from './actions';
import {ModuleKey} from './ModuleKey';
import reducer, {initialState} from './reducers';
import * as selectors from './selectors';
import ToolSpeedDial from "./components/ToolSpeedDial";

export const getInitialState = (loadingState) => {
  let contentState = loadingState.get(ModuleKey) || initialState;
  return loadingState.set(ModuleKey, contentState);
}

// noinspection JSUnusedLocalSymbols
/**
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 * @param {function} state - The redux state
 */
const init = (Providers, dispatch, state) => {

  let toolSpeedDialContainer = document.getElementById('toolSpeedDialContainer');
  if (toolSpeedDialContainer) {
    ReactDOM.render(
      <Providers key="content-provider" name="content-provider">
        <ToolSpeedDial/>
      </Providers>,
      toolSpeedDialContainer
    );
  }
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer,
  selectors
};
