const SEARCH = "SEARCH";
const CODE = "CODE";
const NOTES = "NOTES";
const LAWS = "LAWS";
const PUBLIC_DOCUMENTS = "PUBLIC_DOCUMENTS";
const QUESTIONS = "QUESTIONS";
const ADMIN = "ADMIN";
const HELP = "HELP";
const ZONING = "ZONING";
const OTHER = "OTHER";
const HOME = "HOME";

export {
  SEARCH,
  CODE,
  NOTES,
  LAWS,
  PUBLIC_DOCUMENTS,
  QUESTIONS,
  ADMIN,
  HELP,
  ZONING,
  OTHER,
  HOME
};