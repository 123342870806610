import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import {getReviewers} from "../../common/selectors";
import {setAssignedTo} from "../actions";
import ReviewerSelect from "../components/ReviewerSelect";
import {getAssignedTo, isFinalized, isLocked} from "../selectors";

const mapStateToProps = createStructuredSelector({
  assignedTo: getAssignedTo,
  reviewers: getReviewers,
  isFinalized: isFinalized,
  isLocked: isLocked
});

const mapDispatchToProps = (dispatch) => ({
  setAssignedTo: (id, assignedTo) => {
    dispatch(setAssignedTo(id, assignedTo));
  },
});

const ReviewerSelectContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(ReviewerSelect);
ReviewerSelectContainer.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default ReviewerSelectContainer;