import React from "react";
import PropTypes from "prop-types";

import {Chip, PageControl, PageControlHeader, PageControlContent} from "../";
import {If} from "../../containers";

import "./FiltersWidget.css";

const FiltersWidget = ({filters, remove, ...props}) => (
  <If test={filters && filters.length > 0}>
    <PageControl {...props} className="fixed filtersWidget">
      <PageControlHeader label="Current Filters"/>
      <PageControlContent>
        {filters.map(filter => (
          <Chip
            key={filter.key}
            label={filter.name}
            title={filter.name}
            className="filterChip"
            onDelete={remove(filter.string)}
          />
        ))}
      </PageControlContent>
    </PageControl>
  </If>
);

FiltersWidget.propTypes = {
  filters: PropTypes.array.isRequired,
};

export {FiltersWidget};
export default FiltersWidget;