import React from "react";
import ReactDOM from "react-dom";
import {List, Map} from "immutable";
import * as actions from "./actions";
import {initializeState} from "./actions";
import {ModuleKey} from "./ModuleKey";
import reducer, {initialState, Reference, Word} from "./reducers";
import * as selectors from "./selectors";
import {newNav, pageSubType} from "../common/utils/server-data";
import {INDEX} from "../common/utils/page-sub-types";
import IndexPageContainer from "./containers/IndexPageContainer";
import {indexFilterWidgetEl, indexPageContentEl, indexWordAreas} from "../common/components/domNodes";

const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  let indexState = loadingState.get(ModuleKey) || initialState;
  return loadingState.set(ModuleKey, indexState.withMutations(loadingIndexState =>
    loadingIndexState.set("words", Map())
  ));
};

/**
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 */
const init = (Providers, dispatch) => {
  if (newNav && pageSubType === INDEX) {

    let letter = "Number";
    if (indexFilterWidgetEl) {
      letter = indexFilterWidgetEl.dataset.letter;
    }

    if (indexPageContentEl) {
      const words = [];
      for (const wordContainerElement of indexWordAreas) {
        const word = wordContainerElement.dataset.word;
        const wordReferenceElements = wordContainerElement.getElementsByClassName("wordReference");
        const references = [];
        for (const wordReferenceElement of wordReferenceElements) {
          const referenceLabel = wordReferenceElement.innerText || wordReferenceElement.textContent;
          const referenceHref = wordReferenceElement.href;
          references.push(Reference({label: referenceLabel, href: referenceHref}));
        }
        words.push(Word({word: word, references: List(references)}));
      }
      dispatch(initializeState(List(words), letter));
      ReactDOM.render(
        <Providers key="index-page-providers" name="index-page">
          <IndexPageContainer/>
        </Providers>,
        indexPageContentEl
      );
    }
  }
};

export {ModuleKey} from "./ModuleKey";
export {
  getInitialState,
  init,
  actions,
  reducer,
  selectors
};
