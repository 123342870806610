import React from "react";
import * as PropTypes from "prop-types";

import {archiveId, custId} from "../../common/utils/server-data";
import {Edit as EditIcon, FilterList as FilterListIcon, Pdf as PdfIcon} from "../../common/components/icons";
import {IconButton, InputAdornment, Paper, TextField, Tooltip} from "../../common/components";
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "../../common/components/table";
import {If} from "../../common/containers";
import EditLawDialogContainer from "../containers/EditLawDialogContainer";
import moment from "moment";

const TitleCellType = ({row, fieldName}) => (
  <div className="titleLawCell">
    <a className="law" href={`/${custId}/laws/${row.documentId}.pdf`} target="_blank" title={row[fieldName]} rel="noopener noreferrer">
      <span>{row[fieldName]}</span>&nbsp;<PdfIcon size={16}/>
    </a>
  </div>
);

const SimpleCellType = ({row, fieldName}) => (
  <div className={`${fieldName}LawCell`}>{row[fieldName]}&nbsp;</div>
);

const DispositionCellType = ({row, fieldName}) => (
  <div className="dispositionsLawCell">
    {row[fieldName].length === 0 ? "" : row[fieldName]
    .map((disposition) => <a key={disposition.guid} className="xref dispositionXref"
                             href={(archiveId ? ("/" + archiveId) : "") + "/" + disposition.guid + "#" + disposition.guid}>{disposition.name}</a>)
      .reduce((prev, curr) => [prev, ', ', curr])}&nbsp;
  </div>
);

const SuppedByCellType = ({row, fieldName}) => {
  const suppedBy = row[fieldName];
  if (!suppedBy) return (<div className="suppedByLawCell"/>);
  const linkTitle = !suppedBy.publishingIdentifier ? moment(suppedBy.updatedDate).format("YYYY-MM-DD")
    : (suppedBy.publishingIdentifier + "- Last Updated " + moment(suppedBy.importTime).format("YYYY-MM-DD"));
  return (
    <div className="suppedByLawCell">
      <a href={"/law-ledger/" + (suppedBy ? suppedBy.id : "")} className="codelink">{linkTitle}</a>
    </div>
  );
};

const EditCellType = ({row, startEditing}) => (
  <div className="editLawCell">
    <IconButton aria-label="Edit" onClick={() => startEditing(row.documentId)}>
      <EditIcon/>
    </IconButton>
  </div>
);

const COLUMN_INFO = {
  title: {
    header: "Title",
    component: TitleCellType,
    sortable: true,
    defaultSort: "asc"
  },
  adopted: {
    header: "Adopted",
    component: SimpleCellType,
    sortable: true,
    defaultSort: "desc"
  },
  subject: {
    header: "Subject",
    component: SimpleCellType,
    sortable: true,
    defaultSort: "asc"
  },
  dispositions: {
    header: "Affects",
    component: DispositionCellType,
    sortable: true,
    defaultSort: "asc"
  },
  suppedBy: {
    header: "Supped By",
    component: SuppedByCellType,
    sortable: true,
    defaultSort: "asc"
  },
  edit: {
    header: " ",
    width: "61px",
    component: EditCellType
  },
};

const getSortProps = (columnName, sort) => {
  const matches = sort.filter(s => s.columnName === columnName);
  return matches.length > 0 ? {active: true, direction: matches[0].direction} : {active: false};
};

const getNewSort = (columnName, currentSort, append) => {
  const matches = currentSort.filter(s => s.columnName === columnName);
  if (append) {
    if (matches.length > 0) {
      return [...currentSort.map(s => {
        if (s.columnName !== columnName) return s;
        return {columnName, direction: s.direction === "asc" ? "desc" : "asc"};
      })];
    } else {
      return [...currentSort, {columnName, direction: COLUMN_INFO[columnName].defaultSort}];
    }
  } else if (matches.length > 0) {
    return [{columnName, direction: matches[0].direction === "asc" ? "desc" : "asc"}];
  } else {
    return [{columnName, direction: COLUMN_INFO[columnName].defaultSort}];
  }
};

const filterRows = (filter, year, chapters) => (law) => {
  const f = filter.toLowerCase();
  return (f === ""
    || law.title.toLowerCase().indexOf(f) >= 0
    || law.customTitle.toLowerCase().indexOf(f) >= 0
    || law.adopted.toLowerCase().indexOf(f) >= 0
    || law.subject.toLowerCase().indexOf(f) >= 0
    || law.comment.toLowerCase().indexOf(f) >= 0
    || law.dispositions.filter(d => d.name.toLowerCase().indexOf(f) >= 0).length > 0)
    && (!year || law.adoptedYear === year || (!law.adoptedYear && (year === -1 || year === "-1")))
    && (!chapters || chapters.length === 0 || law.dispositions.filter(d => d.chapter && chapters.indexOf(d.chapter.guid) !== -1).length > 0);
};

const compareRows = (sorting) => (a, b) => {
  let order = 0;
  for (let i = 0; i < sorting.length && order === 0; i++) {
    const direction = sorting[i].direction === "asc" ? 1 : -1;
    let aVal = getCellValue(sorting[i].columnName, a);
    let bVal = getCellValue(sorting[i].columnName, b);
    if (bVal && !aVal) {
      order = direction;
    } else if (aVal && !bVal) {
      order = direction * -1;
    } else if (aVal > bVal) {
      order = direction;
    } else if (bVal > aVal) {
      order = direction * -1;
    }
  }
  return order;
};

const getCellValue = (columnName, rowData) => {
  let value = rowData[columnName];
  if (Array.isArray(value) && value.length > 0) {
    return formatCellValue(value[0]);
  } else {
    return formatCellValue(value);
  }
};

const formatCellValue = (value) => {
  if (value !== null && typeof value === 'object') value = (value.sortId ? value.sortId : value.name);
  if (typeof value === "string") value = value.toUpperCase();
  return value;
};

class LawTable extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.string,
    laws: PropTypes.array,
    filterText: PropTypes.string,
    filterYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterChapters: PropTypes.array,
    isNewNav: PropTypes.bool,
    setFilterText: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    startEditing: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {columns} = props;
    this.state = { columns: columns.split(",").map(columnName => ({name: columnName, ...COLUMN_INFO[columnName]})) };
  }

  sortingChanged = (columnName) => (e) => {
    const {sort, setSort} = this.props;
    const newSort = getNewSort(columnName, sort, e.shiftKey);
    setSort(newSort);
  };

  filterChanged = (e) => {
    this.props.setFilterText(e.target.value);
  };

  render() {
    const {columns} = this.state;
    const {laws, filterText, filterYear, filterChapters, sort, isNewNav, startEditing, isFilterable, isSortable, tableId} = this.props;
    const isLawLedger = (tableId === "lawLedgerTable");
    if (sort && sort.length > 0) laws.sort(compareRows(sort));
    return (
      <div id={tableId + "Container"} className="lawTableContainer">
        {!isNewNav && isFilterable === "true" &&
          <div className="lawTableTopContainer">
            <TextField
              className="lawTableSearch"
              value={filterText}
              onChange={this.filterChanged}
              InputProps={{
                startAdornment: <InputAdornment position="start"><label htmlFor={isLawLedger ? "lawLedgerTableSearchField" : "lawTableSearchField"}><FilterListIcon title="Filter the Laws listing"/></label></InputAdornment>,
                id: (isLawLedger ? "lawLedgerTableSearchField" : "lawTableSearchField"),
                title: "Filter the Laws listing",
                'aria-label': "Filter the Laws listing"
              }}
            />

          </div>
        }
        <Paper className="paperColor responsiveTable">
          <Table id={tableId} className="lawTable reactTable">
            <colgroup>
              {columns.map((column) =>
                <col key={column.name} style={column.width ? {width: column.width} : {}}/>
              )}
            </colgroup>
            <TableHead>
              <TableRow>
                {columns.map((column) =>
                  <TableCell key={column.name}>
                    {column.sortable && isSortable === "true" ?
                      <Tooltip
                        title="Sort"
                        placement="bottom-start"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          {...getSortProps(column.name, sort)}
                          onClick={this.sortingChanged(column.name)}
                        >
                          {column.header}
                        </TableSortLabel>
                      </Tooltip>
                      : column.header
                    }
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {laws
                .filter(filterRows(filterText, filterYear, filterChapters))
                .map(law => [
                  <TableRow key={law.documentId}>
                    {columns.map((column) =>
                      <TableCell key={`${law.documentId}-${column.name}`} style={law.comment ? {borderBottomWidth: "0px"} : {}} data-column-name={column.header.trim() ? column.header : null}>
                        <column.component
                          row={law}
                          fieldName={column.name}
                          startEditing={startEditing}
                        />
                      </TableCell>
                    )}
                  </TableRow>,
                  <If test={law.comment} key={`${law.documentId}-detailRow`}>
                    <TableRow className="tableDetailRow">
                      <TableCell colSpan={columns.length}>
                        <div>{law.comment}</div>
                      </TableCell>
                    </TableRow>
                  </If>
                ])}
            </TableBody>
          </Table>
        </Paper>
        <EditLawDialogContainer/>
      </div>
    );
  }
}

export default LawTable;
