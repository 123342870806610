import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {getPageSubType, getVersions} from "../selectors";
import {getLetter} from "../../index/selectors";
import {toJS} from "../utils";
import VersionSelector from "../components/VersionSelector";

const mapStateToProps = createStructuredSelector({
  versions: getVersions,
  pageSubType: getPageSubType,
  indexLetter: getLetter
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(VersionSelector);
