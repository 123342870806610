import classNames from "classnames";

import * as PropTypes from "prop-types";
import React, {Component} from "react";
import scrollToComponent from "react-scroll-to-component";
import {buildLoadableSingle} from "../buildLoadable";
import {putFile} from "../../utils/fetch";
import {ReferenceComponent, referenceStrategy} from "../../decorators/reference";
import {ContentState, EditorState, convertToRaw, getSelectedBlock, Modifier} from "../../draftjs/dynamic";
import draftToHtml from "../../draftjs/draftjs-to-html";
import htmlToDraft from "../../draftjs/html-to-draftjs";
import InsertReferenceEditorButtonContainer from "../../containers/InsertReferenceEditorButtonContainer";
import "./Editor.css";

const DraftEditor = buildLoadableSingle(import("../../editor/Editor"), "DraftEditor");

class Editor extends Component {

  static propTypes = {
    html: PropTypes.string,
    editing: PropTypes.bool.isRequired,
    scrollIntoView: PropTypes.bool.isRequired
  };
  static defaultProps = {
    editing: true,
    scrollIntoView: false
  };

  editorWrapperRef = null;

  constructor(props) {
    super(props);
    this.state = {
      editorState: this.initEditorState(this.props.html),
      focusOffset: null,
      focusKey: null
    }
  }

  initEditorState = (html) => {
    let editorState = EditorState.createEmpty();
    if (html) {
      const blocksFromHTML = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      editorState = EditorState.createWithContent(contentState);
    }
    return editorState;
  };

  setHtml = (html) => {
    this.setState({ editorState: this.initEditorState(html) });
  };

  removeWhiteBackground = (html) => html.replace(/background-color:\s*rgb\(255,255,255\)/g, '');

  getHtml = (editorState) => {
    if (!editorState && this.editorRef?.props?.editorState) {
      editorState = this.editorRef.props.editorState;
    }
    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const rawState = convertToRaw(contentState);
      return this.removeWhiteBackground(draftToHtml(rawState));
    }
    return "";
  };

  uploadCallBack = (file) => putFile(`/user/file`, file, {
    cache: "no-cache",
    headers: { "File-name": file.name },
  }).catch(error => {
    throw new Error(`Failed to upload image [${file.name}] - ${error}`);
  }).then(payload =>
    ({data: {link: payload.url}})
  ).catch(e => {
    console.error(`Failed to put file: ${e}`);
    throw e;
  });

  scrollIntoView = () => {
    if (this.editorWrapperRef && this.props.scrollIntoView) {
      scrollToComponent(this.editorWrapperRef, {
        offset: -176,
        align: "top",
        duration: 300,
      });
    } else {
      //If we don't have a ref keep trying until we do
      setTimeout(this.scrollIntoView, 50);
    }
  };
  setWrapperRef = (editorWrapperRef) => {
    this.editorWrapperRef = editorWrapperRef;
    if (this.props.editing) {
      this.scrollIntoView();
    }
  };

  setEditorRef = (editorRef) => {
    this.editorRef = editorRef;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editing && !prevProps.editing) {
      this.scrollIntoView();
    }
    if (this.props.html !== prevProps.html) {
      this.setHtml(this.props.html);
    }
  }

  componentDidMount() {
    if (this.props.editing) {
      this.scrollIntoView();
    }
  };

  onChange = (newEditorState) => {
    this.setState({ editorState: newEditorState });
    if (this.props.onChange) {
      this.props.onChange(this.getHtml(newEditorState));
    }
  };

  focusEditor = () => {
    this.editorRef.focus();
  };

  refreshSelection = () => {
    const editorState = this.state.editorState;
    let focusIndex = this.state.focusOffset;
    if (focusIndex == null) {
      focusIndex = (editorState?.getSelection()?.focusOffset || null);
    }
    let focusKey = this.state.focusKey;
    if (focusKey == null) {
      focusKey = (getSelectedBlock(editorState)?.getKey() || null);
    }
    if (editorState && focusIndex !== null && focusKey !== null) {
      let updatedSelection = editorState.getSelection().merge({
        anchorKey: focusKey,
        focusKey: focusKey,
        anchorOffset: focusIndex,
        focusOffset: focusIndex
      });
      this.setState({editorState: EditorState.forceSelection(editorState, updatedSelection)});
    }
  };

  insertSpace = (editorState, spaceIndex) => {
    const updatedSelection = editorState.getSelection().merge({
      anchorOffset: spaceIndex,
      focusOffset: spaceIndex,
    });
    let newEditorState = EditorState.forceSelection(editorState, updatedSelection);
    const contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      updatedSelection,
      ' ',
      newEditorState.getCurrentInlineStyle(),
      undefined,
    );
    newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');
    return newEditorState;
  };

  addReferenceLink = ({text, url, targetOption}) => {

    let newEditorState = this.state.editorState;
    let selectedBlock = getSelectedBlock(newEditorState);
    let selectedBlockText = selectedBlock.getText();
    let focusOffset = newEditorState.getSelection().focusOffset;
    let previousChar = selectedBlockText.charAt(focusOffset - 1);

    // If the previous character wasn't a space, add one before inserting the link
    if (previousChar !== ' ') {
      newEditorState = this.insertSpace(newEditorState, focusOffset);
      selectedBlock = getSelectedBlock(newEditorState);
      selectedBlockText = selectedBlock.getText();
      focusOffset += 1;
    }

    // Check if we need to insert a space after the link later
    let spaceAlreadyPresent = false;
    if (selectedBlockText.charAt(focusOffset) === ' ') {
      spaceAlreadyPresent = true;
    }

    // Create and insert the new entity
    let currentContent = newEditorState.getCurrentContent()
    .createEntity("REFERENCE", "IMMUTABLE", {
      text: text,
      url: url,
      targetOption: targetOption
    });
    const entityKey = currentContent.getLastCreatedEntityKey();
    let updatedSelection = newEditorState.getSelection().merge({
      anchorOffset: focusOffset,
      focusOffset: focusOffset
    });
    newEditorState = EditorState.forceSelection(newEditorState, updatedSelection);
    let contentState = Modifier.replaceText(
      currentContent,
      updatedSelection,
      text,
      newEditorState.getCurrentInlineStyle(),
      entityKey
    );
    newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');
    focusOffset += text.length;

    // Insert a blank space after reference if necessary
    if (!spaceAlreadyPresent) {
      newEditorState = this.insertSpace(newEditorState, focusOffset);
    }

    this.onChange(newEditorState);

    setTimeout(() => { this.refreshSelection(); }, 100);
  };

  onFocus = (e) => {
    // If we're actually switching focus away from the editor content, trigger any focus events
    // and set values for the focus-related state values
    if (this.state.focusOffset === null && e.target.className.indexOf("DraftEditor-content") !== -1) {
      if (this.props.onFocus) {
        this.props.onFocus(this.getHtml());
      }
      this.setState({
        focusOffset: this.state.editorState.getSelection().focusOffset,
        focusKey: this.state.editorState.getSelection().focusKey
      });
    }
  };

  onBlur = (e) => {
    // If we're actually switching focus to the editor content, trigger any blur events
    // and clear the focus-related state values
    if (this.state.focusOffset !== null && e.target.className.indexOf("DraftEditor-content") !== -1) {
      if (this.props.onBlur) {
        this.props.onBlur(this.getHtml());
      }
      this.setState({
        focusOffset: null,
        focusKey: null
      });
    }
  };

  render() {
    const {editing, html, scrollIntoView, onChange, onBlur, onFocus, ...otherProps} = this.props;
    const {editorState} = this.state;
    return (
      <div className="editorWrapper" ref={this.setWrapperRef}>
        <DraftEditor
          toolbarHidden={!editing}
          readOnly={!editing}
          editorState={editorState}
          onEditorStateChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          spellCheck={true}
          editorClassName={classNames({
            "editorEditing": editing,
            "editorInactive": !editing,
            "editor": true,
          })}
          editorRef={this.setEditorRef}
          handlePastedText={() => {}}
          customDecorators={[
            {
              strategy: referenceStrategy,
              component: ReferenceComponent
            },
          ]}
          toolbarCustomButtons={[
              <InsertReferenceEditorButtonContainer
                key={"AddReferenceButton"}
                ReferenceComponent={InsertReferenceEditorButtonContainer}
                addReferenceLink={this.addReferenceLink}
                focusEditor={this.focusEditor}
              />
          ]}
          toolbar={{
            inline: {
              options: ["bold", "italic", "underline", "strikethrough", "superscript", "subscript"],
            },
            emoji: {
              emojis: ["🇺🇸", "😀", "😎", "☹", "👶", "👦", "👧", "👨", "👩", "👮", "👪", "👈", "👉", "✋", "👍", "👎", "✍", "🤝", "☂", "❤", "🛑", "📢", "🚮", "♿", "⚠", "🚫", "♻", "✔", "❌", "❓", "🐕", "🐈", "🐦", "🐟", "🐛", "🌲", "🌳", "🌎", "☀", "🌧", "❄", "🔥", "⚡", "🎟", "🥇", "⚽", "🎭", "🍔", "🎂", "🏕", "🏗", "🏘", "🚌", "🚑", "🚒", "🚓", "🚗", "🚲", "🚨", "🚦", "🚧", "✈", "⏰", "🌡", "🎈", "🎁", "☎", "🖨", "🔋", "📺", "💡", "📖", "📰", "💳", "✉", "📤", "📥", "📪", "🗳", "📝", "📁", "🔒", "🔑", "☠"]
            },
            image: {
              uploadCallback: this.uploadCallBack,
              previewImage: true,
              alignmentEnabled: false,
              alt: { present: true },
            },
            attachment: {
              uploadCallback: this.uploadCallBack,
            },
            options: [
              "fontFamily",
              "fontSize",
              "inline",
              "colorPicker",
              "textAlign",
              "list",
              "attachment",
              "link",
              "emoji",
              "image",
              "remove",
              "history"
            ],
          }}
          {...otherProps}
        />
      </div>
    );
  }
}

export default Editor;