import * as PropTypes from "prop-types";
import React from "react";
import NavigationSubItem from "./NavigationSubItem";

class NavigationItem extends React.PureComponent {

  static propTypes = {
    hidden: PropTypes.bool,
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    selected: PropTypes.bool,
    title: PropTypes.string,
    count: PropTypes.number,
    iconSize: PropTypes.number,
    railExpanded: PropTypes.bool,
    expandRail: PropTypes.func,
    collapseRail: PropTypes.func
  };

  toggleExpanded = () => {
    const { expanded, expandRail, collapseRail } = this.props;
    if (!expanded && expandRail) expandRail();
    if (expanded && collapseRail) collapseRail();
  }

  getSubItems = (children) => {
    if (!children) return [];
    let subItems = [];
    for (const child of children) {
      if (Array.isArray(child)) {
        subItems = subItems.concat(this.getSubItems(child));
      } else if (child.type && child.type.displayName === NavigationSubItem.displayName) {
        subItems.push(child);
      }
    }
    return subItems;
  }

  render() {
    const { hidden, label, href, openNewTab, selected, title, children, count, expanded, icon } = this.props;
    if (hidden) return null;
    const subItems = this.getSubItems(children);
    const visibleSubItems = subItems ? subItems.filter(c => (c.props && !c.props.hidden && (c.props.href || c.props.onClick))) : [];
    if (visibleSubItems.length > 1) {
      let itemLabel = "";
      if(count != null) {
        itemLabel = label +  " (" + count +  ")";
      } else {
        itemLabel = label;
      }
      return (
        <div className={"item" + (selected ? " selected" : "") + " nav-expand " + (expanded ? "expanded" : "collapsed")} aria-roledescription={"button"}>
          <button className="item-label expand-toggle no-js-link" title={title ? title : label} onClick={this.toggleExpanded}>
            {icon}
            <span className="item-label-text">
              {itemLabel}
            </span>
          </button>
          <div className="subitems">
            <div className="item-wrapper">
              {subItems}
            </div>
          </div>
        </div>
      );
    } else {
      const parentHref = href ? href : (visibleSubItems.length > 0 ? visibleSubItems[0].props.href : null);
      if (!parentHref) return null;
      const parentOpenNewTab = openNewTab ? openNewTab : (visibleSubItems.length > 0 ? visibleSubItems[0].props.openNewTab : false);
      let itemLabel = "";
      if(count != null) {
        itemLabel = label +  " (" + count +  ")";
      } else {
        itemLabel = label;
      }
      return (
        <div className={"item" + (selected ? " selected" : "")}>
          <a className={"item-label"}
             href={parentHref}
            title={title ? title : label}
            target={(!selected && parentOpenNewTab) ? "_blank" : null}
            rel={(!selected && parentOpenNewTab) ? "noopener noreferrer" : null}
          >
            {icon}
            <span className="item-label-text">
              {itemLabel}
            </span>
          </a>
        </div>
      );
    }
  }
}

export default NavigationItem;