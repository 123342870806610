/*
 * Copyright 2018 General Code
 */
import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import {IconButton} from "../../common/components";
import {Collapse, Expand} from "../../common/components/icons";

import "./QuestionIcon.css";

const QuestionIcon = ({toggleExpanded, isOpen}) => (
  <Fragment>
    <IconButton className="questionIcon" onClick={toggleExpanded} title={isOpen ? "Collapse question" : "Expand question"}>
      {isOpen ?
        <Collapse title="Collapse question"/> :
        <Expand title="Expand question"/>
      }
    </IconButton>
  </Fragment>
);

QuestionIcon.propTypes = {
  toggleExpanded: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default QuestionIcon;
