import {ModuleKey} from "../ModuleKey";
import {Map} from "immutable";
import {getReferenceGroupNames} from "../ReferenceGroupFactory";

const getReferenceNodes = (state, props) => {
  return state[ModuleKey]?.referenceGroups.get(props.groupName)?.nodes;
}
const getReferenceMatches = (state, props) => {
  return state[ModuleKey]?.referenceGroups.get(props.groupName)?.matches;
}
const getAreMatchesLoading = (state, props) => {
  return state[ModuleKey]?.referenceGroups.get(props.groupName)?.areMatchesLoading;
}
const getAllReferenceNodes = (state) => Map(getReferenceGroupNames().reduce((map, groupName) => {
  map[groupName] = state[ModuleKey]?.referenceGroups.get(groupName)?.nodes;
  return map;
}, {}));

export {
  getReferenceNodes,
  getReferenceMatches,
  getAreMatchesLoading,
  getAllReferenceNodes
};
