import {createAction} from "redux-actions";
import {getReferenceGroup} from "../ReferenceGroupFactory";
import {getJson} from "../utils";
import {registerError} from "./index";

const clearActiveReferenceNode = createAction('ACTIVE_REF_NODE/CLEAR/SUCCESS', (groupName) => ({groupName}));
const clearReferenceMatches = createAction('REF_MATCHES/CLEAR/SUCCESS', (groupName) => ({groupName}));
const clearReferenceNodes = createAction('REF_NODES/CLEAR/SUCCESS', (groupName) => ({groupName}));

const fetchRootReferenceNodesStart = createAction('REF_NODES/FETCH/START', (groupName) => ({groupName}));
const fetchRootReferenceNodesSuccess = createAction('REF_NODES/FETCH/SUCCESS', (groupName, nodes) => ({groupName, nodes}));
const fetchRootReferenceNodesFail = createAction('REF_NODES/FETCH/FAIL', (groupName, error) => ({groupName, error}));
const fetchRootReferenceNodesFinally = createAction('REF_NODES/FETCH/FINALLY', (groupName) => ({groupName}));
const fetchRootReferenceNodes = (groupName) => async (dispatch) => {
  console.debug("Fetching root reference nodes... ", groupName);
  dispatch(fetchRootReferenceNodesStart(groupName));
  try {
    const refGroup = getReferenceGroup(groupName);
    if (refGroup) {
      const nodes = await getJson(refGroup.getRootNodesUrl());
      dispatch(fetchRootReferenceNodesSuccess(groupName, nodes.map((node, index) => ({
        ...node,
        name: refGroup.getNodeName(node),
        index: `${index}`,
        children: [],
      }))));
    }
  } catch (error) {
    dispatch(registerError("Problem retrieving references tree", null, [groupName], error));
    dispatch(fetchRootReferenceNodesFail(groupName, error));
  } finally {
    dispatch(fetchRootReferenceNodesFinally(groupName));
  }
};

const fetchReferenceMatchesStart = createAction('REF_MATCHES/FETCH/START', (groupName, query) => ({groupName, query}));
const fetchReferenceMatchesSuccess = createAction('REF_MATCHES/FETCH/SUCCESS', (groupName, matches) => ({groupName, matches}));
const fetchReferenceMatchesFail = createAction('REF_MATCHES/FETCH/FAIL', (groupName, query, error) => ({groupName, query, error}));
const fetchReferenceMatchesFinally = createAction('REF_MATCHES/FETCH/FINALLY', (groupName, query) => ({groupName, query}));
const fetchReferenceMatches = (groupName, query) => async (dispatch) => {
  if (!query || query.length === 0) {
    dispatch(clearReferenceMatches(groupName));
    return;
  }
  dispatch(fetchReferenceMatchesStart(groupName, query));
  try {
    const refGroup = getReferenceGroup(groupName);
    if (refGroup) {
      const matches = await getJson(refGroup.getMatchesUrl(query));
      dispatch(fetchReferenceMatchesSuccess(groupName, matches));
    }
  } catch (error) {
    dispatch(registerError("Problem retrieving matching references", null, [groupName, query], error));
    dispatch(fetchReferenceMatchesFail(groupName, query, error));
  } finally {
    dispatch(fetchReferenceMatchesFinally(groupName, query));
  }
};

const toggleReferenceNodeSuccess = createAction('REF_NODE/TOGGLE/SUCCESS', (groupName, node, toggled) => ({groupName, node, toggled}));
const fetchReferenceNodeChildrenStart = createAction('REF_NODE_CHILDREN/FETCH/START', (groupName, node) => ({groupName, node}));
const fetchReferenceNodeChildrenSuccess = createAction('REF_NODE_CHILDREN/FETCH/SUCCESS', (groupName, node, children) => ({groupName, node, children}));
const fetchReferenceNodeChildrenFail = createAction('REF_NODE_CHILDREN/FETCH/FAIL', (groupName, node, error) => ({groupName, node, error}));
const fetchReferenceNodeChildrenFinally = createAction('REF_NODE_CHILDREN/FETCH/FINALLY', (groupName, node) => ({groupName, node}));
const toggleReferenceNode = (groupName, node, toggled) => async (dispatch) => {
  console.debug("Toggling reference node... ", groupName, node, toggled);
  dispatch(toggleReferenceNodeSuccess(groupName, node, toggled));
  if (toggled && node.children && node.children.length === 0) {
    console.debug("Fetching reference node children... ", groupName, node);
    dispatch(fetchReferenceNodeChildrenStart(groupName, node));
    try {
      const refGroup = getReferenceGroup(groupName);
      if (refGroup) {
        const children = await getJson(refGroup.getNodeChildrenUrl(node));
        dispatch(fetchReferenceNodeChildrenSuccess(groupName, node, children.map((child, index) => ({
          ...child,
          name: refGroup.getNodeName(child),
          index: `${node.index}-${index}`,
          children: ['document', 'section'].indexOf(child.type) >= 0 ? null : [],
        }))));
      }
    } catch (error) {
      dispatch(registerError("Problem expanding reference item", null, [groupName, node.title, toggled], error));
      dispatch(fetchReferenceNodeChildrenFail(groupName, node, error));
    } finally {
      dispatch(fetchReferenceNodeChildrenFinally(groupName, node));
    }
  }
};

export {
  clearReferenceNodes,
  clearReferenceMatches,
  clearActiveReferenceNode,
  fetchRootReferenceNodes,
  fetchRootReferenceNodesSuccess,
  fetchReferenceMatches,
  fetchReferenceMatchesStart,
  fetchReferenceMatchesSuccess,
  fetchReferenceMatchesFail,
  fetchReferenceMatchesFinally,
  toggleReferenceNode,
  toggleReferenceNodeSuccess,
  fetchReferenceNodeChildrenSuccess,
};
