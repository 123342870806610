import React from "react";
import * as PropTypes from "prop-types";

class DomContainer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.array.isRequired,
    domRef: PropTypes.shape({
      current: PropTypes.object,
    }),
    removeParent: PropTypes.func,
    component: PropTypes.element,
  };

  constructor(props) {
    super(props);

    if(props.domRef) {
      this.ref = props.domRef;
    } else {
      this.ref = React.createRef();
    }
  }

  componentDidMount() {
    const {children, removeParent} = this.props;
    const current = this.ref.current;
    children.forEach(el => current.appendChild(el));
    //if the parent was passed in for cleanup and it exists
    if(removeParent) {
      removeParent();
    }
  }

  componentWillUnmount() {
    const {children} = this.props;
    const current = this.ref.current;
    children.forEach(el => current.remove(el));
  }

  render() {
    // strip out children and domRef from the collection of passed props
    const {children, domRef, component, ...props} = this.props;

    return <div {...props} ref={this.ref}/>;
  }
}

export default DomContainer;
