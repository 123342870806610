import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {
  getAnalysisId,
  getBodyTop,
  getReviewers,
  hasIa2CompletePermission,
  hasIa2FinalizePermission,
  hasIa2PrintPermission, hasIa2StatusReportPermission, isNewNav
} from "../../common/selectors";

import {toJS} from "../../common/utils";
import {
  checkAnalysis,
  clearAnalysisMessage,
  clearFilters,
  completeAnalysis,
  displayMoreQuestions,
  filterQuestions,
  finalizeAnalysis,
  setAllExpanded,
} from "../actions";
import QuestionFilters from "../components/QuestionFilters";
import {
  getAnalysis,
  getAssignedFilter,
  getAssignedToFilter,
  getDeferrableFilter,
  getDeferFilter,
  getDisplayedQuestionIds,
  getFilterText,
  getNeedsReviewFilter,
  getQuestions,
  getStatusFilter
} from "../selectors";
import * as PropTypes from "prop-types";
import {setPageFilters} from "../../common/actions";

const mapStateToProps = createStructuredSelector({
  filters: createStructuredSelector({
    needsReviewFilter: getNeedsReviewFilter,
    assignedFilter: getAssignedFilter,
    assignedToFilter: getAssignedToFilter,
    deferrableFilter: getDeferrableFilter,
    statusFilter: getStatusFilter,
    filterText: getFilterText,
    deferFilter: getDeferFilter
  }),
  reviewers: getReviewers,
  questions: getQuestions,
  displayedQuestionIds: getDisplayedQuestionIds,
  bodyTop: getBodyTop,
  analysis: getAnalysis,
  analysisId: getAnalysisId,
  hasFinalizePermission: hasIa2FinalizePermission,
  hasCompletePermission: hasIa2CompletePermission,
  hasPrintPermission: hasIa2PrintPermission,
  hasStatusReportPermission: hasIa2StatusReportPermission,
  isNewNav: isNewNav
});

const mapDispatchToProps = (dispatch) => ({
  filterQuestions: ({
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText,
    deferFilter
  }) => {
    const selectedFilters = {
      needsReviewFilter,
        assignedFilter,
        assignedToFilter,
        deferrableFilter,
        statusFilter,
        filterText,
        deferFilter
    };
    dispatch(filterQuestions(selectedFilters));
    dispatch(setPageFilters([
      "needsReviewFilter",
      "assignedFilter",
      "assignedToFilter",
      "deferrableFilter",
      "statusFilter",
      "filterText",
      "deferFilter"
    ], selectedFilters));
  },
  clearFilters: () => dispatch(clearFilters()),
  checkAnalysis: (analysisId) => {
    dispatch(checkAnalysis(analysisId));
  },
  clearAnalysisMessage: (analysisId) => {
    dispatch(clearAnalysisMessage(analysisId));
  },
  completeAnalysis: (analysisId) => {
    dispatch(completeAnalysis(analysisId));
  },
  displayMoreQuestions: () => {
    dispatch(displayMoreQuestions());
  },
  expandAllQuestions: (analysisId) => {
    dispatch(setAllExpanded(analysisId, true));
  },
  collapseAllQuestions: (analysisId) => {
    dispatch(setAllExpanded(analysisId, false));
  },
  finalizeAnalysis: (analysisId) => {
    dispatch(finalizeAnalysis(analysisId));
  },
});

const QuestionFiltersContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionFilters);
QuestionFiltersContainer.propTypes = {
  analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default QuestionFiltersContainer;