/*
 * Copyright 2019 General Code
 */

export const API = "API";
export const API_START = "COMMON/API/START";
export const API_END = "COMMON/API/END";
export const API_ERROR = "COMMON/API/ERROR";
export const BODY_TOP_SET = "COMMON/BODY/TOP/SET";
export const DIALOG_CLOSE = "DIALOG/CLOSE";
export const DIALOG_INIT = "DIALOG/INIT";
export const DIALOG_SUBMIT_DISABLE = "DIALOG/SUBMIT/DISABLE";
export const ERROR_ALL_DISMISS = "COMMON/ERROR/ALL/DISMISS";
export const ERROR_NON_FATAL_REGISTER = "COMMON/ERROR/NON_FATAL/REGISTER";
export const ERROR_REGISTER = "COMMON/ERROR/REGISTER";
export const EVENT_LOG = "COMMON/EVENT/LOG";
export const MEDIA_MATCH_HEIGHT = "COMMON/MEDIA/MATCH/HEIGHT";
export const MEDIA_MATCH_WIDTH = "COMMON/MEDIA/MATCH/WIDTH";
export const PAGE_FILTER_ADD = "PAGE_FILTER/ADD";
export const PAGE_FILTER_REMOVE = "PAGE_FILTER/REMOVE";
export const PAGE_FILTER_SET = "PAGE_FILTER_SET";
export const PRINT_LOADING_SET = "COMMON/PRINT/LOAD/SET";
export const SERVER_DATA_LOAD = "COMMON/SERVER_DATA/LOAD";
export const USER_MESSAGE_SHOW = "COMMON/USER/MESSAGE/SHOW";
export const USER_MESSAGE_HIDE = "COMMON/USER/MESSAGE/HIDE";
