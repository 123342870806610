import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import { isNewNav } from "../../common/selectors";
import {getLaws, getFilterText, getFilterYear, getFilterChapters, getSort} from "../selectors";
import {startEditing, setSort, setFilterText} from "../actions";
import {toJS} from "../../common/utils";
import LawTable from '../components/LawTable';
import * as PropTypes from "prop-types";

const mapStateToProps = createStructuredSelector({
  laws: getLaws,
  filterText: getFilterText,
  filterYear: getFilterYear,
  filterChapters: getFilterChapters,
  sort: getSort,
  isNewNav: isNewNav
});
const mapDispatchToProps = (dispatch) => ({
  setFilterText: (filterText) => { dispatch(setFilterText(filterText)); },
  setSort: (sort) => { return dispatch(setSort(sort)); },
  startEditing: (documentId) => { return dispatch(startEditing(documentId)); },
});

const LawTableContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(LawTable);
LawTableContainer.propTypes = {
  columns: PropTypes.string
};
export default LawTableContainer;

