import * as dateMath from 'date-arithmetic';
import {connect} from "react-redux";
import {compose, renderNothing} from "recompose";
import branch from 'recompose/branch';
import {createStructuredSelector} from "reselect";
import {getCustId, getDueWarningDays, getDueWarningIntervalHours} from "../../common/selectors";
import {toJS} from "../../common/utils";
import AnalysisDue from '../components/AnalysisDue';


const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  dueWarningDays: getDueWarningDays,
  dueWarningIntervalHours: getDueWarningIntervalHours
});

const ifDue = branch(({dueDate, dueWarningDays}) =>
  //we have a due date
  !(dueDate && (dateMath.gte(new Date(), dateMath.subtract(new Date(dueDate), dueWarningDays, 'day')))), renderNothing)(AnalysisDue);

const AnalysisDueContainer = compose(connect(mapStateToProps), toJS)(ifDue);

export default AnalysisDueContainer;
