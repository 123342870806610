import {ModuleKey} from "../ModuleKey";
import {List, Map} from "immutable";
import {hasPubDocPrivateViewPermission} from "../../common/selectors";
import createCachedSelector from "re-reselect";

const getSuggestedPubDocTypes = (state) => state[ModuleKey].suggestedPubDocTypes
  ? state[ModuleKey].suggestedPubDocTypes : Map();
const getPubDocsSidebarName = (state) => state[ModuleKey].pubDocsSidebarName;
const getPubDocTypes = (state) => state[ModuleKey].pubDocTypes
  ? state[ModuleKey].pubDocTypes.sort((t1, t2) => t1.title.toLowerCase().localeCompare(t2.title.toLowerCase(), undefined, { numeric: true }))
  : List();
const getPubDocTypeId = (state, props) => (props && (props.typeId || props.pubDocTypeId))
  ? (props.typeId || props.pubDocTypeId) : state[ModuleKey].pubDocTypeId;
const pubDocTypeSelector = (extract) => (state, props) => extract(getPubDocTypes(state)
  .filter(pubDocType => pubDocType.id === getPubDocTypeId(state, props)).get(0));
const getPubDocTypeTitle = pubDocTypeSelector(pubDocType => pubDocType && pubDocType.title);
const getPubDocTypeDescription = pubDocTypeSelector(pubDocType => pubDocType && pubDocType.description);
const getPubDocTypeSortType = pubDocTypeSelector(pubDocType => pubDocType && pubDocType.sortType);
const getPubDocs = (state) => state[ModuleKey].documents;
const getPubDocsLoading = (state) => state[ModuleKey].documentsLoading;
const getDisplayedCategoryIds = (state) => state[ModuleKey].displayedCategoryIds;
const getAllCategories = (state) => state[ModuleKey].categories;
const getCategories = (state, props) => {
  let categories = getAllCategories(state);
  const typeId = getPubDocTypeId(state, props);
  if (typeId !== "all") {
    categories = categories.filter(category => category.typeId === typeId);
  }
  if (getPubDocTypeSortType(state, props) === "CATEGORY_NAME_DESC") {
    return categories.sort((c1, c2) => c2.title.toLowerCase()
      .localeCompare(c1.title.toLowerCase(), undefined, {numeric: true}));
  } else {
    return categories.sort((c1, c2) => c1.title.toLowerCase()
      .localeCompare(c2.title.toLowerCase(), undefined, {numeric: true}));
  }
};
const getTypeEditOpen = (state) => state[ModuleKey].typeEditOpen;
const getTypeEditId = (state) => state[ModuleKey].typeEditId;
const getCategoryEditId = (state) => state[ModuleKey].categoryEditId;
const getCategoryEditOpen = (state) => state[ModuleKey].categoryEditOpen;
const getTypeDeleteId = (state) => state[ModuleKey].typeDeleteId;
const getTypeDeleteOpen = (state) => state[ModuleKey].typeDeleteOpen;
const getCategoryDeleteId = (state) => state[ModuleKey].categoryDeleteId;
const getCategoryDeleteOpen = (state) => state[ModuleKey].categoryDeleteOpen;
const getCategoryDeleteErrorOpen = (state) => state[ModuleKey].categoryDeleteErrorOpen;
const getFilterOptions = (state) => state[ModuleKey].filterOptions;
const getSelectedFilters = (state) => state[ModuleKey].selectedFilters;
const getDocumentEditOpen = (state) => state[ModuleKey].documentEditOpen;
const getDocumentCategoryEditId = (state) => state[ModuleKey].documentCategoryEditId;
const getDocumentEditId = (state) => state[ModuleKey].documentEditId;
const getSelectedDocIds = (state) => state[ModuleKey].selectedDocIds;
const getSelectedDocs = createCachedSelector(
  [getPubDocs, getSelectedDocIds],
  (documents, selectedIds) => {
    return (!documents || !selectedIds || selectedIds.length < 1) ? []
      : documents.filter(document => selectedIds.contains(document.id));
  }
)((state) => getSelectedDocIds(state).join(","));

const getDocumentEditLoading = (state) => state[ModuleKey].documentEditLoading;
const getCategoryEditLoading = (state) => state[ModuleKey].categoryEditLoading;
const getTypeEditLoading = (state) => state[ModuleKey].typeEditLoading;
const getDocumentMoveOpen = (state) => state[ModuleKey].documentMoveOpen;
const getDocumentMoveId = (state) => state[ModuleKey].documentMoveId;
const getFilteredCategories = (state, props) => {
  const allTypes = getPubDocTypeId(state, props) === "all";
  const filters = getSelectedFilters(state);
  const categoryFilter = filters.category == null ? "" : filters.category;
  return getCategories(state, props)
    .filter(category => (allTypes || getDisplayedCategoryIds(state).includes(category.id))
      && (category.title === categoryFilter || categoryFilter === '')
      && (!category.private || hasPubDocPrivateViewPermission(state))
    );
};
const getBulkUploaderLoading = (state) => state[ModuleKey].bulkUploaderLoading;
const getBulkUploadedDocuments = (state) => state[ModuleKey].bulkUploadedDocuments;
const getDownloadErrorOpen = (state) => state[ModuleKey].downloadErrorOpen;
const getBulkDeleteOpen = (state) => state[ModuleKey].bulkDeleteOpen;

const getCategory = (_, props) => props.category;

const getCategoryId = (_, props) => props.category?.id;

const filteredDocumentsCombiner = (category, documents, selectedFilters) => {
  const yearFilter = selectedFilters.year == null ? "" : selectedFilters.year;
  const monthFilter = selectedFilters.month == null ? "" : selectedFilters.month;
  const dayFilter = selectedFilters.day == null ? "" : selectedFilters.day;
  return documents.filter(document => {
    const documentDate = document.dateObj;
    return document.categoryId === category.id
      && (yearFilter === "" ||  (documentDate === null ? yearFilter === "-1" : documentDate.getFullYear() === parseInt(yearFilter, 10)))
      && (monthFilter === "" ||  (documentDate === null ? monthFilter === "-1" : (documentDate.getMonth() + 1) === parseInt(monthFilter, 10)))
      && (dayFilter === "" ||  (documentDate === null ? dayFilter === "-1" : documentDate.getDate() === parseInt(dayFilter, 10)))
      && (hasPubDocPrivateViewPermission || !document.private);
  }).sort((document1, document2) => documentComparator(category, document1, document2));
};

const getFilteredCategoryDocuments = createCachedSelector(
  [getCategory, getPubDocs, getSelectedFilters],
  (category, documents, selectedFilters) => filteredDocumentsCombiner(category, documents, selectedFilters)
)(getCategoryId);

const getFilteredDocuments = createCachedSelector(
  [getFilteredCategories, getPubDocs, getSelectedFilters],
  (categories, documents, selectedFilters) => {
    let filteredDocuments = [];
    if (!documents || documents.length === 0) {
      return filteredDocuments;
    }
    if (categories) {
      for (let category of categories) {
        filteredDocuments = filteredDocuments.concat(filteredDocumentsCombiner(category, documents, selectedFilters).toArray());
      }
    } else {
      filteredDocuments = filteredDocuments.concat(filteredDocumentsCombiner(null, documents, selectedFilters).toArray());
    }
    return List(filteredDocuments);
  }
)((state, props) => "categories:" + getFilteredCategories(state, props).map(c => c.id).join(",")
  + "documents:" + getPubDocs(state).map(c => c.id).join(","));

const documentComparator = (category, document1, document2) => {
  let sortVal = 0;
  const firstYear = document1.yearNum;
  const secondYear = document2.yearNum;
  //The default numbers for null dates are the smallest possible date in javascript
  const document1Date = document1.dateObj ? document1.dateObj : -8640000000000000;
  const document2Date = document2.dateObj ? document2.dateObj : -8640000000000000;
  if (category.groupByType === "Year_Ascending") {
    sortVal = firstYear - secondYear;
    if (sortVal !== 0) {
      return sortVal;
    }
  } else if (category.groupByType === "Year_Descending") {
    sortVal = secondYear - firstYear;
    if (sortVal !== 0) {
      return sortVal;
    }
  }
  sortVal = getSortValue(category.sortByFirstType, document1, document1Date, document2, document2Date);
  if (sortVal === 0) {
    sortVal = getSortValue(category.sortBySecondType, document1, document1Date, document2, document2Date);
  }
  return sortVal;
};

const getSortValue = (sortByType, document1, document1Date, document2, document2Date) => {
  if (sortByType === "Alphabetical_Ascending") {
    return new Intl.Collator([], { numeric: true, sensitivity: 'base' })
      .compare(document1.titleLc, document2.titleLc);
  } else if (sortByType === "Alphabetical_Descending") {
    return new Intl.Collator([], { numeric: true, sensitivity: 'base' })
      .compare(document2.titleLc, document1.titleLc);
  } else if (sortByType === "Date_Ascending") {
    return document1Date - document2Date;
  } else if (sortByType === "Date_Descending") {
    return document2Date - document1Date;
  }
};

export {
  getSuggestedPubDocTypes,
  getTypeEditOpen,
  getPubDocs,
  getPubDocsLoading,
  getPubDocTypeId,
  getPubDocTypeTitle,
  getPubDocTypeDescription,
  getPubDocTypes,
  getTypeEditId,
  getCategoryEditOpen,
  getDisplayedCategoryIds,
  getAllCategories,
  getCategories,
  getFilteredCategories,
  getCategoryEditId,
  getTypeDeleteId,
  getTypeDeleteOpen,
  getCategoryDeleteId,
  getCategoryDeleteOpen,
  getCategoryDeleteErrorOpen,
  getFilterOptions,
  getSelectedFilters,
  getDocumentEditOpen,
  getDocumentCategoryEditId,
  getDocumentEditId,
  getDocumentEditLoading,
  getCategoryEditLoading,
  getTypeEditLoading,
  getDocumentMoveOpen,
  getDocumentMoveId,
  getPubDocsSidebarName,
  getSelectedDocIds,
  getSelectedDocs,
  getFilteredCategoryDocuments,
  getFilteredDocuments,
  getBulkUploaderLoading,
  getBulkUploadedDocuments,
  getDownloadErrorOpen,
  getBulkDeleteOpen
};
