/*
 * Copyright 2018 General Code
 */

import React from 'react';
import { withStyles } from '../../common/theme';
import MainControlsContainer from '../containers/MainControlsContainer';
import TitleContainer from '../containers/TitleContainer';

const style = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'beginning',
  },
};

const Header = ({id, classes, ...props}) => (
  <div className={classes.root}>
    <TitleContainer {...props} id={id}/>
    <MainControlsContainer {...props} id={id}/>
  </div>
);

export default withStyles(style)(Header);


