import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import SearchResults from "../components/SearchResults";

import {getCustomers, getCurrentPage, getMaxPages, getResults, isSearchLoading, getSelectedCustIds,
  getSelectedCustomerFilters} from "../selectors";

import {getNextResultsPage} from "../actions";

const mapStateToProps = createStructuredSelector({
  customers: getCustomers,
  currentPage: getCurrentPage,
  maxPages: getMaxPages,
  results: getResults,
  isSearchLoading: isSearchLoading,
  selectedCustIds: getSelectedCustIds,
  selectedCustomerFilters: getSelectedCustomerFilters
});

const mapDispatchToProps = (dispatch) => ({
  getNextPage: () => {
    dispatch(getNextResultsPage());
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SearchResults);