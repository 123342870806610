import * as PropTypes from "prop-types";
import React, {Fragment} from "react";
import moment from "moment";
import {Autocomplete, Button, ButtonGroup, Collapse, Dialog, FileUploader, FormControl, FormControlLabel, IconButton,
  InputLabel, LinearProgress, MenuItem, PrimaryButton, Select, Switch, TextField} from "../../common/components";
import {Table, TableHead, TableBody, TableRow, TableCell} from "../../common/components/table";
import {Remove as RemoveIcon, Checkmark as CheckmarkIcon, Warning as WarningIcon,
  Error as ErrorIcon} from "../../common/components/icons";
import {If} from "../../common/containers";
import _ from "lodash";
import {getDateFromText, CURRENT_CENTURY} from "../../common/utils/date-formatter";

import "./PubDocBulkUploaderDialog.css";

const EXTRACT_FROM_FILENAME = 1;
const EXTRACT_FROM_MODIFIED_DATE = 2;
const NO_EXTRACTION = 3;
const DATE_EXTRACTION_OPTIONS = {
  [EXTRACT_FROM_FILENAME]: "Extract from filename",
  [EXTRACT_FROM_MODIFIED_DATE]: "Extract from file Modified Date",
  [NO_EXTRACTION]: "None"
};

let CENTURY_OPTIONS = [];
for (let century = CURRENT_CENTURY; century >= 1800; century -= 100) {
  CENTURY_OPTIONS.push(century);
}
const DATE_PATTERNS = [
  "Auto",
  "yy-MM-dd",
  "yyMMdd"
];
const DEFAULT_SATE = {
  files: [],
  typeId: 0,
  categoryId: 0,
  dateExtractionMethod: EXTRACT_FROM_FILENAME,
  dateExtractionPattern: DATE_PATTERNS[0],
  dateExtractionCentury: 0,
  isPrivate: false,
  specialTitleProcessing: false,
  includeFilenameInTitle: true,
  titlePrefix: "",
  titleSuffix: "",
  dialogStep: 1,
  pubDocs: [],
  cancelConfirmOpen: false,
  backConfirmOpen: false,
  activeTableControl: null
};

class PubDocBulkUploaderDialog extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    pubDocTypes: PropTypes.array,
    typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    typeTitle: PropTypes.string,
    categories: PropTypes.array,
    loading: PropTypes.bool,
    uploadedDocuments: PropTypes.array,
    pubDocsFileTypes: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    bulkAddPubDocs: PropTypes.func.isRequired,
    clearBulkUploadedDocuments: PropTypes.func.isRequired
  };

  getDefaultState = () => {
    let defaultState = DEFAULT_SATE;
    defaultState.files = [];
    defaultState.typeId = this.props.typeId;
    return defaultState;
  };

  getCategoryId = (typeId) => {
    const { categories } = this.props;
    const { categoryId } = this.state;
    if (categoryId === 0 && categories.length > 0) {
      let uncategorizedCategory = categories.filter(category => {return category.typeId === typeId && category.title === "(Uncategorized)";});
      if (uncategorizedCategory.length > 0) {
        return uncategorizedCategory[0].id;
      }
    }
    return categoryId;
  };

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  handleDialogClose = () => {
    const { onCancel, clearBulkUploadedDocuments } = this.props;
    onCancel();
    this.setState(this.getDefaultState());
    clearBulkUploadedDocuments();
  };

  calculateDateInsertions = (titleString) => {
    let dateInsertions = {};
    let dateInsertRegex = new RegExp("%DATE.*%", "gmi");
    let titleStringMatches = titleString.match(dateInsertRegex);
    if (titleStringMatches) {
      for (const titleStringMatch of titleStringMatches) {
        if (!dateInsertions[titleStringMatch]) {
          // Trim out %DATE%
          let dateInsertFormat = titleStringMatch.substring(5, (titleStringMatch.length - 1));
          // Trim out the parenthesis if needed
          if (dateInsertFormat.startsWith("(") && dateInsertFormat.endsWith(")")) {
            dateInsertFormat = dateInsertFormat.substring(1, (dateInsertFormat.length - 1));
          }
          // Moment.js handles d/D and y/Y differently than Java
          dateInsertions[titleStringMatch] = (dateInsertFormat === "") ? "YYYY-MM-DD" : dateInsertFormat
            .replace(/y/g, "Y").replace(/d/g, "D");
        }
      }
    }
    return dateInsertions;
  };

  openSecondDialogStep = () => {
    const { typeId, files, dateExtractionMethod, dateExtractionPattern, dateExtractionCentury, isPrivate,
      specialTitleProcessing, includeFilenameInTitle, titlePrefix, titleSuffix } = this.state;
    const categoryId = this.getCategoryId(typeId);
    let prefixDateInsertions = null;
    let suffixDateInsertions = null;
    if (specialTitleProcessing) {
      prefixDateInsertions = this.calculateDateInsertions(titlePrefix);
      suffixDateInsertions = this.calculateDateInsertions(titleSuffix);
    }
    let pubDocs = [];
    for (const file of files) {
      let date = null;
      if (dateExtractionMethod === EXTRACT_FROM_MODIFIED_DATE) {
        date = new Date(file.lastModified);
      } else if (dateExtractionMethod === EXTRACT_FROM_FILENAME) {
        date = getDateFromText(file.name,
          (dateExtractionPattern === "Auto" ? null : dateExtractionPattern),
          dateExtractionCentury
        );
      }
      let title = "";
      if (!specialTitleProcessing || includeFilenameInTitle) {
        title += file.name.replace(/\.[^/.]+$/, "");
      }
      if (specialTitleProcessing) {
        let modifiedPrefix = titlePrefix;
        for (const [key, value] of Object.entries(prefixDateInsertions)) {
          let replaceValue = date ? moment(date).format(String(value)) : "";
          modifiedPrefix = modifiedPrefix.replace(new RegExp(_.escapeRegExp(key), "gmi"), replaceValue);
        }
        let modifiedSuffix = titleSuffix;
        for (const [key, value] of Object.entries(suffixDateInsertions)) {
          let replaceValue = date ? moment(date).format(String(value)) : "";
          modifiedSuffix = modifiedSuffix.replace(new RegExp(_.escapeRegExp(key), "gmi"), replaceValue);
        }
        title = modifiedPrefix + (modifiedPrefix.endsWith(" ") ? "" : " ") + title
          + (modifiedSuffix.startsWith(" ") ? "" : " ") + modifiedSuffix;
      }
      pubDocs.push({
        documentType: "DOCUMENT",
        isPrivate: isPrivate,
        typeId: typeId,
        categoryId: categoryId,
        dateObject: date,
        date: (date ? moment(date).format("YYYY-MM-DD") : null),
        title: title,
        description: "",
        file: file
      });
    }
    this.setState({dialogStep: 2, pubDocs: pubDocs});
  };

  openFirstDialogStep = () => {
    this.setState({backConfirmOpen: false, dialogStep: 1});
  };

  openCancelConfirm = () => {
    this.setState({cancelConfirmOpen: true});
  }

  closeCancelConfirm = () => {
    this.setState({cancelConfirmOpen: false});
  }

  openBackConfirm = () => {
    this.setState({backConfirmOpen: true});
  }

  closeBackConfirm = () => {
    this.setState({backConfirmOpen: false});
  }

  handleSelectChange = (field) => (e) => {
    this.setState({[field]: e.target.value});
  };

  handleAutocompleteChange = (field, defaultValue) => (option) => {
    this.setState({[field]: option ? option.value : defaultValue});
  };

  handleSwitchChange = (field) => (e) => {
    this.setState({[field]: e.target.checked});
  };

  setFiles = (newFiles) => {
    this.setState({files: newFiles});
  };

  handleUpload = () => {
    const { bulkAddPubDocs } = this.props;
    const { pubDocs } = this.state;
    bulkAddPubDocs(pubDocs);
  };

  removePubDoc = (index) => {
    this.setState({pubDocs: this.state.pubDocs.filter((pubDoc, i) => (i !== index))});
  };

  setActiveTableControl = (pubDocIndex, columnIndex) => () => {
    this.setState({activeTableControl: (pubDocIndex === null ? null : (pubDocIndex + "-" + columnIndex))});
  };

  persistDateChange = (pubDocIndex, value) => () => {
    if (value != null) {
      this.updatePubDoc(pubDocIndex, "date", value);
    }
    this.setActiveTableControl(null)();
  };

  handlePubDocTextFieldChange = (pubDocIndex, field) => (e) => {
    this.updatePubDoc(pubDocIndex, field, e.target.value);
  };

  handlePubDocSelectChange = (pubDocIndex, field) => (e) => {
    this.updatePubDoc(pubDocIndex, field, e.target.value);
    this.setActiveTableControl(null)();
  };

  handlePubDocSwitchChange = (pubDocIndex, field) => (e) => {
    this.updatePubDoc(pubDocIndex, field, e.target.checked);
  };

  updatePubDoc = (pubDocIndex, field, value) => {
    const { pubDocs } = this.state;
    let newPubDoc = pubDocs[pubDocIndex];
    newPubDoc[field] = value;
    this.setState({pubDocs: pubDocs.map((pubDoc, i) => (i === pubDocIndex) ? newPubDoc : pubDoc)});
  };

  handleEnterPress = (e) => {
    if (e.key === "Enter" && e.target){
      e.target.blur();
    }
  };

  areDuplicateDocuments = (d1, d2) => ((d1.categoryId === d2.categoryId || (d1.categoryId === 0 && d2.isUncategorized) || (d2.categoryId === 0 && d1.isUncategorized))
      && d1.date === d2.date
      && (d1.title !== null && d2.title !== null && d1.title.toLowerCase() === d2.title.toLowerCase())
  );

  renderPubDocTableRow = (i, pubDoc, blankTitles, duplicateDocuments, uploadedDocuments, typeMap, categoryMap, filteredCategories) => {
    const { pubDocTypes, loading } = this.props;
    const { activeTableControl } = this.state;
    const pubDocKey = (i + "-" + pubDoc.date + "-" + pubDoc.title + "-" + pubDoc.file.size);
    const hasBlankTitle = (blankTitles.indexOf(i) !== -1);
    const isDuplicate = (duplicateDocuments.indexOf(i) !== -1);
    const isUploaded = (uploadedDocuments && uploadedDocuments.indexOf(i) !== -1);
    const hasError = (!loading && uploadedDocuments && uploadedDocuments.indexOf(i) === -1);
    return (
      <TableRow key={pubDocKey} className={((hasBlankTitle || isDuplicate) ? "warning" : "") + (isUploaded ? " success": "") + (hasError ? " error" : "")}>
        <TableCell className={"bulkUploader-documentTable-column actions-column"}>
          <IconButton className="removeIcon" aria-label={"Remove " + pubDoc.title} onClick={() => { this.removePubDoc(i); }}>
            <RemoveIcon title={"Remove " + pubDoc.title} />
          </IconButton>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column type-column"}>
          <If test={!activeTableControl || activeTableControl !== (i + "-" + 1) || isUploaded}>
            <div className={"bulkUploader-documentTable-value" + (isUploaded ? "" : " editable")} onClick={this.setActiveTableControl(i, 1)}>
              {typeMap[pubDoc.typeId] ? typeMap[pubDoc.typeId].title : pubDoc.typeId}
            </div>
          </If>
          <If test={activeTableControl && activeTableControl === (i + "-" + 1) && !isUploaded}>
            <Select value={pubDoc.typeId}
              onChange={this.handlePubDocSelectChange(i, "typeId")}
              onBlur={this.setActiveTableControl(null)}
              onKeyPress={this.handleEnterPress}
              autoFocus={true}
            >
              { pubDocTypes.map(type => <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>) }
            </Select>
          </If>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column category-column"}>
          <If test={!activeTableControl || activeTableControl !== (i + "-" + 2) || isUploaded}>
            <div className={"bulkUploader-documentTable-value" + (isUploaded ? "" : " editable")} onClick={this.setActiveTableControl(i, 2)}>
              {categoryMap[pubDoc.categoryId] ? categoryMap[pubDoc.categoryId].title : "(Uncategorized)"}
            </div>
          </If>
          <If test={activeTableControl && activeTableControl === (i + "-" + 2) && !isUploaded}>
            <Select value={pubDoc.categoryId}
              onChange={this.handlePubDocSelectChange(i, "categoryId")}
              onBlur={this.setActiveTableControl(null)}
              onKeyPress={this.handleEnterPress}
              autoFocus={true}
            >
              { filteredCategories.map(category => <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>) }
            </Select>
          </If>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column filename-column"}>
          <div className="bulkUploader-documentTable-value">{pubDoc.file.name}</div>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column title-column"}>
          <If test={!activeTableControl || activeTableControl !== (i + "-" + 4) || isUploaded}>
            <div className={"bulkUploader-documentTable-value" + (isUploaded ? "" : " editable")} onClick={this.setActiveTableControl(i, 4)}>
              {pubDoc.title}
            </div>
          </If>
          <If test={activeTableControl && activeTableControl === (i + "-" + 4) && !isUploaded}>
            <TextField margin="dense" defaultValue={pubDoc.title}
              onBlur={(e) => { this.handlePubDocTextFieldChange(i, "title")(e); this.setActiveTableControl(null)(e); } }
              onKeyPress={this.handleEnterPress}
              autoFocus={true}
            />
          </If>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column date-column"}>
          <If test={!activeTableControl || activeTableControl !== (i + "-" + 5) || isUploaded}>
            <div className={"bulkUploader-documentTable-value " + (isUploaded ? "" : " editable")} onClick={this.setActiveTableControl(i, 5)}>
              {pubDoc.date}
            </div>
          </If>
          <If test={activeTableControl && activeTableControl === (i + "-" + 5) && !isUploaded}>
            <TextField type="date" margin="dense" value={pubDoc.tempDate == null ? pubDoc.date : pubDoc.tempDate}
              onChange={this.handlePubDocTextFieldChange(i, "tempDate")}
              onBlur={this.persistDateChange(i, pubDoc.tempDate)}
              onKeyPress={this.handleEnterPress}
              autoFocus={true}
            />
          </If>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column private-column"}>
          <div style={{marginLeft: -12}}>
            <Switch checked={pubDoc.isPrivate} onChange={this.handlePubDocSwitchChange(i, "isPrivate")} />
          </div>
        </TableCell>
        <TableCell className={"bulkUploader-documentTable-column status-column"}>
          <If test={!uploadedDocuments && (hasBlankTitle || isDuplicate)}>
            <WarningIcon title="Duplicate document detected" className="warning" />
          </If>
          <If test={uploadedDocuments && isUploaded}>
            <CheckmarkIcon title="Uploaded successfully" className="success" />
          </If>
          <If test={!loading && uploadedDocuments && !isUploaded}>
            <ErrorIcon title="Upload failed" className="error" />
          </If>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { open = false, pubDocTypes, categories, documents, loading, pubDocsFileTypes, typeTitle,
      typeSelectDisabled } = this.props;
    const { files, typeId, dateExtractionMethod, dateExtractionPattern, dateExtractionCentury, isPrivate,
      specialTitleProcessing, includeFilenameInTitle, titlePrefix, titleSuffix, pubDocs, dialogStep, cancelConfirmOpen,
      backConfirmOpen } = this.state;
    const categoryId = this.getCategoryId(typeId);
    let filteredCategories = categories.filter(category => category.typeId === typeId)
      .sort((c1, c2) => c1.title.toLowerCase().localeCompare(c2.title.toLowerCase(), undefined, { numeric: true }));
    if (filteredCategories.filter(category => {return category.title === "(Uncategorized)";}).length === 0) {
      filteredCategories.unshift({ id: 0, title: "(Uncategorized)", typeId: typeId });
    }
    const typeMap = Object.fromEntries(pubDocTypes.map((t) => [t.id, t]));
    const categoryMap = Object.fromEntries(categories.map((c) => [c.id, c]));
    let duplicateDocuments = [];
    let blankTitles = [];
    let uploadedDocuments = this.props.uploadedDocuments ? [] : null;
    if (dialogStep === 2) {
      // If we're on the second step of the dialog, determine if there are any duplicate
      // documents (excluding ones successfully uploaded) or ones with blank titles
      for (let i = 0; i < pubDocs.length; i++) {
        let newDocument = pubDocs[i];
        if (newDocument.title === null || newDocument.title === "") {
          blankTitles.push(i);
        }
        for (const existingDocument of documents) {
          if (this.areDuplicateDocuments(newDocument, existingDocument)) {
            let uploaded = false;
            if (this.props.uploadedDocuments) {
              for (const uploadedDocument of this.props.uploadedDocuments) {
                if (this.areDuplicateDocuments(newDocument, uploadedDocument)) {
                  uploaded = true;
                  break;
                }
              }
            }
            if (uploaded) {
              uploadedDocuments.push(i);
            } else {
              duplicateDocuments.push(i);
            }
          }
        }
      }
    }
    return (
      <Dialog
        id="doc-bulk-upload-dialog"
        title={
          <Fragment>
            <span>Bulk Uploader</span>
            <If test={dialogStep === 2}>
              <If test={!loading && uploadedDocuments && uploadedDocuments.length !== pubDocs.length}>
                <div className="message">
                  <span className="error">Error: </span>
                  <span> Some documents failed to upload. Please refresh the page and try again later.</span>
                </div>
              </If>
              <If test={blankTitles.length > 0}>
                <div className="message">
                  <span className="warning">Warning: </span>
                  <span> All PubDocs must have a title. Please review any highlighted documents and be sure that they have a title.</span>
                </div>
              </If>
              <If test={duplicateDocuments.length > 0}>
                <div className="message">
                  <span className="warning">Warning: </span>
                  <span> Duplicate documents detected. Please review any highlighted documents to be sure there isn't already a document with that name and date in the selected category.</span>
                </div>
              </If>
            </If>
          </Fragment>
        }
        open={open}
        onClose={this.handleDialogClose}
        width={dialogStep === 1 ? "md" : "xl"}
        DialogContentProps={{className:"dialogContent pubDocBulkUploaderDialogContent"}}
        actions={
          <ButtonGroup>
            <Button onClick={dialogStep === 1 ? this.handleDialogClose : this.openCancelConfirm}>
              Cancel
            </Button>
            <If test={dialogStep === 1}>
              <PrimaryButton variant="contained" onClick={this.openSecondDialogStep} disabled={loading || files.length < 1 || typeId == null}>
                Next
              </PrimaryButton>
            </If>
            <If test={dialogStep === 2}>
              <Button onClick={this.openBackConfirm}>
                Back
              </Button>
              <PrimaryButton variant="contained" onClick={this.handleUpload} disabled={loading || (blankTitles.length > 0) || (duplicateDocuments.length > 0)}>
                Upload
              </PrimaryButton>
            </If>
          </ButtonGroup>
        }
      >
        <If test={dialogStep === 1}>
          <div className="dialogRow flex">
            <If test={typeSelectDisabled}>
              <div>
                <label
                  id="edit-doc-type-label"
                  htmlFor="edit-doc-type"
                  className={"pubDocEditDialogTypeLabel"}
                >
                  Type:
                </label>
                <span id="edit-doc-type">{typeTitle}</span>
              </div>
            </If>
            <If test={!typeSelectDisabled}>
              <FormControl
                variant="outlined"
                className="outlined-control pubDocBulkUploaderDialogTypeSelect"
                style={{width: "100%"}}
              >
                <InputLabel className="outlined-control-label">Type</InputLabel>
                <Select
                  value={typeId}
                  onChange={this.handleSelectChange("typeId")}
                  inputProps={{className: "outlined-control-input"}}
                  SelectDisplayProps={{className: "outlined-control-select"}}
                >
                  {pubDocTypes.map((type) => (<MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>))}
                </Select>
              </FormControl>
            </If>
          </div>
          <div className="dialogRow flex">
            <FormControl variant="outlined" className="outlined-control pubDocBulkUploaderDialogCategorySelect"
                         style={{width: "100%"}}>
              <InputLabel className="outlined-control-label">Category</InputLabel>
              <Select value={categoryId}
                      onChange={this.handleSelectChange("categoryId")}
                      inputProps={{className: "outlined-control-input"}}
                      SelectDisplayProps={{className: "outlined-control-select"}}
              >
                {filteredCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>))}
              </Select>
            </FormControl>
          </div>
          <div className="dialogRow flex">
            <FileUploader files={files} setFiles={this.setFiles} multiple={true} previewAreaHeight={200}
              accept={(!pubDocsFileTypes ? "*" : pubDocsFileTypes)}
            >
              <span><strong>Drop</strong> files here or <strong>click</strong> to select files.</span>
            </FileUploader>
          </div>
          <div className="dialogRow flex">
            <FormControl variant="outlined" className="outlined-control pubDocBulkUploaderDialogDateExtractionSelect"
                         style={{width: "100%"}}>
              <InputLabel className="outlined-control-label">Date Extraction Method</InputLabel>
              <Select value={dateExtractionMethod}
                      onChange={this.handleSelectChange("dateExtractionMethod")}
                      inputProps={{className: "outlined-control-input"}}
                      SelectDisplayProps={{className: "outlined-control-select"}}
              >
                {Object.entries(DATE_EXTRACTION_OPTIONS).map(([id, label]) => (
                  <MenuItem key={id} value={Number(id)}>{label}</MenuItem>))}
              </Select>
            </FormControl>
          </div>
          <Collapse in={dateExtractionMethod === EXTRACT_FROM_FILENAME}
                    style={{marginTop: ((dateExtractionMethod === EXTRACT_FROM_FILENAME) ? 16 : 0)}}>
            <div className="dialogRow flex">
              <FormControl variant="outlined" className="pubDocBulkUploaderDialogDatePatternAutocomplete"
                           style={{width: "50%"}}>
                <InputLabel className="outlined-control-label" style={{transform: "translate(14px, -6px) scale(0.75)"}}>Date
                  Pattern</InputLabel>
                <Autocomplete value={dateExtractionPattern}
                              options={DATE_PATTERNS.map(value => {
                                return {value: value, label: value};
                              })}
                              onChange={this.handleAutocompleteChange("dateExtractionPattern", DATE_PATTERNS[0])}
                              inputProps={{className: "outlined-control-input"}}
                              SelectDisplayProps={{className: "outlined-control-select"}}
                              wrapperStyle={{height: "100%"}}
                              style={{height: "100%"}}
                />
              </FormControl>
              <FormControl variant="outlined" className="outlined-control pubDocBulkUploaderDialogCenturySelect"
                           style={{width: "50%"}}>
                <InputLabel className="outlined-control-label">Default Century</InputLabel>
                <Select value={dateExtractionCentury}
                        onChange={this.handleSelectChange("dateExtractionCentury")}
                        inputProps={{className: "outlined-control-input"}}
                        SelectDisplayProps={{className: "outlined-control-select"}}
                >
                  <MenuItem key={0} value={0}>Auto</MenuItem>
                  {CENTURY_OPTIONS.map(value => (<MenuItem key={value} value={value}>{value}</MenuItem>))}
                </Select>
              </FormControl>
            </div>
          </Collapse>
          <div style={{marginTop: 16}}>
            <FormControlLabel label="Private?" control={
              <Switch checked={isPrivate} onChange={this.handleSwitchChange("isPrivate")}/>
            }/>
          </div>
          <div>
            <FormControlLabel label="Special Title Processing" control={
              <Switch checked={specialTitleProcessing} onChange={this.handleSwitchChange("specialTitleProcessing")}/>
            }/>
          </div>
          <Collapse in={specialTitleProcessing}>
            <div>
              <FormControlLabel label="Include Filename in Title" control={
                <Switch checked={includeFilenameInTitle} onChange={this.handleSwitchChange("includeFilenameInTitle")}/>
              }/>
            </div>
            <div className="dialogRow flex">
              <TextField label="Title Prefix" variant="outlined" className="pubDocBulkUploaderDialogTitlePrefixField"
                         margin="dense"
                         style={{width: "50%"}} value={titlePrefix} onChange={this.handleSelectChange("titlePrefix")}
              />
              <TextField label="Title Suffix" variant="outlined" className="pubDocBulkUploaderDialogTitleSuffixField"
                         margin="dense"
                         style={{width: "50%"}} value={titleSuffix} onChange={this.handleSelectChange("titleSuffix")}
              />
            </div>
          </Collapse>
        </If>
        <If test={dialogStep === 2}>
          <Table id="bulkUploader-documentTable" className="reactTable">
            <colgroup>
              <col width="5%"/>
              <col width="12%"/>
              <col width="15%"/>
              <col width="25%"/>
              <col width="25%"/>
              <col width="10%"/>
              <col width="5%"/>
              <col width="3%"/>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>
                  <span className="bulkUploader-documentTable-label">Type</span>
                </TableCell>
                <TableCell>
                  <span className="bulkUploader-documentTable-label">Category</span>
                </TableCell>
                <TableCell>
                  <span className="bulkUploader-documentTable-label">Filename</span>
                </TableCell>
                <TableCell>
                  <span className="bulkUploader-documentTable-label">Title</span>
                </TableCell>
                <TableCell>
                  <span className="bulkUploader-documentTable-label">Date</span>
                </TableCell>
                <TableCell>
                  <span className="bulkUploader-documentTable-label">Private</span>
                </TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <If test={loading}>
              <TableHead className="tableLoadingHeader">
                <TableRow>
                  <TableCell colSpan={8}>
                    <LinearProgress/>
                  </TableCell>
                </TableRow>
              </TableHead>
            </If>
            <TableBody>
              {pubDocs.map((pubDoc, i) =>
                this.renderPubDocTableRow(i, pubDoc, blankTitles, duplicateDocuments, uploadedDocuments, typeMap, categoryMap, filteredCategories))
              }
            </TableBody>
          </Table>
          <Dialog
            title="Cancel Confirm"
            open={cancelConfirmOpen}
            onCancel={this.closeCancelConfirm}
            onSubmit={this.handleDialogClose}
          >
            Are you sure you want to cancel? All progress will be lost.
          </Dialog>
          <Dialog
            title="Back Confirm"
            open={backConfirmOpen}
            onCancel={this.closeBackConfirm}
            onSubmit={this.openFirstDialogStep}
          >
            Are you sure you want to go back? Any manual changes you've made will be lost.
          </Dialog>
          <Dialog
            title="Documents Uploaded"
            open={Boolean(uploadedDocuments && pubDocs.length === uploadedDocuments.length)}
            onClose={this.handleDialogClose}
          >
            All documents have been successfully uploaded!
          </Dialog>
        </If>
      </Dialog>
    );
  }
}
export default PubDocBulkUploaderDialog;