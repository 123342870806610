import * as PropTypes from "prop-types";
import React from "react";

import {Checkbox, FormControlLabel, Typography} from "../../common/components";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "../../common/components/expansion";
import {ExpandMore as ExpandMoreIcon} from "../../common/components/icons";
import {If} from "../../common/containers";

const blankOptionGroup = {
  id: "0-",
  name: "",
  sortOrder: 0,
  options: []
};

class CheckboxListFacet extends React.Component {

  static propTypes = {
    facet: PropTypes.object.isRequired,
    facetSelections: PropTypes.array.isRequired,
    addFacetSelections: PropTypes.func.isRequired,
    removeFacetSelections: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {facet, facetSelections} = props;
    this.state = {
      defaultExpanded: facet.options.some(option => facetSelections.indexOf(`${facet.value}:${option.value}`) >= 0),
      optionGroupMap: this.getOptionGroupMap()
    };
  }

  getOptionGroupMap = () => {
    const {facet} = this.props;
    if (!facet.optionGroups) {
      return {[blankOptionGroup.id]: {...blankOptionGroup, options: facet.options}};
    }
    let optionGroupMap = {};
    for (let optionGroup of facet.optionGroups) {
      let options = [];
      for (const guid of optionGroup.optionValues) {
        for (const option of facet.options) {
          if (option && option.value === guid) {
            options.push(option);
            break;
          }
        }
      }
      const id = optionGroup.sortOrder + "-" + optionGroup.name;
      optionGroupMap[id] = {
        id: id,
        name: optionGroup.name,
        sortOrder: optionGroup.sortOrder,
        options: options
      };
    }
    return optionGroupMap;
  };

  handleCheckboxChange = (selection) => (e) => {
    const {addFacetSelections, removeFacetSelections} = this.props;
    if (e.target.checked) {
      addFacetSelections([selection]);
    } else {
      removeFacetSelections([selection]);
    }
  };

  handleGroupCheckboxChange = (groupId) => (e) => {
    const {facet, addFacetSelections, removeFacetSelections} = this.props;
    const {optionGroupMap} = this.state;
    const groupOption = !optionGroupMap[groupId] ? blankOptionGroup : optionGroupMap[groupId];
    const optionValues = groupOption.options.map((option) => `${facet.value}:${option.value}`);
    if (e.target.checked) {
      addFacetSelections(optionValues);
    } else {
      removeFacetSelections(optionValues);
    }
  };

  isGroupChecked = (groupId) => {
    const {facet, facetSelections} = this.props;
    const {optionGroupMap} = this.state;
    const groupOption = !optionGroupMap[groupId] ? blankOptionGroup : optionGroupMap[groupId];
    for (const option of groupOption.options) {
      const optionValue = `${facet.value}:${option.value}`;
      if (facetSelections.indexOf(optionValue) === -1) {
        return false;
      }
    }
    return true;
  };

  renderOption = (option) => {
    const {facet, facetSelections} = this.props;
    const selection = `${facet.value}:${option.value}`;
    return (
      <div className="checkboxListFacetOption" key={option.name}>
        <div>
          <FormControlLabel
            className="filterLabel"
            control={
              <Checkbox
                checked={facetSelections.indexOf(selection) >= 0}
                onChange={this.handleCheckboxChange(selection)}
                value={selection}
                className="checkboxListFacetOptionCheckbox"
              />
            }
            label={<Typography component="span" variant="body2" dangerouslySetInnerHTML={{__html: `${option.name} (${option.count.toLocaleString()})`}} />}
          />
        </div>
      </div>
    );
  };

  renderOptionGroup = (groupId) => {
    const {optionGroupMap} = this.state;
    const optionGroup = !optionGroupMap[groupId] ? {} : optionGroupMap[groupId];
    const hasGroupName = optionGroup.name != null && optionGroup.name !== ""
      && optionGroup.name !== "null" && optionGroup.options.length > 0;
    return (
      <div className={"checkboxListFacetOptionGroup" + (hasGroupName ? " grouped" : "")} key={groupId}>
        <If test={hasGroupName}>
          <FormControlLabel
            className="filterLabel"
            control={
              <Checkbox
                checked={this.isGroupChecked(groupId)}
                onChange={this.handleGroupCheckboxChange(groupId)}
                value={groupId}
                className="checkboxListFacetOptionGroupCheckbox"
              />
            }
            label={<Typography component="span" variant="body1" dangerouslySetInnerHTML={{__html: optionGroup.name }}/>}
          />
        </If>
        {optionGroup.options.map((option) => this.renderOption(option))}
      </div>
    );
  };

  render() {
    const {facet, isNewNav} = this.props;
    const {optionGroupMap, defaultExpanded} = this.state;
    return (
      <ExpansionPanel defaultExpanded={defaultExpanded} className={isNewNav ? "" : "filterExpansionPanel"}>
        <ExpansionPanelSummary
          className="filterExpansionPanelSummary"
          classes={{expanded: "filterExpansionPanelSummaryExpanded", content: "filterExpansionPanelSummaryContent", expandIcon: "filterExpansionPanelButton"}}
          expandIcon={<ExpandMoreIcon />}
        >
          <div>
            <span className="cardTitleSmall">Filter By {facet.name}</span>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="filterExpansionPanelDetails">
          {Object.keys(optionGroupMap).map((groupId) => this.renderOptionGroup(groupId))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
export default CheckboxListFacet;