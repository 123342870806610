const ADMIN = "ADMIN";
const ARCHIVES = "ARCHIVES";
const CODE = "CODE";
const DASHBOARD = "DASHBOARD";
const HELP = "HELP";
const HELP_ABOUT = "HELP_ABOUT";
const HELP_ADMINISTRATION = "HELP_ADMINISTRATION";
const HELP_FAQ = "HELP_FAQ";
const HELP_LAWS = "HELP_LAWS";
const HELP_PUBLIC_DOCUMENTS = "HELP_PUBLIC_DOCUMENTS";
const HELP_SEARCH = "HELP_SEARCH";
const HELP_SUPPORT = "HELP_SUPPORT";
const HELP_USERS = "HELP_USERS";
const HOME = "HOME";
const INDEX = "INDEX";
const JOB_HISTORY = "JOB_HISTORY";
const MANAGE_USERS = "MANAGE_USERS";
const NAVIGATION = "NAVIGATION";
const NEW_LAWS = "NEW_LAWS";
const NOTES = "NOTES";
const OTHER = "OTHER";
const LAW_LEDGER = "LAW_LEDGER";
const PUBLIC_DOCUMENTS = "PUBLIC_DOCUMENTS";
const PUBLIC_DOCUMENTS_LANDING = "PUBLIC_DOCUMENTS_LANDING";
const QUESTIONS = "QUESTIONS";
const QUESTIONS_STATUS = "QUESTIONS_STATUS";
const SEARCH_RESULTS = "SEARCH_RESULTS";
const SITE_SETTINGS = "SITE_SETTINGS";
const TITLES = "TITLES";
const TITLE_DISTRIBUTIONS = "TITLE_DISTRIBUTIONS"
const TOOLBAR = "TOOLBAR";

export {
  ADMIN,
  ARCHIVES,
  CODE,
  DASHBOARD,
  HELP,
  HELP_ABOUT,
  HELP_ADMINISTRATION,
  HELP_FAQ,
  HELP_LAWS,
  HELP_PUBLIC_DOCUMENTS,
  HELP_SEARCH,
  HELP_SUPPORT,
  HELP_USERS,
  HOME,
  INDEX,
  JOB_HISTORY,
  MANAGE_USERS,
  NAVIGATION,
  NEW_LAWS,
  NOTES,
  OTHER,
  LAW_LEDGER,
  PUBLIC_DOCUMENTS,
  PUBLIC_DOCUMENTS_LANDING,
  QUESTIONS,
  QUESTIONS_STATUS,
  SEARCH_RESULTS,
  SITE_SETTINGS,
  TITLES,
  TITLE_DISTRIBUTIONS,
  TOOLBAR
};