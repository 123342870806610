/*
 * Copyright 2018 General Code
 */

import {List, Map, Record} from 'immutable';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';
import {getLocalFilteredNotes} from '../selectors';

const State = Record({
  groups: null,
  notes: Map(),
  noteFilters: Map(),
  showHelp: false,
  realIdMapping: Map()
});

const initialState = State({});

const NoteGroup = Record({
  key: null,
  name: null
});

const Person = Record({
  name: null,
  email: null
});

const Note = Record({
  id: null,
  color: "default",
  content: null,
  createdBy: Person(),
  createdOn: null,
  guid: -1,
  editable: false,
  title: "",
  updatedBy: Person(),
  updatedOn: null,
  version: null,
  visibility: "private",
  open: true,
  editing: false,
  displayMode: true,
  saving: false,
  previous: null,
});

const setNoteFilter = (state, {payload: {filterId, value}}) => state.setIn(['noteFilters', filterId], value);
const removeNoteFilter = (state, {payload: {filterId}}) => state.removeIn(['noteFilters', filterId]);

const toggleNote = (state, {payload: {id}}) => state.setIn(['notes', id, 'open'], !state.getIn(['notes', id, 'open'], true));
const editNoteStart = (state, {payload: {id}}) => state.updateIn(['notes', id], note => note.set('previous', note).set('editing', true).set('open', true));
const editNoteCancel = (state, {payload: {id}}) => {
  if (id.startsWith('new-')) {
    setTimeout(() => document.getElementById(id).remove(), 0);
    return state.deleteIn(['notes', id]);
  } else {
    return state.updateIn(['notes', id], note => note.previous.set('editing', false).set('open', note.previous.displayMode === 'expanded'));
  }
};

const addNote = (state, {payload: {guid, id, date}}) => state
  .setIn(['notes', id], new Note({id, guid, date, open: true, editing: true, content: ""}));

const deleteNote = (state, {payload: {id}}) =>  {
  state = state.deleteIn(['notes', id]);
  if (!state.get('noteFilters').isEmpty() && getLocalFilteredNotes(state).size === 0) {
    state = state.set('noteFilters', Map());
  }
  let noteElement = document.getElementById(`note-${id}`);
  if (!noteElement) noteElement = document.getElementById(`${id}`);
  if (noteElement) {
    setTimeout(() => {noteElement.remove();}, 0);
  }
  if (window.generalcode?.refreshNoteLocations && state.get('notes').size === 0) {
    setTimeout(window.generalcode.refreshNoteLocations, 0);
  }
  return state;
};

const saveNoteStart = (state, {payload: {id}}) => state
  .updateIn(['notes', id], note => note.set('saving', true));
const saveNoteSuccess = (state, {payload: {id, real, version}}) => state
  .updateIn(['notes', id], note => note.set('editing', false).set('saving', false).set('previous', null).set('open', note.displayMode === 'expanded').set('version', version)).setIn(['realIdMapping', id],real);
const saveNoteFail = (state, {payload: {id}}) => state
  .updateIn(['notes', id], note => note.set('editing', false).set('saving', false).set('previous', null).set('open', note.displayMode === 'expanded'));
const saveNoteFinally = (state) => state;

const initNote = (state, {payload: {id, color, content, createdBy, createdOn, editable, title, updatedBy, updatedOn, version, visibility, displayMode, guid}}) => {
  return state
    .setIn(['notes', id], Note({id, color, content, createdBy: Person(createdBy), createdOn, editable, title, version, visibility, updatedBy: Person(updatedBy), updatedOn, displayMode, guid, open: displayMode === "expanded"}));
};

const setNoteGroups = (state, {payload: {groups}}) => state.set('groups', List(groups.map(group => NoteGroup(group))));

const setNoteGroupsEmpty = (state) => state.set('groups', List());

const updateProperty = prop => (state, {payload: {id, ...props}}) => state
  .setIn(['notes', id, prop], props[prop])
  .removeIn(['noteFilters', prop]);
const updateTitle = updateProperty('title');
const updateContent = updateProperty('content');
const updateColor = updateProperty('color');
const updateVisibility = updateProperty('visibility');
const updateDisplayMode = updateProperty('displayMode');

const reducer = handleActions({
  [actions.loadNoteGroupsSuccess]: setNoteGroups,
  [actions.loadNoteGroupsFail]: setNoteGroupsEmpty,
  [actions.setNoteFilter]: setNoteFilter,
  [actions.removeNoteFilter]: removeNoteFilter,
  [actions.addNote]: addNote,
  [actions.editNoteStart]: editNoteStart,
  [actions.editNoteCancel]: editNoteCancel,
  [actions.deleteNoteSuccess]: deleteNote,
  [actions.saveNoteStart]: saveNoteStart,
  [actions.saveNoteSuccess]: saveNoteSuccess,
  [actions.saveNoteFail]: saveNoteFail,
  [actions.saveNoteFinally]: saveNoteFinally,
  [actions.toggleNote]: toggleNote,
  [actions.initNote]: initNote,
  [actions.updateTitle]: updateTitle,
  [actions.updateContent]: updateContent,
  [actions.updateColor]: updateColor,
  [actions.updateDisplayMode]: updateDisplayMode,
  [actions.updateVisibility]: updateVisibility,
}, initialState);

export {State, initialState, Note};
export default reducer;