/**
 * Hamburger menu for a note
 * @module ecode/notes/note-control
 * @copyright 2018 General Code
 */

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';

import MainControls from '../components/MainControls';
import {isOpen, isEditable, isEditing, isUnsaved} from '../selectors';
import {editNote, deleteNote} from '../actions';


const mapStateToProps = createStructuredSelector({
  open: isOpen,
  editable: isEditable,
  editing: isEditing,
  isUnsaved: isUnsaved,
});

const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  editNote: () => editNote(id),
  deleteNote: () => deleteNote(id),
}, dispatch);


const MainControlsContainer = connect(mapStateToProps,mapDispatchToProps)(MainControls);
export default MainControlsContainer;
