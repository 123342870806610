import ReactDOM from "react-dom";

/*
 * Replaces html node with react component
 * @param element
 * @param reactComponent
 */
export function replaceNodeWithReactComponent (element, reactComponent) {
  const parent = document.createElement('div');
  ReactDOM.render(reactComponent, parent, () => {
    element.replaceWith(...Array.from(parent.childNodes));
  });
}