/*
 * Copyright 2018 General Code
 */

import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Checkbox, CircularProgress, Dialog, FormControlLabel, Paper, PrimaryButton,
  TextField, Tooltip} from "../../common/components";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "../../common/components/expansion";
import If from "../../common/containers/If";
import {
  CheckmarkCircle as CheckmarkCircleIcon,
  Circle as IncompleteIcon,
  CircleX as ErrorIcon
} from "../../common/components/icons";
import QuestionIcon from "../components/QuestionIcon";
import ReferenceContainer from "../containers/ReferenceContainer";
import ReviewerSelectContainer from "../containers/ReviewerSelectContainer";
import Options from "./Options";
import QuestionNoteDialog from "./QuestionNoteDialog";
import {username} from "../../common/utils/server-data";

class Question extends Component {

  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    references: PropTypes.array.isRequired,
    questionNote: PropTypes.object,
    options: PropTypes.object.isRequired,
    optionListMode: PropTypes.string.isRequired,
    ia2FileTypes: PropTypes.string,
    showDiscussionText: PropTypes.string,
    hideDiscussionText: PropTypes.string,
    isFinalized: PropTypes.bool,
    isLocked: PropTypes.bool,
    hasCommentAddPermission: PropTypes.bool,
    hasCommentUpdatePermission: PropTypes.bool,
    hasQuestionLockPermission: PropTypes.bool,
    hasQuestionUnlockPermission: PropTypes.bool,
    loadCollapsed: PropTypes.bool,
    loadExpanded: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    defaultExpanded: PropTypes.bool,
    displayedQuestionIds: PropTypes.array,
    error: PropTypes.string,
    hasError: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    lastEdit: PropTypes.object,
    questionHistory: PropTypes.array,
    comments: PropTypes.array,
    reviewers: PropTypes.object,
    scrollTo: PropTypes.bool,
    inline: PropTypes.bool,
    toggleExpanded: PropTypes.func.isRequired,
    setLocked: PropTypes.func.isRequired,
    setNeedsReview: PropTypes.func.isRequired,
    createResponse: PropTypes.func.isRequired,
    deleteResponse: PropTypes.func.isRequired,
    updateResponse: PropTypes.func.isRequired,
    resetResponses: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired,
    loadQuestionHistory: PropTypes.func.isRequired,
    addComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    updateComment: PropTypes.func.isRequired
  };
  static defaultProps = {
    scrollTo: false,
    inline: false
  };

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      historyOpen: false,
      resetConfirmOpen: false,
      noteEditorOpen: false,
      showDiscussion: false,
      commentText: "",
      deleteCommentConfirm: null,
      editCommentDialog: null,
      editCommentText: ""
    };
  }

  componentDidMount() {
    const {questionId, isFinalized, hasError, isComplete, toggleExpanded, inline, isOpen, defaultExpanded} = this.props;
    // If the question isn't open, but it should be by default, open it
    if (!isOpen && (defaultExpanded || (defaultExpanded == null && !inline && !isFinalized && (!isComplete || hasError)))) {
      toggleExpanded(questionId);
    }
  }

  scrollTarget = null;
  scrollIntoView = () => {
    if (window.generalcode?.scrollToHash) {
      // If we have a scroll target and it's visible in the DOM, scroll to it
      if (this.scrollTarget && this.scrollTarget.offsetParent != null) {
        window.generalcode.scrollToHash();
      } else {
        //If we don't have a ref keep trying until we do
        setTimeout(this.scrollIntoView, 500);
      }
    }
  };
  setScrollTarget = (ref) => {
    this.scrollTarget = ref;
    if (this.getThisQuestionHash() === window.location.hash) {
      this.scrollIntoView();
    }
  };
  scrollToThisQuestion = (event) => {
    event.preventDefault();
    window.location.href = window.location.href.replace(window.location.hash, "") + this.getThisQuestionHash();
    this.scrollIntoView();
  };
  getThisQuestionHash = () => {
    const {questionId} = this.props;
    if (window.location.pathname.indexOf("questions") !== -1) {
      return "#question-" + questionId;
    } else {
      let path = window.location.pathname.split("/");
      return "#question-" + path[path.length - 1] + "-" + questionId;
    }
  };

  handleNeedsReviewChange = (event) => {
    const {questionId} = this.props;
    this.props.setNeedsReview(questionId, event.target.checked);
  };

  cancelReset = () => {
    this.setState({resetConfirmOpen: false});
  };

  resetQuestion = () => {
    this.cancelReset();
    const {resetResponses, questionId} = this.props;
    resetResponses(questionId);
  };

  openCommentDeleteConfirm = (id) => () => {
    this.setState({deleteCommentConfirm: id});
  };

  cancelDeleteComment = () => {
    this.setState({deleteCommentConfirm: null});
  };

  confirmDeleteComment = () => {
    const {deleteComment, questionId} = this.props;
    const {deleteCommentConfirm} = this.state;
    deleteComment(questionId, deleteCommentConfirm);
    this.cancelDeleteComment();
  };

  openEditCommentDialog = (id, text) => () => {
    this.setState({editCommentDialog: id, editCommentText: text});
  };

  cancelEditComment = () => {
    this.setState({editCommentDialog: null, editCommentText: ""});
  };

  updateComment = () => {
    const {updateComment, questionId} = this.props;
    const {editCommentDialog, editCommentText} = this.state;
    updateComment(questionId, editCommentDialog, editCommentText);
    this.cancelEditComment();
  };

  handleCommentEditChange = (e) => {
    this.setState({editCommentText: e.target.value});
  }

  handleResetClick = () => {
    this.setState({resetConfirmOpen: true});
  };

  cancelNoteEdit = () => {
    this.setState({noteEditorOpen: false});
  };

  confirmNoteEdit = (content, fileDescriptors) => {
    const {addNote, questionId, questionNote} = this.props;
    this.setState({noteEditorOpen: false});
    let qn = {};
    if (!questionNote) {
      qn = {content: content};
      addNote(questionId, qn, fileDescriptors);
    } else {
      questionNote.content = content;
      addNote(questionId, questionNote, fileDescriptors);
    }
  };

  deleteNote = () => {
    const {deleteNote, questionId, questionNote} = this.props;
    this.setState({noteEditorOpen: false});
    deleteNote(questionId, questionNote);
  };

  handleAddNoteClick = () => {
    this.setState({noteEditorOpen: true});
  };

  toggleHistoryOpen = () => {
    const {loadQuestionHistory, questionId} = this.props;
    const {historyOpen} = this.state;
    loadQuestionHistory(questionId);
    this.setState({historyOpen: !historyOpen});
  };

  toggleHistoryClosed = () => {
    const {historyOpen} = this.state;
    this.setState({historyOpen: !historyOpen});
  }

  getHtmlText = (html) => {
    const htmlElement = document.createElement('html');
    htmlElement.innerHTML = html;
    return htmlElement.textContent;
  };

  handleDiscussionClick = () => {
    const {showDiscussion} = this.state;
    this.setState({showDiscussion: !showDiscussion});
  };

  handleCommentChange = (e) => {
    this.setState({commentText: e.target.value});
  };

  handleCommentEnter = (e) => {
    if(e.key === "Enter") {
      this.handleCommentSave();
    }
  };

  handleCommentSave = () => {
    const {addComment, questionId} = this.props;
    const {commentText} = this.state;
    addComment(questionId, commentText);
    this.setState({commentText: ''});
  };

  handleLock = () => {
    const {questionId} = this.props;
    this.props.setLocked(questionId, this.props.isLocked);
  };

  render() {
    const { questionId, text, number, references, questionNote, options, optionListMode, isNeedsReview, ia2FileTypes,
      isFinalized, isOpen, displayedQuestionIds, error, hasError, isComplete, lastEdit, questionHistory, comments,
      reviewers, toggleExpanded, setLocked, createResponse, deleteResponse, updateResponse, inline, isLocked,
      hasCommentAddPermission, hasCommentUpdatePermission, hasQuestionLockPermission, hasQuestionUnlockPermission,
      showDiscussionText, hideDiscussionText, ...props
    } = this.props;
    const { historyOpen, resetConfirmOpen, noteEditorOpen, showDiscussion, commentText, deleteCommentConfirm,
      editCommentDialog, editCommentText
    } = this.state;
    const selectedOptions = Object.values(options).filter((option) => option.selected);
    return (
      <div className={"questionContainer" + (inline ? " inline" : "") + (isOpen ? " expanded" : " collapsed")
      + ((displayedQuestionIds && displayedQuestionIds.indexOf(questionId) === -1) ? " hide" : "")}
      ref={this.setScrollTarget}>
        <Dialog
          title={"Question History"}
          open={historyOpen}
          width={"md"}
          onCancel={this.toggleHistoryClosed}
          onSubmit={this.toggleHistoryOpen}
        >
          <If test={!questionHistory}>
            <div className="spinnerContainer">
              <CircularProgress size={54}/>
            </div>
          </If>
          <If test={!!questionHistory}>
            {questionHistory && questionHistory.map((logEvent) => (
              <div key={logEvent.id} className="historyEvent">
                <span className="historyAccessTime">{logEvent.accessTime}</span> <span className="historyMessage" title={logEvent.displayMessage}>{logEvent.displayMessage}</span></div>
            ))}
          </If>
        </Dialog>
        <If test={!isFinalized && !isLocked}>
          <Dialog
            title="Reset Question?"
            open={resetConfirmOpen}
            onCancel={this.cancelReset}
            onSubmit={this.resetQuestion}
          >
            Are you sure you want to remove the answers to this question?
          </Dialog>
        </If>
        <Dialog
          title="Delete Comment?"
          open={deleteCommentConfirm !== null}
          onCancel={this.cancelDeleteComment}
          onSubmit={this.confirmDeleteComment}
        >
          Are you sure you want to remove this comment?
        </Dialog>
        <Dialog
          title={"Edit Comment"}
          open={editCommentDialog !== null}
          width="md"
          onCancel={this.cancelEditComment}
          onSubmit={this.updateComment}
        >
          <TextField className="questionCommentEdit"
            variant="outlined"
            margin="dense"
            placeholder="Add a comment..."
            value={editCommentText}
            onChange={this.handleCommentEditChange}
            style={{width: "100%"}}
          />
        </Dialog>
        <QuestionIcon isOpen={isOpen} toggleExpanded={() => {toggleExpanded(questionId);}}/>
        <Paper elevation={1} className={"question customerAccentColor_Border" + (error ? " error" : "")}>
          <div>
            <a className="questionTitle questionNumber"
              onClick={this.scrollToThisQuestion}
              href={window.location.pathname.replace(window.location.hash, "") + this.getThisQuestionHash()}
            >
              Question {number}
            </a>
            <If test={!isFinalized}>
              <If test={isComplete}>
                <div className="questionState complete">
                  <CheckmarkCircleIcon title="Question is complete" color="#008000"/>
                </div>
              </If>
              <If test={hasError}>
                <div className="questionState error">
                  <ErrorIcon title="Answer needs to be checked" color="#D32F2F"/>
                </div>
              </If>
              <If test={!isComplete && !hasError}>
                <div className="questionState incomplete">
                  <IncompleteIcon title="Question response is incomplete"/>
                </div>
              </If>
            </If>
            <If test={isFinalized && isComplete}>
              <div className="questionState complete finalized">
                <CheckmarkCircleIcon title="Analysis is finalized" color="#B2B2B2"/>
              </div>
            </If>
            <If test={references && references.length > 0 && !isOpen}>
              <div className="questionReferences muiChipContainer">
                {references.map((reference) => (
                  <a key={reference.id} href={"/" + reference.guid + "#question-" + reference.guid + "-"  + questionId} className="clickable muiChip">
                    <span className="titleIndexNum">
                      {reference.structure.content.type === "code" ? "Code"
                        : (reference.structure.content.indexNum ? reference.structure.content.indexNum : reference.structure.content.title)}
                    </span>
                  </a>
                ))}
              </div>
            </If>
            <If test={error}><div className="questionError">{error}</div></If>
            <If test={references && references.length > 0 && isOpen}>
              <div className="questionReferences">
                <div className="questionSectionLabel">Code Content:</div>
                {references.map((reference) => (<ReferenceContainer key={reference.id} questionId={questionId} reference={reference} {...props}/>))}
              </div>
            </If>
            <If test={!inline || isOpen}>
              <div className="questionText questionContent questionHtml clearfix" dangerouslySetInnerHTML={{__html: text}}/>
            </If>
            <If test={inline && !isOpen}>
              <div className="questionText questionContent questionHtml clearfix">{this.getHtmlText(text)}</div>
            </If>
            <div className="questionDetails">
              <Options questionId={questionId} optionListId={questionId} optionListMode={optionListMode} options={options} />
            </div>
            <If test={questionNote}>
              <div className="questionNoteDisplay printSection">
                <div className="questionSectionLabel">Note:</div>
                <div>{questionNote ? questionNote.content : ""}</div>
                {
                  questionNote ? questionNote.noteFiles.map((noteFile) => (<div key={noteFile.id}>{noteFile.localfile ?
                    <div><a href={URL.createObjectURL(noteFile.localfile)} target="_blank" rel="noopener noreferrer">{noteFile.localfile.name}</a></div> :
                    <div><a href={"/user/file/" + (noteFile ? noteFile.filePath : "")} target="_blank" rel="noopener noreferrer">{noteFile ? noteFile.originalFilename : ""}</a></div>
                  }</div>)) : ""
                }
              </div>
            </If>

            <div className="questionDiscussionContainer">
              <ExpansionPanel className="discussionPanel"
                expanded={showDiscussion}
                onChange={this.handleDiscussionClick}
              >
                <ExpansionPanelSummary
                  className="discussionPanelLabel"
                  expanded={showDiscussion}
                >
                  {showDiscussion ? hideDiscussionText : showDiscussionText}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="discussionPanelContent">
                    <If test={hasCommentAddPermission}>
                      <div className="commentInputArea">
                        <TextField className="commentInput"
                          variant="outlined"
                          margin="dense"
                          placeholder="Add a comment..."
                          value={commentText}
                          onChange={this.handleCommentChange}
                          onKeyPress={this.handleCommentEnter}
                        />
                        <PrimaryButton className="commentButton"
                          variant="contained"
                          onClick={this.handleCommentSave}
                          disabled={commentText.length < 1}
                        >
                          Save
                        </PrimaryButton>
                      </div>
                    </If>
                    <div className="questionComments">
                      <If test={!comments || comments.length < 1}>
                        <div>No comments yet</div>
                      </If>
                      <If test={comments && comments.length > 0}>
                        {comments && comments.map((comment) => (
                          <div key={comment.id} className="questionComment">
                            <div className="commentHeader">
                              <span className="commentAuthor">
                                {reviewers[comment.createdBy] ? reviewers[comment.createdBy].displayName : comment.createdBy}
                              </span>
                              <span className="commentDate">{comment.created}</span>
                            </div>
                            <div className="commentText">{comment.text}</div>
                            <If test={hasCommentAddPermission && comment.createdBy === username}>
                              <div className="commentActions">
                                <button className="commentAction commentEdit"
                                  onClick={this.openEditCommentDialog(comment.id, comment.text)}
                                >
                                  Edit
                                </button>
                                <button className="commentAction commentDelete"
                                  onClick={this.openCommentDeleteConfirm(comment.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </If>
                          </div>
                        ))}
                      </If>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>

            <div className="questionActionsContainer">
              <ReviewerSelectContainer questionId={questionId}/>
              <If test={!isFinalized}>
                <div className="needsReviewContainer">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isNeedsReview}
                        onChange={this.handleNeedsReviewChange}
                        style={{height: 'auto'}}
                        disabled={isLocked}
                      />
                    }
                    label="Needs Review"
                    className="needsReviewLabel"
                    labelPlacement="start"
                  />
                </div>
              </If>
            </div>
            <If test={!isFinalized}>
              <If test={selectedOptions.length > 0}>
                <div className="questionReset">
                  <PrimaryButton variant="contained" onClick={this.handleResetClick} disabled={isLocked}>Reset</PrimaryButton>
                </div>
              </If>
            </If>
            <If test={hasQuestionUnlockPermission || hasQuestionLockPermission}>
              <div className="questionLock">
                <Tooltip title={(!hasQuestionUnlockPermission && isLocked)? "Please ask a moderator for assistance in unlocking this question": ''}>
                  <PrimaryButton variant="contained" onClick={this.handleLock} disabled={(isLocked  && !hasQuestionUnlockPermission) || (!isLocked && !hasQuestionLockPermission)}>
                    <If test={isLocked}>
                      Unlock
                    </If>
                    <If test={!isLocked}>
                      Lock
                    </If>
                  </PrimaryButton>
                </Tooltip>
              </div>
            </If>
            <If test={!isFinalized || questionNote}>
              <div className="questionNote">
                <PrimaryButton variant="contained" onClick={this.handleAddNoteClick} className={"noteButton"} disabled={isLocked}>
                  {(questionNote ? (isFinalized ? "View Note to Editor" : "Edit Note to Editor") : "Add Note to Editor")}
                </PrimaryButton>
              </div>
              <QuestionNoteDialog
                title={number}
                questionId={questionId}
                questionNote={questionNote}
                ia2FileTypes={ia2FileTypes}
                isFinalized={isFinalized}
                open={noteEditorOpen}
                onCancel={this.cancelNoteEdit}
                onConfirm={this.confirmNoteEdit}
                onDelete={this.deleteNote}
              />
            </If>
            <div className="lastEditContainer">
              <If test={lastEdit !== null}>
                <button className="toggleHistoryButton" onClick={this.toggleHistoryOpen}>
                  Last modified by {lastEdit === null ? "" : lastEdit.user} on {lastEdit === null ? "" : lastEdit.accessTime}
                </button>
              </If>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
export default Question;