/**
 * Laws display component
 * @module ecode/laws
 *
 * Copyright 2018 General Code
 */
import React from "react";
import ReactDOM from "react-dom";
import {List, Map, OrderedMap} from "immutable";
import {replaceState} from "../common/history";
import {hasAdvancedSearchPermission, hasMulticodePermission} from "../common/selectors";
import {SEARCH_RESULTS} from "../common/utils/page-sub-types";
import {replaceNodeWithReactComponent} from "../common/utils/ReactHelper";
import SearchBarContainer from "./containers/SearchBarContainer";
import * as actions from "./actions";
import {ModuleKey} from "./ModuleKey";
import reducer, {initialState, RelatedTerm, Result, State} from "./reducers";
import * as selectors from "./selectors";
import CodeFinderDialogContainer from "./containers/CodeFinderDialogContainer";
import AdvancedSearchDialogContainer from "./containers/AdvancedSearchDialogContainer";
import SearchResultsPageActionsContainer from "./containers/SearchResultsPageActionsContainer";
import SearchResultsContainer from "./containers/SearchResultsContainer";
import {newNav, pageSubType} from "../common/utils/server-data";
import "nodelist-foreach-polyfill";
import {addPageFilter} from "../common/actions";

const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  const searchState = loadingState.get(ModuleKey) || initialState;

  let loadedState;
  const searchResultsPageContainer = document.getElementById("searchResultsPageContainer");
  if (searchResultsPageContainer) {
    const pageResults = [];
    for (let resultElement of Array.from(document.querySelectorAll("#resultsContainer .resultPaper"))) {
      pageResults.push(Result({
        custId: resultElement.dataset.custId,
        page: resultElement.dataset.page,
        type: resultElement.dataset.type,
        number: resultElement.dataset.number,
        title: resultElement.dataset.title,
        url: resultElement.dataset.url,
        printUrl: resultElement.dataset.printUrl,
        parents: OrderedMap(JSON.parse(resultElement.dataset.parents)),
        otherInfo: JSON.parse(resultElement.dataset.otherInfo),
        affectsLinks: OrderedMap(JSON.parse(resultElement.dataset.affectsLinks)),
        snippet: resultElement.dataset.snippet,
        pdf: (resultElement.dataset.pdf === "true"),
        hits: OrderedMap(JSON.parse(resultElement.dataset.hits))
      }));
    }

    const facetContainer = document.getElementById("facetContainer");
    loadedState = {
      isSearchResultsPage: true,
      query: searchResultsPageContainer.dataset.query,
      scope: searchResultsPageContainer.dataset.scope,
      sortOrder: searchResultsPageContainer.dataset.sortOrder,
      currentPage: parseInt(searchResultsPageContainer.dataset.page, 10),
      maxPages: parseInt(searchResultsPageContainer.dataset.maxPages, 10),
      totalResultCount: parseInt(searchResultsPageContainer.dataset.totalResultCount, 10),
      scopeTypes: JSON.parse(searchResultsPageContainer.dataset.scopeTypes),
      selectedCustIds: searchResultsPageContainer.dataset.selectedCustIds ?
        List(JSON.parse(searchResultsPageContainer.dataset.selectedCustIds)) :
        List([document.body.dataset.custId]),
      selectedCustomerFilters: searchResultsPageContainer.dataset.selectedCustomerFilters ?
        List(JSON.parse(searchResultsPageContainer.dataset.selectedCustomerFilters).filters) :
        List(),
      searchedCodeCount: parseInt(searchResultsPageContainer.dataset.searchedCodeCount, 10),
      selectedStructures: Map(JSON.parse(searchResultsPageContainer.dataset.selectedStructures)),
      isCodeFinderOpen: new URLSearchParams(window.location.search).has("codeFinder") ?
        ((new URLSearchParams(window.location.search).get("codeFinder")) === "true") : false,
      results: List(pageResults),
      customers: Map(JSON.parse(searchResultsPageContainer.dataset.customers)),
      facets: facetContainer ? JSON.parse(facetContainer.dataset.facets) : List(),
      facetSelections: facetContainer ? List(facetContainer.dataset.facetSelections.split(",")) : List(),
      relatedTerms: List(JSON.parse(searchResultsPageContainer.dataset.relatedTerms).map(relatedTerm => RelatedTerm(relatedTerm)))
    };
    loadedState.selectedCustomerFilters.forEach(customerFilter => customerFilter.json = JSON.stringify(customerFilter));
    replaceState(State(loadedState).toJS(), document.title, window.location.href );
  } else {
    const hasCustomer = document.body.dataset.custId && document.body.dataset.customer;
    loadedState = {
      isSearchResultsPage: false,
      selectedCustIds: hasCustomer ? List([document.body.dataset.custId]) : List(),
      isCodeFinderOpen: new URLSearchParams(window.location.search).has("codeFinder") ?
        ((new URLSearchParams(window.location.search).get("codeFinder") === "true") && hasMulticodePermission(loadingState)) : false,
      results: List(),
      customers: hasCustomer ? Map({[document.body.dataset.custId]: JSON.parse(document.body.dataset.customer)}) : Map()
    };
  }
  return loadingState.set(ModuleKey, reducer(searchState, actions.initializeSearchState(loadedState)));
};

const setCodeFinderOpen = (dispatch, isCodeFinderOpen) => (event) => {
  event.preventDefault();
  dispatch(actions.setCodeFinderOpen(isCodeFinderOpen));
};

const createCheckboxChangeHandler = (dispatch) => (event) => {
  const parent = event.target.parentNode.parentNode;
  dispatch(actions.toggleSelectedStructure(parent.dataset.guid, parent.dataset.fullTitle));
};

const handleHistoryStateChange = (dispatch) => (event) => {
  if (event.state) {
    dispatch(actions.initializeSearchState(event.state));
  }
};

/**
 * Given a guid and a container object this makes an editor for it
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 */
const init = (Providers, dispatch, state) => {

  const selectionBoxes = document.getElementsByClassName("selectionBoxInput");
  const checkboxChangeHandler = createCheckboxChangeHandler(dispatch);
  for (const selectionBox of selectionBoxes) {
    selectionBox.addEventListener("change", checkboxChangeHandler);
  }

  const searchBar = document.getElementById("search-bar");
  if (searchBar) {
    replaceNodeWithReactComponent(searchBar, <Providers key="search-bar-providers" name="search-bar"><SearchBarContainer/></Providers>);
  }

  const codeFinderContainer = document.createElement("div");
  codeFinderContainer.id = "codeFinderContainer";
  document.body.appendChild(codeFinderContainer);
  ReactDOM.render(
    <Providers key="code-finder-providers" name="code-finder">
      <CodeFinderDialogContainer/>
    </Providers>,
    codeFinderContainer
  );

  const multicodeSearchButton = document.getElementById("multicodeSearchButton");
  if (multicodeSearchButton) {
    multicodeSearchButton.addEventListener("click", setCodeFinderOpen(dispatch, true));
  }

  if (hasAdvancedSearchPermission) {
    const advancedSearchContainer = document.createElement("div");
    advancedSearchContainer.id = "advancedSearchContainer";
    document.body.appendChild(advancedSearchContainer);
    ReactDOM.render(
      <Providers key="advanced-search-providers" name="advanced-search">
        <AdvancedSearchDialogContainer/>
      </Providers>,
      advancedSearchContainer
    );
  }

  if (newNav && pageSubType === SEARCH_RESULTS) {
    const pageActions = document.getElementById("pageActions");
    if (pageActions) {
      ReactDOM.render(
        <Providers key="search-page-actions-providers" name="search-page-actions">
          <SearchResultsPageActionsContainer/>
        </Providers>,
        pageActions
      );
    }
    const searchResults = document.getElementById("searchResultsPageContainer");
    if (searchResults) {
      replaceNodeWithReactComponent(searchResults,
        <Providers key="search-results-providers" name="search-results">
          <SearchResultsContainer/>
        </Providers>
      );
    }
    for (const filterId of selectors.getFacetSelections(state)) {
      dispatch(addPageFilter(filterId));
    }
  }

  window.addEventListener("popstate",  handleHistoryStateChange(dispatch));
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer,
  selectors,
  getInitialState
};
