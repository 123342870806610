/*
 * Copyright 2018 General Code
 */

import React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";

import {Dialog, Editor, Paper} from "../../common/components";
import {withStyles} from "../../common/theme";
import {If} from "../../common/containers";
import EditControlsContainer from "../containers/EditControlsContainer";
import MetadataContainer from "../containers/MetadataContainer";
import NoteIcon from "./NoteIcon";
import NoteHeader from "./Header";

import "./Note.css";

export const styles = theme => ({
  note: {
    borderLeftColor: theme.palette.customer.default,
  },
  default: {
    borderLeftColor: theme.palette.site.default
  },
  accent: {
    borderLeftColor: theme.palette.customer.accent
  },
  banner: {
    borderLeftColor: theme.palette.customer.banner
  },
  title: {
    borderLeftColor: theme.palette.customer.title
  },
  teal: {
    borderLeftColor: theme.palette.site.teal
  },
  mint: {
    borderLeftColor: theme.palette.site.mint
  },
  berry: {
    borderLeftColor: theme.palette.site.berry
  },
  pineapple: {
    borderLeftColor: theme.palette.site.pineapple
  },
  roc: {
    borderLeftColor: theme.palette.site.roc
  },
});

class Note extends React.Component {

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      saveErrors: []
    };
  }

  isDisplayable = (note, filters = {}) => {
    for (const field of Object.keys(filters)) {
      if (note[field] !== filters[field] && !note.editing) return false;
    }
    return true;
  };

  onSave = () => {
    const {title} = this.props;
    const saveErrors = [];

    if (title === null || title.trim().length === 0) {
      saveErrors.push("Please add a title to the note before saving.");
    }

    let html = null;
    if (this.editorRef?.current) {
      html = this.editorRef.current.getHtml();
    }
    if (!html) {
      saveErrors.push("Please add something to the note before saving.");
    }

    if (title && title.length > 255) {
      saveErrors.push(`Please shorten the title of the note.  It must be ${title.length - 255} letters shorter`);
    }

    if (saveErrors.length === 0) {
      if (this.props.saveNote) {
        this.props.saveNote(html);
      }
    }
    this.setState({saveErrors: saveErrors});
  };

  onEditNoteCancel = () => {
    if (this.editorRef?.current) {
      this.editorRef.current.setHtml(this.props.content);
    }
  };

  onValidationClose = () => {
    this.setState({saveErrors: []});
  };

  render() {
    const {id, content, toggleNote, open, editing, color, visibility, filters, classes, guid, ...otherProps} = this.props;
    const {saveErrors} = this.state;
    return (
      <If test={this.isDisplayable({color: color, visibility: visibility, editing: editing, guid: guid}, filters)}>
        <aside className={classNames({
          "noteContainer": true,
          "noteContainerMaximized": open,
          "noteContainerMinimized": !open,
        })}>
          <NoteIcon open={open} editing={editing} toggle={() => toggleNote()}/>
          <Paper elevation={1} className={classNames(["note", classes.note, classes[color]])}>
            <NoteHeader {...otherProps} id={id} open={open}/>
            <If test={open}>
              <Editor editing={editing} html={content} ref={this.editorRef} {...otherProps}/>
            </If>
            <MetadataContainer {...otherProps} id={id}/>
            <EditControlsContainer {...otherProps} id={id} onSave={this.onSave} onCancel={this.onEditNoteCancel} />
          </Paper>
          <Dialog
            title="Problems with note"
            open={saveErrors.length > 0}
            onClose={this.onValidationClose}
          >
            {saveErrors.map((text) => <div key={text}>{text}</div>)}
          </Dialog>
        </aside>
      </If>
    );
  };
}

Note.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  visibility: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  toggleNote: PropTypes.func.isRequired,
  saveNote: PropTypes.func.isRequired
};
export default withStyles(styles)(Note);
