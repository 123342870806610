import {custId} from './common/utils/server-data';

//taken from https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data
//even if analytics call fails we want to navigate within the next second
function createFunctionWithTimeout(callback, opt_timeout) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

const genrefListener = e => {
  e.preventDefault();
  e.stopPropagation();

  const link = e.target;
  const dest = link.getAttribute('href');
  const text = link.parentNode.textContent;

  //we only want to navigate AFTER the event is sent to google analytics

  window.gtag('event','click',{
    event_category: 'genref',
    event_label: `${custId} / ${text}`,
    custId,
    destination: dest,
    event_callback: createFunctionWithTimeout(() =>
      document.location.assign(dest)
      //navigate to where the link points
      //replace just the last part of the url with the href, this is because genrefs are relative links and we might be in an archive
      //document.location.pathname.replace(/\/[^/]+$/, `/${dest}`)
    )
  });

};

const addGenrefListener = el => el.addEventListener("click", genrefListener);
document.querySelectorAll(".chapterrefblock a.xref").forEach(addGenrefListener);
