import * as PropTypes from "prop-types";
import React from "react";

import {archiveId, custId} from "../../common/utils/server-data";
import {CodeReference, Paper} from "../../common/components";
import {ListSubheader} from "../../common/components/list";
import {MenuItem, MenuList} from "../../common/components/menu";

import "./AutoSuggestComponent.css";

class AutoSuggestComponent extends React.PureComponent {
  static propTypes = {
    isSearchFieldActive: PropTypes.bool.isRequired,
    suggestions: PropTypes.object.isRequired,
    selectedCustIds: PropTypes.array.isRequired,
    selectedCustomerFilters: PropTypes.array.isRequired,
    setSearchFieldActive: PropTypes.func.isRequired
  };

  handleClick = (guid) => (event) => {
    if (!event.ctrlKey) window.location = `/${archiveId ? archiveId + "/" : ""}${guid}#${guid}`;
  };

  handleKeyDown = (event) => {
    const {setSearchFieldActive} = this.props;
    if (event.key && event.key === 'Tab') {
      setSearchFieldActive(false);
    }
  };

  render() {
    const {isSearchFieldActive, suggestions, selectedCustIds, selectedCustomerFilters} = this.props;
    const isMulticode = (selectedCustomerFilters && selectedCustomerFilters.length > 0)
      || (selectedCustIds && (selectedCustIds.length > 1 || (selectedCustIds.length === 1 && (!custId || selectedCustIds[0] !== custId))));
    return (
      <div id="autoSuggestContainer" className={(!isSearchFieldActive || suggestions.quickJumps.length === 0 || isMulticode) ? "hidden" : ""}>
        <Paper>
          <ListSubheader component="div" className="autoSuggestSubheader" tabIndex="0">Jump to...</ListSubheader>
          <MenuList dense={true}>
            {suggestions.quickJumps.map((qj) =>
              <MenuItem key={qj.guid} className="quickJumpItem" onClick={this.handleClick(qj.guid)} onKeyDown={this.handleKeyDown}>
                <CodeReference number={qj.indexNum} title={qj.title} guid={qj.guid} tooltip={qj.fullTitle} />
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </div>
    );
  }
}
export default AutoSuggestComponent;
