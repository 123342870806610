/*
 * Copyright 2018 General Code
 */

import * as PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {Dialog, HelpButton, IconButton, Typography} from "../../common/components";
import {Delete as DeleteIcon, Edit as EditIcon} from "../../common/components/icons";
import {If} from "../../common/containers";
import "./MainControls.css";

class MainControls extends PureComponent {

  state = {
    isDeleteConfirmOpen: false,
  };

  onDeleteConfirmOpen = () => {
    this.setState({isDeleteConfirmOpen: true});
  };

  onDeleteConfirm = () => {
    this.props.deleteNote();
    this.onDeleteConfirmClose();
  };

  onDeleteConfirmClose = () => {
    this.setState({isDeleteConfirmOpen: false});
  };

  render() {
    const {editNote, editable, editing, isUnsaved} = this.props;
    const {isDeleteConfirmOpen} = this.state;

    return (
      <div className="noteMainControls">
        <If test={editable}>
          <IconButton aria-label="Delete" disabled={isUnsaved} onClick={this.onDeleteConfirmOpen} className="noteMainControls-delete">
            <DeleteIcon size={24}/>
          </IconButton>
          <IconButton aria-label="Edit" disabled={editing} onClick={editNote} className="noteMainControls-edit">
            <EditIcon size={24}/>
          </IconButton>
          <Dialog
            title="Are you sure?"
            open={isDeleteConfirmOpen}
            onCancel={this.onDeleteConfirmClose}
            onSubmit={this.onDeleteConfirm}
            submitLabel="Delete"
          >
            <Typography>This will permanently delete this note.</Typography>
          </Dialog>
        </If>
        <HelpButton topic="users#addnote"/>
      </div>
    );
  }
}

MainControls.propTypes = {
  editNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
};

export default MainControls;
