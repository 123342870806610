import {connect} from "react-redux";
import {compose} from "recompose";
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";
import {addFacetSelectionsAndGetResults, removeFacetSelectionsAndGetResults} from "../actions";

import {isNewNav} from "../../common/selectors";
import {getFacets, getFacetSelections} from "../selectors";
import {toJS} from "../../common/utils";
import FacetList from "../components/FacetList";

const mapStateToProps = createStructuredSelector({
  facets: getFacets,
  facetSelections: getFacetSelections,
  isNewNav: isNewNav
});

const mapDispatchToProps = (dispatch) => ({
  addFacetSelections: (selections) => {
    dispatch(addFacetSelectionsAndGetResults(selections));
  },
  removeFacetSelections: (selections) => {
    dispatch(removeFacetSelectionsAndGetResults(selections));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(FacetList);

