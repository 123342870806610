import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import Result from '../components/Result';

import {getArchiveId} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  archiveId: getArchiveId,
});

export default compose(connect(mapStateToProps))(Result);

