import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';
import {toJS} from "../../common/utils";
import Reference from "../components/Reference";

import {getPreText, getPostText, getSelectedText, getDisplayHtml} from "../selectors";

const mapStateToProps = createStructuredSelector({
  preText: getPreText,
  postText: getPostText,
  selectedText: getSelectedText,
  displayHtml: getDisplayHtml
});

const mapDispatchToProps = () => ({});

const ReferenceContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(Reference);
ReferenceContainer.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  reference: PropTypes.object.isRequired
};
export default ReferenceContainer;