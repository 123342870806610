import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {
  deletePubDocType,
  setPubDocTypeEditOpen,
  setPubDocCategoryEditOpen,
  setPubDocEditOpen,
  setDocumentMoveOpen,
  setDownloadErrorOpen,
  setBulkDeleteOpen,
  setPubDocTypeDeleteOpen
} from "../actions";
import {getPubDocsLoading, getPubDocTypeId, getPubDocTypeTitle, getSelectedDocIds,
  getSelectedDocs} from "../selectors";
import PubDocsPageActions from "../components/PubDocsPageActions";
import {
  getCustId, hasPubDocTypeDeletePermission, hasPubDocTypeEditPermission,
  hasDocumentAdminPermission, getPageSubType
} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypeId: getPubDocTypeId,
  pubDocTypeTitle: getPubDocTypeTitle,
  documentsLoading: getPubDocsLoading,
  selectedDocIds: getSelectedDocIds,
  selectedDocs: getSelectedDocs,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  pageSubType: getPageSubType
});

const mapDispatchToProps = (dispatch) => ({
  deletePubDocType: (custId, pubDocTypeId) => {
    dispatch(deletePubDocType(custId, pubDocTypeId));
  },
  openDeleteDialog: (typeId) => {
    dispatch(setPubDocTypeDeleteOpen(true, typeId));
  },
  setEditOpen: (open, typeEditId) => {
    dispatch(setPubDocTypeEditOpen(open, typeEditId));
  },
  setCategoryEditOpen: (open, categoryId, typeId) => {
    dispatch(setPubDocCategoryEditOpen(open, categoryId, typeId));
  },
  openDocumentAddDialog: () => {
    dispatch(setPubDocEditOpen(true, null, null, null));
  },
  openDocumentMoveDialog: () => {
    dispatch(setDocumentMoveOpen(true, null));
  },
  openDownloadErrorDialog: () => {
    dispatch(setDownloadErrorOpen(true));
  },
  openBulkDeleteDialog: () => {
    dispatch(setBulkDeleteOpen(true));
  }
});

const PubDocsPageActionsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsPageActions);
export default PubDocsPageActionsContainer;