import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import CodeFinderDialog from "../components/CodeFinderDialog";
import {
  getFoundCustomers,
  getSelectedCustIds,
  getSelectedCustomerFilters,
  getCustomerSearchOptions,
  isCodeFinderOpen,
  isCodeFinderLoading,
  getCustomers,
  getQuery,
  isSearchResultsPage,
  isSearchLoading
} from "../selectors";
import {
  updateWindowHistory,
  findCustomers,
  clearFoundCustomers,
  setSelectedCustomers,
  submitNewSearch,
  findCustomerSearchOptions,
  setCodeFinderOpenAndLog,
  setQuery,
  resetSearchedCodeCount,
  resetSelectedStructures
} from "../actions";
import {getMulticodeStates} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  isCodeFinderOpen: isCodeFinderOpen,
  customers: getCustomers,
  foundCustomers: getFoundCustomers,
  defaultSelectedCustIds: getSelectedCustIds,
  defaultSelectedCustomerFilters: getSelectedCustomerFilters,
  customerSearchOptions: getCustomerSearchOptions,
  isCodeFinderLoading: isCodeFinderLoading,
  query: getQuery,
  isSearchResultsPage: isSearchResultsPage,
  isSearchLoading: isSearchLoading,
  multicodeStates: getMulticodeStates
});

const mapDispatchToProps = (dispatch) => ({
  setCodeFinderOpen: (isCodeFinderOpen) => {
    dispatch(setCodeFinderOpenAndLog(isCodeFinderOpen));
  },
  findCustomers: (name, state, counties, govtypes, govsubtype, minPop, maxPop, zipcode, distance) => {
    dispatch(findCustomers(name, state, counties, govtypes, govsubtype, minPop, maxPop, zipcode, distance));
  },
  clearFoundCustomers: () => {
    dispatch(clearFoundCustomers());
  },
  setSelectedCustomers: (selectedCustIds, selectedCustomerFilters) => {
    dispatch(setSelectedCustomers(selectedCustIds, selectedCustomerFilters));
  },
  submitSearch: () => {
    dispatch(submitNewSearch(true, false));
  },
  findCustomerSearchOptions: (name, state, counties, govtypes, govsubtype) => {
    dispatch(findCustomerSearchOptions(name, state, counties, govtypes, govsubtype));
  },
  updateWindowHistory: (stateChanges) => {
    dispatch(updateWindowHistory(stateChanges));
  },
  setQuery: (query) => {
    dispatch(setQuery(query));
  },
  resetSearchedCodeCount: () => {
    dispatch(resetSearchedCodeCount());
  },
  resetSelectedStructures: () => {
    dispatch(resetSelectedStructures());
  }
});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(CodeFinderDialog);
