import * as PropTypes from "prop-types";
import {instanceOf} from "prop-types";
import React, {Fragment} from "react";
import PubDocTypeEditDialogContainer from "../../../pub-docs/containers/PubDocTypeEditDialogContainer";
import NavigationItem from "./NavigationItem";
import NavigationSubItem from "./NavigationSubItem";
import {
  ADMIN,
  CODE as CODE_PAGE,
  HELP,
  HOME,
  LAWS,
  NOTES,
  PUBLIC_DOCUMENTS,
  QUESTIONS,
  SEARCH,
  ZONING
} from "../../utils/page-types";
import {
  ARCHIVES,
  CODE as CODE_SUB_PAGE,
  DASHBOARD,
  HELP as HELP_SUB_PAGE,
  HELP_ABOUT,
  HELP_ADMINISTRATION,
  HELP_FAQ,
  HELP_LAWS,
  HELP_PUBLIC_DOCUMENTS,
  HELP_SEARCH,
  HELP_SUPPORT,
  HELP_USERS,
  INDEX,
  JOB_HISTORY,
  LAW_LEDGER,
  MANAGE_USERS,
  NAVIGATION,
  NEW_LAWS,
  PUBLIC_DOCUMENTS_LANDING,
  SITE_SETTINGS, TITLE_DISTRIBUTIONS,
  TITLES,
  TOOLBAR,
} from "../../utils/page-sub-types";
import {
  Admin as AdminIcon,
  Code as CodeIcon,
  ECode as ECodeIcon,
  Help as HelpIcon,
  Home as HomeIcon,
  NewLawNew as LawIcon,
  Note as NotesIcon,
  PubDoc as PubDocsIcon,
  Questions as QuestionIcon,
  Search as SearchIcon,
  Zoning as ZoningIcon
} from "../icons";
import {Button} from "../button";
import {Snackbar} from "../snackbar";
import {Cookies, withCookies} from "react-cookie";

class NavigationRail extends React.PureComponent {

  static propTypes = {
    archiveId: PropTypes.string,
    codeId: PropTypes.string,
    codeLabel: PropTypes.string.isRequired,
    cookies: instanceOf(Cookies).isRequired,
    custId: PropTypes.string,
    displayedQuestionIds: PropTypes.array,
    hasArchivesViewPermission: PropTypes.bool.isRequired,
    hasDashboardPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    hasIndexViewPermission: PropTypes.bool.isRequired,
    hasLawViewPermission: PropTypes.bool.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasPubDocTypeAddPermission: PropTypes.bool.isRequired,
    hasPubDocTypeDeletePermission: PropTypes.bool.isRequired,
    hasPubDocTypeEditPermission: PropTypes.bool.isRequired,
    hasViewPermission: PropTypes.bool.isRequired,
    lawCount: PropTypes.number.isRequired,
    lawLedgerCount: PropTypes.number,
    mapUrl: PropTypes.string,
    noteCount: PropTypes.number.isRequired,
    pageSubType: PropTypes.string,
    pageType: PropTypes.string,
    pubDocTypeId: PropTypes.number,
    pubDocTypes: PropTypes.array,
    pubDocsLabel: PropTypes.string.isRequired,
    pubDocsOnly: PropTypes.bool.isRequired,
    searchResultsUrl: PropTypes.string,
    closePubDocTypeAddDialog: PropTypes.func.isRequired,
    zoningGuid: PropTypes.string
  };


  constructor(props) {
    super(props);
    const {cookies, globalPublicMessage} = props;
    let publicMessageShown = (cookies.get('publicMessageShown') || "") === globalPublicMessage;
    let publicMessageDismissed = (cookies.get('publicMessageDismissed') || "") === globalPublicMessage;
    this.state = { expandedPage: null, showPublicMessage: (!publicMessageDismissed && !publicMessageShown && (globalPublicMessage.length > 0)) };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(e) {
    const { expandedPage } = this.state;
    if (expandedPage && this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({...this.state, expandedPage: null});
    }
  }

  setCookie = (name,value,days) => {
    const {cookies} = this.props;
    let options = { path: "/", sameSite: true }
    if (days > 0) {
      options.maxAge = 3600*24*days;
    }
    cookies.set(name,value,options);
  }

  closePublicMessage = () => {
    this.setState( { ...this.state, showPublicMessage: false});
  }

  dismissPublicMessage = () => {
    const {globalPublicMessage, globalPublicMessageDismissalDays} = this.props;
    this.setCookie("publicMessageDismissed",globalPublicMessage,globalPublicMessageDismissalDays);
    this.closePublicMessage();
  }

  setExpanded = (pageId) => () => {
    const pageGridElement = document.getElementById("page-grid");
    if (pageId) {
      pageGridElement.classList.remove("rail-collapsed");
      pageGridElement.classList.add("rail-expanded");
    } else {
      pageGridElement.classList.remove("rail-expanded");
      pageGridElement.classList.add("rail-collapsed");
    }
    this.setState({ ...this.state, expandedPage: (pageId ? pageId : null) });
  }

  buildGuidUrl = (guid) => {
    if (!guid) return null;
    const { archiveId } = this.props;
    if (archiveId) {
      return `/${archiveId}/${guid}#${guid}`;
    } else {
      return `/${guid}#${guid}`;
    }
  }

  closePubDocTypeAddDialog = () => this.props.closePubDocTypeAddDialog();

  render() {
    const {
      adminLongName,
      adminShortName,
      analysisId,
      archiveId,
      codeId,
      codeLongName,
      codeShortName,
      custId,
      displayedQuestionIds,
      globalPublicMessage,
      hasArchivesViewPermission,
      hasCustomizationAdminPermission,
      hasDashboardHistoryPermission,
      hasDashboardPermission,
      hasDashboardTitlesPermission,
      hasDocumentAdminPermission,
      hasFeatureAdminPermission,
      hasIndexViewPermission,
      hasLawViewPermission,
      hasPubDocPrivateViewPermission,
      hasPubDocTypeAddPermission,
      hasPubDocTypeDeletePermission,
      hasPubDocTypeEditPermission,
      hasUserAdminPermission,
      hasViewPermission,
      helpLongName,
      helpShortName,
      homeShortName,
      lawCount,
      lawLedgerCount,
      lawLedgerName,
      lawsShortName,
      mapUrl,
      newLawsName,
      noteCount,
      notesShortName,
      pageSubType,
      pageType,
      pubDocTypeId,
      pubDocTypes,
      pubDocsOnly,
      pubdocsShortName,
      questionsShortName,
      searchResultsUrl,
      zoningChapterName,
      zoningGuid,
      zoningLongName,
      zoningMapName,
      zoningShortName,
      hasDistributionData,
      indexViewVisible,
      adminOptionHide
    } = this.props;
    const { expandedPage, showPublicMessage } = this.state;
    return (
      <Fragment>
        <div id="rail" className={expandedPage ? "expanded" : "collapsed"} ref={this.wrapperRef}>
          <Snackbar
            className={"public-message"}
            open={showPublicMessage}
            ClickAwayListenerProps={{mouseEvent: false}}
            message={<div dangerouslySetInnerHTML={{__html: globalPublicMessage}}/>}
            action={
              <Button color="inherit" size="small" onClick={this.dismissPublicMessage} >
                DISMISS
              </Button>
            }
            onClose={this.closePublicMessage}
          />
          <div className="items">
            <NavigationItem
              label={homeShortName}
              hidden={Boolean(!custId || !hasViewPermission)}
              icon={<HomeIcon key={"home"} className={"nav-rail-icon"} size={32}/>}
              href={`/${custId}/home`}
              selected={pageType === HOME}
            />
            <NavigationItem
              label={codeShortName}
              icon={<CodeIcon key={"code"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(pubDocsOnly || !custId || !hasViewPermission)}
              href={`/${codeId}`}
              selected={pageType === CODE_PAGE}
              expanded={expandedPage === CODE_PAGE}
              expandRail={this.setExpanded(CODE_PAGE)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem
                label={codeLongName}
                href={`/${archiveId ? (archiveId + "/") : ""}${custId}`}
                selected={pageSubType === CODE_SUB_PAGE}
                isTitle={true}
              />
              <NavigationSubItem
                label="Index"
                hidden={Boolean(!hasIndexViewPermission || !indexViewVisible)}
                href={`/${custId}${archiveId ? "/code/" + archiveId : ""}/index`}
                selected={pageSubType === INDEX}
              />
              <NavigationSubItem
                label="Archives"
                hidden={Boolean(!hasArchivesViewPermission)}
                href={`/archives/${custId}`}
                selected={pageSubType === ARCHIVES}
              />
            </NavigationItem>
            <NavigationItem
              label={zoningShortName}
              title="Zoning specific resources"
              icon={<ZoningIcon key={"zoning"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(!custId || !hasViewPermission || (!mapUrl && !zoningGuid))}
              expanded={expandedPage === ZONING}
              expandRail={this.setExpanded(ZONING)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem label={zoningLongName} isTitle={true} isLink={false}/>
              <NavigationSubItem label={zoningMapName} href={mapUrl} openNewTab={true} />
              <NavigationSubItem label={zoningChapterName} href={this.buildGuidUrl(zoningGuid)} />
            </NavigationItem>
            <NavigationItem
              label={(archiveId || lawLedgerCount === 0)? newLawsName : lawsShortName}
              title="Laws, Ordinances, and other Enactments"
              icon={<LawIcon key={"laws"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(pubDocsOnly || !codeId || !hasViewPermission || !hasLawViewPermission)}
              selected={pageType === LAWS}
              count={lawCount}
              expanded={expandedPage === LAWS}
              expandRail={this.setExpanded(LAWS)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem
                label={newLawsName}
                href={`/${codeId}/laws`}
                selected={pageSubType === NEW_LAWS}
                count={lawCount}
              />
              <NavigationSubItem
                label={lawLedgerName}
                hidden={(archiveId || lawLedgerCount === 0)}
                href={`/${codeId}/law-ledger`}
                selected={pageSubType === LAW_LEDGER}
                count={lawLedgerCount}
              />
            </NavigationItem>
            <NavigationItem
              label={notesShortName}
              title="View Notes and Annotations that have added to the Code"
              icon={<NotesIcon key={"notes"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(!custId || !hasViewPermission || archiveId || noteCount === 0)}
              href={`/note/${custId}`}
              selected={pageType === NOTES}
              count={noteCount}
            />
            <NavigationItem
              label={pubdocsShortName}
              title="Documents posted and shared for easy access"
              icon={<PubDocsIcon key={"pubdocs"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(!custId || !hasViewPermission || archiveId
                || (pubDocTypes.length === 0 && !hasDocumentAdminPermission && !hasPubDocTypeAddPermission
                  && !hasPubDocTypeDeletePermission && !hasPubDocTypeEditPermission))}
              href={`/${custId}/document/types`}
              selected={pageType === PUBLIC_DOCUMENTS}
              expanded={expandedPage === PUBLIC_DOCUMENTS}
              expandRail={this.setExpanded(PUBLIC_DOCUMENTS)}
              collapseRail={this.setExpanded(null)}
            >
              {(hasDocumentAdminPermission || hasPubDocTypeAddPermission || hasPubDocTypeDeletePermission || hasPubDocTypeEditPermission) && (
                <NavigationSubItem
                  label="Management"
                  href={`/${custId}/document/types`}
                  isTitle={true}
                  selected={pageSubType === PUBLIC_DOCUMENTS_LANDING}
                />
              )}
              {pubDocTypes.map((type) => (
                <NavigationSubItem
                  key={type.id}
                  label={type.title}
                  hidden={Boolean(!hasDocumentAdminPermission
                    && ((hasPubDocPrivateViewPermission ? type.count : (type.count - type.privateCount)) < 1))}
                  href={`/${custId}/documents/${encodeURIComponent(type.title)}`}
                  selected={pubDocTypeId === type.id}
                  count={(hasPubDocPrivateViewPermission ? type.count : (type.count - type.privateCount))}
                />
              ))}
            </NavigationItem>
            <NavigationItem
              label={questionsShortName}
              title="Editorial and Legal Analysis Questions"
              icon={<QuestionIcon key={"questions"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(!custId || !hasViewPermission || archiveId || !analysisId)}
              href={`/${custId}/questions/status`}
              selected={pageType === QUESTIONS}
              count={displayedQuestionIds ? displayedQuestionIds.length : 0}
            >
            </NavigationItem>

            <NavigationItem
              label={adminShortName}
              title="Access administrative tools"
              icon={<AdminIcon key={"admin"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(!custId || !hasDashboardPermission)}
              href={`/dashboard/${custId}`}
              selected={pageType === ADMIN}
              expanded={expandedPage === ADMIN}
              expandRail={this.setExpanded(ADMIN)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem
                label={adminLongName}
                isTitle={true}
                isLink={false}
              />
              <NavigationSubItem
                label={"Manage Users"}
                href={`/dashboard/${custId}`}
                hidden={Boolean(!custId || !hasUserAdminPermission)}
                selected={pageSubType === MANAGE_USERS}
              />
              <NavigationSubItem
                label={"Site Settings"}
                href={`/dashboard/${custId}/settings`}
                hidden={Boolean(!custId || !hasFeatureAdminPermission || !hasCustomizationAdminPermission)}
                selected={pageSubType === SITE_SETTINGS}
              />
              <NavigationSubItem
                label={"Dashboard"}
                href={`/dashboard/${custId}/stats`}
                selected={pageSubType === DASHBOARD}
              />
              <NavigationSubItem
                label={"Titles"}
                hidden={Boolean(!custId || !hasDashboardTitlesPermission || adminOptionHide)}
                href={`/dashboard/${custId}/titles`}
                selected={pageSubType === TITLES}
              />
              <NavigationSubItem
                label={"Title Distribution"}
                href={`/dashboard/${custId}/distribution`}
                hidden={Boolean(!hasDistributionData || adminOptionHide)}
                selected={pageSubType === TITLE_DISTRIBUTIONS}
              />
              <NavigationSubItem
                label={"Job History"}
                hidden={Boolean(!custId || !hasDashboardHistoryPermission || adminOptionHide)}
                href={`/dashboard/${custId}/history`}
                selected={pageSubType === JOB_HISTORY}
              />
            </NavigationItem>

            <NavigationItem
              label={helpShortName}
              title="Get help using eCode360"
              href={"/help" + (custId ? ("/" + custId) : "")}
              icon={<HelpIcon key={"help"} className={"nav-rail-icon"} size={32}/>}
              selected={pageType === HELP}
              expanded={expandedPage === HELP}
              expandRail={this.setExpanded(HELP)}
              collapseRail={this.setExpanded(null)}
            >
              <NavigationSubItem
                label={helpLongName}
                isTitle={true}
                isLink={false}
              />
              <NavigationSubItem
                label={"Welcome"}
                href={"/help" + (custId ? ("/" + custId) : "")}
                selected={pageSubType === HELP_SUB_PAGE}
              />
              <NavigationSubItem
                label={"Basic Navigation"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/navigation"}
                selected={pageSubType === NAVIGATION}
              />
              <NavigationSubItem
                label={"Toolbar"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/tools"}
                selected={pageSubType === TOOLBAR}
              />
              <NavigationSubItem
                label={"Searching"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/search"}
                selected={pageSubType === HELP_SEARCH}
              />
              <NavigationSubItem
                label={"Tools: Municipal Users"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/users"}
                selected={pageSubType === HELP_USERS}
              />
              <NavigationSubItem
                label={"Tools: Administrators"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/administration"}
                selected={pageSubType === HELP_ADMINISTRATION}
              />
              <NavigationSubItem
                label={"New Laws"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/laws"}
                selected={pageSubType === HELP_LAWS}
              />
              <NavigationSubItem
                label={"Public Documents"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/publicdocuments"}
                selected={pageSubType === HELP_PUBLIC_DOCUMENTS}
              />
              <NavigationSubItem
                label={"FAQ"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/faq"}
                selected={pageSubType === HELP_FAQ}
              />
              <NavigationSubItem
                label={"Request Support"}
                href={"/help" + (custId ? ("/" + custId) : "") + "/support"}
                selected={pageSubType === HELP_SUPPORT}
              />
              <NavigationSubItem
                label="About"
                href={"/help" + (custId ? ("/" + custId) : "") + "/about"}
                selected={pageSubType === HELP_ABOUT}
              />
            </NavigationItem>

            <NavigationItem
              label="Search"
              title="Results from the recent search"
              icon={<SearchIcon key={"search"} className={"nav-rail-icon"} size={32}/>}
              hidden={Boolean(pageType !== SEARCH && !searchResultsUrl)}
              href={searchResultsUrl ? searchResultsUrl : (codeId ? `/${codeId}/search` : "search?codeFinder=true")}
              selected={pageType === SEARCH}
              expanded={expandedPage === SEARCH}
              expandRail={this.setExpanded(SEARCH)}
              collapseRail={this.setExpanded(null)}
            />

            <div id="gcLogoSpacer"><ECodeIcon size={50} /></div>

            <a href={"/help" + (custId ? ("/" + custId) : "") + "/about"} id="gcLogo" target="_blank" rel="noopener noreferrer">
              <ECodeIcon size={50} />
            </a>

          </div>
        </div>
        <PubDocTypeEditDialogContainer onCancel={this.closePubDocTypeAddDialog} />
      </Fragment>

    );
  }
}

export default withCookies(NavigationRail);