import {List, Map} from "immutable";

export default (messages = List([]), action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return messages.shift().push(Map(action.messageToAdd));
    case 'REMOVE_MESSAGE':
      return messages.filterNot(msg => msg.get('id') === action.messageId);
    default:
      return messages;
  }
};
