import React from 'react';
import {Dialog} from "./Dialog";
import {getUserMessageText, getUserMessageTitle, isUserMessageShown} from "../../selectors";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hideUserMessage} from "../../actions";

const UserMessageDialog = ({show, close, title, text}) =>
  <Dialog open={show} onClose={close} title={title}>
    {text}
  </Dialog>;

const mapStateToProps = (state) => ({
  show: isUserMessageShown(state),
  title: getUserMessageTitle(state),
  text: getUserMessageText(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  close: hideUserMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserMessageDialog);
