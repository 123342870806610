import { combineReducers } from 'redux-immutable';
import messagesReducer from './messages-reducer';
import openMessageReducer from "./open-message-reducer";

const reducer = combineReducers({
  showMessage: openMessageReducer,
  messages: messagesReducer,
});

export default reducer
export * from './selectors'

