import React from 'react';

export const toJS = WrappedComponent => wrappedComponentProps => {
  const KEY = 0;
  const VALUE = 1;

  const propsJS = Object.entries(
    wrappedComponentProps
  ).reduce((newProps, wrappedComponentProp) => {
    const key = wrappedComponentProp[KEY];
    const value = wrappedComponentProp[VALUE];
    newProps[key] = value && value.toJS && !value._immutable
      ? value.toJS()
      : value;
    return newProps;
  }, {});

  return <WrappedComponent {...propsJS} />;
};
