import {custId} from "./utils/server-data";

class ReferenceGroup {
  name = '';
  friendlyName = '';
  getMatchesUrl = () => '';
  getRootNodesUrl = () => '';
  getNodeChildrenUrl = () => '';
  getNodeName = () => '';
  getMatchOptionTitle = () => '';
  getReferenceDetails = () => ({text: '', url: '', targetOption: '_self'});
}

export const codeReferenceType = 'code';
class CodeReferenceGroup extends ReferenceGroup {
  name = codeReferenceType;
  friendlyName = 'Code';
  getMatchesUrl = (query) => `/api/location/${custId}?search=${query}`;
  getRootNodesUrl = () => `/api/location/${custId}/children`;
  getNodeChildrenUrl = (node) => `/api/location/${node.key}/children`;
  getNodeName = (node) => `${node.context ? `${node.context}: ` : ''}${node.title}`;
  getMatchOptionTitle = (match) => `${match.context ? `${match.context}: ` : ''}${match.title}`;
  getReferenceDetails = (nodeOrMatch) => nodeOrMatch ? {
    text: `${nodeOrMatch.context ? `${nodeOrMatch.context}: ` : ''}${nodeOrMatch.title}`,
    guid: `${nodeOrMatch.key}`, url: `/${nodeOrMatch.key}`
  } : {
    text: '',
    guid:'',
    url: '',
    targetOption: '_self'
  };
}

export const pubDocsReferenceType = 'pubDocs';
class PubDocsReferenceGroup extends ReferenceGroup {
  name = pubDocsReferenceType;
  friendlyName = 'Public Documents';
  getMatchesUrl = (query) => `/api/location/${custId}/pub-docs?search=${query}`;
  getRootNodesUrl = () => `/api/location/${custId}/pub-doc/types`;
  getNodeChildrenUrl = (node) => {
    switch (node.type) {
      case 'type':
        return `/api/location/${custId}/pub-doc/type/${node.key}/categories`;
      case 'category':
        return `/api/location/${custId}/pub-doc/category/${node.key}/children`;
      case 'year':
        const keyParts = node.key.split(':');
        return `/api/location/${custId}/pub-doc/category/${keyParts[0]}/year/${keyParts[1]}/children`;
      default:
        return null;
    }
  };
  getNodeName = (node) => node.title;
  getMatchOptionTitle = (match) => {
    let type = '';
    switch (match.type) {
      case 'type':
        type = 'Public Document Type';
        break;
      case 'category':
        type = 'Public Document Category';
        break;
      case 'year':
        type = 'Public Document Category Year';
        break;
      default:
        type = 'Public Document';
        break;
    }
    return `${type} :: ${match.context ? `${match.context}: ` : ''}${match.title}`;
  };
  getReferenceDetails = (nodeOrMatch) => {
    let text = '', url = '', targetOption = '_self';
    if (!nodeOrMatch) return {text, url, targetOption};

    text = `${nodeOrMatch.context ? `${nodeOrMatch.context}: ` : ''}${nodeOrMatch.title}`;
    switch (nodeOrMatch.type) {
      case 'type':
        return {text, url: `/documents/pub/${custId}/${encodeURI(nodeOrMatch.title)}/?`, targetOption};
      case 'category':
        return {text, url: `/documents/pub/${custId}/${encodeURI(nodeOrMatch.context)}/?subCategory=${encodeURI(nodeOrMatch.title)}`, targetOption};
      case 'year':
        const contextParts = nodeOrMatch.context.split(": ");
        return {text, url: `/documents/pub/${custId}/${encodeURI(contextParts[0].trim())}/?subCategory=${encodeURI(contextParts[1].trim())}&year=${encodeURI(nodeOrMatch.title)}`, targetOption};
      case 'document':
        return {text, url: `/documents/${custId}/public/${nodeOrMatch.key}.pdf`, targetOption};
      default:
        return {text, url, targetOption};
    }
  };
}

const referenceGroups = {
  code: new CodeReferenceGroup(),
  pubDocs: new PubDocsReferenceGroup(),
};

export const getReferenceGroup = (groupName) => referenceGroups[groupName];
export const getReferenceGroupNames = () => Object.keys(referenceGroups);
