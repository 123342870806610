import {createStructuredSelector} from "reselect";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import PubDocsDownloadErrorDialog from "../components/PubDocsDownloadErrorDialog";
import {getSelectedDocs, getDownloadErrorOpen} from "../selectors";
import {setDownloadErrorOpen} from "../actions";

const mapStateToProps = createStructuredSelector({
  selectedDocs: getSelectedDocs,
  downloadErrorOpen: getDownloadErrorOpen
});

const mapDispatchToProps = (dispatch) => ({
  closeDownloadErrorDialog: () => {
    dispatch(setDownloadErrorOpen(false));
  }
});

const PubDocsDownloadErrorDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsDownloadErrorDialog);
export default PubDocsDownloadErrorDialogContainer;