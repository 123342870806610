import {createAction} from 'redux-actions';

const updateTitle = createAction('NOTE/UPDATE/TITLE', (id, title) => ({id, title: title.target ? title.target.value : title}));
const updateContent = createAction('NOTE/UPDATE/CONTENT', (id, content) => ({id, content}));
//color can be a dom event
const updateColor = createAction('NOTE/UPDATE/COLOR', (id, color = 'green') => ({id, color}));
const updateVisibility = createAction('NOTE/UPDATE/VISIBILITY', (id, visibility = 'private') => ({id, visibility}));
const updateDisplayMode = createAction('NOTE/UPDATE/INITIAL-DISPLAY', (id, displayMode) => ({id,displayMode}));

export {updateTitle, updateContent, updateColor, updateVisibility, updateDisplayMode};
