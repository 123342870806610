import * as PropTypes from "prop-types";
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDocCategory from "../components/PubDocCategory";
import { hasPubDocCategoryAdminPermission } from "../../common/selectors";
import {getSelectedFilters, getFilteredCategoryDocuments} from "../selectors";

const mapStateToProps = createStructuredSelector({
  documents: getFilteredCategoryDocuments,
  selectedFilters: getSelectedFilters,
  hasPubDocCategoryAdminPermission: hasPubDocCategoryAdminPermission
});

const PubDocCategoryContainer = compose(connect(mapStateToProps), toJS)(PubDocCategory);
PubDocCategoryContainer.propTypes = {
  category: PropTypes.object.isRequired
};
export default PubDocCategoryContainer;