import {createStructuredSelector} from "reselect";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import PubDocToolbar from "../components/PubDocToolbar";
import {getCustId, hasDocumentAdminPermission} from "../../common/selectors";
import {getSelectedDocIds, getSelectedDocs, getSelectedFilters} from "../selectors";
import {setBulkDeleteOpen, setDocumentMoveOpen, setDownloadErrorOpen, setPubDocEditOpen} from "../actions";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  selectedDocIds: getSelectedDocIds,
  selectedDocs: getSelectedDocs,
  selectedFilters: getSelectedFilters
});

const mapDispatchToProps = (dispatch) => ({
  setDocumentEditOpen: (open, documentId, documentCategoryId) => {
    dispatch(setPubDocEditOpen(open, documentId, documentCategoryId, null));
  },
  setDocumentMoveOpen: (open, documentId) => {
    dispatch(setDocumentMoveOpen(open, documentId));
  },
  openDownloadErrorDialog: () => {
    dispatch(setDownloadErrorOpen(true));
  },
  openBulkDeleteDialog: () => {
    dispatch(setBulkDeleteOpen(true));
  }
});

const PubDocToolbarContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocToolbar);
export default PubDocToolbarContainer;