import React from "react";

import DomContainer from "./DomContainer";
import {drawerContainer, main} from "./domNodes";
import If from "../../common/containers/If";

const CodePage = () => (
  <React.Fragment>
    <If key="code-page-if" test={drawerContainer.length > 0}>
      <DomContainer key="code-page-drawer" id="drawerContainer" children={drawerContainer}/>
    </If>
    <DomContainer key="code-page-main" id="main" children={main}/>
  </React.Fragment>
);

export default CodePage;

