import {ModuleKey} from '../ModuleKey';
import {createSelector} from 'reselect';

const getNewLawMap = (state) => state[ModuleKey].laws;
const getLawLedgerMap = (state) => state[ModuleKey].lawLedger;
const getEditingId = (state) => state[ModuleKey].editingId;
const getFilterText = (state) => state[ModuleKey].filterText;
const getFilterYear = (state) => state[ModuleKey].filterYear;

const getFilterChapters = (state) => state[ModuleKey].filterChapters;

const getSort = (state) => state[ModuleKey].sort;

const getTableId = (_, props) => { return props.tableId };

const getNewLaws = createSelector(
  getNewLawMap,
  lawMap => lawMap.toList()
);
const getLawLedger = createSelector(
  getLawLedgerMap,
  lawLedgerMap => lawLedgerMap.toList()
);
const getLaws = createSelector(
  getNewLaws,
  getLawLedger,
  getTableId,
  (newLaws, lawLedger, tableId) => (tableId === "lawLedgerTable") ? lawLedger : newLaws
);

const getEditingLaw = createSelector(
  [getEditingId, getNewLawMap, getLawLedgerMap],
  (editingId,laws,lawLedger) => editingId === null ? null
    : (laws.get(editingId) ? laws.get(editingId) : lawLedger.get(editingId))
);
const getFilterYearOptions = createSelector(getLaws, (laws) =>
  laws.map(l => ({value: (!l.adoptedYear ? -1 : l.adoptedYear), label: (!l.adoptedYear ? "Unspecified" : l.adoptedYear)}))
  .filter((v,i,a)=> a.findIndex(t=>(t.value===v.value))===i).sort((a, b) => a.value - b.value));
const getFilterChapterOptions = createSelector(getLaws, (laws) => {
  let chapterMap = {};
  for (const law of laws) {
    if (law.dispositions) {
      for (const disposition of law.dispositions) {
        const chapter = disposition.chapter;
        const chapterGuid = chapter.guid;
        const chapterTitle = chapter.name;
        const chapterSortId = chapter.sortId;
        let count= 0;
        if (chapterMap[chapterGuid]) {
          count = chapterMap[chapterGuid].count;
        }
        chapterMap[chapterGuid] = {value: chapterGuid, label: chapterTitle, sortId: chapterSortId, count: (count + 1)};
      }
    }
  }
  return Object.values(chapterMap).sort((a, b) => (a.sortId - b.sortId));
});

export {
  getLaws,
  getNewLaws,
  getLawLedger,
  getEditingLaw,
  getEditingId,
  getFilterText,
  getFilterYear,
  getFilterChapters,
  getSort,
  getFilterYearOptions,
  getFilterChapterOptions
};
