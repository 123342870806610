import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import {If} from "../../common/containers";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "../../common/components";
import {custId} from "../../common/utils/server-data";
import pubDocTypeId from "../utils/type-id";

class PubDocsFilters extends React.PureComponent {

  static propTypes = {
    documentsLoading: PropTypes.bool,
    filterOptions: PropTypes.object.isRequired,
    selectedFilters: PropTypes.object
  }

  handleCategoryFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: event.target.value,
      year: selectedFilters.year,
      month: selectedFilters.month,
      day: selectedFilters.day
    });
  }

  handleYearFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: selectedFilters.category,
      year: event.target.value,
      month: selectedFilters.month,
      day: selectedFilters.day
    });
  }

  handleMonthFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: selectedFilters.category,
      year: selectedFilters.year,
      month: event.target.value,
      day: selectedFilters.day
    });
  }

  handleDayFilterChange = (event) => {
    const {updateFilterState, selectedFilters} = this.props;
    updateFilterState(custId, pubDocTypeId, {
      category: selectedFilters.category,
      year: selectedFilters.year,
      month: selectedFilters.month,
      day: event.target.value
    });
  }

  render() {
    const { documentsLoading, filterOptions, selectedFilters } = this.props;
    const categoryFilter = selectedFilters.category == null ? "" : selectedFilters.category;
    const yearFilter = selectedFilters.year == null ? "" : selectedFilters.year;
    const monthFilter = selectedFilters.month == null ? "" : selectedFilters.month;
    const dayFilter = selectedFilters.day == null ? "" : selectedFilters.day;
    const monthList = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September",
      "October", "November", "December"];
    monthList[-1] = "(Undated)";
    const hasCategoryFilter = ((filterOptions?.categoryOptions ? filterOptions.categoryOptions.length : 0) > 1);
    const hasYearFilter = ((filterOptions?.yearOptions ? filterOptions.yearOptions.length : 0) > 1);
    const hasMonthFilter = ((filterOptions?.monthOptions ? filterOptions.monthOptions.length : 0) > 1);
    const hasDayFilter = ((filterOptions?.dayOptions ? filterOptions.dayOptions.length : 0) > 1);
    return (
      <Fragment>
        <If test={documentsLoading}>
          <div className="spinnerContainer"><CircularProgress size={80}/></div>
        </If>
        <If test={!documentsLoading}>
          <If test={hasCategoryFilter}>
            <FormControl className={"pubDocCategoryForm"} fullWidth>
              <InputLabel shrink id="categoryLabel">Category</InputLabel>
              <Select displayEmpty value={categoryFilter} onChange={this.handleCategoryFilterChange}>
                <MenuItem value={""}>(All)</MenuItem>
                { hasCategoryFilter ? filterOptions.categoryOptions.map((category) =>
                  <MenuItem key={category} value={category}>
                    <div className={"menuItemEllipsis"}>{category}</div>
                  </MenuItem>
                ) : null }
              </Select>
            </FormControl>
          </If>
          <If test={hasYearFilter}>
            <FormControl className={"pubDocYearForm"} fullWidth>
              <InputLabel shrink id="yearLabel">Year</InputLabel>
              <Select displayEmpty value={yearFilter} onChange={this.handleYearFilterChange}>
                <MenuItem value={""}>(All)</MenuItem>
                { hasYearFilter ? filterOptions.yearOptions.map((year) =>
                  <MenuItem key={year} value={year}>
                    {(year !== "-1") ? year : "(Undated)"}
                  </MenuItem>
                ) : null }
              </Select>
            </FormControl>
          </If>
          <If test={hasMonthFilter}>
            <FormControl className={"pubDocMonthForm"} fullWidth>
              <InputLabel shrink id="monthLabel">Month</InputLabel>
              <Select displayEmpty value={monthFilter} onChange={this.handleMonthFilterChange}>
                <MenuItem value={""}>(All)</MenuItem>
                { hasMonthFilter ? filterOptions.monthOptions.map((month) =>
                  <MenuItem key={month} value={month}>
                    {month ? monthList[month] : "(Undated)"}
                  </MenuItem>
                ) : null }
              </Select>
            </FormControl>
          </If>
          <If test={hasDayFilter}>
            <FormControl className={"pubDocDayForm"} fullWidth>
              <InputLabel shrink id="dayLabel">Day</InputLabel>
              <Select displayEmpty value={dayFilter} onChange={this.handleDayFilterChange}>
                <MenuItem value={""}>(All)</MenuItem>
                { hasDayFilter ? filterOptions.dayOptions.map((day) =>
                  <MenuItem key={day} value={day}>
                    {(day !== "-1") ? day : "(Undated)"}
                  </MenuItem>
                ) : null }
              </Select>
            </FormControl>
          </If>
        </If>
      </Fragment>
    );
  }
}
export default PubDocsFilters;
