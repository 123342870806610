import React, {Fragment} from "react";
import {PrimaryButton} from "../../common/components";
import {PageControl, PageControlContent, PageControlHeader, TocPageControl} from "../../common/components/pagecontrol";
import PubDocsFiltersContainer from "../containers/PubDocsFiltersContainer";
import PropTypes from "prop-types";
import {If} from "../../common/containers";

class PubDocsPageControls extends React.PureComponent {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    pubDocTypes: PropTypes.array.isRequired,
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categories: PropTypes.array,
    documents: PropTypes.array,
    filterOptions: PropTypes.object,
    hasPubDocTypeAddPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    openPubDocTypeAddDialog: PropTypes.func.isRequired,
    openCategoryAddDialog: PropTypes.func.isRequired,
    openDocumentAddDialog: PropTypes.func.isRequired,
    showAllCategories: PropTypes.bool
  }

  openAddTypeDialog = () => {
    this.props.openPubDocTypeAddDialog();
  };

  openAddCategoryDialog = () => {
    this.props.openCategoryAddDialog();
  };

  openAddDocumentDialog = () => {
    this.props.openDocumentAddDialog();
  };

  getToc = () => {
    const {custId, pubDocTypes, pubDocTypeId, categories, documents, hasDocumentAdminPermission,
      showAllCategories
    } = this.props;
    let typeNodes = [];
    for (const pubDocType of pubDocTypes) {
      let typeNode = {
        id: `toc-type-${pubDocType.id}`,
        label: pubDocType.title,
        href: `/${custId}/documents/${pubDocType.title?.replace(/ /g, "_")}`,
        pageHeadingId: "pageHeadingId",
      };
      const isTypeSelected = (pubDocType.id === pubDocTypeId);
      if (showAllCategories || isTypeSelected) {
        if (isTypeSelected) {
          typeNode.selected = true;
          typeNode.href = "#";
        }
        let categoryNodes = [];
        for (const category of categories) {
          if (category.typeId === pubDocType.id) {
            let categoryIdSuffix = `category-${category.id}`;
            let categoryNode = {
              id: `toc-${categoryIdSuffix}`,
              label: category.title,
              href: (isTypeSelected ? "" : typeNode.href) + "#" + categoryIdSuffix,
              pageHeadingId: categoryIdSuffix,
            };
            let categoryDocuments = documents ? documents.filter(d => d.categoryId === category.id) : [];
            if (categoryDocuments.length > 0 || hasDocumentAdminPermission) {
              if (categoryDocuments.length && (category.groupByType === "Year_Ascending" || category.groupByType === "Year_Descending")) {
                let yearNodes = [];
                let previousYear = null;
                for (const document of categoryDocuments) {
                  if (previousYear !== document.year) {
                    const yearIdSuffix = `category-${category.id}-year-${document.year?.replace(/ /g, "")}`;
                    const yearNode = {
                      id: `toc-${yearIdSuffix}`,
                      label: document.year,
                      href: (isTypeSelected ? "" : typeNode.href) + "#" + yearIdSuffix,
                      pageHeadingId: yearIdSuffix
                    };
                    yearNodes.push(yearNode);
                    previousYear = document.year;
                  }
                }
                categoryNode.childNodes = yearNodes;
              }
              categoryNodes.push(categoryNode);
            }
          }
        }
        typeNode.childNodes = categoryNodes;
      }
      typeNodes.push(typeNode);
    }
    return typeNodes;
  };

  shouldShowFilters = () => {
    const {pubDocTypeId, filterOptions} = this.props;
    return pubDocTypeId && (((filterOptions.categoryOptions ? filterOptions.categoryOptions.length : 0) > 1)
        || ((filterOptions.yearOptions ? filterOptions.yearOptions.length : 0) > 1)
        || ((filterOptions.monthOptions ? filterOptions.monthOptions.length : 0) > 1)
        || ((filterOptions.dayOptions ? filterOptions.dayOptions.length : 0) > 1));
  };

  render() {
    const {hasPubDocTypeAddPermission} = this.props;
    return (
      <Fragment>
        <If test={hasPubDocTypeAddPermission}>
          <PageControl id="actionButtonsPageControl">
            <PageControlContent>
              <div>
                <PrimaryButton
                  variant="contained"
                  onClick={this.openAddTypeDialog}
                  title="Add a new document type"
                >
                  Add Type
                </PrimaryButton>
                <PrimaryButton
                  variant="contained"
                  onClick={this.openAddCategoryDialog}
                  title="Add a new document category"
                >
                  Add Category
                </PrimaryButton>
                <PrimaryButton
                  variant="contained"
                  onClick={this.openAddDocumentDialog}
                  title="Add a new document"
                >
                  Add Document
                </PrimaryButton>
              </div>
            </PageControlContent>
          </PageControl>
        </If>
        <If test={this.shouldShowFilters()}>
          <PageControl>
            <PageControlHeader label="Filters" htmlFor="filterTextInput"/>
            <PageControlContent>
              <PubDocsFiltersContainer/>
            </PageControlContent>
          </PageControl>
        </If>
        <TocPageControl type="pub-docs" tocNodes={this.getToc()}/>
      </Fragment>
    );
  }
}
export default PubDocsPageControls;
