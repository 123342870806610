import React from "react";
import {BoxedSection, Checkbox, Dialog, FormControlLabel} from "../../common/components";
import "./EnabledFeaturesSection.css";

class EnabledFeaturesSection extends React.PureComponent {

  constructor(props) {
    super(props);

    const featureGroupNotes = JSON.parse(props["featureGroupNotesJson"]);
    const featurePublicNotes = JSON.parse(props["featurePublicNotesJson"]);
    const featureIndexView = JSON.parse(props["featureIndexViewJson"]);
    console.log(featurePublicNotes)
    this.state = {
      privateGroupNotes: (featureGroupNotes["currentLevel"] === "USER"),
      privateGroupNotesAllowed: (featureGroupNotes["maximumLevel"] === "USER"),
      publicGroupNotes: (featurePublicNotes["currentLevel"] === "ADMIN"),
      publicGroupNotesAllowed: (featurePublicNotes["maximumLevel"] === "ADMIN"),
      pubdocsDateDisabled: (props.customization?.pubdocsDateDisabled),
      indexView: (!!featureIndexView["currentLevel"] && featureIndexView["currentLevel"] !== "NOBODY"),
      indexViewEnabled: (!!featureIndexView["maximumLevel"] && featureIndexView["maximumLevel"] !== "NOBODY"),
      translateEnabled: (props.customization?.translate),
      translateAcknowledgementShown: false,
      translateAcknowledged: false,
      translateAcknowledgementRequiredShown: false,
    };
  }

  handleGroupNotesCheckboxChange = (event) => {
    const enabled = event.target.checked;
    this.props.setEnableGroupNotes(enabled);
    this.setState({privateGroupNotes: enabled});
  };

  handlePublicNotesCheckboxChange = (event) => {
    const enabled = event.target.checked;
    this.props.setEnablePublicNotes(enabled);
    this.setState({publicGroupNotes: enabled});
  };

  handlePubDocsDateDisabledClick = (event) => {
    const pubdocsDateDisabled = event.target.checked;
    this.props.setPubDocsDateDisabled(pubdocsDateDisabled);
    this.setState({pubdocsDateDisabled});
  }

  handleIndexViewCheckboxChange = (event) => {
    const enabled = event.target.checked;
    this.props.setEnableIndexView(enabled);
    this.setState({indexView: enabled});
  };

  handleTranslateCheckboxChange = (event) => {
    const enabled = event.target.checked;
    if (enabled) {
      this.setState({translateAcknowledgementShown: true, translateAcknowledged: false, translateAcknowledgementRequiredShown: false});
    } else {
      this.props.setEnableTranslate(false);
      this.setState({translateEnabled: false});
    }
  };

  handleTranslateAcknowledgementChange = (event) => {
    const acknowledged = event.target.checked;
    this.setState({translateAcknowledged: acknowledged});
  };

  handleTranslateCancel = () => {
    this.setState({translateAcknowledgementShown: false});
  };

  handleTranslateEnable = () => {
    const acknowledged = this.state.translateAcknowledged;
    if (acknowledged) {
      this.props.setEnableTranslate(true);
      this.setState({translateAcknowledgementShown: false, translateEnabled: true});
    } else {
      this.setState({translateAcknowledgementRequiredShown: true});
    }
  };

  render() {
    const { isTranslateAdmin, termsOfUseUrl } = this.props;
    const {
      privateGroupNotes, privateGroupNotesAllowed, publicGroupNotes, publicGroupNotesAllowed, indexView, indexViewEnabled, translateEnabled,
      translateAcknowledgementShown, translateAcknowledged, translateAcknowledgementRequiredShown, pubdocsDateDisabled
    } = this.state;
    return (
      <BoxedSection
        title="Enabled Features"
        instructions="Enable or disable features within your Code:"
        help={{topic: 'administration', subtopic: 'managefeatures'}}
        id={"enabledFeaturesSection"}
      >
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-groupNotesEnabled"
                checked={privateGroupNotes}
                onChange={this.handleGroupNotesCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Group Notes"
            disabled={!privateGroupNotesAllowed}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-publicNotesEnabled"
                checked={publicGroupNotes}
                onChange={this.handlePublicNotesCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Public Notes"
            disabled={!publicGroupNotesAllowed}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-indexViewEnabled"
                checked={indexView}
                onChange={this.handleIndexViewCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Index Page"
            disabled={!indexViewEnabled}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                id="adminSettingsPage-translateEnabled"
                checked={translateEnabled}
                onChange={this.handleTranslateCheckboxChange}
                style={{padding: '4px 12px'}}
              />
            }
            label="Translate"
            disabled={!isTranslateAdmin}
          />
          <Dialog
            id="translateAcknowledgementDialog"
            title="Translate"
            open={translateAcknowledgementShown}
            onCancel={this.handleTranslateCancel}
            onSubmit={this.handleTranslateEnable}
            submitLabel="Enable"
            width="md"
          >
            <div>
              As described in the <a href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">Terms of Use</a>, General Code is providing “Google Translate”, a free
              service provided by Google, as a convenience to assist eCode360 users in reading E-Codes in languages other than English.
            </div>
            <div id="translateAcknowledgement">
              <FormControlLabel
                control={
                  <Checkbox
                    id="adminSettingsPage-translateAcknowledgement"
                    checked={translateAcknowledged}
                    onChange={this.handleTranslateAcknowledgementChange}
                    style={{padding: '4px 12px'}}
                  />
                }
                label={<span>I have read and understood the <a href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">Terms of Use</a> related to the use of Google Translate.</span>}
              />
            </div>
            {translateAcknowledgementRequiredShown &&
              <div id="translateAcknowledgementRequired">
                <span>You must check the box acknowledging that you have read the <a href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">Terms of Use</a> before enabling this feature.</span>
              </div>
            }
          </Dialog>
        </div>
        <div>
          <FormControlLabel
            className="gcAdminSettingsList-CheckboxLabel"
            control={
              <Checkbox
                id="adminSettingsPage-pubdocsCreatedByDate"
                checked={pubdocsDateDisabled}
                onChange={this.handlePubDocsDateDisabledClick}
              />
            }
            label="Hide Created/Updated by Dates for PubDocs"
          />
        </div>
      </BoxedSection>
    );
  }
}

export default EnabledFeaturesSection;
