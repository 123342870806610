/*
 * Copyright 2019 General Code
 */

import {createAction} from "redux-actions";
import {API_END, API_ERROR, API_START} from "../actions/types";

export const apiStart = createAction(API_START, label => ({label}));
export const apiEnd = createAction(API_END, label => ({label}));
export const apiError = createAction(API_ERROR, (label, errorMessage, error) => ({label, errorMessage, error}));

