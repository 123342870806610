/*
 * Copyright 2018 General Code
 */

import {connect} from 'react-redux';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import renderNothing from 'recompose/renderNothing';
import {bindActionCreators} from 'redux';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {editNoteCancel} from '../actions';
import {isEditing, getTitle, getContent} from '../selectors';
import EditControls from '../components/EditControls';

const mapStateToProps = createStructuredSelector({
  editing: isEditing,
  title: getTitle,
  content: getContent
});

const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  editNoteCancel: () => editNoteCancel(id)
}, dispatch);

const ifEditing = branch(props => !props.editing, renderNothing);

const EditControlsContainer = compose(connect(mapStateToProps, mapDispatchToProps), ifEditing)(EditControls);
export default EditControlsContainer;
