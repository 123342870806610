import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import ContextHelp from "../components/ContextHelp";

import {getHelpTopics, getHelpVideos} from '../selectors';
import {toJS} from "../utils";

const mapStateToProps = createStructuredSelector({
  helpTopics: getHelpTopics,
  helpVideos: getHelpVideos
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(ContextHelp);
