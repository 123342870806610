import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {IconButton, Snackbar} from "../../common/components";
import CloseIcon from "@material-ui/icons/Close";
import {Message} from "./Message";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

class MessageList extends React.Component {

  constructor(props) {
    super(props);

    this.messageInfo = React.createRef(undefined);
  }

  processQueue = () => {
    const {messages} = this.props;
    if (messages.size > 0) {
      this.messageInfo.current = messages.first()?.toJS();
    }
  };

  handleClose = (event, reason) => {
    const {onRemoveClick} = this.props;
    if (reason === 'clickaway') {
      return;
    }
    onRemoveClick(this.messageInfo.current.id);
  };

  handleExited = () => {
    this.processQueue();
  };

  render() {
    const {showMessage} = this.props;
    this.processQueue();
    return (
      <Snackbar
          className={"public-message"}
          key={this.messageInfo.current ? this.messageInfo.current.id : -1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={this.handleClose}
          onExited={this.handleExited}
          ContentProps={{
            'aria-describedby': 'message-id',

          }}

          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
        <Message
          onClose={this.handleClose}
          variant={this.messageInfo.current ? this.messageInfo.current.type : "info"}
          message={this.messageInfo.current ? this.messageInfo.current.body : ""}
        />
      </Snackbar>
    );
  }
}

MessageList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageList);
