import React, {Component} from "react";
import ButtonGroup from "../././button/ButtonGroup";
import PrimaryButton from "../././button/PrimaryButton";
import Editor from "./Editor";

class EditorFrame extends Component {

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      savedHtml: this.props.html,
      hasUnsavedChanges: false
    };
  }

  onUnload = (e) => {
    if (this.state.hasUnsavedChanges) {
      const message = "You have an unsaved changes. Are you sure you want to leave?";
      e.returnValue = message;
      e.preventDefault();
      return message;
    }
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  };

  onChange = (newHtml) => {
    this.setState({hasUnsavedChanges: (newHtml !== this.state.savedHtml)});
  };

  onSave = () => {
    let newHtml = this.editorRef.current.getHtml();
    if (this.editorRef?.current) {
      if (this.props.onSave) {
        this.props.onSave(newHtml);
      }
    }
    this.setState({hasUnsavedChanges: false, savedHtml: newHtml});
  };

  render() {
    const {open = true, html, saveLabel = "Save", onSave, ...otherProps} = this.props;
    return (
      <div>
        <Editor open={open} html={html} ref={this.editorRef} onChange={this.onChange} { ...otherProps }/>
        <ButtonGroup style={{marginTop: 8}}>
          <PrimaryButton onClick={this.onSave} disabled={!this.state.hasUnsavedChanges}>
            { saveLabel }
          </PrimaryButton>
        </ButtonGroup>
      </div>
    );
  }
}

export default EditorFrame;