/*
 * Copyright 2018 General Code
 */

import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {updateTitle} from "../actions";
import Title from '../components/Title';
import {getTitle, isEditing} from "../selectors";


const mapStateToProps = createStructuredSelector({
  title: getTitle,
  editing: isEditing,
});

const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  updateTitle: content => updateTitle(id, content),
}, dispatch);

const TitleContainer = connect(mapStateToProps, mapDispatchToProps)(Title);

TitleContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default TitleContainer;

