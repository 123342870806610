import {custId} from "./server-data";

class RequestError extends Error {
  constructor(url, method, status, statusText = "", title = null, message = null) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super();

    this.url = url;
    this.method = method;
    this.status = status;
    this.statusText = statusText;

    if (status === 401) {
      this.title = title ? title : "Insufficient Permissions";
      this.message = message ? message : "Please log in with appropriate permissions to use this function.";
      let previousUrl = encodeURIComponent(window.location.pathname + window.location.search);
      this.redirectUrl = custId ? `/user/login/${custId}?url=${previousUrl}` : `/user/login?url=${previousUrl}`;
    } else if (status === 403) {
      this.title = title ? title : "Insufficient Permissions";
      this.message = message ? message : "Please log in with appropriate permissions to use this function.";
      this.redirectUrl = window.location.pathname + window.location.search;
    } else {
      this.title = title;
      this.message = message;
      this.redirectUrl = null;
    }

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }
  }
}
export default RequestError;