/*
 * Copyright 2018 General Code
 */

import {connect} from 'react-redux';
import compose from "recompose/compose";
import setDisplayName from "recompose/setDisplayName";
import withProps from "recompose/withProps";
import {bindActionCreators} from "redux";
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";
import {Picker} from "../../common/components";
import {hasGroupNotesPermission, hasPublicNotesPermission} from "../../common/selectors";
import {updateVisibility} from "../actions";
import {getGroups, getVisibility} from "../selectors";

const getDisplay = (state) => {
  const groups = getGroups(state);
  const display = {
    order: [],
    options: {
      private: {
        primary: "Private",
        secondary: "Only you can see or edit",
        short: "Private",
      },
    },
  };

  if (hasPublicNotesPermission(state)) {
    display.order.push('public');
    display.options.public = {
      primary: "Public",
      secondary: "Anyone can see; Admins can edit",
      short: "Public",
    };
  }

  if (groups && hasGroupNotesPermission(state)) {
    for (let group of groups) {
      display.options[group.key] = {
        primary: "Group",
        secondary: `Only ${group.name} users can see or edit`,
        short: `Group - ${group.name}`,
      };
      display.order.push(group.key);
    }
  }

  display.order.push("private");
  return display;
};

const mapStateToProps = createStructuredSelector({
  value: getVisibility,
  display: getDisplay,
});

const mapDispatchToProps = (dispatch, {id}) => bindActionCreators({
  updateValue: visibility => updateVisibility(id, visibility),
}, dispatch);

const staticProps = {
  type: "visibility",
  label: "Visibility",
  tooltip: "Select who can see and edit this note",
};

const VisibilityPickerContainer = compose(setDisplayName("VisibilityPickerContainer"), withProps(staticProps), connect(mapStateToProps, mapDispatchToProps))(
  Picker);
export default VisibilityPickerContainer;
