/*
 * Copyright 2018 General Code
 */

import React from "react";
import * as PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";
import "./Button.css";

const Button = ({color = 'secondary', size = 'small', variant = 'outlined', primary = null, ...props}) => <span className={`reactButtonWrapper ${props.className || 'defaultButton'}Wrapper ${primary ? 'reactButtonWrapper-primary' : ''}`}><MuiButton {...props} primary={primary} variant={primary ? 'contained' : variant } color={primary ? 'primary' : color} size={size}/></span>;

Button.propTypes = {
  /** Color of the button.  Defaults to 'secondary' which is a grey color */
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
  primary: PropTypes.string,
  /** Size of the button.  Defaults to 'small' which has some padding and is suitable for use in a dialog */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Button;