import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {loadPubDocs, loadPubDocsCategories} from "../actions";
import {
  getFilteredCategories,
  getPubDocs,
  getPubDocsLoading,
  getPubDocTypeDescription,
  getPubDocTypeId
} from "../selectors";
import {getCustId, hasDocumentAdminPermission, isNewNav} from "../../common/selectors";
import PubDocType from "../components/PubDocType";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypeId: getPubDocTypeId,
  pubDocTypeDescription: getPubDocTypeDescription,
  documents: getPubDocs,
  documentsLoading: getPubDocsLoading,
  categories: getFilteredCategories,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  isNewNav: isNewNav
});

const mapDispatchToProps = (dispatch) => ({
  loadPubDocs: (custId, pubDocTypeId) => {
    dispatch(loadPubDocs(custId, pubDocTypeId));
  },
  loadPubDocsCategories: (custId, pubDocTypeId) => {
    dispatch(loadPubDocsCategories(custId, pubDocTypeId));
  }
});

const PubDocTypeContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocType);
export default PubDocTypeContainer;