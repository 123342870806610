import React from "react";
import parse from "html-react-parser";

class HtmlElement extends React.Component {
  render() {
    const {element, html} = this.props;
    if (!element && !html) return null;
    return parse((element && element.outerHTML) ? element.outerHTML : html);
  }
}
export default HtmlElement;