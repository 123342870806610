/*
 * Copyright 2019 General Code 
 */

import * as PropTypes from 'prop-types';
import React from 'react';
import './RelatedTerms.css';

class RelatedTerms extends React.PureComponent {
  static propTypes = {
    relatedTerms: PropTypes.array.isRequired
  };


  render() {
    const {relatedTerms} = this.props;

    if (relatedTerms.length === 0) return null;
    return (
      <div id="relatedTermsContainer" className="relatedTermsContainer">
        <div id="relatedTermsLabel" className="customerTitleColor">
        Related searches:
        </div>
        {relatedTerms.map((relatedTerm) =>
          <span key={relatedTerm.term} className="relatedTermContainer">
            <a href={relatedTerm.url} target="_self" className="relatedTermLink resultInfoLine">{relatedTerm.term}({relatedTerm.count})</a>
          </span>
        )}
      </div>
    );
  }
}

export default RelatedTerms;
