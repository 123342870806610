import {createStructuredSelector} from "reselect";
import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import PubDocsBulkDeleteDialog from "../components/PubDocsBulkDeleteDialog";
import {getCustId} from "../../common/selectors";
import {getSelectedDocIds, getSelectedDocs, getBulkDeleteOpen} from "../selectors";
import {deleteMultipleDocuments, setBulkDeleteOpen} from "../actions";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  selectedDocIds: getSelectedDocIds,
  selectedDocs: getSelectedDocs,
  bulkDeleteOpen: getBulkDeleteOpen
});

const mapDispatchToProps = (dispatch) => ({
  closeBulkDeleteDialog: () => {
    dispatch(setBulkDeleteOpen(false));
  },
  bulkDeleteDocuments: (custId, documentIdList) => {
    dispatch(deleteMultipleDocuments(custId, documentIdList));
  }
});

const PubDocsBulkDeleteDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsBulkDeleteDialog);
export default PubDocsBulkDeleteDialogContainer;