import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {Dialog} from "../../common/components";

class PubDocsDownloadErrorDialog extends Component {

  static propTypes = {
    selectedDocs: PropTypes.array.isRequired,
    downloadErrorOpen: PropTypes.bool.isRequired,
    closeDownloadErrorDialog: PropTypes.func.isRequired
  }

  handleCloseDownloadError = () => {
    this.props.closeDownloadErrorDialog();
  }

  render() {
    const { selectedDocs, downloadErrorOpen } = this.props;
    const linkDocuments = selectedDocs.filter(currDoc => (currDoc.type === "AUDIO_LINK" || currDoc.type === "VIDEO_LINK"));
    return (
      <Dialog
        title="Error downloading document(s)"
        open={downloadErrorOpen}
        onClose={this.handleCloseDownloadError}
        submitLabel="Close"
      >
        <div className={"pubDocModalText"}>Downloads are not supported for linked Public Documents. Please unselect the following documents and try again:</div>
        <ul className={"pubDocModalList"}>
          {linkDocuments.map((currDoc) => (<li className={"pubDocModalListItem"} key={currDoc.id}>{currDoc.title}</li>))}
        </ul>
      </Dialog>
    );
  }
}
export default PubDocsDownloadErrorDialog;