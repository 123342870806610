import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDocsSidebar from "../components/PubDocsSidebar";
import {
  getCustId, hasPubDocPrivateViewPermission,
  hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission
} from "../../common/selectors";
import {deletePubDocType, setPubDocTypeEditOpen} from "../actions";
import {getTypeEditId, getPubDocTypeId, getPubDocTypes, getPubDocsSidebarName} from "../selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypes: getPubDocTypes,
  pubDocTypeId: getPubDocTypeId,
  hasPubDocTypeAddPermission: hasPubDocTypeAddPermission,
  typeEditId: getTypeEditId,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission: hasPubDocTypeEditPermission,
  pubDocsSidebarName: getPubDocsSidebarName,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission
});

const mapDispatchToProps = (dispatch) => ({
  deletePubDocType: (custId, pubDocTypeId) => {
    dispatch(deletePubDocType(custId, pubDocTypeId));
  },
  setEditOpen: (open, typeEditId) => {
    dispatch(setPubDocTypeEditOpen(open, typeEditId));
  }
});

const PubDocsSidebarContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsSidebar);
PubDocsSidebarContainer.propTypes = {};
export default PubDocsSidebarContainer;