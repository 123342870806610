import {connect} from "react-redux";
import {compose} from "recompose";
import {toJS} from "../../common/utils";
import {getFacets, getFacetSelections} from "../selectors";
import {removeFacetSelectionsAndGetResults} from "../actions";
import {FiltersWidget} from '../../common/components';

const mapStateToProps = (state) => {
  const facets = getFacets(state);
  const facetSelections = getFacetSelections(state).filter(facetString => facetString !== "")
  .map(facetString => ({type: facetString.substring(0, 2), option: facetString.substring(3), string: facetString}))
  .map(facetSelection => facets.filter(f => f.value === facetSelection.type)
  .map(f => ({
    type: f.value,
    name: f.name,
    string: facetSelection.string,
    option: f.options.filter(option => option.value === facetSelection.option).first()
  })).first())
  .map(facet => ({
    type: 'facet',
    name: `${facet.name}: ${facet.option.name}`,
    string: facet.string,
    key: `filter-${facet.type.replaceAll("\\s+", '')}-${facet.option.value.replaceAll("\\s+", '')}`
  }));


  return {filters: [...facetSelections]}
};

const mapDispatchToProps = (dispatch) => ({
  remove: (filter) => () => {
    dispatch(removeFacetSelectionsAndGetResults([filter]));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(FiltersWidget);