import * as PropTypes from "prop-types";
import React from "react";

import If from "../../common/containers/If";
import HelpButton from "./HelpButton";
import VideoButton from "./VideoButton";

import "./PageTitle.css";

class ContextHelp extends React.PureComponent {

  static propTypes = {
    contextId: PropTypes.string.isRequired,
    helpTopics: PropTypes.object,
    helpVideos: PropTypes.object
  };

  render() {
    const {contextId, helpTopics = {}, helpVideos = {}} = this.props;
    return (
      <If test={helpTopics[contextId] || helpVideos[contextId]}>
        <div className="moreActions">
          <If test={helpVideos[contextId]}>
            <VideoButton url={helpVideos[contextId]}/>
          </If>
          <If test={helpTopics[contextId]}>
            <HelpButton topic={helpTopics[contextId]}/>
          </If>
        </div>
      </If>
    );
  }
}

export default ContextHelp;
