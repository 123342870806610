import React from "react";
import PageTitleContainer from "../../common/containers/PageTitleContainer";
import SearchResultsPageActionsContainer from "../containers/SearchResultsPageActionsContainer";

class SearchResultsPageTitle extends React.Component {
  render() {
    const {isNewNav} = this.props;
    return isNewNav ?
      (<PageTitleContainer pageTitle="Search Results" />) :
      (<PageTitleContainer pageTitle="Search Results">
        <SearchResultsPageActionsContainer />
      </PageTitleContainer>);
  }
}
export default SearchResultsPageTitle;
