import * as PropTypes from "prop-types";
import React from "react";
import PageActionsContainer from "../containers/PageActionsContainer";
import "./PageTitle.css";

class PageTitle extends React.PureComponent {

  static propTypes = {
    pageTitle: PropTypes.string.isRequired
  };

  render() {
    const {pageId, pageTitle, children} = this.props;
    return (
      <div id="pageTitleContainer" className="clearfix">
        <div id="pageTitle" className="customerTitleColor"><span>{pageTitle}</span></div>
        <div id="additionalPageTitleContent">
          <div className="moreActions">
            {children ? children : <PageActionsContainer pageId={pageId} />}
          </div>
        </div>
      </div>
    );
  }
}

export default PageTitle;