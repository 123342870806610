import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import {updateOptionResponse, deleteOptionResponse} from "../actions";
import RequiredOption from "../components/RequiredOption";
import {isFinalized} from "../selectors";

const mapStateToProps = createStructuredSelector({
  isFinalized: isFinalized
});

const mapDispatchToProps = (dispatch) => ({
  createResponse: (questionId, optionId, type) => {
    dispatch(updateOptionResponse(questionId, optionId, type, null));
  },
  deleteResponse: (questionId, optionId) => {
    dispatch(deleteOptionResponse(questionId, optionId));
  },
});

const RequiredOptionContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(RequiredOption);
RequiredOptionContainer.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  option: PropTypes.object.isRequired
};
export default RequiredOptionContainer;