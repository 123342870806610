import {connect} from 'react-redux';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import QuestionsPageActions from "../components/QuestionsPageActions";
import {displayAllQuestions} from "../actions";
import {setPrintLoading} from "../../common/actions";
import {
  getDisplayedQuestionCount,
  getDisplayedQuestionIds
} from "../selectors";

const mapStateToProps = createStructuredSelector({
  displayedQuestionCount: getDisplayedQuestionCount,
  displayedQuestionIds: getDisplayedQuestionIds
});

const mapDispatchToProps = (dispatch) => ({
  setPrintLoading: (isLoading) => {
    dispatch(setPrintLoading(isLoading));
  },
  displayAllQuestions: () => {
    dispatch(displayAllQuestions());
  }
});

const QuestionsPageActionsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionsPageActions);
export default QuestionsPageActionsContainer;