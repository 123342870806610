import Entity from 'draft-js/lib/DraftEntity';

const getEntityId = (node) => {
  let entityId = undefined;
  if (
    node instanceof HTMLAnchorElement
  ) {
    const entityConfig = {};
    if (node.dataset && node.dataset.reference !== undefined) {
      entityConfig.url = node.pathname || node.href;
      entityConfig.text = node.innerHTML;
      entityConfig.value = node.dataset.value;
      entityId = Entity.__create(
        'REFERENCE',
        'IMMUTABLE',
        entityConfig,
      );
    } else {
      entityConfig.url = node.getAttribute ? node.getAttribute('href') || node.href : node.href;
      entityConfig.title = node.innerHTML;
      entityConfig.targetOption = node.target;
      entityId = Entity.__create(
        'LINK',
        'MUTABLE',
        entityConfig,
      );
    }
  } else if (
    node instanceof HTMLImageElement
  ) {
    entityId = Entity.__create(
      'IMAGE',
      'IMMUTABLE',
      {
        src: node.src,
        alt: node.alt,
        height: node.style.height,
        width: node.style.width,
      },
    );
  }
  return entityId;
};

export default getEntityId;
