/*
 * Copyright 2018 General Code
 */

import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {forceVisible} from "react-lazyload";
import {IconButton, Menu, MenuItem} from "../../common/components";
import If from "../../common/containers/If";
import PageActionsContainer from "../../common/containers/PageActionsContainer";
import {MenuOptionsVert as MenuOptionsVertIcon} from "../../common/components/icons";
import {PUBLIC_DOCUMENTS, PUBLIC_DOCUMENTS_LANDING} from "../../common/utils/page-sub-types";

/**
 * Call forceVisible multiple times to force all lazy loaded components to be visible
 *
 * Wrap each in a timeout so React has a chance to render previously lazy children and once we have done it 4 times actually print
 * @param recurse number of times this function has been called recursively already. defaults to zero.
 */
const printPage = (recurse = 0) => {
  if (recurse < 4) {
    forceVisible();
    setTimeout(printPage, 0, recurse + 1);
  } else {
    setTimeout(() => {
      window.print();
    });
  }
};

class PubDocsPageActions extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypeTitle: PropTypes.string,
    documentsLoading: PropTypes.bool.isRequired,
    selectedDocIds: PropTypes.array,
    selectedDocs: PropTypes.array,
    hasPubDocTypeDeletePermission: PropTypes.bool.isRequired,
    hasPubDocTypeEditPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    pageSubType: PropTypes.string,
    deletePubDocType: PropTypes.func.isRequired,
    openDeleteDialog: PropTypes.func.isRequired,
    setEditOpen: PropTypes.func.isRequired,
    setCategoryEditOpen: PropTypes.func.isRequired,
    openDocumentAddDialog: PropTypes.func.isRequired,
    openDocumentMoveDialog: PropTypes.func.isRequired,
    openDownloadErrorDialog: PropTypes.func.isRequired,
    openBulkDeleteDialog: PropTypes.func.isRequired
  };

  state = {typeAnchorEl: null};

  openTypeMenu = (event) => {
    this.setState({typeAnchorEl: event.currentTarget});
  };

  closeTypeMenu = () => {
    this.setState({typeAnchorEl: null});
  };

  handleOpenDelete = () => {
    this.setState({typeAnchorEl: null});
    this.props.openDeleteDialog(this.props.pubDocTypeId);
  };

  openEditTypeDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.setEditOpen(true, this.props.pubDocTypeId);
  };

  openAddTypeDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.setEditOpen(true, null);
  };

  openEditCategoryDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.setCategoryEditOpen(true, null, this.props.pubDocTypeId);
  };

  openAddDocumentDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.openDocumentAddDialog();
  };

  openMoveDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.openDocumentMoveDialog();
  };

  openBulkDeleteDialog = () => {
    this.setState({typeAnchorEl: null});
    this.props.openBulkDeleteDialog();
  };

  handleDownloadPubDocs = () => {
    const {custId, selectedDocs, selectedDocIds, openDownloadErrorDialog} = this.props;
    const linkDocuments = selectedDocs.filter(currDoc => (currDoc.type === "AUDIO_LINK" || currDoc.type === "VIDEO_LINK"));
    if (linkDocuments.length > 0) {
      openDownloadErrorDialog();
    } else {
      window.location.href = `/api/${custId}/pub-doc/${selectedDocIds}/download`;
    }
  };

  render() {
    const {documentsLoading, selectedDocs, hasPubDocTypeDeletePermission,
      hasPubDocTypeEditPermission, hasDocumentAdminPermission, pageSubType
    } = this.props;
    const {typeAnchorEl} = this.state;
    return (
      <PageActionsContainer pageId="pubDocs" printable={true} printOverride={printPage}>
        <If test={!documentsLoading && (hasPubDocTypeEditPermission || hasPubDocTypeDeletePermission || (selectedDocs && selectedDocs.length > 0 && hasDocumentAdminPermission))}>
          <IconButton
            id="pub-doc-button"
            aria-label="Open the pub doc type menu"
            className="pubDocTypeButton"
            onClick={this.openTypeMenu}
            aria-haspopup="true"
          >
            <MenuOptionsVertIcon id="pubDocMenuOpen" title="Open the pub doc type menu"/>
          </IconButton>
          <Menu
            disableAutoFocusItem
            anchorEl={typeAnchorEl}
            className="pubDocTypeMenu"
            open={typeAnchorEl !== null}
            onClose={this.closeTypeMenu}
            MenuListProps={{'aria-labelledby': 'pubDocMenuOpen'}}
          >
            <If test={pageSubType === PUBLIC_DOCUMENTS_LANDING}>
              <If test={hasPubDocTypeEditPermission}>
                <MenuItem onClick={this.openEditTypeDialog}>
                  Add Type
                </MenuItem>
                <MenuItem onClick={this.openEditCategoryDialog}>
                  Add Category
                </MenuItem>
                <MenuItem onClick={this.openAddDocumentDialog}>
                  Add Document
                </MenuItem>
              </If>
            </If>
            <If test={pageSubType === PUBLIC_DOCUMENTS}>
              <If test={hasPubDocTypeEditPermission}>
                <MenuItem onClick={this.openAddTypeDialog}>
                  Add Type
                </MenuItem>
                <MenuItem onClick={this.openEditTypeDialog}>
                  Edit Type
                </MenuItem>
              </If>
              <If test={hasPubDocTypeDeletePermission}>
                <MenuItem onClick={this.handleOpenDelete}>
                  Delete Type
                </MenuItem>
              </If>
              <MenuItem onClick={this.openEditCategoryDialog}>
                Add Category
              </MenuItem>
              <MenuItem onClick={this.openAddDocumentDialog}>
                Add Document
              </MenuItem>
              <If test={selectedDocs && selectedDocs.length > 0 && hasDocumentAdminPermission}>
                <MenuItem onClick={this.openMoveDialog}>Move Selected Documents</MenuItem>
                <MenuItem onClick={this.openBulkDeleteDialog}>Delete Selected Documents</MenuItem>
                <MenuItem onClick={this.handleDownloadPubDocs}>Download Selected Documents</MenuItem>
              </If>
            </If>
          </Menu>
        </If>
      </PageActionsContainer>
    );
  }
}
export default PubDocsPageActions;