import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from 'reselect';

import {registerError} from '../actions';
import {toJS} from "../utils";
import ErrorBoundary from "../components/ErrorBoundary";

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = (dispatch) => ({
  registerError: (error, errorData, detailedError) => dispatch(registerError(error, null, errorData, detailedError)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(ErrorBoundary);
