import {connect} from "react-redux";
import {compose} from "recompose";
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";

import {toJS} from "../utils";
import {isSearchResultsPage} from "../../search/selectors";
import App from "../components/App";
import {getBodyTop} from "../selectors";

const mapStateToProps = createStructuredSelector({
  bodyTop: getBodyTop,
  isSearchResultsPage: isSearchResultsPage
});


const mapDispatchToProps = () => ({});

const AppContainer = compose( connect(mapStateToProps, mapDispatchToProps), toJS)(App);
export default AppContainer;
