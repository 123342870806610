import React from "react";

import {PageControl} from "../../common/components";
import CheckboxListFacet from "./CheckboxListFacet";

import "./FacetList.css";

const hasFacets = (facets, facetSelections) => {
  return facets.length > 0 && facets.reduce((result, facet) => result || isFacetDisplayable(facet, facetSelections), false);
};

const isFacetDisplayable = (facet, facetSelections) => {
  return facet.options.length > 1 || facetSelections.reduce((result, selection) => result || selection.indexOf(`${facet.value}:`) === 0, false);
};

const FacetList = ({facets, facetSelections, addFacetSelection, addFacetSelections, removeFacetSelection, removeFacetSelections, isNewNav}) => {
  return !hasFacets(facets, facetSelections) ? null : (
    <PageControl nested>
      {facets.map((facet) => !isFacetDisplayable(facet, facetSelections) ? null :
        <CheckboxListFacet
          key={facet.name}
          facet={facet}
          facetSelections={facetSelections}
          addFacetSelection={addFacetSelection}
          addFacetSelections={addFacetSelections}
          removeFacetSelection={removeFacetSelection}
          removeFacetSelections={removeFacetSelections}
          isNewNav={isNewNav}
        />
      )}
    </PageControl>
  );
};

export default FacetList;