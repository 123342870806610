import React from "react";
import * as PropTypes from "prop-types";
import {Select, MenuItem} from "../../common/components";
import {PageControlContent, PageControlHeader, PageControl} from "../../common/components/pagecontrol";

class IndexPageControls extends React.PureComponent {

  static propTypes = {
    letter: PropTypes.string,
    custId: PropTypes.string.isRequired,
    codeId: PropTypes.string
  };

  onChange = (e) => {
    e.preventDefault();
    const {codeId, custId} = this.props;
    window.location.href = `/${custId}${(codeId && codeId !== custId) ? ("/code/" + codeId) : ""}/index?letter=${e.target.value}`;
  };

  render() {
    const {letter = ""} = this.props;
    return (
      <PageControl>
        <PageControlHeader label="Filter By Letter" htmlFor="indexLetterInput"/>
        <PageControlContent>
          <div className="page-control-input">
            <Select value={letter} onChange={this.onChange} fullWidth>
              <MenuItem value="Number">#</MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="D">D</MenuItem>
              <MenuItem value="E">E</MenuItem>
              <MenuItem value="F">F</MenuItem>
              <MenuItem value="G">G</MenuItem>
              <MenuItem value="H">H</MenuItem>
              <MenuItem value="I">I</MenuItem>
              <MenuItem value="J">J</MenuItem>
              <MenuItem value="K">K</MenuItem>
              <MenuItem value="L">L</MenuItem>
              <MenuItem value="M">M</MenuItem>
              <MenuItem value="N">N</MenuItem>
              <MenuItem value="O">O</MenuItem>
              <MenuItem value="P">P</MenuItem>
              <MenuItem value="Q">Q</MenuItem>
              <MenuItem value="R">R</MenuItem>
              <MenuItem value="S">S</MenuItem>
              <MenuItem value="T">T</MenuItem>
              <MenuItem value="U">U</MenuItem>
              <MenuItem value="V">V</MenuItem>
              <MenuItem value="W">W</MenuItem>
              <MenuItem value="X">X</MenuItem>
              <MenuItem value="Y">Y</MenuItem>
              <MenuItem value="Z">Z</MenuItem>
            </Select>
          </div>
        </PageControlContent>
      </PageControl>
    );
  }
}
export default IndexPageControls;
