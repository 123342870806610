import * as PropTypes from "prop-types";
import React, {PureComponent} from "react";
import {AuditLine, Checkbox, Dialog, FormControlLabel, FormLabel, TextField, Typography} from "../../common/components";
import If from "../../common/containers/If";
import "./EditLawDialog.css";

class EditLawDialog extends PureComponent {
  static propTypes = {
    law: PropTypes.shape({
      documentId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      comment: PropTypes.string,
      description: PropTypes.string,
      originalFilename: PropTypes.string,
    }).isRequired,
    saveEditing: PropTypes.func.isRequired,
    cancelEditing: PropTypes.func.isRequired,
  };

  state = {
    useCustomTitle: this.props.law.customTitle !== "",
    title: this.props.law.title || "",
    comment: this.props.law.comment || "",
    titleError: false,
  };

  onTitleChange = event => {
    this.setState({title: event.target.value, titleError: false});
  };

  onCommentChange = event => {
    this.setState({comment: event.target.value});
  };

  onUseCustomTitleChange = event => {
    this.setState({useCustomTitle: event.target.checked, titleError: false});
  };

  validateAndSave = () => {
    const {useCustomTitle, title, comment} = this.state;
    const {saveEditing, law} = this.props;
    if (!useCustomTitle || title.trim() !== "") {
      saveEditing(law.documentId, useCustomTitle ? title.trim() : "", comment.trim());
    }
  };


  render() {
    const {law, cancelEditing} = this.props;
    const {description = "", originalFilename = ""} = law;
    const {useCustomTitle, title, titleError, comment} = this.state;
    return (
      <Dialog
        title="Customize new law display"
        contextId="laws#manage"
        open={law !== null}
        onCancel={cancelEditing}
        onSubmit={this.validateAndSave}
        submitDisabled={useCustomTitle && !title}
        classes={{paper: "editLawDialogPaper"}}
        footer={<AuditLine entity={law}/>}
      >
        <div className="formSection">
          <FormLabel>Title</FormLabel>
          <Typography variant="subtitle1" className={useCustomTitle ? "textDisabled" : ""}>{description}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={useCustomTitle}
                onChange={this.onUseCustomTitleChange}
                style={{height: 'auto'}}
              />
            }
            label="Set a custom title"
            className="useCustomTitleLabel"
          />
          <TextField
            value={title}
            onChange={this.onTitleChange}
            fullWidth={true}
            className="editLawDialogField"
            required={true}
            disabled={!useCustomTitle}
            error={titleError}
            helperText={titleError ? "Please enter a title or uncheck the custom title option" : null}
          />
        </div>
        <div className="formSection">
          <TextField
            label="Comment"
            value={comment}
            onChange={this.onCommentChange}
            fullWidth={true}
            className="editLawDialogField"
            multiline={true}
            rowsMax={4}
          />
        </div>
        <If test={description !== "" || originalFilename !== ""}>
          <div className="formSection">
            <FormLabel>Information</FormLabel>
            <If test={description !== ""}>
              <Typography variant="subtitle1">Designation - {description}</Typography>
            </If>
            <If test={originalFilename !== ""}>
              <Typography variant="subtitle1">Original Filename - {originalFilename}</Typography>
            </If>
          </div>
        </If>
      </Dialog>
    );
  }
}

export default EditLawDialog;
