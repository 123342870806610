import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {getHelpTopics, getHelpVideos, isPrintLoading} from "../selectors";
import {toJS} from "../utils";
import PageActions from "../components/PageActions";
import * as PropTypes from "prop-types";

const mapStateToProps = createStructuredSelector({
  isPrintLoading: isPrintLoading,
  helpTopics: getHelpTopics,
  helpVideos: getHelpVideos
});

const mapDispatchToProps = () => ({});

const PageActionsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PageActions);
PageActionsContainer.propTypes = {
  pageId: PropTypes.string.isRequired,
  printable: PropTypes.bool,
  printOverride: PropTypes.func,
  emailSubject: PropTypes.string
};
export default PageActionsContainer;