/**
 * Laws display component
 * @module ecode/laws
 *
 * Copyright 2018 General Code
 */
import React from "react";
import ReactDOM from "react-dom";
import * as actions from "./actions";
import {initHistory, initLaw, initLawLedger} from "./actions";
import {ModuleKey} from "./ModuleKey";
import reducer, {initialState} from "./reducers";
import * as selectors from "./selectors";
import {getPageType, isNewNav} from "../common/selectors";
import LawTableContainer from "./containers/LawTableContainer";
import {LAWS} from "../common/utils/page-types";

import "./components/LawTable.css";
import "nodelist-foreach-polyfill";
import {setPageFilters} from "../common/actions";

const loadLaw = (law, state) => {
  const dataset = law.dataset;
  const documentId = dataset.documentId;
  const version = dataset.version;
  const oldLaw = state.getIn(["laws",documentId]);
  //The law exists from the previous cached state
  if (oldLaw) {
    console.debug("Law already exists: ", documentId, oldLaw);
    return state;
  }
  let newLaw = {
    documentId,
    title: dataset.title,
    customTitle: dataset.customTitle,
    description: dataset.description,
    originalFilename: dataset.originalFilename,
    comment: dataset.comment,
    adopted: dataset.adopted,
    adoptedYear: dataset.adoptedYear,
    dispositions: JSON.parse(dataset.dispositions),
    subject: dataset.subject,
    rank: dataset.rank,
    updatedBy: JSON.parse(dataset.updatedBy),
    updatedOn: dataset.updatedOn,
    version: version,
    suppedBy: JSON.parse(dataset.suppedBy)
  };

  const lastLawString = sessionStorage.getItem(`law-${documentId}`);
  //If we are trying to load a law and we have a newer version of in storage use that instead
  if (lastLawString) {
    try {
      const lastLaw = JSON.parse(lastLawString);
      const lastKnownVersion = lastLaw.version;
      if (lastKnownVersion && lastKnownVersion > version) {
        console.debug("Loading law from session store", lastLaw);

        //If we have a newer version that was deleted remember it
        if(lastLaw.deleted) {
          return;
        }

        //merge the cached data into what we have from the html
        newLaw = {
          ...newLaw,
          ...lastLaw,
        };
      } else {
        //out of date
        sessionStorage.removeItem(`law-${documentId}`);
      }
    } catch (e) {
      console.warn(`Failed to deserialize ${lastLawString}`, e);
      //invalid
      sessionStorage.removeItem(`law-${documentId}`);
    }
  }

  return reducer(state, initLaw(newLaw));
};

const loadLawLedger = (law, state) => {
  const dataset = law.dataset;
  const documentId = dataset.documentId;
  const version = dataset.version;
  const oldLaw = state.getIn(["lawLedger",documentId]);
  //The law exists from the previous cached state
  if (oldLaw) {
    console.debug("Law has already been loaded: ", documentId, oldLaw);
    return state;
  }
  let newLaw = {
    documentId,
    title: dataset.title,
    customTitle: dataset.customTitle,
    description: dataset.description,
    originalFilename: dataset.originalFilename,
    comment: dataset.comment,
    adopted: dataset.adopted,
    adoptedYear: dataset.adoptedYear,
    dispositions: JSON.parse(dataset.dispositions),
    subject: dataset.subject,
    rank: dataset.rank,
    updatedBy: JSON.parse(dataset.updatedBy),
    updatedOn: dataset.updatedOn,
    version: version,
    suppedBy: JSON.parse(dataset.suppedBy)
  };

  const lastLawString = sessionStorage.getItem(`law-${documentId}`);
  //If we are trying to load a law and we have a newer version of in storage use that instead
  if (lastLawString) {
    try {
      const lastLaw = JSON.parse(lastLawString);
      const lastKnownVersion = lastLaw.version;
      if (lastKnownVersion && lastKnownVersion > version) {
        console.debug("Loading law from session store", lastLaw);

        //If we have a newer version that was deleted remember it
        if(lastLaw.deleted) {
          return;
        }

        //merge the cached data into what we have from the html
        newLaw = {
          ...newLaw,
          ...lastLaw,
        };
      } else {
        //out of date
        sessionStorage.removeItem(`law-${documentId}`);
      }
    } catch (e) {
      console.warn(`Failed to deserialize ${lastLawString}`, e);
      //invalid
      sessionStorage.removeItem(`law-${documentId}`);
    }
  }

  return reducer(state, initLawLedger(newLaw));
};


export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }

  let lawsState = loadingState.get(ModuleKey) || initialState;
  const newLaws = document.querySelectorAll("#newLaws tbody tr:not(.muiTableDetailRow)");
  const lawLedger = document.querySelectorAll("#lawLedger tbody tr:not(.muiTableDetailRow)");
  return loadingState.set(ModuleKey, lawsState.withMutations(loadingLawsState => {
    let state = loadingLawsState;
    for (let law of Array.from(lawLedger)) {
      state = loadLawLedger(law, state);
    }
    for (let law of Array.from(newLaws)) {
      state = loadLaw(law, state);
    }
    return state;
  }));
};
/**
 * Given a guid and a container object this makes an editor for it
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 * @param {function} state - The redux state
 */
const init = (Providers, dispatch, state) => {
  if (getPageType(state) === LAWS) {
    //initialize the law table if it's on the page
    if (isNewNav(state)) {
      const lawTable = document.querySelector(".lawTableContainer");
      let lawTableProps = lawTable ? {...lawTable.dataset} : {};
      if (lawTable) {
        ReactDOM.render(
          <Providers key="laws-table-providers" name="laws-table">
            <LawTableContainer {...lawTableProps}/>
          </Providers>,
          lawTable
        );
      }
      initHistoryFromUrl(dispatch);
      window.addEventListener("popstate", initHistoryFromEvent(dispatch));

    } else {
      const lawTablesHTML = document.getElementsByClassName("lawTableContainer");
      const lawTables = Array.from(lawTablesHTML);
      if (lawTables.length > 0) {
        ReactDOM.render(
          <Providers key="laws-table-providers" name="laws-table">
            <LawTableContainer {...(lawTables[0].dataset)}/>
          </Providers>,
          lawTables[0]
        );
        if (lawTables.length > 1) {
          ReactDOM.render(
            <Providers key="laws-table-second-providers" name="laws-table-second">
              <LawTableContainer {...(lawTables[1].dataset)}/>
            </Providers>,
            lawTables[1]
          );
        }
      }
    }
  }
};

const initHistoryFromUrl = (dispatch) => {
  const urlParams = new URLSearchParams(window.location.search);
  const filterText = urlParams.has("filter") ? urlParams.get("filter") : "";
  const filterYear = urlParams.has("year") ? urlParams.get("year") : "";
  const filterChapters = urlParams.has("chapters") ? urlParams.getAll("chapters") : [];
  const sort = urlParams.has("sort") ? urlParams.get("sort").split(",")
    .map(s => ({direction: s.substring((s.indexOf("_") + 1), s.length).toLowerCase(), columnName: s.substring(0, s.indexOf("_"))}))
    : [];
  dispatch(initHistory(filterText, filterYear, filterChapters, sort));
  dispatch(setPageFilters([
    "filter",
    "year",
    "chapters"
  ], {filter: filterText, year: filterYear, chapters: filterChapters.join(",")}));
};
const initHistoryFromEvent = (dispatch) => (e) => {
  const filterText = e.state ? e.state.filterText : "";
  const filterYear = e.state ? e.state.filterYear : "";
  const filterChapters = (e.state?.filterChapters) ? e.state.filterChapters : [];
  const sort = (e.state?.sort) ? e.state.sort : [];
  dispatch(initHistory(filterText, filterYear, filterChapters, sort));
  dispatch(setPageFilters([
    "filter",
    "year",
    "chapters"
  ], {filter: filterText, year: filterYear, chapters: filterChapters.join(",")}));
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from './ModuleKey';

export {
  init,
  actions,
  reducer,
  selectors,
};
