import React from "react";
import ReactLoadable from "react-loadable";

const Loading = () => <div/>;
const buildLoadable = (promise, name, opts) => {
  const NamedComponent = ReactLoadable({
    loader: () => promise.then(exports => exports[name]),
    loading: Loading,
    ...opts,
  });
  Object.defineProperty(NamedComponent, 'name', {value: name});
  NamedComponent.displayName = `Loadable(${name})`;
  return NamedComponent;
};

const buildLoadableSingle = (promise, name, opts) => {
  const NamedComponent = ReactLoadable({
    loader: () => promise,
    loading: Loading,
    ...opts,
  });
  Object.defineProperty(NamedComponent, 'name', {value: name});
  NamedComponent.displayName = `Loadable(${name})`;
  return NamedComponent;
};

export {buildLoadable, buildLoadableSingle};
