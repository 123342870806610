import React from "react";

import {InfiniteScroll, LinearProgress} from "../../common/components";
import If from "../../common/containers/If";
import {custId} from "../../common/utils/server-data";
import RelatedTermsContainer from "../containers/RelatedTermsContainer";
import ResultContainer from "../containers/ResultContainer";

import "./SearchResultsPage.css";

class SearchResults extends React.Component {

  render() {
    const {customers, currentPage, maxPages, results, isSearchLoading, selectedCustIds,
      selectedCustomerFilters, getNextPage
    } = this.props;
    const isMulticodeSearch = !custId || selectedCustomerFilters.length > 0 || selectedCustIds.length > 1
      || (selectedCustIds.length === 1 && selectedCustIds[0] !== custId);
    return (
      <div id="searchResultsPageContainer">
        <RelatedTermsContainer/>
        <div id="resultsContainer">
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={getNextPage}
            hasMore={(currentPage + 1) < maxPages}
            threshold={800}
          >
            {
              results.length > 0 ? results.map((result) => (
                <ResultContainer key={result.id || result.url}
                  result={result}
                  customer={customers[result.custId]}
                  isMulticodeSearch={isMulticodeSearch}
                />
              )) : (!isSearchLoading ? "No results found..." : "")
            }
          </InfiniteScroll>
          <If test={isSearchLoading}><LinearProgress/></If>
        </div>
      </div>
    );
  }
}
export default SearchResults;
