const updateLogoutUrl = (url) => {
  const logoutButton = document.getElementById("topLogoutButton");
  if (logoutButton) {
    logoutButton.href = logoutButton.href.replace(/\?url=.*$/, `?url=${encodeURIComponent(url)}`);
  }
};
const updateLoginUrl = (url) => {
  const loginButton = document.getElementById("topLoginButton");
  if (loginButton) {
    loginButton.href = loginButton.href.replace(/\?url=.*$/, `?url=${encodeURIComponent(url)}`);
  }
};

//only for search.  Assume the base url in the link is correct and only update the query string.
const returnToArchive = (url) => {
  const returnLink = document.getElementById("versionReturn");
  if (returnLink && returnLink.href.indexOf("?") !== -1 && returnLink.href.indexOf("/search") !== -1) {
    const newQuery = url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"));
    returnLink.href = returnLink.href.replace(/\?.*$/, newQuery);
  }
};

//newurl is fired from the jquery.history implementation and our react methods so anything changing the url should update the listed UI elements
window.addEventListener("newurl", e => {
  const url = e.url.replace(/^https?:\/\/[^/]+\//,"/");
  updateLogoutUrl(url);
  updateLoginUrl(url);
  returnToArchive(url);
});
