import {ModuleKey} from "../ModuleKey";
import {
  contentPreviewDelay,
  contentPreviewSelector,
  customerWarnings,
  globalPublicMessage,
  globalPublicMessageDismissalDays,
  hasDashboardAccess,
  hasDistributionData,
  isAdminOptionHide,
  isIndexViewVisible,
  lawCount,
  lawLedgerCount,
  myNotesCount,
  newNav,
  noteCount,
  noteLocationData,
  pageSubType,
  pageType,
  pubDocsOnly,
  searchResultsUrl,
  versions,
  zoningGuid
} from "../utils/server-data";
import createCachedSelector from "re-reselect";

const getAnalysisId = (state) => state[ModuleKey].analysisId;
const getArchiveId = (state) => state[ModuleKey].archiveId;
const getAssignedFilter = (state) => state[ModuleKey].assignedFilter;
const getAssignedToFilter = (state) => state[ModuleKey].assignedToFilter;
const getBodyTop = (state) => state[ModuleKey].bodyTop;
const getCustId = (state) => state[ModuleKey].custId;
const getCustomer = (state) => state[ModuleKey].customer;
const getCodeId = (state) => (getArchiveId(state) ? getArchiveId(state) : (getCustId(state) ? getCustId(state) : null));
const getCustomization = (state) => getCustomer(state) ? getCustomer(state).customization : null;
const getCodeLabel = (state) => getCustomization(state) && getCustomization(state).sidebarLabel
  ? getCustomization(state).sidebarLabel : "Code";

const getSubdivisionName = (state) => getCustomer(state) ? getCustomer(state).subdivisionName : "County";

const getSubdivisionNamePlural = (state) => getCustomer(state) ? getCustomer(state).subdivisionNamePlural : "Counties";
const getMapUrl = (state) => getCustomization(state) ? getCustomization(state).mapUrl : null;
const getMediaHeight = (state) => state[ModuleKey].mediaHeight;
const getMediaWidth = (state) => state[ModuleKey].mediaWidth;
const getDeferrableFilter = (state) => state[ModuleKey].deferrableFilter;
const getDeferFilter = (state) => state[ModuleKey].deferFilter;
const getDisplayedQuestionIds = (state) => state[ModuleKey].displayedQuestionIds;
const getDueWarningDays = (state) => state[ModuleKey].dueWarningDays;
const getDueWarningIntervalHours = (state) => state[ModuleKey].dueWarningIntervalHours;
const getErrorMessages = (state) => state[ModuleKey].errorMessages;
const getErrorTitles = (state) => state[ModuleKey].errorTitles;
const getErrorRedirectUrl = (state) => state[ModuleKey].errorRedirectUrl;
const getFilterText = (state) => state[ModuleKey].filterText;
const getHelpTopics = (state) => state[ModuleKey].helpTopics;
const getHelpVideos = (state) => state[ModuleKey].helpVideos;
const getPageFilterCount = (state) => state[ModuleKey].pageFilters.size;
const getHomeLongName = (state) => getCustomization(state) ? getCustomization(state).homeLongName : "Home";
const getHomeShortName = (state) => getCustomization(state) ? getCustomization(state).homeShortName : "Home";
const getCodeShortName = (state) => getCustomization(state) ? getCustomization(state).codeShortName : "Code";
const getCodeLongName = (state) => getCustomization(state) ? getCustomization(state).codeLongName : "Code";
const getZoningLongName = (state) => getCustomization(state) ? getCustomization(state).zoningLongName : "Zoning";
const getZoningShortName = (state) => getCustomization(state) ? getCustomization(state).zoningShortName : "Zoning";
const getLawsLongName = (state) => getCustomization(state) ? getCustomization(state).lawsLongName : "Zoning";
const getLawsShortName = (state) => getCustomization(state) ? getCustomization(state).lawsShortName : "Zoning";
const getNotesLongName = (state) => getCustomization(state) ? getCustomization(state).notesLongName : "Notes";
const getNotesShortName = (state) => getCustomization(state) ? getCustomization(state).notesShortName : "Notes";
const getQuestionsLongName = (state) => getCustomization(state) ? getCustomization(state).questionsLongName : "Questions";
const getQuestionsShortName = (state) => getCustomization(state) ? getCustomization(state).questionsShortName : "Questions";
const getPubdocsShortName = (state) => getCustomization(state) ? getCustomization(state).pubdocsShortName : "Public Documents";
const getPubdocsLongName = (state) => getCustomization(state) ? getCustomization(state).pubdocsLongName : "Public Documents";
const getAdminLongName = (state) => getCustomization(state) ? getCustomization(state).adminLongName : "Admin";
const getAdminShortName = (state) => getCustomization(state) ? getCustomization(state).adminShortName : "Admin";
const getHelpLongName = (state) => getCustomization(state) ? getCustomization(state).helpLongName : "Help";
const getHelpShortName = (state) => getCustomization(state) ? getCustomization(state).helpShortName : "Help";
const getZoningMapName = (state) => getCustomization(state) ? getCustomization(state).zoningMapName : "Zoning Map";
const getZoningChapterName = (state) => getCustomization(state) ? getCustomization(state).zoningChapterName : "Zoning Chapter";
const getNewLawsName = (state) => getCustomization(state) ? getCustomization(state).newLawsName : "New Laws";
const getLawLedgerName = (state) => getCustomization(state) ? getCustomization(state).lawLedgerName : "Law Ledger";
const getNeedsReviewFilter = (state) => state[ModuleKey].needsReviewFilter;
const getQuestions = (state) => state[ModuleKey].questions;
const getReviewers = (state) => state[ModuleKey].reviewers;
const getSearchSuggestionWaitTime = (state) => state[ModuleKey].searchSuggestionWaitTime;
const getStatusFilter = (state) => state[ModuleKey].statusFilter;
const getTermsOfUseUrl = (state) => state[ModuleKey].termsOfUseUrl;
const getIa2FileTypes = (state) => state[ModuleKey].ia2FileTypes;
const getShowDiscussionText = (state) => state[ModuleKey].showDiscussionText;
const getHideDiscussionText = (state) => state[ModuleKey].hideDiscussionText;
const getMulticodeStates = (state) => state[ModuleKey].multicodeStates;
const getPubDocsFileTypes = (state) => state[ModuleKey].pubDocsFileTypes;
const getUsername = (state) => state[ModuleKey].username;
const isPrintLoading = (state) => state[ModuleKey].isPrintLoading;
const isUserMessageShown = (state) => state[ModuleKey].userMessageTitle !== null && state[ModuleKey].userMessageText !== null;
const getUserMessageTitle = (state) => state[ModuleKey].userMessageTitle;
const getUserMessageText = (state) => state[ModuleKey].userMessageText;

const getCustomerWarnings = () => customerWarnings;
const getPubDocsOnly = () => pubDocsOnly;
const getPageType = () => pageType;
const getPageSubType = () => pageSubType;
const getZoningGuid = () => zoningGuid;
const getNoteCount = () => noteCount;
const getHasDistributionData = () => hasDistributionData;
const getIsIndexViewVisible = () => isIndexViewVisible;
const getIsAdminOptionHide = () => isAdminOptionHide;
const getMyNotesCount = () => myNotesCount;
const getLawCount = () => lawCount;
const getLawLedgerCount = () => lawLedgerCount;
const getSearchResultsUrl = () => searchResultsUrl;
const getNoteLocationData = () => noteLocationData;
const isNewNav = () => newNav;
const getVersions = () => versions;
const getContentPreviewDelay = () => contentPreviewDelay;
const getContentPreviewSelector = () => contentPreviewSelector;

const countWords = (str) => str.trim().split(/\s+/).length;
const globalPublicAutoHide = 5000 + ((countWords(globalPublicMessage) / 3) * 1000);
const getGlobalPublicAutoHide = () => globalPublicAutoHide;
const getGlobalPublicMessage = () => globalPublicMessage;
const getGlobalPublicMessageDismissalDays = () => globalPublicMessageDismissalDays;

const hasPermission = (state, permission) => state[ModuleKey].grantedPermissions?.includes(permission);
const hasAdvancedSearchPermission = (state) => hasPermission(state, 'SEARCH/QUERY/ADVANCED');
const hasArchivesViewPermission = (state) => hasPermission(state, 'VERSION');
const hasBulkUploadPermission = (state) => hasPermission(state, 'BULK_UPLOAD');
const hasContentPreviewPermission = (state) => hasPermission(state,'CONTENT/PREVIEW');
const hasCustomizationAdminPermission = (state) => hasPermission(state,'CUSTOMIZATION_ADMIN');
const hasDashboardBudgetPermission = (state) => hasPermission(state, 'DASHBOARD_BUDGET');
const hasDashboardDistributionPermission = (state) => hasPermission(state,'DASHBOARD_DISTRIBUTION');
const hasDashboardHistoryPermission = (state) => hasPermission(state,'DASHBOARD_HISTORY');
const hasDashboardPermission = () => hasDashboardAccess;
const hasDashboardTitlesPermission = (state) => hasPermission(state,'DASHBOARD_TITLES');
const hasDocumentAdminPermission = (state) => hasPermission(state, 'DOCUMENT_ADMIN');
const hasFeatureAdminPermission = (state) => hasPermission(state,'FEATURE_ADMIN');
const hasGroupNotesPermission = (state) => hasPermission(state, 'GROUP_ANNOTATION');
const hasIa2CommentAddPermission = (state) => hasPermission(state, 'IA2/COMMENT/ADD');
const hasIa2CommentUpdatePermission = (state) => hasPermission(state, 'IA2/COMMENT/UPDATE');
const hasIa2CompletePermission = (state) => hasPermission(state, 'IA2/FINALIZED/COMPLETE');
const hasIa2FinalizePermission = (state) => hasPermission(state, 'IA2/ANALYSIS/FINALIZE');
const hasIa2PrintPermission = (state) => hasPermission(state, 'IA2/QUESTION/PRINT');
const hasIa2QuestionLockPermission = (state) => hasPermission(state, 'IA2/QUESTION/LOCK');
const hasIa2QuestionUnlockPermission = (state) => hasPermission(state, 'IA2/QUESTION/UNLOCK');
const hasIa2StatusReportPermission = (state) => hasPermission(state, 'IA2/STATUS/VIEW');
const hasIndexViewPermission = (state) => hasPermission(state, 'INDEX/VIEW');
const hasLawViewPermission = (state) => hasPermission(state, 'LAW/ALL/VIEW');
const hasMulticodePermission = (state) => hasPermission(state, 'MULTI');
const hasPubDocCategoryAdminPermission = (state) => hasPermission(state, 'CATEGORY_ADMIN');
const hasPubDocPrivateViewPermission = (state) => hasPermission(state, 'PUBDOC/PRIVATE/VIEW');
const hasPubDocTypeAddPermission = (state) => hasPermission(state, 'PUBDOC/TYPE/ADD');
const hasPubDocTypeDeletePermission = (state) => hasPermission(state, 'PUBDOC/TYPE/DELETE');
const hasPubDocTypeEditPermission = (state) => hasPermission(state, 'PUBDOC/TYPE/EDIT');
const hasPublicNotesPermission = (state) => hasPermission(state, 'PUBLIC_ANNOTATION');
const hasUserAdminPermission = (state) => hasPermission(state,'USER_ADMIN');
const hasViewPermission = (state) => hasPermission(state, 'VIEW');

const getDialogs = (state) => state[ModuleKey].dialogs;
const getId = (state, props) => props.id;
const dialogSelector = (extract) => createCachedSelector([getDialogs, getId], (dialogs, id) => extract(dialogs.get(id)))(getId);
const getDialogOnCancel = dialogSelector(dialog => dialog?.onCancel);
const getDialogOnSubmit = dialogSelector(dialog => dialog?.onSubmit);
const getDialogOnClose = dialogSelector(dialog => dialog?.onClose);
const getDialogWidth = dialogSelector(dialog => dialog?.width);
const getDialogMaxWidth = dialogSelector(dialog => dialog?.maxWidth);
const getDialogFullWidth = dialogSelector(dialog => dialog?.fullWidth);
const getDialogOpen = dialogSelector(dialog => dialog?.open);
const getDialogTitle = dialogSelector(dialog => dialog?.title);
const getDialogContextId = dialogSelector(dialog => dialog?.contextId);
const getDialogActions = dialogSelector(dialog => dialog?.actions);
const getDialogContent = dialogSelector(dialog => dialog?.content);
const getDialogCancelLabel = dialogSelector(dialog => dialog?.cancelLabel);
const getDialogSubmitLabel = dialogSelector(dialog => dialog?.submitLabel);
const getDialogSubmitDisabled = dialogSelector(dialog => dialog?.submitDisabled);
const getDialogTitleProps = dialogSelector(dialog => dialog?.DialogTitleProps);
const getDialogActionsProps = dialogSelector(dialog => dialog?.DialogActionsProps);
const getDialogContentProps = dialogSelector(dialog => dialog?.DialogContentProps);

export {
  getAnalysisId,
  getArchiveId,
  getAssignedFilter,
  getAssignedToFilter,
  getBodyTop,
  getCodeId,
  getCodeLabel,
  getContentPreviewSelector,
  getContentPreviewDelay,
  getCustId,
  getCustomer,
  getCustomerWarnings,
  getCustomization,
  getDeferrableFilter,
  getDeferFilter,
  getDisplayedQuestionIds,
  getDueWarningDays,
  getDueWarningIntervalHours,
  getErrorMessages,
  getErrorRedirectUrl,
  getErrorTitles,
  getFilterText,
  getGlobalPublicAutoHide,
  getGlobalPublicMessage,
  getGlobalPublicMessageDismissalDays,
  getHomeLongName,
  getHomeShortName,
  getCodeShortName,
  getCodeLongName,
  getZoningLongName,
  getZoningShortName,
  getLawsLongName,
  getLawsShortName,
  getNotesLongName,
  getNotesShortName,
  getQuestionsLongName,
  getQuestionsShortName,
  getPubdocsShortName,
  getPubdocsLongName,
  getAdminLongName,
  getAdminShortName,
  getHelpLongName,
  getHelpShortName,
  getZoningMapName,
  getZoningChapterName,
  getNewLawsName,
  getLawLedgerName,
  getHelpTopics,
  getHelpVideos,
  getPageFilterCount,
  getHideDiscussionText,
  getIa2FileTypes,
  getLawCount,
  getMediaHeight,
  getMediaWidth,
  getMapUrl,
  getMulticodeStates,
  getNeedsReviewFilter,
  getNoteCount,
  getMyNotesCount,
  getPageSubType,
  getPageType,
  getLawLedgerCount,
  getPubDocsFileTypes,
  getPubDocsOnly,
  getQuestions,
  getReviewers,
  getSearchResultsUrl,
  getSearchSuggestionWaitTime,
  getShowDiscussionText,
  getStatusFilter,
  getTermsOfUseUrl,
  getUsername,
  getVersions,
  getZoningGuid,
  hasAdvancedSearchPermission,
  hasArchivesViewPermission,
  hasBulkUploadPermission,
  hasContentPreviewPermission,
  hasCustomizationAdminPermission,
  hasDashboardPermission,
  hasDashboardBudgetPermission,
  hasDashboardDistributionPermission,
  hasDashboardHistoryPermission,
  hasDashboardTitlesPermission,
  hasDocumentAdminPermission,
  hasFeatureAdminPermission,
  hasIa2CommentAddPermission,
  hasIa2CommentUpdatePermission,
  hasIa2CompletePermission,
  hasIa2FinalizePermission,
  hasIa2PrintPermission,
  hasIa2QuestionLockPermission,
  hasIa2QuestionUnlockPermission,
  hasIa2StatusReportPermission,
  hasIndexViewPermission,
  hasLawViewPermission,
  hasMulticodePermission,
  hasPermission,
  hasPubDocCategoryAdminPermission,
  hasPubDocPrivateViewPermission,
  hasPubDocTypeAddPermission,
  hasPubDocTypeDeletePermission,
  hasPubDocTypeEditPermission,
  hasPublicNotesPermission,
  hasGroupNotesPermission,
  hasUserAdminPermission,
  hasViewPermission,
  isPrintLoading,
  getNoteLocationData,
  isNewNav,
  getSubdivisionName,
  getSubdivisionNamePlural,
  getDialogOnCancel,
  getDialogOnSubmit,
  getDialogOnClose,
  getDialogWidth,
  getDialogMaxWidth,
  getDialogFullWidth,
  getDialogOpen,
  getDialogTitle,
  getDialogContextId,
  getDialogActions,
  getDialogContent,
  getDialogCancelLabel,
  getDialogSubmitLabel,
  getDialogSubmitDisabled,
  getDialogTitleProps,
  getDialogActionsProps,
  getDialogContentProps,
  getHasDistributionData,
  getIsIndexViewVisible,
  getIsAdminOptionHide,
  isUserMessageShown,
  getUserMessageTitle,
  getUserMessageText,
};
