import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "../../common/components/expansion";
import {ExpandMore as ExpandMoreIcon} from "../../common/components/icons";

class IndexPage extends Component {

  static propTypes = {
    words: PropTypes.array.isRequired
  };

  render() {
    const { words } = this.props;
    return (
      <div>
        {words.map((word) => (
          <ExpansionPanel key={word.word} defaultExpanded={false} className={"wordExpansionPanel"}>
            <ExpansionPanelSummary
              className="wordExpansionPanelSummary"
              classes={{
                expanded: "wordExpansionPanelSummaryExpanded",
                content: "wordExpansionPanelSummaryContent",
                expandIcon: "wordExpansionPanelButton"
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <div><span className="cardTitleSmall">{word.word}</span></div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="wordExpansionPanelDetails">
              <div>
                {word.references.map((reference) => (
                  <div key={reference.label} style={{marginBottom: 4}}>
                    <a href={reference.href} className="wordReference">{reference.label}</a>
                  </div>
                ))}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}
export default IndexPage;
