import {connect} from "react-redux";
import {compose} from "recompose";
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDocsPageControls from "../components/PubDocsPageControls";
import {
  getFilteredCategories,
  getPubDocTypeId,
  getPubDocTypes,
  getFilteredDocuments,
  getFilterOptions
} from "../selectors";
import {getCustId, hasPubDocTypeAddPermission, hasDocumentAdminPermission} from "../../common/selectors";
import {setPubDocCategoryEditOpen, setPubDocEditOpen, setPubDocTypeEditOpen} from "../actions";
import * as PropTypes from "prop-types";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypes: getPubDocTypes,
  pubDocTypeId: getPubDocTypeId,
  categories: getFilteredCategories,
  documents: getFilteredDocuments,
  filterOptions: getFilterOptions,
  hasPubDocTypeAddPermission: hasPubDocTypeAddPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission
});

const mapDispatchToProps = (dispatch) => ({
  openPubDocTypeAddDialog: () => { dispatch(setPubDocTypeEditOpen(true, null)); },
  openCategoryAddDialog: (typeId) => { dispatch(setPubDocCategoryEditOpen(true, null, typeId)); },
  openDocumentAddDialog: (typeId) => { dispatch(setPubDocEditOpen(true, null, null, typeId)); }
});

const PubDocsPageControlsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsPageControls);
PubDocsPageControlsContainer.propTypes = {
  showAllCategories: PropTypes.bool
};

export default PubDocsPageControlsContainer;
