import React from "react";

import {Sticky, StickyContainer} from "react-sticky";
import SearchResultsPageTitleContainer from "../containers/SearchResultsPageTitleContainer";
import SearchResultsContainer from "../containers/SearchResultsContainer";
import FacetContainer from "../containers/FacetContainer";
import ScopeSelectorContainer from "../containers/ScopeSelectorContainer";
import SortContainer from "../containers/SortContainer";
import "./SearchResultsPage.css";

class SearchResultsPage extends React.Component {

  render() {
    const {selectedCustIds, selectedCustomerFilters, scope, bodyTop} = this.props;
    return (
      <StickyContainer id="main" className="clearfix">
        <div className="sidebarArea">
          <Sticky topOffset={-bodyTop}>
            {({style, isSticky}) => (
              <div id="scopeSelectorContainer" className={"sidebarItem " + (isSticky ? "isSticky" : "isNotSticky")} style={style}>
                <ScopeSelectorContainer id="scopeSelectorContent"/>
              </div>
            )}
          </Sticky>
          <SortContainer id={"sortFacet"} hasDateSort={scope === "doc"}
            hasNaturalOrderSort={scope === "code" && selectedCustIds.length === 1 && selectedCustomerFilters.length === 0}
          />
          <FacetContainer className="sidebarItem"/>
        </div>
        <div id="content">
          <div id="contentArea">
            <div id="text">
              <div id="contentContainer">
                <SearchResultsPageTitleContainer />
                <SearchResultsContainer />
              </div>
            </div>
          </div>
        </div>
      </StickyContainer>
    );
  }
}
export default SearchResultsPage;
