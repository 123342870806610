import {connect} from "react-redux";
import {compose} from "recompose";
// noinspection ES6CheckImport
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {
  getPubDocsLoading,
  getFilterOptions,
  getSelectedFilters
} from "../selectors";
import {setPageFilters} from "../../common/actions";
import {loadFilters, updateFilterState} from "../actions";
import PubDocsFilters from "../components/PubDocsFilters";

const mapStateToProps = createStructuredSelector({
  documentsLoading: getPubDocsLoading,
  filterOptions: getFilterOptions,
  selectedFilters: getSelectedFilters
});

const mapDispatchToProps = (dispatch) => ({
  updateFilterState: (custId, pubDocTypeId, selectedFilters) => {
    dispatch(updateFilterState(selectedFilters));
    dispatch(setPageFilters(["category", "year", "month", "day"], selectedFilters));
    dispatch(loadFilters(custId, pubDocTypeId));
  }
});

const PubDocsFiltersContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocsFilters);
export default PubDocsFiltersContainer;
