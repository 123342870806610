/*
 * Copyright 2018 General Code
 */
import {createAction} from "redux-actions";
import {registerError} from "../../common/actions";
import {getJson, deleteJson, putJson} from "../../common/utils";
import {getUsername} from "../../common/selectors";
import {getColor, getGuid, getRealIdMapping, getTitle, getVisibility, getDisplayMode, getVersion} from "../selectors";
import {updateColor, updateContent, updateDisplayMode, updateTitle, updateVisibility} from "./content";

//add appropriate defaults and construct payload
const initNote = createAction('NOTE/INIT',
  ({
    id,
    color = "default",
    content,
    createdBy = {name: "unknown", email: "unknown@invalid.com"},
    createdOn = new Date().toDateString().split(':')[0],
    editable = false,
    guid,
    title,
    updatedBy,
    updatedOn,
    version,
    visibility = "private",
    displayMode = "expanded"
  }) => ({
    id,
    color,
    content,
    createdBy,
    createdOn,
    editable,
    guid,
    title,
    updatedBy,
    updatedOn,
    version,
    visibility,
    displayMode,
  })
);

const loadNoteGroupsSuccess = createAction('NOTE/GROUPS/LOAD/SUCCESS', (groups) => ({groups}));
const loadNoteGroupsFail = createAction('NOTE/GROUPS/LOAD/FAIL');
const getNoteGroups = (custId) => async (dispatch) => {
  try {
    const groups = await getJson("/api/notes/groups" + (custId ? ("/" + custId) : "") + "/all/user/current");
    dispatch(loadNoteGroupsSuccess(groups));
  } catch (error) {
    dispatch(loadNoteGroupsFail());
  }
};

const setNoteFilter = createAction('NOTE/FILTER/SET', (filterId, value) => ({filterId, value}));
const removeNoteFilter = createAction('NOTE/FILTER/REMOVE', (filterId) => ({filterId}));

//title can be a dom event
const toggleNote = createAction('NOTE/TOGGLE', id => ({id}));
const editNote = (id) => async (dispatch) => {
  return dispatch(editNoteStart(id));
};

const editNoteStart = createAction('NOTE/EDIT/START', id => ({id}));
const editNoteCancel = createAction('NOTE/EDIT/CANCEL', id => ({id}));

const addNote = (guid, id) => async (dispatch, getState) => {
  dispatch(initNote({
    id: id,
    content: "",
    title: "",
    guid: guid,
    createdBy: {name: getUsername(getState()), email: ""},
    editable: true,
  }));
  return dispatch(editNote(id));
};


const deleteNoteStart = createAction('NOTE/DELETE/START', (id) => ({id}));
const deleteNoteSuccess = createAction('NOTE/DELETE/SUCCESS', (id) => ({id}));
const deleteNoteFail = createAction('NOTE/DELETE/FAIL', (id, error) => ({id, error}));
const deleteNoteFinally = createAction('NOTE/DELETE/FINALLY', (id) => ({id}));

const deleteNote = id => async (dispatch,getState) => {
  const state = getState();
  const realId = id.startsWith('new-') ? getRealIdMapping(state).get(id) || 0 : id;
  const version = getVersion(state, id);
  dispatch(deleteNoteStart(realId));
  try {
    window.addEventListener("unload", () => {console.debug("Unload event because of delete to note: ", id);});
    const response = await deleteJson(`/api/notes/${realId}`);
    if(sessionStorage) {
      try {
        sessionStorage.setItem(`note-${response.id}`, JSON.stringify({id,version:version + 1, deleted: true}));
      } catch (e) {
        console.warn(`Failed to cache update for note ${realId}`, e);
      }
    }
    return dispatch(deleteNoteSuccess(id, response));
  } catch (e) {
    dispatch(registerError("Problem deleting note", null, [id], e));
    dispatch(deleteNoteFail(id, e));
  } finally {
    dispatch(deleteNoteFinally(realId));
  }
};

const saveNoteStart = createAction('NOTE/SAVE/START', (id) => ({id}));
const saveNoteSuccess = createAction('NOTE/SAVE/SUCCESS', (id, response) => ({id, real: response.id}));
const saveNoteFail = createAction('NOTE/SAVE/FAIL', (id, error) => ({id, error}));
const saveNoteFinally = createAction('NOTE/SAVE/FINALLY', (id) => ({id}));

const saveNote = (id, content) => async (dispatch, getState) => {
  const state = getState();

  const realId = id.startsWith('new-') ? getRealIdMapping(state).get(id) || 0 : id;

  const note = {
    id: realId,
    guid: getGuid(state, {id}),
    title: getTitle(state, {id}),
    content: content,
    color: getColor(state, {id}),
    visibility: getVisibility(state, {id}),
    displayMode: getDisplayMode(state, {id}),
    startOpen: getDisplayMode(state, {id}) === 'expanded',
  };
  dispatch(saveNoteStart(id));
  try {
    window.addEventListener("unload", () => {console.debug("Unload event because of save to note: ", id);});
    const response = await putJson(`/api/notes/${realId}`, note);
    if(sessionStorage) {
      try {
        sessionStorage.setItem(`note-${response.id}`, JSON.stringify(response));
      } catch (e) {
        console.warn(`Failed to cache update for note ${realId}`, e);
      }
    }
    return dispatch(saveNoteSuccess(id, response));
  } catch (error) {
    dispatch(registerError("Problem saving note", null, [realId], error));
    dispatch(saveNoteFail(id, error));
  } finally {
    dispatch(saveNoteFinally(id));
  }
};

export {
  getNoteGroups,
  loadNoteGroupsSuccess,
  loadNoteGroupsFail,
  addNote,
  editNote,
  editNoteCancel,
  editNoteStart,
  saveNote,
  saveNoteStart,
  saveNoteSuccess,
  saveNoteFail,
  saveNoteFinally,
  initNote,
  updateTitle,
  updateContent,
  updateColor,
  updateVisibility,
  updateDisplayMode,
  deleteNote,
  deleteNoteStart,
  deleteNoteSuccess,
  deleteNoteFail,
  deleteNoteFinally,
  setNoteFilter,
  removeNoteFilter,
  toggleNote
};
