/*
 * Copyright 2019 General Code
 */

export const INIT = "ADMIN/SETTINGS/INIT";

export const MAP_URL_SET = "ADMIN/SETTINGS/MAP_URL/SET";
export const MAP_URL_UPDATED = "ADMIN/SETTINGS/MAP_URL/UPDATED";

export const DISPLAY_DATE_SET = "ADMIN/SETTINGS/DISPLAY_DATE/SET";
export const DISPLAY_DATE_UPDATED = "ADMIN/SETTINGS/DISPLAY_DATE/UPDATED";
