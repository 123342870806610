/*
 * Copyright 2019 General Code
 */

import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import {getQuery, getRelatedTerms} from "../selectors";
import RelatedTerms from "../components/RelatedTerms";

const mapStateToProps = createStructuredSelector({
  query: getQuery,
  relatedTerms: getRelatedTerms,
});

const mapDispatchToProps = () => ({});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(RelatedTerms);