import PropTypes from "prop-types";
import React from "react";
import LazyLoad from "react-lazyload";
import {rowHeight} from "../components/PubDoc";
import PubDocContainer from "./PubDocContainer";

const docChunkSize = 300;

const PubDocChunk = ({documents, chunkNumber, category, once, ...props}) => (
  <LazyLoad height={Math.min(docChunkSize,documents.length) * rowHeight} offset={Math.min(docChunkSize,documents.length)  * rowHeight} throttle={300} once={once}>
    <>
      {documents
        .slice(chunkNumber * docChunkSize, (chunkNumber + 1) * docChunkSize)
        .map((document, index, docArray) =>
          <PubDocContainer {...props} key={`pub-doc-container-${document.id}`} category={category} doc={document}
            prevDoc={(index < 1 ? null : docArray[index - 1])}/>
        )
      }
    </>
  </LazyLoad>
);

PubDocChunk.propTypes = {
  documents: PropTypes.array.isRequired,
  chunkNumber: PropTypes.number.isRequired,
  category: PropTypes.any.isRequired,
  once: PropTypes.bool,
};

export default PubDocChunk;
export {
  docChunkSize
};