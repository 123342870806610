import * as dateMath from "date-arithmetic";
import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import If from "../../common/containers/If";
import {Dialog} from "../../common/components";
import "./Question.css";

class AnalysisDue extends Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    custId: PropTypes.string,
    dueDate: PropTypes.string,
    dueWarningIntervalHours: PropTypes.number
  };

  handleDialogClose = () => {
    const {cookies, custId} = this.props;
    cookies.set(`lastDueWarning-${custId}`, new Date(), {path: "/"});
  };

  render() {
    const {cookies, custId, dueDate, dueWarningIntervalHours} = this.props;
    const openDialog = (!cookies.get(`lastDueWarning-${custId}`) ||
      //if now is after the end of the last day we showed this
      dateMath.gt(new Date(), dateMath.startOf(dateMath.add(new Date(cookies.get(`lastDueWarning-${custId}`)), dueWarningIntervalHours, 'hours'), "day")));
    let dueIn = dateMath.diff(dateMath.startOf(new Date(), 'day'), dateMath.startOf(new Date(dueDate), 'day'), 'day');
    return (
      <div className="analysisDue">
        <Dialog
          title="Analysis Due"
          open={openDialog}
          onClose={this.handleDialogClose}
        >
          <If test={dueIn > 0}>
            Your responses to the questions in this analysis are due in {dueIn} days.
          </If>
          <If test={dueIn === 0}>
            Your responses to the questions in this analysis are due TODAY.
          </If>
          <If test={dueIn < 0}>
            Your responses to the questions in this analysis are now PAST DUE.
          </If>
        </Dialog>
        <If test={dueIn > 0}>
          Due in {dueIn} days ({dueDate})
        </If>
        <If test={dueIn === 0}>
          Due TODAY ({dueDate})
        </If>
        <If test={dueIn < 0}>
          PAST DUE ({dueDate})
        </If>
      </div>
    );
  }

}

export default withCookies(AnalysisDue);

