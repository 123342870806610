import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {AppBar, Menu, MenuItem, PrimaryButton, Toolbar} from "../../common/components";
import If from "../../common/containers/If";
import {ExpandMore as ExpandMoreIcon} from "../../common/components/icons";
import PubDocsFiltersContainer from "../containers/PubDocsFiltersContainer";

class PubDocToolbar extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    selectedDocIds: PropTypes.array.isRequired,
    selectedDocs: PropTypes.array.isRequired,
    selectedFilters: PropTypes.object,
    setDocumentEditOpen: PropTypes.func.isRequired,
    setDocumentMoveOpen: PropTypes.func.isRequired,
    openDownloadErrorDialog: PropTypes.func.isRequired,
    openBulkDeleteDialog: PropTypes.func.isRequired
  }

  state = {
    downloadErrorOpen: false,
    documentToolbarMenuOpen: false
  }

  openToolbarDocumentMenu = (event) => {
    this.setState({documentToolbarMenuOpen: true, documentToolbarAnchorEl: event.currentTarget});
  }

  closeToolbarDownloadMenu = () => {
    this.setState({documentToolbarMenuOpen: false, documentToolbarAnchorEl: null});
  }

  openEditDocumentDialog = (documentId) => () => {
    this.setState({typeAnchorEl: null});
    this.props.setDocumentEditOpen(true, documentId, null);
  }

  handleMove = () => {
    this.props.setDocumentMoveOpen(true, null);
  }

  openBulkDeleteDialog = () => {
    this.props.openBulkDeleteDialog();
    this.setState({documentToolbarMenuOpen: false, documentToolbarAnchorEl: null});
  }

  handlePubDocsDownload = () => {
    const {custId, selectedDocs, selectedDocIds, openDownloadErrorDialog} = this.props;
    const linkDocuments = selectedDocs.filter(currDoc => (currDoc.type === "AUDIO_LINK" || currDoc.type === "VIDEO_LINK"));
    if (linkDocuments.length > 0) {
      openDownloadErrorDialog();
    } else {
      window.location.href = `/api/${custId}/pub-doc/${selectedDocIds}/download`;
    }
  }

  render() {
    const {hasDocumentAdminPermission, selectedDocs, selectedFilters} = this.props;
    const {documentToolbarMenuOpen, documentToolbarAnchorEl} = this.state;
    const categoryFilter = selectedFilters.category == null ? "" : selectedFilters.category;
    return (
      <AppBar className="appBarSticky pubDocAppBar" position="sticky">
        <Toolbar className={"pubDocToolbar"}>
          <div className={"pubDocToolbarFiltersContainer"}>
            <PubDocsFiltersContainer/>
          </div>
          <div className={"pubDocToolbarButtonContainer"}>
            <If test={hasDocumentAdminPermission}>
              <PrimaryButton variant="contained" className={"pubDocToolbarButton"} onClick={this.openEditDocumentDialog(null)}>
                Add Document
              </PrimaryButton>
            </If>
            <If test={selectedDocs.length > 0 && hasDocumentAdminPermission}>
              <PrimaryButton variant="contained" className={"pubDocToolbarButton pubDocActionsButton"} onClick={this.openToolbarDocumentMenu}>
                Actions<ExpandMoreIcon />
              </PrimaryButton>
              <Menu
                open={documentToolbarMenuOpen}
                anchorEl={documentToolbarAnchorEl}
                onClose={this.closeToolbarDownloadMenu}
              >
                <MenuItem onClick={this.handleMove}>Move</MenuItem>
                <MenuItem onClick={this.openBulkDeleteDialog}>Delete</MenuItem>
                <MenuItem onClick={this.handlePubDocsDownload}>Download</MenuItem>
              </Menu>
            </If>
          </div>
        </Toolbar>
        <AppBar className="gpsCategoryBar pubDocToolbarCategorySticky" position="static">
          <Toolbar variant="dense">
            <p id="gpsCategoryText">{categoryFilter}</p>
          </Toolbar>
        </AppBar>
      </AppBar>
    );
  }
}

export default PubDocToolbar;