import * as PropTypes from 'prop-types';
import React from 'react';
import svg4everybody from 'svg4everybody';

import MuiSvgIcon from '@material-ui/core/SvgIcon';
svg4everybody();

const SvgIcon = ({title, src, id = 'icon', ...props}) => (
  <MuiSvgIcon {...props} titleAccess={title}>
    <use xlinkHref={`${src}#${id}`} href={`${src}#${id}`}/>
  </MuiSvgIcon>
);

SvgIcon.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default SvgIcon;