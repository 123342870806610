import React from "react";
import InputLabel from "../InputLabel";
import {If} from "../../containers";

const PageControlHeader = ({className, label, htmlFor, children, ...props}) => (
  <h2 className={`${className ? className + ' ' : ''} page-control-widget-header`} {...props}>
    <If test={label}>
      <InputLabel className="widget-label" htmlFor={htmlFor}>
        {label}
      </InputLabel>
    </If>
    {children}
  </h2>
);

export {PageControlHeader};
export default PageControlHeader;