import RequestError from "./RequestError";

const throwError = async (url, method, response) => {
  let title = null;
  let message = null;
  try {
    const responseData = await response.json();
    title = (responseData && responseData.title) ? responseData.title : null;
    message = (responseData && responseData.message) ? responseData.message : null;
  } finally { }
  throw new RequestError(url, method, response.status, response.statusText, title, message);
};

//Private method to handle if headers is undefined so that the default from the wrapper can be used
const request = (url, method = "GET", options, {headers: {contentType = 'application/json', ...extraHeaders} = {}} = {headers: {}}) => fetch(url,
  {
    method,
    ...options,
    "headers": {
      'Content-Type': contentType,
      ...(extraHeaders || {}),
    },
    credentials: "include",
  }).then(response => response.ok ? response.json() : throwError(url, method, response));
/**
 * Post data to a url as json. Error if the response is an error.
 * @function
 * @param {string} url - the url
 * @param {object?} data - thing to be run through JSON.stringify and posted as the body
 * @param {string?} type - the content-type to specify in the header to send for this request.  default to application/json
 * @returns {object} the response from the server
 */
export const post = (url, data, type = "application/json") =>
  fetch(url,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": type },
      credentials: "same-origin",
    }
  ).then(response => response.ok && !response.error ? response : throwError(url, "POST", response));
/**
 * Send a post to a url. Error if the response is an error. Otherwise parse the response as json
 * @function
 * @param {string} url - the url
 * @param {object?} data - thing to be run through JSON.stringify and posted as the body
 * @param {object?} options - Additional options
 * @returns {object} the response from the server as a js object
 */
export const postJson = (url, data, ...options) => request(url, "POST", {
  body: JSON.stringify(data),
  ...options
});
/**
 * Post data to a url as form data. Let fetch determine the content type of the multipart request.  Error if the response is an error.
 * @function
 * @param {string} url - the url
 * @param {object?} formData - thing to be posted as the body
 * @returns {object} the response from the server
 */
export const postFormData = (url, formData) =>
  fetch(url,
    {
      method: "POST",
      body: formData,
      credentials: "same-origin",
    }
  ).then(response => response.ok && !response.error ? response : throwError(url, "POST", response));

export const deleteFormData = (url, formData) =>
  fetch(url,
    {
      method: "DELETE",
      body: formData,
      credentials: "same-origin",
    }
  ).then(response => response.ok && !response.error ? response : throwError(url, "DELETE", response));

/**
 * Get from a url. Error if the response is an error
 * @function
 * @param {string} url - the url
 * @returns {object} the response from the server
 */
export const get = (url) => fetch(url, {
  credentials: "same-origin",
}).then(response => response.ok ? response : throwError(url, "GET", response));

/**
 * Get from a url. Error if the response is an error. Otherwise parse the response as json
 * @function
 * @param {string} url - the url
 * @returns {object} the response from the server as a js object
 */
export const getJson = (url) => request(url, "GET");
/**
 * Get from a url, adding a header to disable caching.
 * Error if the response is an error. Otherwise parse the response as json.
 * @function
 * @param {string} url - the url
 * @returns {object} the response from the server as a js object
 */
export const getJsonNoCache = (url) => request(url, "GET",
  {headers: {contentType: 'application/json', 'cache-control': 'no-cache', 'pragma': 'no-cache'}}
);
/**
 * Send a delete to a url. Error if the response is an error. Otherwise parse the response as json
 * @function
 * @param {string} url - the url
 * @returns {object} the response from the server as a js object
 */
export const deleteJson = (url) => request(url, "DELETE");
/**
 * Send a put to a url. Error if the response is an error. Otherwise parse the response as json
 * @function
 * @param {string} url - the url
 * @param {object?} data - thing to be run through JSON.stringify and posted as the body
 * @param {object?} options - Additional options
 * @returns {object} the response from the server as a js object
 */
export const putJson = (url, data, ...options) => request(url, "PUT", {
  body: JSON.stringify(data),
  ...options
});
/**
 * Put data to a url as form data. Let fetch determine the content type of the multipart request.  Error if the response is an error.
 * @function
 * @param {string} url - the url
 * @param {object?} formData - thing to be posted as the body
 * @returns {object} the response from the server
 */
export const putFormData = (url, formData) =>
  fetch(url,
    {
      method: "PUT",
      body: formData,
      credentials: "same-origin",
    }
  ).then(response => response.ok && !response.error ? response : throwError(url, "PUT", response));
/**
 * Send a put to a url containing a file for the body and File-name and Content-type headers. Error if the response is an error. Otherwise parse the response as json
 * @function
 * @param {string} url - the url
 * @param {File} file - File object from upload picker
 * @param {object?} headers - Header file overrides
 * @param {object?} options - Additional options
 * @returns {object} the response from the server as a js object
 */
export const putFile = (url, file, {headers = {}, ...options} = {}) => request(url, "PUT", {
  body: file,
  ...options,
}, {headers: {
  'File-name': file.name,
  contentType: file.type,
  ...headers
}
});

/**
 * Send a patch to a url. Error if the response is an error. Otherwise parse the response as json
 * @function
 * @param {string} url - the url
 * @param {object?} data - thing to be run through JSON.stringify and posted as the body
 * @param {object?} options - Additional options
 * @returns {object} the response from the server as a js object
 */
export const patchJson = (url, data, ...options) => request(url, "PATCH", {
  body: JSON.stringify(data),
  ...options
});
