/*
 * Copyright 2018 General Code
 */

import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import PageActionsContainer from "../../common/containers/PageActionsContainer";

class QuestionsPageActions extends Component {

  static propTypes = {
    displayedQuestionCount: PropTypes.number,
    displayedQuestionIds: PropTypes.array,
    setPrintLoading: PropTypes.func,
    displayAllQuestions: PropTypes.func
  };

  handlePrint = () => {
    const {setPrintLoading, displayAllQuestions} = this.props;
    setPrintLoading(true);
    displayAllQuestions();
    this.waitToPrint();
  };

  waitToPrint = () => {
    const {displayedQuestionCount, displayedQuestionIds, setPrintLoading} = this.props;
    if (displayedQuestionCount < displayedQuestionIds.length) {
      setTimeout(this.waitToPrint, 500);
    } else {
      setPrintLoading(false);
      window.print();
    }
  };

  render() {
    return <PageActionsContainer pageId="questions" printable={true} printOverride={this.handlePrint} />;
  }
}
export default QuestionsPageActions;