/*
 * Copyright 2018 General Code
 */
import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import {IconButton} from "../../common/components";
import {Note as NoteEditingIcon, NoteClose as NoteCloseIcon, NoteOpen as NoteOpenIcon } from "../../common/components/icons";
import {If} from "../../common/containers";
import "./NoteIcon.css";

const NoteIcon = ({toggle, open, editing}) => (
  <Fragment>
    <If test={editing}>
      <IconButton className="noteIcon" onClick={toggle} disabled={true}>
        <NoteEditingIcon/>
      </IconButton>
    </If>
    <If test={!editing}>
      <IconButton className="noteIcon" onClick={toggle}>
        {open ? <NoteCloseIcon/> : <NoteOpenIcon/>}
      </IconButton>
    </If>
  </Fragment>
);

NoteIcon.propTypes = {
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default NoteIcon;
