import React from "react";
import {If} from "../containers";
import {List, ListItem, ListItemIcon, ListItemText} from "./list";
import Paper from "./Paper";
import {
  Code as CodeIcon, NewLawNew as NewLawIcon, PubDoc as PubDocIcon, Help as HelpIcon,
  Link as LinkIcon, Phone as PhoneIcon, EmailAlt as EmailAltIcon, PinDrop as PinDropIcon,
  Questions as QuestionIcon, Person as PersonIcon
} from "./icons";
import "./HomePage.css";

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      // Code Info
      muniUrl: props.customization.muniUrl,
      customerName: props.customer.name,
      county: props.customer.county,
      govType: props.customer.govtype,

      // Graphical Links
      codeGraphicalLinkEnabled: props.customization.codeGraphicalLinkEnabled,
      lawsGraphicalLinkEnabled: props.customization.lawsGraphicalLinkEnabled,
      codifiedLawsGraphicalLinkEnabled: props.customization.codifiedLawsGraphicalLinkEnabled,
      helpGraphicalLinkEnabled: props.customization.helpGraphicalLinkEnabled,

      // Content Graphical Links
      contentGraphicalQuickLinks: props.contentGraphicalQuickLinks,

      // Textual Links
      textQuickLinks: props.textQuickLinks,

      // Contact Info
      website: props.customization.website,
      muniAddress: props.customization.muniAddress,
      muniContactPerson: props.customization.muniContactPerson,
      muniEmail: props.customization.muniEmail,
      muniPhone: props.customization.muniPhone,

      // Home Page Message
      homePageMessage: props.customization.homePageMessage,

      // Page Names
      codeLongName: props.customization.codeLongName,
      newLawsName: props.customization.newLawsName,
      lawLedgerName: props.customization.lawLedgerName,
      helpLongName: props.customization.helpLongName,
      questionName: props.customization.questionsLongName
    }
  }

  showGraphicalLinks = () => {
    const {codeGraphicalLinkEnabled, contentGraphicalQuickLinks, lawsGraphicalLinkEnabled,
      codifiedLawsGraphicalLinkEnabled, helpGraphicalLinkEnabled} = this.state;
    const {pubDocTypes, pubDocsOnly, hasViewPermission, analysisId} = this.props;

    // If the Help graphical quick link is enabled, show graphical quick links
    if (helpGraphicalLinkEnabled) {
      return true;
    }

    // If there is an Analysis, show the graphical quick links
    if (hasViewPermission && (typeof analysisId !== 'undefined') && (analysisId !== null)) {
      return true;
    }

    // PubDocs Standalone codes shouldn't display any code-related graphical quick links
    if (!pubDocsOnly) {
      // But if this isn't a PubDocs Standalone code, check if there are any code-related graphical quick links
      if (codeGraphicalLinkEnabled || lawsGraphicalLinkEnabled || codifiedLawsGraphicalLinkEnabled) {
        return true;
      }
      if (contentGraphicalQuickLinks?.some((link) => link.enabled)) {
        return true;
      }
    }

    // If any of the PubDocs types have graphical quick links enabled, display them
    if (pubDocTypes.some((type) => (type.graphicalLinkEnabled && type.count > 0))) {
      return true;
    }

    return false;
  };

  buildExternalLinkUrl = (url) => {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      return url;
    } else {
      return "https://" + url;
    }
  };

  renderBanner = (customer, customerName, county, govtype) => {
    return (
      <Paper id="top">
        <div id="muniName" className="homeMuniName">{customerName}</div>
        <If test={customer && county && (govtype?.toLowerCase() !== "county")}>
          <div id="countyName" className="homeCountyName">
            {county + " " + customer.subdivisionName}
          </div>
        </If>
      </Paper>
    );
  };

  render() {
    const {customer, custId, pubDocsOnly, hasViewPermission, hasLawViewPermission, lawLedgerCount,
      hasDocumentAdminPermission, hasPubDocTypeAddPermission, hasPubDocTypeDeletePermission,
      hasPubDocTypeEditPermission, pubDocTypes, archiveId, analysisId
    } = this.props;
    const {customerName, county, govtype, codeGraphicalLinkEnabled,lawsGraphicalLinkEnabled,
      codifiedLawsGraphicalLinkEnabled, helpGraphicalLinkEnabled, textQuickLinks, contentGraphicalQuickLinks,
      website, muniAddress, muniContactPerson, muniEmail, muniPhone, muniUrl, homePageMessage, codeLongName,
      newLawsName, lawLedgerName, helpLongName, questionName
    } = this.state;
    return(
      <div id="homeContainer">
        <If test={website}>
          <a href={website} id="websiteLink" target="_blank" rel="noopener noreferrer">
            {this.renderBanner(customer, customerName, county, govtype)}
          </a>
        </If>
        <If test={!website}>
          {this.renderBanner(customer, customerName, county, govtype)}
        </If>
        <If test={homePageMessage}>
          <Paper id="homePageMessage" className="homeContainer">
            <div dangerouslySetInnerHTML={{__html: homePageMessage}}/>
          </Paper>
        </If>
        <If test={this.showGraphicalLinks()}>
          <Paper id="graphicalLinks" className="homeContainer">
            <div className="graphicalLinkRow">
              <If test={codeGraphicalLinkEnabled && (!pubDocsOnly && hasViewPermission)}>
                <a className="graphicalLink" href={"/" + custId}>
                  <figure className="graphicalFigure">
                    <CodeIcon className={"graphicalImage home-page-icon"} size={48}/>
                    <figcaption className="graphicalCaption customerTitleColor">{codeLongName}</figcaption>
                  </figure>
                </a>
              </If>
              <If test={!pubDocsOnly && hasViewPermission && contentGraphicalQuickLinks?.some((link) => link.enabled)}>
                {contentGraphicalQuickLinks?.map((link) => link.enabled ? (
                  <a className="graphicalLink" href={"/" + link.guid}>
                    <figure className="graphicalFigure">
                      <CodeIcon className={"graphicalImage home-page-icon"} size={48}/>
                      <figcaption className="graphicalCaption customerTitleColor">{link.label}</figcaption>
                    </figure>
                  </a>
                ) : null)}
              </If>
              <If test={lawsGraphicalLinkEnabled && (!pubDocsOnly && hasViewPermission && hasLawViewPermission)}>
                <a className="graphicalLink" href={`/${custId}/laws`}>
                  <figure className="graphicalFigure">
                    <NewLawIcon className={"graphicalImage home-page-icon"} size={48}/>
                    <figcaption className="graphicalCaption customerTitleColor">{newLawsName}</figcaption>
                  </figure>
                </a>
              </If>
              <If test={codifiedLawsGraphicalLinkEnabled && (!pubDocsOnly && hasViewPermission && hasLawViewPermission && (lawLedgerCount !== 0))}>
                <a className="graphicalLink" href={`/${custId}/law-ledger`}>
                  <figure className="graphicalFigure">
                    <NewLawIcon className={"graphicalImage home-page-icon"} size={48}/>
                    <figcaption className="graphicalCaption customerTitleColor">{lawLedgerName}</figcaption>
                  </figure>
                </a>
              </If>
              <If test={(hasViewPermission && !archiveId &&
                (pubDocTypes.length !== 0 || hasDocumentAdminPermission || hasPubDocTypeEditPermission
                  || hasPubDocTypeAddPermission || hasPubDocTypeDeletePermission))}
              >
                {pubDocTypes.map((type) => (
                  <If test={type.graphicalLinkEnabled && type.count > 0} key={type.id}>
                    <a className="graphicalLink" href={"/" + custId + "/documents/" + type.title}>
                      <figure className="graphicalFigure">
                        <PubDocIcon className={"graphicalImage home-page-icon"} size={48}/>
                        <figcaption className="graphicalCaption customerTitleColor">{type.title}</figcaption>
                      </figure>
                    </a>
                  </If>
                ))}
              </If>
              <If test={hasViewPermission && analysisId}>
                <a className="graphicalLink" href={"/" + custId + "/questions/status"}>
                  <figure className="graphicalFigure">
                    <QuestionIcon className={"graphicalImage home-page-icon"} size={48}/>
                    <figcaption className="graphicalCaption customerTitleColor">{questionName}</figcaption>
                  </figure>
                </a>
              </If>
              <If test={helpGraphicalLinkEnabled}>
                <a className="graphicalLink" href={"/help/" + custId}>
                  <figure className="graphicalFigure">
                    <HelpIcon className={"graphicalImage home-page-icon"} size={48}/>
                    <figcaption className="graphicalCaption customerTitleColor">{helpLongName}</figcaption>
                  </figure>
                </a>
              </If>
            </div>
          </Paper>
        </If>
        <div className="homeHalfPage">
          <If test={textQuickLinks && textQuickLinks.length > 0}>
            <Paper id="quickLinks" className="homeContainer">
              <span className="largeTitle homeTitle">Quick Links</span>
              <List>
                {textQuickLinks.map((textQuickLink) => (
                  <ListItemLink
                    key={textQuickLink.id}
                    href={textQuickLink.textualQuickLinkType === "INTERNAL" ? textQuickLink.url : this.buildExternalLinkUrl(textQuickLink.url)}
                    className={`textualQuickLink ${textQuickLink.textualQuickLinkType === "INTERNAL" ? "internal" : "external"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemIcon>
                      <LinkIcon/>
                    </ListItemIcon>
                    <ListItemText>
                      {textQuickLink.title}
                    </ListItemText>
                  </ListItemLink>
                ))}
              </List>
            </Paper>
          </If>
          <If test={(muniAddress || muniContactPerson || muniEmail || muniPhone || muniUrl)}>
            <Paper id="contactInfo" className="contactInfoPaper homeContainer">
              <span className="largeTitle homeTitle">Contact Information</span>
              <List>
                <If test={muniContactPerson}>
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon/>
                    </ListItemIcon>
                    <ListItemText primary={muniContactPerson}/>
                  </ListItem>
                </If>
                <If test={muniPhone}>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={muniPhone}/>
                  </ListItem>
                </If>
                <If test={muniEmail}>
                  <ListItemLink href={"mailto:" + muniEmail}>
                    <ListItemIcon>
                      <EmailAltIcon/>
                    </ListItemIcon>
                    <ListItemText primary={muniEmail}/>
                  </ListItemLink>
                </If>
                <If test={muniAddress}>
                  <ListItem>
                    <ListItemIcon>
                      <PinDropIcon/>
                    </ListItemIcon>
                    <ListItemText primary={muniAddress}/>
                  </ListItem>
                </If>
                <If test={muniUrl}>
                  <ListItemLink href={this.buildExternalLinkUrl(muniUrl)}>
                    <ListItemIcon>
                      <LinkIcon/>
                    </ListItemIcon>
                    <ListItemText primary={muniUrl}/>
                  </ListItemLink>
                </If>
              </List>
            </Paper>
          </If>
        </div>
    </div>
    );
  }
}

export default HomePage;