/*
 * Copyright 2018 General Code
 */

import React, {Component} from "react";
import * as PropTypes from "prop-types";
import * as dateMath from "date-arithmetic";
import {Dialog} from "../../common/components/dialog";
import {withCookies, Cookies} from "react-cookie";
import If from "../../common/containers/If";
import {custId} from "../../common/utils/server-data";

import "./QuestionsSidebar.css";

class QuestionsSidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      analysisName: props.customization.analysisName
    }
  }

  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    isQuestionsPage: PropTypes.bool,
    filters: PropTypes.shape({
      needsReviewFilter: PropTypes.bool,
      assignedFilter: PropTypes.bool,
      assignedToFilter: PropTypes.string,
      deferrableFilter: PropTypes.bool,
      statusFilter: PropTypes.string,
      filterText: PropTypes.string,
      deferFilter: PropTypes.bool
    }),
    questions: PropTypes.object.isRequired,
    currentUser: PropTypes.string,
    filterQuestions: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    hasFinalizePermission: PropTypes.bool.isRequired,
    checkReminderIntervalDays: PropTypes.number,
    analysisId: PropTypes.number
  };

  handleCheckReminderCancel = () => {
    const {cookies} = this.props;
    cookies.set(`lastCheckReminder-` + custId, new Date(), {'path':'/'});
  };

  handleCheckReminderCheck = () => {
    const {analysisId, cookies, isQuestionsPage, checkAnalysis} = this.props;
    if (isQuestionsPage) {
      checkAnalysis(analysisId);
    } else {
      cookies.set(`autoCheck-` + custId, true, {'path':'/'});
      window.location.href = "/" + custId + "/questions";
    }
  };

  handleAllQuestionsClick = (e) => {
    const {isQuestionsPage, clearFilters} = this.props;
    if (isQuestionsPage) {
      e.preventDefault();
      clearFilters();
    }
  };

  handleMyQuestionsClick = (e) => {
    const {isQuestionsPage, filterQuestions, currentUser} = this.props;
    if (isQuestionsPage) {
      e.preventDefault();
      filterQuestions({assignedToFilter: currentUser, statusFilter: "INCOMPLETE"});
    }
  };

  handleNeedsReviewClick = (e) => {
    const {isQuestionsPage, filterQuestions} = this.props;
    if (isQuestionsPage) {
      e.preventDefault();
      filterQuestions({needsReviewFilter: true});
    }
  };

  handleIncompleteClick = (e) => {
    const {isQuestionsPage, filterQuestions} = this.props;
    if (isQuestionsPage) {
      e.preventDefault();
      filterQuestions({statusFilter: "INCOMPLETE"});
    }
  };

  render() {
    const {analysis, cookies, isQuestionsPage, filters, questions, currentUser, hasFinalizePermission} = this.props;
    const {analysisName} = this.state;

    let showMyQuestions = false;
    let myQuestionsCount = 0;
    let needsReviewCount = 0;
    let incompleteCount = 0;
    for (const questionId in questions) {
      const question = questions[questionId];
      if (question.assignedTo === currentUser) {
        showMyQuestions = true;
      }
      if (!question.complete) {
        if (question.assignedTo === currentUser) {
          myQuestionsCount++;
        }
        incompleteCount++;
      }
      if (question.needsReview) {
        needsReviewCount++;
      }
    }

    let readyToCheck = (incompleteCount + needsReviewCount) === 0;

    // Not on the questions page
    let selectedTab = 0;
    let analysisChecked = false;
    if (isQuestionsPage) {
      if (filters.assignedToFilter === currentUser && filters.statusFilter === "INCOMPLETE") {
        // My Questions
        selectedTab = 2;
      } else if (filters.needsReviewFilter === true) {
        // Needs Review
        selectedTab = 3;
      } else if (filters.statusFilter === "INCOMPLETE") {
        // Incomplete
        selectedTab = 4;
      } else {
        // All Questions
        selectedTab = 1;
      }
      analysisChecked = analysis.checked;
    } else {
      analysisChecked = window.SERVER_DATA.analysisChecked;
    }

    const timeToRemind = (!cookies.get(`lastCheckReminder-` + custId, {'path':'/'}) ||
      //if now is after the end of the last day we showed this
      dateMath.gt(new Date(), dateMath.startOf(dateMath.add(new Date(cookies.get(`lastCheckReminder-` + custId, {'path':'/'})), window.SERVER_DATA.checkReminderIntervalDays, 'day'), "day")));

    return (
      <div>
        <Dialog
          title={"Ready to Verify " + analysisName}
          open={timeToRemind && readyToCheck && hasFinalizePermission && !analysisChecked && !cookies.get(`autoCheck-` + custId, {'path':'/'})}
          onCancel={this.handleCheckReminderCancel}
          onSubmit={this.handleCheckReminderCheck}
          submitLabel="Check"
          width="md"
        >
            <div>
              All of your questions are now complete.  Click the “Check” button to verify your analysis.
              Once verified, click the “Submit” button to send your complete analysis back to General Code.
            </div>
        </Dialog>
        <div className="sidebarSpacer lineTitle customerTitleColor">
          <div className="lineTitleBeforeLine lineTitleLine"/>
          <div className="lineTitleTitle">Questions</div>
          <div className="lineTitleAfterLine lineTitleLine"/>
        </div>
        <div className="sidebar">
          <a href={(custId ? "/" + custId : "") + "/questions"} onClick={this.handleAllQuestionsClick} className={"sideButton" + (selectedTab === 1 ? " selectedSide" : "")}>
            <If test={selectedTab === 1}>
              <div id="sideSelectionIndicator"/>
            </If>
            All Questions ({Object.keys(questions).length})
            <If test={selectedTab === 1}>
              <div id="sideArrowShadow"/>
              <div id="sideArrow"/>
            </If>
          </a>
          <If test={showMyQuestions}>
            <a href={(custId ? "/" + custId : "") + "/questions?myQuestions=true"} onClick={this.handleMyQuestionsClick} className={"sideButton" + (selectedTab === 2 ? " selectedSide" : "")}>
              <If test={selectedTab === 2}>
                <div id="sideSelectionIndicator"/>
              </If>
              My Questions ({myQuestionsCount})
              <If test={selectedTab === 2}>
                <div id="sideArrowShadow"/>
                <div id="sideArrow"/>
              </If>
            </a>
          </If>
          <a href={(custId ? "/" + custId : "") + "/questions?needsReview=true"} onClick={this.handleNeedsReviewClick} className={"sideButton" + (selectedTab === 3 ? " selectedSide" : "")}>
            <If test={selectedTab === 3}>
              <div id="sideSelectionIndicator"/>
            </If>
            Needs Review ({needsReviewCount})
            <If test={selectedTab === 3}>
              <div id="sideArrowShadow"/>
              <div id="sideArrow"/>
            </If>
          </a>
          <a href={(custId ? "/" + custId : "") + "/questions?status=INCOMPLETE"} onClick={this.handleIncompleteClick} className={"sideButton" + (selectedTab === 4 ? " selectedSide" : "")}>
            <If test={selectedTab === 4}>
              <div id="sideSelectionIndicator"/>
            </If>
            Incomplete ({incompleteCount})
            <If test={selectedTab === 4}>
              <div id="sideArrowShadow"/>
              <div id="sideArrow"/>
            </If>
          </a>
        </div>
      </div>
    );
  }
}
export default withCookies(QuestionsSidebar);