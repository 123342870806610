/*
 * Copyright 2018 General Code
 */

import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import PageTitleContainer from "../../common/containers/PageTitleContainer";
import PubDocsPageActionsContainer from "../containers/PubDocsPageActionsContainer";

class PubDocsPageTitle extends Component {

  static propTypes = {
    pubDocTypeTitle: PropTypes.string,
    isNewNav: PropTypes.bool.isRequired
  };

  render() {
    const {pubDocTypeTitle, isNewNav} = this.props;
    return isNewNav ?
      (<PageTitleContainer pageTitle={pubDocTypeTitle} />) :
      (<PageTitleContainer pageTitle={pubDocTypeTitle}>
        <PubDocsPageActionsContainer pageId="pubDocType" pageTitle={pubDocTypeTitle} />
      </PageTitleContainer>);
  }
}
export default PubDocsPageTitle;