import {connect} from 'react-redux';
import {compose, branch, renderNothing} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {getEditingLaw, getEditingId} from "../selectors";
import {cancelEditing, saveEditing} from "../actions";
import {toJS} from "../../common/utils";
import EditLawDialog from '../components/EditLawDialog';


//use a key so when the editing ID changes the component gets reconstructed with new initial values
const mapStateToProps = createStructuredSelector({
  law: getEditingLaw,
  key: getEditingId,
});

const mapDispatchToProps = (dispatch) => ({
  cancelEditing: () => {
    return dispatch(cancelEditing());
  },
  saveEditing: (documentId, title, comment) => {
    return dispatch(saveEditing(documentId, title, comment));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), branch(({law}) => law === null, renderNothing), toJS)(EditLawDialog);

