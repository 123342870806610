import React, {useRef, useEffect, useCallback} from "react";
import {connect} from "react-redux";
import {useCookies} from "react-cookie";
import {getMediaWidth, getPageFilterCount} from "../../selectors";
import {mediaWidths} from "../../reducers";
import {Dialog, PrimaryButton, SlideTransition, Typography} from "../index";
import {If} from "../../containers";

import "./ResponsivePageControls.css";

const ResponsivePageControls = ({className, drawerClass, children, mediaWidth, pageFilterCount}) => {

  const pageControlRefs = useRef([]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [pageControlsExpanded, setPageControlsExpanded] = React.useState(false);
  const [cookies, setCookie] = useCookies(["pageControlsExpanded"]);

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => setDrawerOpen(false);

  const togglePageControls = () => {
    if (pageControlsExpanded) {
      collapsePageControls();
    } else {
      expandPageControls();
    }
  };

  const expandPageControls = useCallback(() => {
    if (mediaWidth > mediaWidths.medium) {
      const pageGridElement = document.getElementById("page-grid");
      pageGridElement.classList.remove("page-controls-collapsed");
      pageGridElement.classList.add("page-controls-expanded");
      setCookie("pageControlsExpanded", true, {"path": "/"});
    }
    setPageControlsExpanded(true);
  }, [mediaWidth, setCookie]);

  const collapsePageControls = useCallback(() => {
    if (mediaWidth > mediaWidths.medium) {
      const pageGridElement = document.getElementById("page-grid");
      pageGridElement.classList.remove("page-controls-expanded");
      pageGridElement.classList.add("page-controls-collapsed");
      setCookie("pageControlsExpanded", false, {"path": "/"});
    }
    setPageControlsExpanded(false);
  }, [mediaWidth, setCookie]);

  const onDrawerChildClick = (e) => {
    if (e.target && e.target.closest) {
      const clickedLink = e.target.closest("a");
      if (clickedLink) {
        const href = clickedLink.getAttribute("href");
        if (href) {
          closeDrawer();
        }
      }
    }
  };

  useEffect(() => {
    if (mediaWidth > mediaWidths.medium) {
      if (cookies.pageControlsExpanded == null || cookies.pageControlsExpanded === "true") {
        expandPageControls();
      } else {
        collapsePageControls();
      }
    } else {
      collapsePageControls();
    }
    const pageGridEl = document.getElementById("page-grid");
    if (pageGridEl) {
      const onAnimationEnd = (e) => {
        // If the screen is "Large", the content is going to change in width when collapsed/expanded
        if (e.propertyName === "grid-template-columns" && pageControlRefs?.current
          && mediaWidth > mediaWidths.medium && mediaWidth < mediaWidths.wide) {
          for (let pageControlRef of pageControlRefs.current) {
            if (pageControlRef.onContentSizeChange) {
              pageControlRef.onContentSizeChange();
            }
          }
        }
      }
      pageGridEl.addEventListener("transitionend", onAnimationEnd);
      return () => pageGridEl.removeEventListener("transitionend", onAnimationEnd);
    }
  }, [mediaWidth, collapsePageControls, expandPageControls, cookies.pageControlsExpanded]);

  const cloneChildren = () => {
    return children.map((child, i) =>
      React.cloneElement(child, { ref: (ref) => (pageControlRefs.current[i] = ref) })
    );
  }

  if (!children || children.length < 1) {
    return null;
  } else if (mediaWidth <= mediaWidths.medium) {
    return (
      <div id="page-controls-wrapper" className={className}>
        <div id="page-controls-expand-wrapper">
          <button
            id="page-controls-expand-button"
            className="iconButton"
            type="button"
            title="Expand Page Controls"
            rel="nofollow"
            onMouseDown={e => e.preventDefault()}
            onClick={openDrawer}
          >
            <span
              className="material-icons material-symbols-outlined md-24 notranslate material-symbols-and-filled"
              translate="no"
            />
          </button>
          <div id="page-controls-edge"></div>
          <If test={pageFilterCount > 0}>
            <span id="page-controls-filter-badge">{pageFilterCount}</span>
          </If>
        </div>
        <Dialog
          id="responsiveContentPanelDrawer"
          open={drawerOpen}
          TransitionComponent={SlideTransition}
          onClose={closeDrawer}
          fullScreen={true}
          DialogContentProps={{style: {padding: 0}}}
          keepMounted
          actions={[]}
        >
          <div onClick={onDrawerChildClick} className={"responsiveContentPanelDrawerContents"  + (drawerClass ? (" " + drawerClass) : "")}>
            <div className="stickyCloseContainer">
              <span className="stickyCloseContainerLabel">
                <Typography variant="title" internalDeprecatedVariant>Page Controls</Typography>
              </span>
              <PrimaryButton onClick={closeDrawer}>Close</PrimaryButton>
            </div>
            {cloneChildren()}
          </div>
        </Dialog>
      </div>
    );
  }
  return (
    <>
      <div id="page-controls-expand-wrapper">
        <button
          id="page-controls-expand-button"
          className="iconButton"
          type="button"
          title={pageControlsExpanded ? "Collapse Page Controls" : "Expand Page Controls"}
          rel="nofollow"
          onMouseDown={e => e.preventDefault()}
          onClick={togglePageControls}
        >
          <span
            className="material-icons material-symbols-outlined md-24 notranslate material-symbols-and-filled"
            translate="no"
          />
        </button>
        <div id="page-controls-edge"></div>
        <If test={pageFilterCount > 0}>
          <span id="page-controls-filter-badge">{pageFilterCount}</span>
        </If>
      </div>
      <div id="page-controls-wrapper" className={className}>
        {cloneChildren()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  mediaWidth: getMediaWidth(state),
  pageFilterCount: getPageFilterCount(state)
});

export default connect(mapStateToProps)(ResponsivePageControls);