import React from "react";
import ReactDOM from "react-dom";
import {List, Map} from "immutable";
import PubDocsPageActionsContainer from "./containers/PubDocsPageActionsContainer";
import * as actions from "./actions";
import {initializeState, loadFilters} from "./actions";
import PubDocTypeContainer from "./containers/PubDocTypeContainer";
import PubDocsSidebarContainer from "./containers/PubDocsSidebarContainer";
import {ModuleKey} from "./ModuleKey";
import reducer, {initialState, PubDocType} from "./reducers";
import * as selectors from "./selectors";
import {getQueryParamValue} from "../common/utils/query-params";
import {custId, newNav, pageType, pageSubType} from "../common/utils/server-data";
import {PUBLIC_DOCUMENTS as PUBLIC_DOCUMENTS_PAGE} from "../common/utils/page-types";
import {PUBLIC_DOCUMENTS_LANDING as PUBLIC_DOCUMENTS_LANDING_SUBPAGE} from "../common/utils/page-sub-types";
import typeId from "./utils/type-id";
import {setPageFilters} from "../common/actions";
import PubDocsLandingPage from "./components/PubDocsLandingPage";
import "nodelist-foreach-polyfill";

export const getInitialState = (loadingState) => {
  if (loadingState == null) {
    throw new Error("Trying to load a null state");
  }
  let docsState = loadingState.get(ModuleKey) || initialState;
  return loadingState.set(ModuleKey, docsState.withMutations(loadingDocsState => {
    return loadingDocsState
      .set("pubDocTypeId", typeId)
      .set("pubDocTypes", window.SERVER_DATA.pubDocTypes ? List(window.SERVER_DATA.pubDocTypes.map(pdt => PubDocType(pdt))) : List())
      .set("suggestedPubDocTypes", window.SERVER_DATA.suggestedPubDocTypes ? Map(window.SERVER_DATA.suggestedPubDocTypes) : Map())
      .set("pubDocsSidebarName", window.SERVER_DATA.pubDocsSidebarName)
      .set("documents", List());
  }));
};

/**
 * @param {Component} Providers - Provider wrapper to use on created elements
 * @param {function} dispatch - Dispatch function for redux
 */
const init = (Providers, dispatch) => {
  if (window?.SERVER_DATA?.newPubDocs) {

    let pubDocTypeContainer = document.getElementById("pubDocTypeContainer");
    if (pubDocTypeContainer) {

      // Populate a PubDocs type page
      let contentContainer = document.getElementById("contentContainer");
      ReactDOM.render(
        <Providers key="pub-docs-providers" name="pub-docs">
          <PubDocTypeContainer/>
        </Providers>,
        contentContainer
      );

      // Set up the history handler for filtering
      window.addEventListener("popstate",  handleHistoryStateChange(dispatch, custId, typeId));

      // Load the initial state
      const selectedFilters = {
        category: getQueryParamValue("category"),
        year: getQueryParamValue("year"),
        month: getQueryParamValue("month"),
        day: getQueryParamValue("day")
      }
      const filterState = {selectedFilters};
      dispatch(initializeState(filterState, true));
      dispatch(loadFilters(custId, typeId));
      dispatch(setPageFilters(["category", "year", "month", "day"], selectedFilters));

    } else if (newNav && pageSubType === PUBLIC_DOCUMENTS_LANDING_SUBPAGE) {

      // Populate the PubDocs landing page
      let contentContainer = document.getElementById("contentContainer");
      ReactDOM.render(
        <Providers key="pub-docs-providers" name="pub-docs">
          <PubDocsLandingPage/>
        </Providers>,
        contentContainer
      );

    }

    // Populate the Page Controls
    let pubDocsSidebar = document.getElementById("pubDocsSidebar");
    if (pubDocsSidebar) {
      ReactDOM.render(
        <Providers key="pub-docs-sidebar-providers" name="pub-docs-sidebar">
          <PubDocsSidebarContainer/>
        </Providers>,
        pubDocsSidebar
      );
    }

    // Populate the Page Actions
    if (newNav && pageType === PUBLIC_DOCUMENTS_PAGE) {
      const pageActions = document.getElementById("pageActions");
      if (pageActions) {
        ReactDOM.render(
          <Providers key="pub-docs-page-actions-providers" name="pub-docs-page-actions">
            <PubDocsPageActionsContainer/>
          </Providers>,
          pageActions
        );
      }
    }
  }
};
const handleHistoryStateChange = (dispatch, custId, typeId) => (event) => {
  if (event.state) {
    dispatch(initializeState(event.state, false));
    dispatch(loadFilters(custId, typeId));
  }
};

/**
 * Name of the module for use in reducers and selectors
 * @type {string}
 */
export {ModuleKey} from "./ModuleKey";

export {
  init,
  actions,
  reducer,
  selectors
};
