import React, {Component} from "react";
import uniq from "lodash/uniq";
import * as PropTypes from "prop-types";
import {Dialog, Typography} from "../";

class ErrorDialog extends Component {

  static propTypes = {
    errorMessages: PropTypes.array,
    errorTitles: PropTypes.array,
    errorRedirectUrl: PropTypes.string,
    dismissErrors: PropTypes.func.isRequired
  };

  handleClose = (dismissErrors, redirectUrl) => () => {
    dismissErrors();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  render() {
    const {errorMessages, errorTitles, errorRedirectUrl, dismissErrors} = this.props;
    const title = (!errorTitles || errorTitles.length === 0) ? "There was an unexpected error - please try again" : errorTitles[0];
    return (
      <div>
        <Dialog
          className="errorDialog"
          open={errorMessages.length > 0}
          onClose={this.handleClose(dismissErrors, errorRedirectUrl)}
          title={title}
        >
            {uniq(errorMessages).map((errorMessage) => (
              <Typography key={errorMessage}>{errorMessage}</Typography>
            ))}
        </Dialog>
      </div>
    );
  }
}

export default ErrorDialog;