import * as PropTypes from 'prop-types';
import React from 'react';

const referenceStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'REFERENCE'
      );
    },
    callback,
  );
};

const ReferenceComponent = ({className, children, entityKey, contentState}) => {
  const {url, targetOption} = contentState.getEntity(entityKey).getData();
  return (
    <span className={"rdw-link-decorator-wrapper " + className}>
      <a href={url} target={targetOption}>{children}</a>
    </span>
  );
};

ReferenceComponent.propTypes = {
  entityKey: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.array,
  contentState: PropTypes.object,
};

export {referenceStrategy, ReferenceComponent};
