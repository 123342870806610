import * as PropTypes from "prop-types";
import React, {Fragment} from "react";
import If from "../../common/containers/If";
import HelpButton from "./HelpButton";
import IconButton from "./././button/IconButton";
import VideoButton from "./VideoButton";
import {Email as EmailIcon, Print as PrintIcon} from "./icons";
import {CircularProgress} from "./progress";
import './PageTitle.css';

class PageActions extends React.PureComponent {

  static propTypes = {
    pageId: PropTypes.string.isRequired,
    printable: PropTypes.bool,
    printOverride: PropTypes.func,
    isPrintLoading: PropTypes.bool,
    emailSubject: PropTypes.string,
    helpTopics: PropTypes.object,
    helpVideos: PropTypes.object
  };

  handlePrint = () => {
    const {printOverride} = this.props;
    if (printOverride) {
      printOverride();
    } else {
      window.print();
    }
  };

  handleEmail = (emailSubject) => () => {
    window.open("mailto:?subject=" + emailSubject + "&body=" + encodeURIComponent(window.location.href), "_self");
  };

  render() {
    const { pageId, printable, isPrintLoading, emailSubject, helpTopics, helpVideos, children } = this.props;
    return (
      <Fragment>
        <If test={emailSubject}>
          <IconButton aria-label="Email Action" onClick={this.handleEmail(emailSubject)}
                      className="emailAction iconButton">
            <EmailIcon/>
          </IconButton>
        </If>
        <If test={printable && !isPrintLoading}>
          <IconButton aria-label="Print Action" onClick={this.handlePrint} className="printAction iconButton"
                      rel="nofollow">
            <PrintIcon/>
          </IconButton>
        </If>
        <If test={printable && isPrintLoading}>
          <div className="print-spinner"><CircularProgress size={20}/></div>
        </If>
        <If test={helpVideos[pageId]}>
          <VideoButton url={helpVideos[pageId]}/>
        </If>
        <If test={helpTopics[pageId]}>
          <HelpButton topic={helpTopics[pageId]}/>
        </If>
        {children}
      </Fragment>
    );
  }
}

export default PageActions;