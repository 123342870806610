import './listener';

const updateHistory = method => (data, title, url) => {

  const previousUrl = window.location.href;
  const newUrlEvent = new Event("newurl");
  newUrlEvent.data = data;
  newUrlEvent.title = title;
  newUrlEvent.url = url;
  window.history[method](data, title, url);
  window.dispatchEvent(newUrlEvent);

  // If we're pushing a new page into the history (as opposed to just updating the current page),
  // and the page is different, trigger a page_view event in Google Analytics
  if (window && window.generalcode && window.generalcode.logAnalyticsEvent && method === "pushState" && previousUrl !== url) {
    window.generalcode.logAnalyticsEvent("page_view", {
      page_title: document.title,
      page_location: window.location.href,
      page_referrer: document.referrer,
      page_path: window.location.pathname
    });
  }
};

export const pushState = updateHistory("pushState");
export const replaceState = updateHistory("replaceState");
