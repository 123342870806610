import * as PropTypes from "prop-types";
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDocTypeEditDialog from "../components/PubDocTypeEditDialog";
import {
  getSuggestedPubDocTypes,
  getTypeEditId,
  getTypeEditOpen,
  getPubDocTypes,
  getTypeEditLoading
} from "../selectors";
import {addPubDocType, editPubDocType} from "../actions";
import {getCustId} from "../../common/selectors";

const mapStateToProps = createStructuredSelector({
  suggestedPubDocTypes: getSuggestedPubDocTypes,
  typeEditOpen: getTypeEditOpen,
  pubDocTypes: getPubDocTypes,
  typeEditId: getTypeEditId,
  custId: getCustId,
  typeEditLoading: getTypeEditLoading
});

const mapDispatchToProps = (dispatch) => ({
  addType: (custId, name, description, sortType) => {
    dispatch(addPubDocType(custId, name, description, sortType));
  },
  editType: (custId, name, description, typeId, sortType) => {
    dispatch(editPubDocType(custId, name, description, typeId, sortType));
  },
});

const PubDocTypeEditDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocTypeEditDialog);
PubDocTypeEditDialogContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
export default PubDocTypeEditDialogContainer;