import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {
  setPubDocCategoryEditOpen,
  setPubDocEditOpen,
  setDocumentMoveOpen,
  setCategoryDeleteOpen,
  setCategoryDeleteErrorOpen, deletePubDocCategory, setPubDocTypeDeleteOpen, deletePubDocType
} from "../actions";
import {
  getPubDocTypeId,
  getPubDocTypeTitle,
  getTypeDeleteId,
  getTypeDeleteOpen,
  getCategoryDeleteId,
  getCategoryDeleteOpen,
  getCategoryDeleteErrorOpen,
  getCategories,
  getTypeEditId
} from "../selectors";
import {getCustId, hasPubDocTypeDeletePermission, hasBulkUploadPermission} from "../../common/selectors";
import PubDocDialogs from "../components/PubDocDialogs";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  pubDocTypeId: getPubDocTypeId,
  pubDocTypeTitle: getPubDocTypeTitle,
  hasPubDocTypeDeletePermission: hasPubDocTypeDeletePermission,
  hasBulkUploadPermission: hasBulkUploadPermission,
  typeEditId: getTypeEditId,
  typeDeleteId: getTypeDeleteId,
  typeDeleteOpen: getTypeDeleteOpen,
  categoryDeleteId: getCategoryDeleteId,
  categoryDeleteOpen: getCategoryDeleteOpen,
  categoryDeleteErrorOpen: getCategoryDeleteErrorOpen,
  categories: getCategories
});

const mapDispatchToProps = (dispatch) => ({
  setCategoryEditOpen: (open, categoryId, typeId) => {
    dispatch(setPubDocCategoryEditOpen(open, categoryId, typeId));
  },
  setDocumentEditOpen: (open, documentId, documentCategoryId) => {
    dispatch(setPubDocEditOpen(open, documentId, documentCategoryId, null));
  },
  setDocumentMoveOpen: (open) => {
    dispatch(setDocumentMoveOpen(open, null));
  },
  setTypeDeleteOpen: (open, typeId) => {
    dispatch(setPubDocTypeDeleteOpen(open, typeId));
  },
  deleteType: (custId, typeId) => {
    dispatch(deletePubDocType(custId, typeId));
  },
  setCategoryDeleteOpen: (open, categoryId) => {
    dispatch(setCategoryDeleteOpen(open, categoryId));
  },
  deleteCategory: (custId, categoryId) => {
    dispatch(deletePubDocCategory(custId, categoryId));
  },
  setCategoryDeleteErrorOpen: (open) => {
    dispatch(setCategoryDeleteErrorOpen(open));
  }
});

const PubDocDialogsContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocDialogs);
export default PubDocDialogsContainer;