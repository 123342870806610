import {connect} from 'react-redux';
import {compose} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";

import ScopeSelector from "../components/ScopeSelector";
import {isNewNav} from "../../common/selectors";
import {getScope, getTotalResultCount, getScopeTypes} from "../selectors";
import {setScopeAndGetResults} from "../actions";

const mapStateToProps = createStructuredSelector({
  scope: getScope,
  totalResultCount: getTotalResultCount,
  scopeTypes: getScopeTypes,
  isNewNav: isNewNav
});

const mapDispatchToProps = (dispatch) => ({
  setScope: (scope) => {
    dispatch(setScopeAndGetResults(scope));
  }
});
export default compose(connect(mapStateToProps, mapDispatchToProps), toJS)(ScopeSelector);