import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import SelectReferenceDialog from "../components/SelectReferenceDialog";
import {clearActiveReferenceNode, fetchRootReferenceNodes, fetchReferenceMatches, toggleReferenceNode} from "../actions/references";
import {getReferenceMatches, getReferenceNodes, getAreMatchesLoading} from "../selectors/references";
import {compose} from "recompose";
import {toJS} from "../utils";
import * as PropTypes from "prop-types";

const mapStateToProps = createStructuredSelector({
  nodes: getReferenceNodes,
  matches: getReferenceMatches,
  areMatchesLoading: getAreMatchesLoading
});

const mapDispatchToProps = (dispatch, {groupName}) => ({
  fetchRootReferenceNodes: () => dispatch(fetchRootReferenceNodes(groupName)),
  fetchReferenceMatches: (query) => dispatch(fetchReferenceMatches(groupName, query)),
  toggleReferenceNode: (node, toggled) => dispatch(toggleReferenceNode(groupName, node, toggled)),
  clearActiveReferenceNode: () => dispatch(clearActiveReferenceNode(groupName))
});

const SelectReferenceDialogContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(SelectReferenceDialog);
SelectReferenceDialogContainer.propTypes = {
  open: PropTypes.bool,
  groupName: PropTypes.string,
  selectedGroupName: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  fetchNodes: PropTypes.bool
};
export default SelectReferenceDialogContainer;
