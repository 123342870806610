import CharacterMetadata from 'draft-js/lib/CharacterMetadata';
import ContentBlock from 'draft-js/lib/ContentBlock';
import Entity from 'draft-js/lib/DraftEntity';
import genKey from 'draft-js/lib/generateRandomKey';
import {List, Map, OrderedMap, OrderedSet} from 'immutable';
import {createTextChunk, getAtomicBlockChunk, getBlockDividerChunk, getEmptyChunk, getFirstBlockChunk, getSoftNewlineChunk, joinChunks,} from './chunkBuilder';
import getBlockData from './getBlockData';
import getBlockTypeForTag from './getBlockTypeForTag';
import getEntityId from './getEntityId';
import getSafeBodyFromHTML from './getSafeBodyFromHTML';
import processInlineTag from './processInlineTag';

const SPACE = ' ';
const REGEX_NBSP = new RegExp('&nbsp;', 'g');

let firstBlock = true;

function genFragment(
  node,
  inlineStyle,
  depth,
  lastList,
  inEntity
) {
  const nodeName = node.nodeName.toLowerCase();

  if (nodeName === '#text' && node.textContent !== '\n') {
    return createTextChunk(node, inlineStyle, inEntity);
  }

  if (nodeName === 'br') {
    return {chunk: getSoftNewlineChunk()};
  }

  if (
    nodeName === 'img' &&
    node instanceof HTMLImageElement
  ) {
    return {chunk: {
      text: ' ',
      inlines: [inlineStyle],
      entities: [inEntity],
      blocks: [],
    }};
  }

  if (
    nodeName === 'iframe' &&
    node instanceof HTMLIFrameElement
  ) {
    const entityConfig = {};
    entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
    entityConfig.height = node.height;
    entityConfig.width = node.width;
    const entityId = Entity.__create(
      'EMBEDDED_LINK',
      'MUTABLE',
      entityConfig,
    );
    return {chunk: getAtomicBlockChunk(entityId)};
  }

  const blockType = getBlockTypeForTag(nodeName, lastList);

  let chunk;
  if (blockType) {
    if (nodeName === 'ul' || nodeName === 'ol') {
      lastList = nodeName;
      depth += 1;
    } else {
      if (
        blockType !== 'unordered-list-item' &&
        blockType !== 'ordered-list-item'
      ) {
        lastList = '';
        depth = -1;
      }
      if (!firstBlock) {
        chunk = getBlockDividerChunk(
          blockType,
          depth,
          getBlockData(node)
        );
      } else {
        chunk = getFirstBlockChunk(
          blockType,
          getBlockData(node)
        );
        firstBlock = false;
      }
    }
  }
  if (!chunk) {
    chunk = getEmptyChunk();
  }

  inlineStyle = processInlineTag(nodeName, node, inlineStyle);

  let child = node.firstChild;
  while (child) {
    const entityId = getEntityId(child);
    const {chunk: generatedChunk} = genFragment(child, inlineStyle, depth, lastList, (entityId || inEntity));
    chunk = joinChunks(chunk, generatedChunk);
    child = child.nextSibling;
  }
  return {chunk};
}

function getChunkForHTML(html) {
  const sanitizedHtml = html.trim().replace(REGEX_NBSP, SPACE);
  const safeBody = getSafeBodyFromHTML(sanitizedHtml);
  if (!safeBody) {
    return null;
  }
  firstBlock = true;
  const {chunk} = genFragment(safeBody, new OrderedSet(), -1, '', undefined);
  return {chunk};
}

export default function htmlToDraft(html) {
  const chunkData = getChunkForHTML(html);
  if (chunkData) {
    const {chunk} = chunkData;
    let entityMap = new OrderedMap({});
    chunk.entities && chunk.entities.forEach(entity => {
      if (entity) {
        entityMap = entityMap.set(entity, Entity.__get(entity));
      }
    });
    let start = 0;
    return {
      contentBlocks: chunk.text.split('\r')
        .map(
          (textBlock, ii) => {
            const end = start + textBlock.length;
            const inlines = chunk && chunk.inlines.slice(start, end);
            const entities = chunk && chunk.entities.slice(start, end);
            const characterList = new List(
              inlines.map((style, index) => {
                const data = {style, entity: null};
                if (entities[index]) {
                  data.entity = entities[index];
                }
                return CharacterMetadata.create(data);
              }),
            );
            start = end;
            return new ContentBlock({
              key: genKey(),
              type: (chunk && chunk.blocks[ii] && chunk.blocks[ii].type) || 'unstyled',
              depth: chunk && chunk.blocks[ii] && chunk.blocks[ii].depth,
              data: (chunk && chunk.blocks[ii] && chunk.blocks[ii].data) || new Map({}),
              text: textBlock,
              characterList,
            });
          },
        ),
      entityMap,
    };
  }
  return null;
}
