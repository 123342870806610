import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import './ButtonGroup.css';

const ButtonGroup = ({className, ...props}) =>
  <div className={classNames(['buttonGroup',className])} {...props}/>;

ButtonGroup.propTypes = {
  className: PropTypes.string,
}

export default ButtonGroup;
