/*
 * Copyright 2019 General Code
 */

import {ModuleKey} from '../ModuleKey';


const getState = state => state[ModuleKey];
const getMapUrl = state => getState(state).mapUrl;
const getOldMapUrl = state => getState(state).oldMapUrl;
const getTextQuickLinks = (state) => state[ModuleKey].textQuickLinks;
const getContentGraphicalQuickLinks = (state) => state[ModuleKey].contentGraphicalQuickLinks;
const isDisplayDateAdmin = state => getState(state).isDisplayDateAdmin;
const getDisplayDate = state => getState(state).displayDate;
const getNameOverride = state => getState(state).nameOverride;
const getOldDisplayDate = state => getState(state).oldDisplayDate;
const getZoningStructure = state => getState(state).zoningStructure;
const isZoningStructureLoading = state => getState(state).zoningStructureLoading;

export {
  getDisplayDate,
  getMapUrl,
  getNameOverride,
  getOldDisplayDate,
  getOldMapUrl,
  getTextQuickLinks,
  getContentGraphicalQuickLinks,
  getZoningStructure,
  isDisplayDateAdmin,
  isZoningStructureLoading
};

