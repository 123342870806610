export default (openmessages = false, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      openmessages = true;
      break;
    case 'REMOVE_MESSAGE':
      openmessages = false;
      break;
    default:
      return openmessages;
  }
  return openmessages;
};
