import * as PropTypes from "prop-types";
import React from "react";
import {If} from "../containers";
import {MenuItem, Select} from "./";
import {custId} from "../utils/server-data";
import moment from "moment/moment";
import {INDEX as INDEX_SUB_PAGE, CODE as CODE_SUB_PAGE} from "../utils/page-sub-types";
import "./VersionSelector.css";

class VersionSelector extends React.PureComponent {

  static propTypes = {
    versions: PropTypes.array,
    pageSubType: PropTypes.string,
    indexLetter: PropTypes.string
  };

  onVersionChange = (e) => {
    e.preventDefault();
    const codeId = e.target?.value + "";
    if (codeId) {
      let newUrl = "";
      if (this.props.pageSubType === INDEX_SUB_PAGE) {
        newUrl = "/" + custId + (codeId.toUpperCase() === custId.toUpperCase() ? "" : ("/code/" + codeId))
          + "/index" + (this.props.indexLetter ? ("?letter=" + this.props.indexLetter) : "");
      } else {
        const currentHref = window.location.href;
        newUrl += "/";
        if (this.props.pageSubType !== CODE_SUB_PAGE || codeId.toUpperCase() !== custId.toUpperCase()) {
          newUrl += (codeId + "/");
        }
        newUrl += currentHref.substring(currentHref.lastIndexOf('/') + 1);
      }
      window.location.href = newUrl;
    }
  };

  renderOption = (version) => {
    let importTime = moment(new Date(version.importTime)).format("YYYY-MM-DD");
    let updatedDate = moment(new Date(version.updatedDate)).format("YYYY-MM-DD");
    return (
      <MenuItem
        key={version.id}
        className={!version.current ? "archiveVersion" : ""}
        value={!version.current ? version.id : version.customer.custId}
      >
        <If test={version.current}>(CURRENT) </If>
        <If test={!version.current}>(ARCHIVE) </If>
        <If test={version.publishingIdentifier}>{version.publishingIdentifier} - Last Updated {importTime} </If>
        <If test={!version.publishingIdentifier}>{updatedDate} </If>
        <If test={importTime === updatedDate}>*</If>
      </MenuItem>
    );
  };

  render() {
    const {versions} = this.props;
    if (!versions || versions.length < 2) return null;
    return (
        <div id="version-selector">
          <Select
            className="version-selector-select"
            title="Select a different version"
            SelectDisplayProps={{style: {maxWidth: 20, padding: "0 0 0 6px"}}}
            onChange={this.onVersionChange}
            value=""
          >
            <MenuItem value={custId} style={{display: "none"}} disabled></MenuItem>
            {versions.map((version) => this.renderOption(version))}
          </Select>
        </div>
    );
  }
}
export default VersionSelector;