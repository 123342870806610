import uniqBy from "lodash/uniqBy";
import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {defaultColorName} from "../../common/theme";
import {removeNoteFilter, setNoteFilter} from "../actions";
import NoteFilter from "../components/NoteFilter";
import {getGroups, getNoteFilter, getNoteFilterOptions} from "../selectors";
import {getNoteLocationData} from "../../common/selectors";
import * as PropTypes from "prop-types";

const colorMap = {
  "accent": "Accent",
  "banner": "Banner",
  "text": "Title",
  "berry": "Berry",
  "mint": "Mint",
  "pineapple": "Pineapple",
  "roc": "Roc",
  "teal": "Teal",
};

export const toOption = (value, groups, noteField, locationData) => {
  let label = value.charAt(0).toUpperCase() + value.slice(1);
  if (noteField === 'visibility' && value !== "public" && value !== "private") {
    const possibleGroups = groups ? groups.find(group => group.key === value) : null;
    if (possibleGroups?.name) {
      label = possibleGroups.name;
    }
  }
  if (noteField === 'color') {
    if (value === 'default') {
      value = defaultColorName;
    }
    label = colorMap[value];
  }
  if (noteField === "guid") {
    const possibleLocation = locationData.find(location => location.guid === value);
    if (possibleLocation) {
      label = possibleLocation.indexNum + ' ' + possibleLocation.title;
    }
  }
  return ({label: label, value});
};

export const toOptions = (state, props) => {
  const groups = getGroups(state);
  const locationData = getNoteLocationData()
  const {noteField} = props;
  let optionList =  uniqBy(getNoteFilterOptions(state, props).map(value => toOption(value, groups, noteField, locationData)), 'value');
  if (noteField === 'guid') {
    // For the guids we need to sort so that its the same as the location data order
    optionList = optionList.sort((a, b) => {
      let aPos = locationData.find((el) => el.guid === a.value).position;
      let bPos = locationData.find((el) => el.guid === b.value).position;
      return aPos - bPos;
    })
  }
  return optionList;
};

export const valueOrDefault = (state, props) => {
  const field = props.noteField;
  const filter = getNoteFilter(state, props);
  if (field === 'color' && filter === 'default') {
    return defaultColorName;
  }
  return filter;
};

const mapStateToProps = createStructuredSelector({
  options: toOptions,
  value: valueOrDefault
});

const mapDispatchToProps = dispatch => ({
  removeNoteFilter: (noteField) => dispatch(removeNoteFilter(noteField)),
  setNoteFilter: (noteField, value) => dispatch(setNoteFilter(noteField, value))
});

const NoteFilterContainer = compose(connect(mapStateToProps, mapDispatchToProps))(NoteFilter);
NoteFilterContainer.propTypes = {
  placeholder: PropTypes.string.isRequired,
  noteField: PropTypes.string.isRequired,
  initialValue: PropTypes.string
};
export default NoteFilterContainer;
