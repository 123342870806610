import React from 'react';
import {connect} from "react-redux";
import {getErrorMessage, post} from "../../common/utils";
import {createNewMessage, MessageTypes} from "../../message-queue";
import SetPasswordDialog from "../components/SetPasswordDialog";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOkClick: (component) => {
      post('/user/' + encodeURI(component.props.userName) + '/password', {password: component.state.newPassword})
        .then(() => {
          component.setState({dialogOpen: false, newPassword: ''});
          createNewMessage(dispatch, MessageTypes.SUCCESS, <span>New password has been applied [{component.props.userName}]</span>);
        })
        .catch((error) => {
          createNewMessage(dispatch, MessageTypes.ERROR, getErrorMessage(error.response));
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordDialog);
