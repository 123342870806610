import React from "react";
import PropTypes from "prop-types";

const PageControl = ({className, nested, children, scrollable, ...props}) => (
  <section
    className={`${className ? className + ' ' : ''}${nested ? 'page-control-widget-nested ' : ''}${scrollable ? 'scrollable ' : ''}page-control-widget paper paperColor elevation1`} {...props}>
    {children}
  </section>
);

PageControl.propTypes = {
  className: PropTypes.string,
  nested: PropTypes.bool,
  scrollable: PropTypes.bool
};

export {default as PageControlContent} from "./PageControlContent";
export {default as PageControlHeader} from "./PageControlHeader";
export {default as TocPageControl} from "./TocPageControl";
export {PageControl};
export default PageControl;