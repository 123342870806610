import React from "react";
import {BoxedSection, ButtonGroup, Checkbox, Dialog, EditorFrame, FormControl, FormControlLabel, IconButton, MenuItem,
  PrimaryButton, Select, TextField} from "../../common/components";
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../common/components/table";
import {If} from "../../common/containers";
import SelectReferenceDialogContainer from "../../common/containers/SelectReferenceDialogContainer";
import {codeReferenceType, pubDocsReferenceType, getReferenceGroup, getReferenceGroupNames} from "../../common/ReferenceGroupFactory";
import {Delete as DeleteIcon, Edit as EditIcon} from "../../common/components/icons";
import "./HomePageSection.css";

class HomePageSection extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {

      // Graphical Quick Links
      codeGraphicalLinkEnabled: props.customization.codeGraphicalLinkEnabled,
      lawsGraphicalLinkEnabled: props.customization.lawsGraphicalLinkEnabled,
      codifiedLawsGraphicalLinkEnabled: props.customization.codifiedLawsGraphicalLinkEnabled,
      helpGraphicalLinkEnabled: props.customization.helpGraphicalLinkEnabled,

      // Textual Quick Links
      referenceDialogGroup: null,
      addTextQuickLinkOpen: false,
      titleValue: "",
      urlValue: "",
      linkTypeValue: "INTERNAL",
      referenceTypeValue: codeReferenceType,
      addTextError: false,
      editId: null,
      deleteTextQuickLinkOpen: false,

      // Contact Info
      muniAddress: props.customization.muniAddress,
      muniContactPerson: props.customization.muniContactPerson,
      muniEmail: props.customization.muniEmail,
      muniPhone: props.customization.muniPhone,
      muniUrl: props.customization.muniUrl,

      // Home Page Message
      homePageMessage: props.customization.homePageMessage,

      // Law Settings
      codifiedLawViewEnabled: props.customization?.codifiedLawViewEnabled,

      // code, new laws, law ledger, help
      codeLongName: props.customization.codeLongName,
      newLawsName: props.customization.newLawsName,
      lawLedgerName: props.customization.lawLedgerName,
      helpLongName: props.customization.helpLongName
    };
  }

  handleUpdateGraphicalLink = (nameToUpdate) => (e) => {
    let currentValue = (this.state[nameToUpdate]);
    this.setState({[nameToUpdate]: !currentValue});
    let graphicalLink = {};
    graphicalLink[nameToUpdate] = !currentValue;

    this.props.setGraphicalLinks(graphicalLink);
  };

  handleUpdatePubDocGraphicalLink = (type) => (e) => {
    this.props.setPubDocGraphicalLink(type, e.target.checked)
  };

  handleUpdateContentGraphicalLink = (linkId) => (e) => {
    this.props.setContentGraphicalLinkEnabled(linkId, e.target.checked)
  };

  openAddTextQuickLinkDialog = () => {
    this.setState({
      addTextQuickLinkOpen: true,
      addTextError: false,
      titleValue: "",
      rlValue: "",
      linkTypeValue: "INTERNAL",
      referenceTypeValue: codeReferenceType,
      editId: null
    });
  }

  closeAddTextQuickLinkDialog = () => {
    this.setState({
      addTextQuickLinkOpen: false,
      titleValue: "",
      urlValue: "",
      linkTypeValue: "INTERNAL",
      referenceTypeValue: codeReferenceType,
      addTextError: false,
      editId: null
    });
  }

  handleUpdateTitleText = (e) => {
    this.setState({titleValue: e.target.value});
  }

  handleUpdateUrl = (e) => {
    this.setState({urlValue: e.target.value});
  }

  handleUpdateLinkType = (e) => {
    this.setState({
      linkTypeValue: e.target.value,
      urlValue: (e.target.value !== this.state.linkTypeValue) ? "" : this.state.urlValue
    });
  }

  handleUpdateReferenceType = (e) => {
    this.setState({
      referenceTypeValue: e.target.value,
      urlValue: (e.target.value !== this.state.referenceTypeValue) ? "" : this.state.urlValue
    });
  }

  handleAddTextQuickLink = () => {
    const {titleValue, urlValue, linkTypeValue, editId} = this.state;
    if(titleValue && urlValue && linkTypeValue) {
      if(editId) {
        this.props.editTextQuickLink(editId, titleValue, urlValue, linkTypeValue);
      } else {
        this.props.addTextQuickLink(titleValue, urlValue, linkTypeValue);
      }
      this.closeAddTextQuickLinkDialog();
    } else {
      this.setState({addTextError: true});
    }
  }

  handleOpenDeleteTextQuickLink = (linkId) => () => {
    this.setState({deleteTextQuickLinkOpen: true, linkToDelete: linkId});
  }

  handleCloseDeleteTextQuickLink = () => {
    this.setState({deleteTextQuickLinkOpen: false, linkToDelete: null});
  }

  handleDeleteTextQuickLink = () => {
    const {linkToDelete} = this.state;
    this.props.deleteTextQuickLink(linkToDelete);
    this.handleCloseDeleteTextQuickLink();
  }

  handleOpenEditTextQuickLink = (linkId, linkTitle, linkUrl, linkType, referenceTypeValue) => () => {
    this.setState({
      addTextQuickLinkOpen: true,
      editId: linkId,
      titleValue: linkTitle,
      urlValue: linkUrl,
      linkTypeValue: linkType,
      referenceTypeValue: referenceTypeValue,
      addTextError: false
    });
  }

  openReferenceDialog = (e) => {
    e.preventDefault();
    this.setState({referenceDialogGroup: this.state.referenceTypeValue});
  }

  handleReferenceSelectKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.openReferenceDialog(e);
    }
  }

  handleCloseReference = () => {
    this.setState({referenceDialogGroup: null});
  }

  handleSubmitReference = (reference) => {
    this.setState({
      titleValue: (this.state.titleValue.length > 0 ? this.state.titleValue : reference.text),
      urlValue: reference.url
    });
    this.handleCloseReference();
  }

  handleChangeContactInfoText = (nameToUpdate) => (event) => {
    this.setState({[nameToUpdate]: event.target.value});
  }

  handleUpdateContactInfo = () => {
    this.props.setContactInfo({
      muniAddress: this.state.muniAddress,
      muniContactPerson: this.state.muniContactPerson,
      muniEmail: this.state.muniEmail,
      muniPhone: this.state.muniPhone,
      muniUrl: this.state.muniUrl
    });
  }

  handleUpdateHomePageMessage = (homePageMessage) => {
    this.props.setHomePageMessage(homePageMessage);
  }

  render() {
    const {pubDocTypes, textQuickLinks, contentGraphicalQuickLinks } = this.props;
    const { codeGraphicalLinkEnabled, lawsGraphicalLinkEnabled, codifiedLawsGraphicalLinkEnabled,
      helpGraphicalLinkEnabled, deleteTextQuickLinkOpen, linkToDelete, addTextQuickLinkOpen,
      titleValue, urlValue, linkTypeValue, referenceTypeValue, addTextError, editId,
      referenceDialogGroup, muniAddress, muniContactPerson, muniEmail, muniPhone, muniUrl,
      homePageMessage, codifiedLawViewEnabled, codeLongName, newLawsName, lawLedgerName, helpLongName
    } = this.state;
    return (
      <BoxedSection
        title="Home Page"
        instructions="Modify settings related to the home page:"
        id={"homePageSection"}
      >
        <div>
          <h3 id="contactInformationTitle">Contact Information</h3>
          <TextField
            label={"Address"}
            defaultValue={muniAddress}
            fullWidth={true}
            onChange={this.handleChangeContactInfoText("muniAddress")}
          />
          <TextField
            label={"Person"}
            defaultValue={muniContactPerson}
            fullWidth={true}
            onChange={this.handleChangeContactInfoText("muniContactPerson")}
          />
          <TextField
            label={"Email"}
            defaultValue={muniEmail}
            fullWidth={true}
            onChange={this.handleChangeContactInfoText("muniEmail")}
          />
          <TextField
            label={"Phone"}
            defaultValue={muniPhone}
            fullWidth={true}
            onChange={this.handleChangeContactInfoText("muniPhone")}
          />
          <TextField
            label={"Website Url"}
            defaultValue={muniUrl}
            fullWidth={true}
            onChange={this.handleChangeContactInfoText("muniUrl")}
          />
          <ButtonGroup>
            <PrimaryButton
              onClick={this.handleUpdateContactInfo}
              id="adminSettingsPage-updateContactInfo"
            >
              Update Contact
            </PrimaryButton>
          </ButtonGroup>
        </div>
        <div>
          <h3 id="homePageMessageTitle">Home Page Message</h3>
          <EditorFrame
            html={homePageMessage}
            onSave={this.handleUpdateHomePageMessage}
          />
        </div>
        <div className="gcAdminSettings-section">
          <h3 id="graphicalLinksTitle">Graphical Quick Links</h3>
          <ul>
            <li>
              <FormControlLabel
                className="gcAdminSettingsList-CheckboxLabel"
                control={
                  <Checkbox
                    id="adminSettingsPage-codeGraphical"
                    checked={codeGraphicalLinkEnabled}
                    onChange={this.handleUpdateGraphicalLink("codeGraphicalLinkEnabled")}
                  />
                }
                label={codeLongName}
              />
            </li>
            <li>
              <FormControlLabel
                className="gcAdminSettingsList-CheckboxLabel"
                control={
                  <Checkbox
                    id="adminSettingsPage-lawsGraphical"
                    checked={lawsGraphicalLinkEnabled}
                    onChange={this.handleUpdateGraphicalLink("lawsGraphicalLinkEnabled")}
                  />
                }
                label={newLawsName}
              />
            </li>
            <If test={codifiedLawViewEnabled}>
              <li>
                <FormControlLabel
                  className="gcAdminSettingsList-CheckboxLabel"
                  control={
                    <Checkbox
                      id="adminSettingsPage-codifiedLawsGraphical"
                      checked={codifiedLawsGraphicalLinkEnabled}
                      onChange={this.handleUpdateGraphicalLink("codifiedLawsGraphicalLinkEnabled")}
                    />
                  }
                  label={lawLedgerName}
                />
              </li>
            </If>
            <li>
              <FormControlLabel
                className="gcAdminSettingsList-CheckboxLabel"
                control={
                  <Checkbox
                    id="adminSettingsPage-helpGraphical"
                    checked={helpGraphicalLinkEnabled}
                    onChange={this.handleUpdateGraphicalLink("helpGraphicalLinkEnabled")}
                  />
                }
                label={helpLongName}
              />
            </li>
            <If
              test={contentGraphicalQuickLinks && (contentGraphicalQuickLinks.length > 0 || contentGraphicalQuickLinks.size > 0)}>
              <li><h4 id="divisionGraphicalLinksTitle">Divisions</h4></li>
              {contentGraphicalQuickLinks.map((link) => (
                <li key={link.id}>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id={`adminSettingsPage-contentGraphical-${link.id}`}
                        defaultChecked={link.enabled}
                        onChange={this.handleUpdateContentGraphicalLink(link.id)}
                      />
                    }
                    label={link.label}
                  />
                </li>
              ))}
            </If>
            <If test={pubDocTypes && (pubDocTypes.length > 0 || pubDocTypes.size > 0)}>
              <li><h4 id="pubDocsGraphicalLinksTitle">Public Document Types</h4></li>
              {pubDocTypes.map((type) => (
                <li key={type.id}>
                  <FormControlLabel
                    className="gcAdminSettingsList-CheckboxLabel"
                    control={
                      <Checkbox
                        id="adminSettingsPage-codeGraphical"
                        defaultChecked={type.graphicalLinkEnabled}
                        onChange={this.handleUpdatePubDocGraphicalLink(type)}
                      />
                    }
                    label={type.title}
                  />
                </li>
              ))}
            </If>
          </ul>
        </div>
        <div className="gcAdminSettings-section">
          <h3 id="textLinksTitle">Text Quick Links</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Url</TableCell>
                <TableCell>Type</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {textQuickLinks.map((textQuickLink) => (
                <TableRow key={textQuickLink.id}>
                  <TableCell>{textQuickLink.title}</TableCell>
                  <TableCell>{textQuickLink.url}</TableCell>
                  <TableCell>{textQuickLink.textualQuickLinkType}</TableCell>
                  <TableCell>
                    <IconButton onClick={this.handleOpenDeleteTextQuickLink(textQuickLink.id)}>
                      <DeleteIcon/>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={this.handleOpenEditTextQuickLink(
                      textQuickLink.id,
                      textQuickLink.title,
                      textQuickLink.url,
                      textQuickLink.textualQuickLinkType,
                      textQuickLink.url.startsWith("/documents") ? pubDocsReferenceType : codeReferenceType
                    )}>
                      <EditIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ButtonGroup>
            <PrimaryButton id={"adminSettingsPage-addTextQuickLink"} onClick={this.openAddTextQuickLinkDialog} variant={"contained"} >Add Text Quick Link</PrimaryButton>
          </ButtonGroup>
        </div>
        <Dialog
          title="Delete Text Quick Link?"
          open={deleteTextQuickLinkOpen}
          onCancel={this.handleCloseDeleteTextQuickLink}
          onSubmit={this.handleDeleteTextQuickLink}
        >
          <div>
            Are you sure you want to delete the text quick link: {textQuickLinks.filter(currLink => {
              return currLink.id === linkToDelete
          }).map((currLink) => <span key={currLink.id}>{currLink.title}</span>)}
          </div>
        </Dialog>
        <Dialog
          title={editId ? "Edit a Text Quick Link" : "Add a Text Quick Link"}
          open={addTextQuickLinkOpen}
          onCancel={this.closeAddTextQuickLinkDialog}
          onSubmit={this.handleAddTextQuickLink}
          submitLabel="Confirm"
          width="sm"
        >
          <div className={"dialogRow"}>
            <FormControl>
              <Select value={!linkTypeValue ? "INTERNAL" : linkTypeValue} onChange={this.handleUpdateLinkType} fullWidth>
                <MenuItem value={"INTERNAL"}>Internal Link</MenuItem>
                <MenuItem value={"EXTERNAL"}>External Link</MenuItem>
              </Select>
            </FormControl>
          </div>
          <If test={linkTypeValue === "INTERNAL"}>
            <div className={"dialogRow"}>
              <FormControl fullWidth>
                <Select value={!referenceTypeValue ? codeReferenceType : referenceTypeValue} onChange={this.handleUpdateReferenceType} fullWidth>
                  {getReferenceGroupNames().map((referenceGroupName) =>
                    <MenuItem key={referenceGroupName} value={referenceGroupName}>
                      {getReferenceGroup(referenceGroupName)?.friendlyName} Reference
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </If>
          <If test={linkTypeValue === "INTERNAL"}>
            <div className={"dialogRow"}>
              <TextField
                label={"Reference"}
                variant="outlined"
                value={!urlValue ? "" : urlValue}
                onClick={this.openReferenceDialog}
                onKeyDown={this.handleReferenceSelectKeyPress}
                InputProps={{readOnly: true}}
                inputProps={{style: { cursor: "pointer"}}}
                style={{cursor: "pointer"}}
                fullWidth
              />
              <SelectReferenceDialogContainer
                open={referenceDialogGroup !== null}
                groupName={referenceDialogGroup}
                onSubmit={this.handleSubmitReference}
                onClose={this.handleCloseReference}
                showNewWindowOption={false}
                fetchNodes={true}
              />
            </div>
          </If>
          <If test={linkTypeValue !== "INTERNAL"}>
            <div className={"dialogRow"}>
              <TextField
                label={"URL"}
                variant="outlined"
                value={!urlValue ? "" : urlValue}
                onChange={this.handleUpdateUrl}
                fullWidth
              />
            </div>
          </If>
          <div className={"dialogRow"}>
            <TextField
              label={"Title"}
              variant="outlined"
              value={!titleValue ? "" : titleValue}
              onChange={this.handleUpdateTitleText}
              fullWidth
            />
          </div>
          <If test={addTextError}>
            <span>Please fill out all fields</span>
          </If>
        </Dialog>
      </BoxedSection>
    );
  }
}
export default HomePageSection;
