import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {branch, compose, renderNothing} from 'recompose';
// noinspection ES6CheckImport
import {createStructuredSelector} from 'reselect';
import {
  getColor,
  isOpen,
  isEditing,
  getTitle,
  getVisibility,
  getAllNoteFilters,
  getContent,
  getGuid
} from '../selectors';
import {toggleNote, saveNote} from '../actions';
import {toJS} from '../../common/utils';
import Note from '../components/Note';

const mapStateToProps = createStructuredSelector({
  title: getTitle,
  content: getContent,
  color: getColor,
  open: isOpen,
  editing: isEditing,
  visibility: getVisibility,
  filters: getAllNoteFilters,
  guid: getGuid
});

const mapDispatchToProps = (dispatch, {id}) => ({
  toggleNote: () => dispatch(toggleNote(id)),
  saveNote: (html) => dispatch(saveNote(id, html))
});

// If there is no content then don't bother rendering -- the note is likely loading
const NoteOrNothing = branch(({title}) => !title && title !== '', renderNothing)(Note);

const NoteContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(NoteOrNothing);

NoteContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default NoteContainer;

