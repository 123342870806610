import React from "react";
import {archiveId} from "../utils/server-data";
import Tooltip from "./Tooltip";

const CodeReference = ({number, title, guid, tooltip = null}) => {

  let codeReference =
    <div>
      {number && number !== "" && <span key="context" className="titleNumber">{number} </span>}
      <span className="titleTitle">{title}</span>
    </div>;

  if (guid) {
    codeReference = <a href={`/${archiveId ? archiveId + "/" : ""}${guid}#${guid}`} tabIndex={-1}>{codeReference}</a>;
  }

  if (tooltip && tooltip !== "") {
    codeReference = <Tooltip enterDelay={300} title={tooltip} >{codeReference}</Tooltip>;
  }

  return <div className="codeReference">{codeReference}</div>;
};

export default CodeReference;