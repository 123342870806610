import React, {Component} from "react";
import {ClickAwayListener, Grow, Paper, Popper} from "../";
import Button from "./Button";
import {MenuItem, MenuList} from "../menu";
import {ArrowDropDown as ArrowDropDownIcon} from "../icons";

import "./SplitButton.css";

class SplitButton extends Component {

  anchorRef = null;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedIndex: (props.selectedIndex == null ? 0 : props.selectedIndex)
    }
  }

  onClick = (e) => {
    let selectedOption = this.props.options[this.state.selectedIndex];
    if (selectedOption?.onClick) selectedOption.onClick(e);
  };

  onMenuItemClick = (e, index) => {
    this.setState({selectedIndex: index, open: false});
  };

  onMenuToggle = () => {
    this.setState({open: !this.state.open});
  };

  onClose = (event) => {
    if (this.anchorRef.current?.contains(event.target)) {
      return;
    }
    this.setState({open: false});
  };

  render() {
    const {open, selectedIndex} = this.state;
    const {options,  color = "primary", size = "small", ...otherProps} = this.props;
    return (
      <React.Fragment>
        <div ref={ref => {this.anchorRef = ref;}} className="split-button-container">
          <Button
            className="split-button"
            variant="contained"
            color={color}
            size={size}
            onClick={this.onClick}
            {...otherProps}
          >
            {options[selectedIndex].label}
          </Button>
          <Button
            className="split-button-dropdown-toggle"
            variant="contained"
            color={color}
            size={size}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={this.onMenuToggle}
          >
            <ArrowDropDownIcon/>
          </Button>
        </div>
        <Popper
          open={open}
          style={{ position: "absolute", zIndex: 2}}
          anchorEl={this.anchorRef?.current}
          role={undefined}
          transition
          disablePortal
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.onClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        selected={index === selectedIndex}
                        onClick={(e) => this.onMenuItemClick(e, index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

export default SplitButton;