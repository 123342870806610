import React from "react";

import FacetContainer from "../containers/FacetContainer";
import ScopeSelectorContainer from "../containers/ScopeSelectorContainer";
import SortContainer from "../containers/SortContainer";
import "./SearchResultsPage.css";
import SearchFilters from "../containers/SearchFilters";

class SearchResultsPageControls extends React.Component {

  render() {
    const {selectedCustIds, selectedCustomerFilters, scope} = this.props;
    return (
      <>
        <SearchFilters/>
        <ScopeSelectorContainer id="scopeSelectorContent"/>
        <FacetContainer className="sidebarItem"/>
        <SortContainer
          id={"sortFacet"}
          hasDateSort={scope === "doc"}
          hasNaturalOrderSort={scope === "code" && selectedCustIds.length === 1 && selectedCustomerFilters.length === 0}
        />
      </>
    );
  }
}
export default SearchResultsPageControls;
