/*
 * Copyright 2018 General Code
 */

import React from "react";
import * as PropTypes from "prop-types";

import {FormControl, InputLabel, MenuItem, Select} from "../../common/components";

class ReviewerSelect extends React.Component {

  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    assignedTo: PropTypes.string,
    reviewers: PropTypes.object.isRequired,
    isFinalized: PropTypes.bool,
    isLocked: PropTypes.bool,
    setAssignedTo: PropTypes.func.isRequired
  };

  handleAssignedToChange = (event) => {
    const {questionId} = this.props;
    const assignedTo = event.target.value;
    this.props.setAssignedTo(questionId, assignedTo);
  };

  render() {
    const {questionId, assignedTo, reviewers, isFinalized, isLocked} = this.props;
    return (
      <div className="reviewerSelectContainer">
        <FormControl className={"formSelectControl" + (!assignedTo ? " blank" : "")} margin="normal">
          <InputLabel id={"reviewerSelect-label-" + questionId} htmlFor={"reviewerSelect-" + questionId}  className="formSelectControlLabel">
            Assigned To
          </InputLabel>
          <Select
            value={assignedTo == null ? "" : assignedTo}
            onChange={this.handleAssignedToChange}
            inputProps={{
              id: "reviewerSelect-" + questionId,
              name: "reviewerSelect-" + questionId
            }}
            disabled={isFinalized || isLocked}
            variant="filled"
          >
            <MenuItem value="">None</MenuItem>
            {Object.values(reviewers).map((reviewer) => (<MenuItem key={reviewer.username} value={reviewer.username}>{reviewer.displayName}</MenuItem>))}
          </Select>
        </FormControl>
      </div>
    );
  }
}
export default ReviewerSelect;