import {compose} from "recompose";
import {connect} from "react-redux";
import {toJS} from "../../common/utils";
import {createStructuredSelector} from "reselect";
import {
  setCategoryDeleteErrorOpen,
  setCategoryDeleteOpen,
  setDocumentChecked,
  setDocumentUnchecked,
  setPubDocCategoryEditOpen,
  setPubDocEditOpen
} from "../actions";
import * as PropTypes from "prop-types";
import PubDocCategoryLabel from "../components/PubDocCategoryLabel";
import {getCustId, hasPubDocCategoryAdminPermission, hasPubDocPrivateViewPermission, hasDocumentAdminPermission} from "../../common/selectors";
import {getSelectedDocIds} from "../selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  hasPubDocCategoryAdminPermission: hasPubDocCategoryAdminPermission,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  selectedDocIds: getSelectedDocIds
});

const mapDispatchToProps = (dispatch, {category}) => ({
  setCategoryDeleteOpen: (open) => {
    dispatch(setCategoryDeleteOpen(open, category.id));
  },
  setCategoryDeleteErrorOpen: (open) => {
    dispatch(setCategoryDeleteErrorOpen(open));
  },
  setCategoryEditOpen: (categoryEditOpen, categoryEditId) => {
    dispatch(setPubDocCategoryEditOpen(categoryEditOpen, categoryEditId));
  },
  setDocumentEditOpen: (documentEditOpen, documentId) => {
    dispatch(setPubDocEditOpen(documentEditOpen, documentId, category.id, null));
  },
  setDocumentChecked: (documentId) => {
    dispatch(setDocumentChecked(documentId));
  },
  setDocumentUnchecked: (documentId) => {
    dispatch(setDocumentUnchecked(documentId));
  },
});

const PubDocCategoryLabelContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDocCategoryLabel);
PubDocCategoryLabelContainer.propTypes = {
  category: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired
};
export default PubDocCategoryLabelContainer;