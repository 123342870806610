import React from "react";
import {Chip, IconButton} from "../../common/components";
import {Print as PrintIcon, Email as EmailIcon, Pdf as PdfIcon, Code as CodeIcon,
  NewLawOld as LawIcon, Note as NoteIcon, NewLawNew as NewLawIcon,
  PubDoc as PubDocIcon} from "../../common/components/icons";
import If from "../../common/containers/If";
import "./Result.css";

class Result extends React.PureComponent {

  handlePrint = () => {
    const {result} = this.props;
    window.open(result.printUrl);
  };

  handleEmail = () => {
    const {result, customer} = this.props;
    let body = "";
    let doc = new DOMParser().parseFromString(result.title, 'text/html');
    let resultTile = doc.body.textContent || "";
    if (result.type === "code") {
      body += `${customer.name} Code\n\n`;
      if (Object.keys(result.parents).length > 0) body += `${Object.keys(result.parents).join("\n")}\n`;
      body += `${result.number ? result.number + ": " : ""}${resultTile}\n`;
    } else if (result.type === "law") {
      body += `${resultTile}\n`;
    } else if (result.type === "doc") {
      if (Object.keys(result.parents).length > 0) body += `${Object.keys(result.parents).join(" — ")} — `;
      body += `${resultTile}\n`;
    }
    body += `${window.location.protocol}//${window.location.host}${result.url}\n`;
    window.open(`mailto:?subject=${encodeURIComponent(customer.name)}&body=${encodeURIComponent(body)}`, '_self');
  };

  render() {
    const {result, customer, isMulticodeSearch, archiveId} = this.props;
    if (!result) return null;

    return (
      <div className="resultPaper">
        <div className="moreActions">
          <If test={result.printUrl}>
            <IconButton aria-label="Print Action" onClick={this.handlePrint} className="printAction">
              <PrintIcon/>
            </IconButton>
          </If>
          <If test={result.type !== "note"}>
            <IconButton aria-label="Email Action" onClick={this.handleEmail} className="emailAction">
              <EmailIcon/>
            </IconButton>
          </If>
        </div>
        <div className="resultTitleLine">
          <a href={result.url} target={result.pdf ? "_blank" : "_self"} className="titleLink">
            {result.number && <span className="titleNumber">{result.number} </span>}
            <span className="titleTitle" dangerouslySetInnerHTML={{__html: result.title}}/>
            {result.pdf && <PdfIcon size={16}/>}
          </a>
        </div>
        <If test={customer && customer.custId && isMulticodeSearch}>
          <div className="resultCustomerLine">
            {customer.name + (customer.county ? " (" + customer.county + ")" : "")}</div>
        </If>
        <div className="resultInfoLine">
          <If test={result.type === "code"}>
            <a className="resultParentLink" href={`${archiveId ? `/${archiveId}`  : ""}/${result.custId}`}>
              <div className="resultTypeIcon"><CodeIcon/></div>
              <span className="resultType customerTitleColor">Code</span>
            </a>
          </If>
          <If test={result.type === "doc"}>
            <div className="resultTypeIcon"><PubDocIcon/></div>
            <span className="resultType customerTitleColor">Public Document</span>
          </If>
          <If test={result.type === "law"}>
            <a className="resultParentLink" href={`/${archiveId || result.custId}/laws`}>
              <If test={window.SERVER_DATA.newNav}>
                <div className="resultTypeIcon"><NewLawIcon/></div>
              </If>
              <If test={!window.SERVER_DATA.newNav}>
                <div className="resultTypeIcon"><LawIcon/></div>
              </If>
              <span className="resultType customerTitleColor">New Law</span>
            </a>
          </If>
          <If test={result.type === "note"}>
            <a className="resultParentLink" href={`/note/${archiveId || result.custId}`}>
              <div className="resultTypeIcon"><NoteIcon/></div>
              <span className="resultType customerTitleColor">Note</span>
            </a>
          </If>
          {result.parents && Object.keys(result.parents).map((name) => (
            <span key={name}>
              <span className="resultParentSeparator">/</span>
              <a href={result.parents[name]} className="resultParentLink">
                <span className="resultParent" dangerouslySetInnerHTML={{__html: name}}/>
              </a>
            </span>
          ))}
          <If test={result.otherInfo && result.otherInfo.length > 0}>
            <span className="resultOtherInfo">
              {result.otherInfo.map((info) => (
                <span key={info}>
                  <span className="resultParentSeparator">/</span>
                  <span>{info}</span>
                </span>
              ))}
            </span>
          </If>
        </div>
        <If test={result.affectsLinks && Object.keys(result.affectsLinks).length > 0}>
          <div className="resultAffectsLinks">
            <span className="resultAffectsLinksLabel">Affects:</span>
            {result.affectsLinks && Object.keys(result.affectsLinks).map((indexNum) => (
              <a key={indexNum} href={result.affectsLinks[indexNum]} className="resultAffectsLink">
                <span dangerouslySetInnerHTML={{__html: (indexNum.replace(/ /g, "&nbsp;") + " ")}}/>
              </a>
            ))}
          </div>
        </If>
        <div className="resultSnippetLine" dangerouslySetInnerHTML={{__html: result.snippet}}/>
        <div className="resultTagLine muiChipContainer">
          {result.hits && Object.keys(result.hits).map((hitText) =>
            <Chip key={hitText} className="wrappable muiChip" label={`${hitText} (${result.hits[hitText]})`} />
          )}
        </div>
      </div>
    );
  };
}

export default Result;
