import React from "react";
import ScopeType from "./ScopeType";
import {Card, PageControl, PageControlContent} from "../../common/components";

class ScopeSelector extends React.PureComponent {

  renderWidgetContents = () => {
    const {scope, totalResultCount, scopeTypes, setScope} = this.props;
    return (
      <nav>
        <ScopeType key={0}
          isSelected={!scope || scope === "all"}
          id="all"
          name="All Results"
          title="All Results"
          count={totalResultCount}
          setScope={setScope}
        />
        {
          scopeTypes?.map((scopeType) => {
            return (
              <ScopeType key={scopeType.id}
                isSelected={scope && scope === scopeType.id}
                id={scopeType.id}
                name={scopeType.displayName}
                title={scopeType.displayName}
                count={scopeType.count}
                setScope={setScope}
              />
            );
          })
        }
      </nav>
    );
  }

  render() {
    const {scopeTypes, isNewNav, ...props} = this.props;
    if (isNewNav) {
      return scopeTypes.length === 0 ? null : (
        <PageControl {...props}>
          <PageControlContent>
            {this.renderWidgetContents()}
          </PageControlContent>
        </PageControl>
      );
    } else {
      return scopeTypes.length === 0 ? null : (
        <div {...props}>
          <Card className="sidebarItemContent" elevation={3 /* the scope selector goes over the filter; paper is 1dp thick and 1dp of space to move was added*/}>
            {this.renderWidgetContents()}
          </Card>
        </div>
      );
    }
  }
}
export default ScopeSelector;
