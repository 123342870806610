import {connect} from 'react-redux';
import {compose} from 'recompose';
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import PubDoc from "../components/PubDoc";
import {getCustId, hasDocumentAdminPermission, hasPubDocPrivateViewPermission} from "../../common/selectors";
import * as PropTypes from "prop-types";
import {deletePubDoc, setDocumentChecked, setDocumentUnchecked, setPubDocEditOpen, setDocumentMoveOpen} from "../actions";
import {getSelectedDocIds} from "../selectors";

const mapStateToProps = createStructuredSelector({
  custId: getCustId,
  hasPubDocPrivateViewPermission: hasPubDocPrivateViewPermission,
  hasDocumentAdminPermission: hasDocumentAdminPermission,
  selectedDocIds: getSelectedDocIds
});

const mapDispatchToProps = (dispatch, {doc}) => ({
  deleteDocument: (custId) => {
    dispatch(deletePubDoc(custId, doc.id));
  },
  setDocumentEditOpen: (open) => {
    dispatch(setPubDocEditOpen(open, doc.id, null, null));
  },
  setDocumentChecked: () => {
    dispatch(setDocumentChecked(doc.id));
  },
  setDocumentUnchecked: () => {
    dispatch(setDocumentUnchecked(doc.id));
  },
  setDocumentMoveOpen: (open) => {
    dispatch(setDocumentMoveOpen(open, doc.id));
  }
});

const PubDocContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(PubDoc);
PubDocContainer.propTypes = {
  doc: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  prevDoc: PropTypes.object
};
export default PubDocContainer;