import {connect} from "react-redux";
import InsertReferenceEditorButton from "../components/InsertReferenceEditorButton";
import {createStructuredSelector} from "reselect";
import {fetchRootReferenceNodes} from "../actions/references";
import {getAllReferenceNodes} from "../selectors/references";
import {compose} from "recompose";
import {toJS} from "../utils";
import * as PropTypes from "prop-types";

const mapStateToProps = createStructuredSelector({
  allNodes: getAllReferenceNodes
});

const mapDispatchToProps = (dispatch) => ({
  fetchRootReferenceNodes: (groupName) => dispatch(fetchRootReferenceNodes(groupName))
});

const InsertReferenceEditorButtonContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(InsertReferenceEditorButton);
InsertReferenceEditorButtonContainer.propTypes = {
  ReferenceComponent: PropTypes.func.isRequired,
  addReferenceLink: PropTypes.func.isRequired,
  focusEditor: PropTypes.func.isRequired
};
export default InsertReferenceEditorButtonContainer;