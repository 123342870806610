import _ from "lodash";
import moment from "moment";

const CURRENT_DATE = new Date();
const CURRENT_CENTURY = (Math.floor(CURRENT_DATE.getFullYear() / 100) * 100);
const FIRST_CENTURY_DIGIT = Math.floor(CURRENT_DATE.getFullYear() / 1000);
const YYYY_REGEX = "(?:[1-" + FIRST_CENTURY_DIGIT + "][0-9]{3})";
const YY_REGEX = "(?:[0-9]{2})";
const Y_REGEX = "(?:[0-9]{1,4})";
const MMMM_REGEX = "(?:january)|(?:february)|(?:march)|(?:april)|(?:may)|(?:june)|(?:july)|(?:august)|(?:september)|(?:october)|(?:november)|(?:december)";
const MMM_REGEX = "(?:jan)|(?:feb)|(?:mar)|(?:apr)|(?:may)|(?:jun)|(?:jul)|(?:aug)|(?:sep)|(?:oct)|(?:nov)|(?:dec)";
const MM_REGEX = "(?:12)|(?:11)|(?:10)|(?:09)|(?:08)|(?:07)|(?:06)|(?:05)|(?:04)|(?:03)|(?:02)|(?:01)";
const M_REGEX = "(?:12)|(?:11)|(?:10)|(?:9)|(?:8)|(?:7)|(?:6)|(?:5)|(?:4)|(?:3)|(?:2)|(?:1)";
const MM_M_REGEX = "(?:12)|(?:11)|(?:10)|(?:0?9)|(?:0?8)|(?:0?7)|(?:0?6)|(?:0?5)|(?:0?4)|(?:0?3)|(?:0?2)|(?:0?1)";
const DD_REGEX = "(?:31)|(?:30)|(?:29)|(?:28)|(?:27)|(?:26)|(?:25)|(?:24)|(?:23)|(?:22)|(?:21)|(?:20)|(?:19)|(?:18)|(?:17)|(?:16)|(?:15)|(?:14)|(?:13)|(?:12)|(?:11)|(?:10)|(?:09)|(?:08)|(?:07)|(?:06)|(?:05)|(?:04)|(?:03)|(?:02)|(?:01)";
const D_REGEX = "(?:31)|(?:30)|(?:29)|(?:28)|(?:27)|(?:26)|(?:25)|(?:24)|(?:23)|(?:22)|(?:21)|(?:20)|(?:19)|(?:18)|(?:17)|(?:16)|(?:15)|(?:14)|(?:13)|(?:12)|(?:11)|(?:10)|(?:9)|(?:8)|(?:7)|(?:6)|(?:5)|(?:4)|(?:3)|(?:2)|(?:1)";
const DD_D_REGEX = "(?:31)|(?:30)|(?:29)|(?:28)|(?:27)|(?:26)|(?:25)|(?:24)|(?:23)|(?:22)|(?:21)|(?:20)|(?:19)|(?:18)|(?:17)|(?:16)|(?:15)|(?:14)|(?:13)|(?:12)|(?:11)|(?:10)|(?:0?9)|(?:0?8)|(?:0?7)|(?:0?6)|(?:0?5)|(?:0?4)|(?:0?3)|(?:0?2)|(?:0?1)";

const AUTO_REGEX_START = "(?:^|[^0-9])";
const AUTO_REGEX_SEPARATOR_1 = "[^0-9a-z]+";
const AUTO_REGEX_SEPARATOR_2 = "[^0-9a-z]*";
const AUTO_REGEX_END = "(?:[^0-9]|$)";
const AUTO_REGEX_1 = AUTO_REGEX_START + "(" + MM_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + DD_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_2 = AUTO_REGEX_START + "(" + DD_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_3 = AUTO_REGEX_START + "(" + YYYY_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + DD_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_4 = AUTO_REGEX_START + "(" + YYYY_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + MMMM_REGEX + "|" + MMM_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + DD_D_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_5 = AUTO_REGEX_START + "(" + DD_D_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + MMMM_REGEX + "|" + MMM_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_6 = AUTO_REGEX_START + "(" + MMMM_REGEX + "|" + MMM_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + DD_D_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_7 = AUTO_REGEX_START + "(" + MM_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + DD_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + YY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_8 = AUTO_REGEX_START + "(" + DD_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + YY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_9 = AUTO_REGEX_START + "(" + YY_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + DD_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_10 = AUTO_REGEX_START + "(" + MMMM_REGEX + "|" +  MMM_REGEX + "|" + MM_M_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_1 + "(" + DD_D_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + YYYY_REGEX + "|" +  YY_REGEX + ")"
  + AUTO_REGEX_END;
const AUTO_REGEX_11 = AUTO_REGEX_START + "(" + DD_D_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + MMMM_REGEX + "|" +  MMM_REGEX + "|" + MM_M_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + YYYY_REGEX + "|" +  YY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_12 = AUTO_REGEX_START + "(" + YYYY_REGEX + "|" +  YY_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + MMMM_REGEX + "|" +  MMM_REGEX + "|" + MM_M_REGEX + ")" + AUTO_REGEX_SEPARATOR_1 + "(" + DD_D_REGEX + ")"
  + AUTO_REGEX_END;
const AUTO_REGEX_13 = AUTO_REGEX_START + "(" + YYYY_REGEX + ")" + AUTO_REGEX_SEPARATOR_2 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_2 + "(" + DD_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_14 = AUTO_REGEX_START + "(" + MM_REGEX + ")" + AUTO_REGEX_SEPARATOR_2 + "(" + DD_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_2 + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_15 = AUTO_REGEX_START + "(" + DD_REGEX + ")" + AUTO_REGEX_SEPARATOR_2 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_2 + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_16 = AUTO_REGEX_START + "(" + YY_REGEX + ")" + AUTO_REGEX_SEPARATOR_2 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_2 + "(" + DD_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_17 = AUTO_REGEX_START + "(" + MM_REGEX + ")" + AUTO_REGEX_SEPARATOR_2 + "(" + DD_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_2 + "(" + YY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_18 = AUTO_REGEX_START + "(" + DD_REGEX + ")" + AUTO_REGEX_SEPARATOR_2 + "(" + MM_REGEX + ")"
  + AUTO_REGEX_SEPARATOR_2 + "(" + YY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_19 = AUTO_REGEX_START + "(" + YYYY_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + MMMM_REGEX + "|" + MMM_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_20 = AUTO_REGEX_START + "(" + MMMM_REGEX + "|" + MMM_REGEX + ")" + AUTO_REGEX_SEPARATOR_1
  + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_21 = AUTO_REGEX_START + "(" + YYYY_REGEX + ")" + AUTO_REGEX_END;
const AUTO_REGEX_MAP = {
  [AUTO_REGEX_1]: ["M", "D", "Y"],
  [AUTO_REGEX_2]: ["D", "M", "Y"],
  [AUTO_REGEX_3]: ["Y", "M", "D"],
  [AUTO_REGEX_4]: ["Y", "M", "D"],
  [AUTO_REGEX_5]: ["D", "M", "Y"],
  [AUTO_REGEX_6]: ["M", "D", "Y"],
  [AUTO_REGEX_7]: ["M", "D", "Y"],
  [AUTO_REGEX_8]: ["D", "M", "Y"],
  [AUTO_REGEX_9]: ["Y", "M", "D"],
  [AUTO_REGEX_10]: ["M", "D", "Y"],
  [AUTO_REGEX_11]: ["D", "M", "Y"],
  [AUTO_REGEX_12]: ["Y", "M", "D"],
  [AUTO_REGEX_13]: ["Y", "M", "D"],
  [AUTO_REGEX_14]: ["M", "D", "Y"],
  [AUTO_REGEX_15]: ["D", "M", "Y"],
  [AUTO_REGEX_16]: ["Y", "M", "D"],
  [AUTO_REGEX_17]: ["M", "D", "Y"],
  [AUTO_REGEX_18]: ["D", "M", "Y"],
  [AUTO_REGEX_19]: ["Y", "M"],
  [AUTO_REGEX_20]: ["M", "Y"],
  [AUTO_REGEX_21]: ["Y"]
};

const findDate = (text, regex, datePattern, components, century) => {
  let matches = text.match(new RegExp(regex, "mi"));
  if (matches && matches[0]) {
    let dateString = matches[0];
    if (!datePattern && components) {
      let hasYear = false;
      let year = "";
      let yearPattern = "";
      let hasMonth = false;
      let month = "";
      let monthPattern = "";
      let hasDay = false;
      let day = "";
      let dayPattern = "";
      for (let i = 0; i < 3; i++) {
        let component = components[i];
        if (component === "Y") {
          hasYear = true;
          year = matches[i + 1];
          yearPattern = component.repeat(year.length);
        } else if (component === "M") {
          hasMonth = true;
          month = matches[i + 1];
          monthPattern = component.repeat(month.length);
        } else if (component === "D") {
          hasDay = true;
          day = matches[i + 1];
          dayPattern = component.repeat(day.length);
        }
      }
      dateString = year + ((hasYear && (hasMonth || hasDay)) ? "/" : "") + month
        + ((hasMonth && hasDay) ? "/" : "") + day;
      datePattern = yearPattern + ((hasYear && (hasMonth || hasDay)) ? "/" : "") + monthPattern
        + ((hasMonth && hasDay) ? "/" : "") + dayPattern;
    }
    // Moment.js will automatically guess at the century with normal 2-digit year patterns
    if (century) {
      datePattern = datePattern.replace(/(?:^|[^Y])[Y]{2}(?:[^Y]|$)/g, "Y");
    }
    let date = moment(dateString, datePattern);
    if (date && date.isValid()) {
      date = date.toDate();
      if (century && date.getFullYear() < century) {
        date.setFullYear(century + date.getFullYear());
      }
      return date;
    }
  }
  return null;
};

const getDateFromText = (text, dateFormat = null, century = null) => {
  let date = null;
  if (dateFormat !== null) {
    // Moment.js handles d/D and y/Y differently than Java
    dateFormat = _.escapeRegExp(dateFormat.replace(/y/g, "Y").replace(/d/g, "D"));
    let regex = getRegexFromDateFormat(_.escapeRegExp(dateFormat));
    date = findDate(text, regex, dateFormat, null, century);
  } else {
    for (let regex in AUTO_REGEX_MAP) {
      let formatComponents = AUTO_REGEX_MAP[regex];
      date = findDate(text, regex, null, formatComponents, century);
      if (date) break;
    }
  }
  return date;
};

const getRegexFromDateFormat = (dateFormat) => {
  return dateFormat.replace(/YYYY/gi, "(" + YYYY_REGEX + ")").replace(/YY/gi, "(" + YY_REGEX + ")")
    .replace(/Y/gi, "(" + Y_REGEX + ")").replace(/DD/gi, "(" + DD_REGEX + ")").replace(/D/gi, "(" + D_REGEX + ")")
    .replace(/ww/g, "[0-9]{2}").replace(/w/g, "[0-9]{1,2}").replace(/W/g, "[1-5]").replace(/u/g, "[1-7]")
    .replace(/HH/g, "[0-9]{2}").replace(/H/g, "[0-9]{1,2}")
    .replace(/KK/g, "[0-9]{2}").replace(/K/g, "[0-9]{1,2}")
    .replace(/hh/g, "[0-9]{2}").replace(/h/g, "[0-9]{1,2}")
    .replace(/kk/g, "[0-9]{2}").replace(/k/g, "[0-9]{1,2}")
    .replace(/mm/g, "[0-9]{2}").replace(/m/g, "[0-9]{1,2}")
    .replace(/ss/g, "[0-9]{2}").replace(/s/g, "[0-9]{1,2}")
    .replace(/SSS/g, "[0-9]{3}").replace(/S/g, "[0-9]{1,3}")
    .replace(/aaa/g, "((?:am)|(?:pm))").replace(/aa/g, "((?:am)|(?:pm))").replace(/a/g, "((?:am)|(?:pm))")
    .replace(/MMMM/g, "(" + MMMM_REGEX + ")").replace(/MMM/g, "(" + MMM_REGEX + ")")
    .replace(/MM/g, "(" + MM_REGEX + ")").replace(/M/g, "(" + M_REGEX + ")")
    .replace(/EEEE/g, "((?:monday)|(?:tuesday)|(?:wednesday)|(?:thursday)|(?:friday)|(?:saturday)|(?:sunday))")
    .replace(/EEE/g, "((?:mon)|(?:tue)|(?:wed)|(?:thu)|(?:fri)|(?:sat)|(?:sun))");
};

export { getDateFromText, getRegexFromDateFormat, CURRENT_DATE, CURRENT_CENTURY, YYYY_REGEX, YY_REGEX, Y_REGEX,
  MMMM_REGEX, MMM_REGEX, MM_REGEX, M_REGEX, MM_M_REGEX, DD_REGEX, D_REGEX, DD_D_REGEX };