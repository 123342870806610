import * as PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import If from "../../common/containers/If";
import PubDocCategoryEditDialogContainer from "../containers/PubDocCategoryEditDialogContainer";
import PubDocEditDialogContainer from "../containers/PubDocEditDialogContainer";
import PubDocMoveDialogContainer from "../containers/PubDocMoveDialogContainer";
import PubDocBulkUploaderDialogContainer from "../containers/PubDocBulkUploaderDialogContainer";
import {Dialog} from "../../common/components";
import PubDocsDownloadErrorDialogContainer from "../containers/PubDocsDownloadErrorDialogContainer";
import PubDocsBulkDeleteDialogContainer from "../containers/PubDocsBulkDeleteDialogContainer";

class PubDocDialogs extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    typeEditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypeTitle: PropTypes.string,
    hasPubDocTypeDeletePermission: PropTypes.bool.isRequired,
    hasBulkUploadPermission: PropTypes.bool.isRequired,
    typeDeleteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    typeDeleteOpen: PropTypes.bool.isRequired,
    categoryDeleteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categoryDeleteOpen: PropTypes.bool.isRequired,
    categoryDeleteErrorOpen: PropTypes.bool.isRequired,
    setCategoryEditOpen: PropTypes.func.isRequired,
    setDocumentEditOpen: PropTypes.func.isRequired,
    setDocumentMoveOpen: PropTypes.func.isRequired,
    setCategoryDeleteOpen: PropTypes.func.isRequired,
    setCategoryDeleteErrorOpen: PropTypes.func.isRequired,
    categories: PropTypes.array,
    typeSelectDisabled: PropTypes.bool
  };

  state = {
    documentToolbarMenuOpen: false,
    bulkUploaderOpen: false
  };

  closeEditCategoryDialog = () => {
    this.props.setCategoryEditOpen(false);
  };

  closeEditDocumentDialog = () => {
    this.props.setDocumentEditOpen(false, null, null);
  };

  handleCloseMove = () => {
    this.setState({documentToolbarMenuOpen: false});
    this.props.setDocumentMoveOpen(false, null);
  };

  openBulkUploaderDialog = () => {
    this.setState({bulkUploaderOpen: true});
  };

  closeBulkUploaderDialog = () => {
    this.setState({bulkUploaderOpen: false});
  };

  closeTypeDeleteDialog = () => {
    const { setTypeDeleteOpen, typeDeleteId } = this.props;
    setTypeDeleteOpen(false, typeDeleteId);
  };

  submitTypeDelete = () => {
    const { deleteType, custId, typeDeleteId } = this.props;
    deleteType(custId, typeDeleteId)
  };

  closeCategoryDeleteDialog = () => {
    const { setCategoryDeleteOpen, categoryDeleteId } = this.props;
    setCategoryDeleteOpen(false, categoryDeleteId);
  };

  submitCategoryDelete = () => {
    const { deleteCategory, custId, categoryDeleteId } = this.props;
    deleteCategory(custId, categoryDeleteId)
  };

  closeCategoryDeleteErrorDialog = () => {
    const { setCategoryDeleteErrorOpen } = this.props;
    setCategoryDeleteErrorOpen(false);
  };

  render() {
    const { bulkUploaderOpen } = this.state;
    const { custId, typeEditId, pubDocTypeId, hasPubDocTypeDeletePermission, hasBulkUploadPermission,
      categoryDeleteId, categoryDeleteOpen, categoryDeleteErrorOpen, categories, typeSelectDisabled,
      typeDeleteOpen
    } = this.props;
    let deleteCategoryName = null;
    if (categories != null && categoryDeleteId != null) {
      const deleteCategory = categories.find(category => category.id === categoryDeleteId);
      if (deleteCategory != null) {
        deleteCategoryName = deleteCategory.title;
      }
    }
    const typeId = typeSelectDisabled ? pubDocTypeId : typeEditId;
    return (
      <Fragment>
        <PubDocCategoryEditDialogContainer
          onCancel={this.closeEditCategoryDialog}
          custId={custId}
          typeId={typeId}
          typeSelectDisabled={typeSelectDisabled}
        />
        <PubDocEditDialogContainer
          onCancel={this.closeEditDocumentDialog}
          custId={custId}
          typeId={typeId}
          setBulkUploaderOpen={this.openBulkUploaderDialog}
          typeSelectDisabled={typeSelectDisabled}
        />
        <PubDocMoveDialogContainer onCancel={this.handleCloseMove} />
        <If test={hasBulkUploadPermission}>
          <PubDocBulkUploaderDialogContainer
            typeId={typeId}
            open={bulkUploaderOpen}
            onCancel={this.closeBulkUploaderDialog}
            typeSelectDisabled={typeSelectDisabled}
          />
        </If>
        <If test={hasPubDocTypeDeletePermission}>
          <Dialog
            title="Delete Type?"
            open={Boolean(typeDeleteOpen)}
            onCancel={this.closeTypeDeleteDialog}
            onSubmit={this.submitTypeDelete}
          >
            Are you sure you want to delete this type?
          </Dialog>
        </If>
        <Dialog
          title="Delete Category?"
          open={Boolean(categoryDeleteOpen)}
          onCancel={this.closeCategoryDeleteDialog}
          onSubmit={this.submitCategoryDelete}
        >
          Are you sure you want to delete {deleteCategoryName ? ("category : " + deleteCategoryName) : "this category?"}
        </Dialog>
        <Dialog
          title="Error deleting category"
          open={Boolean(categoryDeleteErrorOpen)}
          onClose={this.closeCategoryDeleteErrorDialog}
        >
          Category cannot be deleted while it still has documents associated
        </Dialog>
        <PubDocsDownloadErrorDialogContainer/>
        <PubDocsBulkDeleteDialogContainer/>
      </Fragment>
    );
  }
}
export default PubDocDialogs;
