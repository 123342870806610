/*
 * Copyright 2018 General Code
 */

import React from 'react';
import * as PropTypes from 'prop-types';
import './Options.css';
import If from "../../common/containers/If";
import AdditionalOptionContentContainer from "../containers/AdditionalOptionContentContainer";

class RequiredOption extends React.Component {

  static propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    option: PropTypes.object.isRequired
  };

  render() {
    const {questionId, option} = this.props;
    const topLevel = !option.parentOptionId;
    return (
      <div id={"option-" + option.id} className={"required questionOption" + (option.error ? " error" : "") + (topLevel && option.selected ? " selected" : "")}>
        <If test={option.error}>
          <div className="questionError">{option.error}</div>
        </If>
        <label className="questionOptionLabel" htmlFor={"option_field_" + option.id} dangerouslySetInnerHTML={{__html: option.prompt}}/>
        <AdditionalOptionContentContainer questionId={questionId} option={option} />
      </div>
    );
  }
}
export default RequiredOption;