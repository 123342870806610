import React, {Fragment} from "react";
import * as PropTypes from "prop-types";
import {Dialog, TextField, Typography} from "../../common/components";
import {Key} from "../../common/components/icons";

class SetPasswordDialog extends React.Component {
  static propTypes = {
    userName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    onOkClick: PropTypes.func.isRequired,
  };

  state = {
    dialogOpen: false,
    newPassword: '',
  };

  handleDialogOpen = () => {
    this.setState({dialogOpen: true});
  };

  handleDialogClose = () => {
    this.setState({dialogOpen: false, newPassword: ''});
  };

  handleNewPasswordChange = (event) => {
    this.setState({newPassword: event.target.value});
  };

  render() {
    return (
      <Fragment>
        <span className="userSetPassword userIcon iconButton clickable" role="button" title="Assign new password"
              onClick={this.handleDialogOpen}>
          <Key/>
        </span>
        <Dialog
          title={`New Password [${this.props.userName}]`}
          open={this.state.dialogOpen}
          onCancel={this.handleDialogClose}
          onSubmit={() => this.props.onOkClick(this)}
          submitDisabled={this.state.newPassword.length < 6}
          maxWidth="md"
        >
          <Typography>Passwords must be at least 6 characters long.</Typography>
          <TextField
            label="New Password"
            value={this.state.newPassword}
            onChange={this.handleNewPasswordChange}
            margin="normal"
            type="password"
            autoFocus={true}
          />
        </Dialog>
      </Fragment>
    );
  }
}

export default SetPasswordDialog;
