import React from "react";

import {default as SketchPicker} from "react-color/lib/Sketch";

import "./ColorPicker.css";

class ColorPicker extends React.Component {

  handleClick = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker});
  };
  handleClose = () => {
    this.setState({displayColorPicker: false});
  };
  handleChange = (color) => {
    if (this.props.onColorSelect) {
      this.props.onColorSelect(this.props.id, color.hex);
    }
    if (this.props.onChange) {
      this.props.onChange('color', color.hex);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }

  render() {
    return (
      <div className="colorPicker">
        <div className="swatch" onClick={this.handleClick}>
          <div className="color" style={{backgroundColor: this.props.color}}/>
        </div>
        {this.state.displayColorPicker &&
        <div className="popover">
          <div className="cover" onClick={this.handleClose}/>
          <SketchPicker color={this.props.color} onChange={this.handleChange} disableAlpha={true}/>
        </div>
        }
      </div>
    );
  }
}

export default ColorPicker;
