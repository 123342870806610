import {connect} from "react-redux";
import {compose} from "recompose";
import {createStructuredSelector} from "reselect";
import {toJS} from "../../common/utils";
import {getWords} from "../selectors";
import IndexPage from "../components/IndexPage";

const mapStateToProps = createStructuredSelector({
  words: getWords
});

const mapDispatchToProps = () => ({});

const IndexPageContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(IndexPage);
export default IndexPageContainer;