import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Checkbox, IconButton, Menu, MenuItem} from "../../common/components";
import {If} from "../../common/containers";
import {VisibilityOff as VisibilityOffIcon, MenuOptionsVert as MenuOptionsVertIcon } from "../../common/components/icons";

import "./PubDocCategory.css";

class PubDocCategoryLabel extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    hasPubDocCategoryAdminPermission: PropTypes.bool.isRequired,
    hasPubDocPrivateViewPermission: PropTypes.bool.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    selectedDocIds: PropTypes.array.isRequired,
    setCategoryDeleteOpen: PropTypes.func.isRequired,
    setCategoryDeleteErrorOpen: PropTypes.func.isRequired,
    setCategoryEditOpen: PropTypes.func.isRequired,
    setDocumentEditOpen: PropTypes.func.isRequired,
    setDocumentChecked: PropTypes.func.isRequired,
    setDocumentUnchecked: PropTypes.func.isRequired
  }

  state = {
    currentCategoryId: null,
    anchorElCategory: null
  }

  onCategoryClose = () => {
    this.setState({anchorElCategory: null});
  }

  openEditCategoryMenu = (categoryEditId) => (e) => {
    this.setState({
      currentCategoryId: categoryEditId,
      anchorElCategory: e.currentTarget
    });
  }

  openEditCategoryDialog = () => {
    this.setState({anchorElCategory: null});
    this.props.setCategoryEditOpen(true, this.state.currentCategoryId);
  }

  handleOpenDeleteCategory = () => {
    this.setState({anchorElCategory: null});
    const {documents} = this.props;
    if (documents.length === 0) {
      this.props.setCategoryDeleteOpen(true);
    } else {
      this.props.setCategoryDeleteErrorOpen(true);
    }
  }

  handleCategoryCheckboxToggle = (e) => {
    const {documents, selectedDocIds, setDocumentChecked, setDocumentUnchecked} = this.props;
    const check = e.target.checked;
    for (const document of documents) {
      const isChecked = selectedDocIds.includes(document.id);
      if (check && !isChecked) {
        setDocumentChecked(document.id);
      } else if (!check && isChecked) {
        setDocumentUnchecked(document.id);
      }
    }
  }

  openEditDocumentDialog = (categoryId) => () => {
    this.setState({anchorElCategory: null});
    this.props.setDocumentEditOpen(true, null, categoryId);
  }

  render() {
    const {category, hasPubDocCategoryAdminPermission, hasPubDocPrivateViewPermission, hasDocumentAdminPermission, selectedDocIds, documents} = this.props;
    const {anchorElCategory} = this.state;
    const categoryChecked = !hasDocumentAdminPermission || documents.every(d => selectedDocIds.includes(d.id));
    return (
      <div className={"pubDocCategoryContainer"}>
        <div className="topCategoryPaneRow">
          <div className="categoryTitleContainer">
            <span id={"category-label-" + category.id} className="mediumTitle customerTitleColor pubDocCategoryTitle">
              <If test={hasDocumentAdminPermission && documents.length > 0}>
                <Checkbox className={"pubDocCheckbox"} onChange={this.handleCategoryCheckboxToggle} name={String(category.id)} checked={categoryChecked}/>
              </If>
              {category.title}
            </span>
            <If test={category.private && hasPubDocPrivateViewPermission}>
              <VisibilityOffIcon className={"pubDocCategoryVisiblityIcon"} titleAccess={"private"}/>
            </If>
          </div>
          <If test={hasPubDocCategoryAdminPermission}>
            <IconButton className={"pubDocCategoryEditButton"} onClick={this.openEditCategoryMenu(category.id)}>
              <MenuOptionsVertIcon/>
            </IconButton>
          </If>
          <Menu
            disableAutoFocusItem
            anchorEl={anchorElCategory}
            className="pubDocTypeMenu"
            open={Boolean(anchorElCategory)}
            onClose={this.onCategoryClose}
            MenuListProps={{
              'aria-labelledby': 'pubDocMenuOpen',
            }}
          >
            <MenuItem onClick={this.openEditCategoryDialog}>
              Edit Category
            </MenuItem>
            <MenuItem onClick={this.handleOpenDeleteCategory}>
              Delete Category
            </MenuItem>
            <MenuItem onClick={this.openEditDocumentDialog(category.id)}>
              Add Document
            </MenuItem>
          </Menu>
        </div>
        <div className={"pubDocCategoryDescriptionContainer"}>
          <span className="pubDocCategoryDescription">{category.description}</span>
        </div>
      </div>
    );
  }
}
export default PubDocCategoryLabel;