import * as PropTypes from "prop-types";
import React, {Component} from "react";
import {CircularProgress} from "../../common/components";
import If from "../../common/containers/If";
import PubDocCategoryContainer from "../containers/PubDocCategoryContainer";
import PubDocDialogsContainer from "../containers/PubDocDialogsContainer";
import PubDocToolbarContainer from "../containers/PubDocToolbarContainer";
import PubDocsPageTitleContainer from "../containers/PubDocsPageTitleContainer";
import "./PubDocType.css";

class PubDocType extends Component {

  static propTypes = {
    custId: PropTypes.string.isRequired,
    pubDocTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pubDocTypeDescription: PropTypes.string,
    documents: PropTypes.array.isRequired,
    documentsLoading: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    hasDocumentAdminPermission: PropTypes.bool.isRequired,
    isNewNav: PropTypes.bool.isRequired,
    loadPubDocs: PropTypes.func.isRequired,
    loadPubDocsCategories: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    this.props.loadPubDocs(this.props.custId, this.props.pubDocTypeId);
    this.props.loadPubDocsCategories(this.props.custId, this.props.pubDocTypeId);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const stickyToolbar = document.getElementsByClassName("appBarSticky");
    const toolbarHeight = stickyToolbar.length > 0 ? stickyToolbar[0].offsetHeight : 0;
    const filteredCategory = this.props.categories.filter(category => {
      if (document.getElementById("category-" + category.id) != null) {
        return document.getElementById("category-" + category.id).getBoundingClientRect().top < toolbarHeight;
      }
      return null;
    });

    const categoryBar = document.getElementsByClassName("gpsCategoryBar");
    if (categoryBar.length > 0) {
      if (filteredCategory.length > 0) {
        categoryBar[0].style.display = "block";
        document.getElementById("gpsCategoryText").textContent = "Current Category: " + filteredCategory[filteredCategory.length - 1].title;
      } else {
        categoryBar[0].style.display = "none";
      }
    }
  }

  shouldShowToolbar = () => {
    const {isNewNav, filterOptions, hasDocumentAdminPermission} = this.props;
    return !isNewNav && (hasDocumentAdminPermission ||
      (filterOptions
        && (((filterOptions.categoryOptions ? filterOptions.categoryOptions.length : 0) > 1)
          || ((filterOptions.yearOptions ? filterOptions.yearOptions.length : 0) > 1)
          || ((filterOptions.monthOptions ? filterOptions.monthOptions.length : 0) > 1)
          || ((filterOptions.dayOptions ? filterOptions.dayOptions.length : 0) > 1))
      )
    );
  }

  render() {
    const { pubDocTypeDescription, documents, documentsLoading, categories
    } = this.props;
    return (
      <div id="contentContainer">
        <PubDocsPageTitleContainer />
        <div id="pageDescriptionContainer">
          <div id="pageDescription">
            <span className="pubDocTypeTitleDescription">{pubDocTypeDescription}</span>
          </div>
        </div>
        <If test={documentsLoading}>
          <div className="spinnerContainer"><CircularProgress size={80}/></div>
        </If>
        <If test={!documentsLoading}>
          <If test={this.shouldShowToolbar()}>
            <PubDocToolbarContainer/>
          </If>
          <div className="pubDocTypeContainer">
            <If test={documents}>
              {categories.map((category) => <PubDocCategoryContainer key={category.id} category={category} />)}
            </If>
          </div>
          <PubDocDialogsContainer typeSelectDisabled={true} />
        </If>
      </div>
    );
  }
}
export default PubDocType;
