import * as PropTypes from "prop-types";
import React, {Component} from "react";
import debounce from "lodash/debounce";

import {Button, ButtonGroup, Checkbox, Dialog, FormControlLabel, PrimaryButton, TextField, Tooltip,
  Tree, VirtualizedSelect} from "../components";
import {Stepper, Step, StepLabel, StepContent} from "./stepper";
import {getReferenceGroup} from "../ReferenceGroupFactory";
import {If} from "../containers";

import "./SelectReferenceDialog.css";

class SelectReferenceDialog extends Component {

  constructor(props) {
    super(props);
    if (props.fetchNodes && (!props.nodes || props.nodes.length === 0)) {
      props.fetchRootReferenceNodes();
    }
    this.state = {
      activeStep: 0,
      query: "",
      selectedMatch: null,
      reference: {
        text: "",
        url: "",
        targetOption: "_self",
      },
    };
    this.initialState = {...this.state};
  }

  resetDialog = () => {
    this.setState(this.initialState);
    this.props.clearActiveReferenceNode();
    if (this.props.onClose) this.props.onClose();
  };

  onCancel = () => {
    this.resetDialog();
    if (this.props.onCancel) this.props.onCancel();
  };

  onInsert = () => {
    if (this.props.onSubmit) this.props.onSubmit(this.state.reference);
    this.resetDialog();
  };

  onTreeNodeToggle = (node, toggled) => {
    let referenceGroup = getReferenceGroup(this.props.groupName);
    this.setState({selectedMatch: null});
    this.setState({query: ""});
    this.setState({reference: referenceGroup?.getReferenceDetails(node)});
    this.props.toggleReferenceNode(node, toggled);
  };

  executeSearch = debounce((query) => {
    this.setState({query});
    this.props.fetchReferenceMatches(query);
  }, 500);

  onMatchClick = (selectedMatch) => {
    let referenceGroup = getReferenceGroup(this.props.groupName);
    this.setState({selectedMatch});
    this.setState({query: ""});
    this.setState({reference: referenceGroup?.getReferenceDetails({...selectedMatch, title: selectedMatch.titleData})});
    this.props.clearActiveReferenceNode();
  };

  onReferenceTextChange = (event) => {
    this.setState({reference: {...this.state.reference, text: event.target.value}});
  };

  onToggleNewWindow = (event) => {
    this.setState({reference: {...this.state.reference, targetOption: event.target.checked ? "_blank" : "_self"}});
  };

  onNextStep = () => {
    this.setState({activeStep: 1});
  };

  onPreviousStep = () => {
    this.setState({activeStep: 0});
  };

  toOption = (match) => {
    let referenceGroup = getReferenceGroup(this.props.groupName);
    return {...match,
      label:
        <Tooltip title={referenceGroup?.getMatchOptionTitle(match)}>
        <span>
          <span className="addReferenceIdentifier">{match.context ? `${match.context}: `: ""}</span>
          <span className="addReferenceTitle">{match.title}</span>
        </span>
        </Tooltip>,
      value: `${match.type}:${match.key}`,
      titleData: match.title,
      title: "",
    };
  }

  render() {
    const {title, open, groupName, selectedGroupName, nodes, matches, areMatchesLoading,
      showNewWindowOption
    } = this.props;
    const {activeStep, query, selectedMatch, reference} = this.state;
    if (!groupName || !nodes || !matches) return null;
    return (
      <Dialog
        title={title ? title : "Select a reference"}
        classes={{ paper: "addReferenceModalPaper" }}
        open={open || (groupName && groupName === selectedGroupName)}
        onClose={this.onCancel}
        style={{overflow: "visible"}}
        DialogContentProps={{className: "addReferenceModalContents", style: {overflow: "visible"}}}
        DialogActionsProps={{className: "stepActionContainer"}}
        actions={(
          <ButtonGroup>
            <Button onClick={this.onCancel}>Cancel</Button>
            <Button onClick={this.onPreviousStep}>Back</Button>
            <PrimaryButton onClick={this.onInsert}>Insert</PrimaryButton>
          </ButtonGroup>
        )}
      >
        <Stepper activeStep={activeStep} orientation="vertical">

          <Step key="choose">
            <StepLabel>Choose a location in the code</StepLabel>
            <StepContent classes={{transition: activeStep === 0 ? "overflowingStep" : ""}}>
              <div className="addReferenceTreeContainer">
                <Tree
                  data={nodes}
                  onToggle={this.onTreeNodeToggle}
                />
              </div>
              <div className="addReferenceSeparatorText">OR...</div>
              <VirtualizedSelect
                className="addReferenceSelect"
                placeholder="Quick pick..."
                value={selectedMatch}
                isLoading={areMatchesLoading}
                onChange={this.onMatchClick}
                onInputChange={this.executeSearch}
                options={matches.map(this.toOption)}
                openOnFocus={true}
                autoBlur={true}
                maxHeight={200}
                filterOptions={(options) => options}
                noResultsText={query === "" || areMatchesLoading ? false : "No results found"}
              />
              <div className="stepActionContainer">
                <Button onClick={this.onCancel}>Cancel</Button>
                <PrimaryButton onClick={this.onNextStep} disabled={!reference.text}>Next</PrimaryButton>
              </div>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Customize your link text</StepLabel>
            <StepContent>
              <TextField
                label="Reference Text"
                value={reference.text}
                onChange={this.onReferenceTextChange}
                className="addReferenceTextField"
              />
              <If test={showNewWindowOption}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reference.targetOption === "_blank"}
                      onChange={this.onToggleNewWindow}
                    />
                  }
                  className="addReferenceTargetOption"
                  label="Open reference in new window"
                />
              </If>
              <div>
                <div className="addReferenceExampleLabel">Example:</div>
                <a href={reference.url} target={reference.targetOption}><span>{reference.text} </span></a>
              </div>
            </StepContent>
          </Step>

        </Stepper>
      </Dialog>
    );
  }
}

SelectReferenceDialog.propTypes = {
  open: PropTypes.bool,
  groupName: PropTypes.string,
  selectedGroupName: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  fetchNodes: PropTypes.bool,
  fetchReferenceMatches: PropTypes.func.isRequired,
  toggleReferenceNode: PropTypes.func.isRequired,
  clearActiveReferenceNode: PropTypes.func.isRequired,
  nodes: PropTypes.array,
  matches: PropTypes.array,
  areMatchesLoading: PropTypes.bool,
  showNewWindowOption: PropTypes.bool

};

export default SelectReferenceDialog;